/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import cx from 'classnames';

import { Text } from '@base/components';
import hash from 'object-hash';
import { OptionType } from '@base/types';
import styles from './DropDownMenu.module.scss';

export interface DropDownMenuEventType { option: OptionType<any>, index: number }

interface DropDownMenuProps {
  className?: string;
  data?: Array<any>;
  options?: Array<OptionType<any>>,
  inlineBlock: 'right' | 'left'
  align?: 'left' | 'center' | 'right',
	// eslint-disable-next-line no-unused-vars
	onClick?: (obj: DropDownMenuEventType) => void
	children?: React.ReactNode,
	triangle?: boolean,
	style?: {
		[key: string]: string
	},
}
// TODO вынести общую логику из этого компонента и компонента DropdownButton
export const DropDownMenu = ({
	className, data, inlineBlock, align, onClick, children, triangle, style, options,
}: DropDownMenuProps) => (
	<div
		style={style}
		className={cx(
			className,
			styles.drop_down_menu,
			styles[`drop_down_menu__inline-block--${inlineBlock}`],
		)}
	>
		{triangle && <div className={styles[`drop_down_menu__triangle--${inlineBlock}`]} />}

		{(!children && options && onClick) && options.map((option, index) => (
			<div key={hash(index)} onClick={() => onClick({ option, index })}>
				<Text type="h5-1" align={align} color="gray-light" className={styles.drop_down_menu__item}>{option.label}</Text>
				{options.length - 1 !== index && <br />}
			</div>
		))}
		{(children || data) && <>{ children }</>}
	</div>
);

export default null;
