import React from 'react';
import { AccountHistoryContainer } from '@client_broker/containers/AccountHistoryContainer';

type AccountHistoryProps = {
	accountType: 'live' | 'demo'
};

export const AccountHistory = ({ accountType }: AccountHistoryProps) => (
	<AccountHistoryContainer accountType={accountType} />
);
export default null;
