import * as tradesTypes from '@client_broker/store/Trades/constants';
import type { TradesActionsType } from '@client_broker/store/Trades/types';
import { RequestInfoChildType, TradesData } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type TradesState = {
	trades: {
		data: TradesData | undefined;
	} & RequestInfoChildType;
};

const initialState: TradesState = {
	trades: {
		data: undefined,
		...RequestInfoChildState,
	},
};

export const tradesReducer = (
	state: TradesState = initialState,
	action: TradesActionsType,
): TradesState => {
	switch (action.type) {
		case tradesTypes.CLIENT_BROKER_TRADES_REQUEST: {
			return {
				...state,
				trades: {
					...state.trades,
					data: undefined,
					isLoading: true,
				},
			};
		}

		case tradesTypes.CLIENT_BROKER_TRADES_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				trades: {
					...state.trades,
					data: payload,
					isLoading: false,
				},
			};
		}

		case tradesTypes.CLIENT_BROKER_TRADES_FAIL: {
			const { payload } = action;
			return {
				...state,
				trades: {
					...state.trades,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
