import * as userActionsInfoPersistTypes from '@base/store/UserActionsInfoPersist/constants';
import type { UserActionsInfoPersistActionsType } from '@base/store/UserActionsInfoPersist/types';

export type UserActionsInfoPersistStateType = {
	securityPasswordEnteredIncorrectly: {
		count: number,
	}
};

const initialState: UserActionsInfoPersistStateType = {
	securityPasswordEnteredIncorrectly: {
		count: 0,
	},
};

export const userActionsInfoPersistReducer = (
	state: UserActionsInfoPersistStateType = initialState,
	action: UserActionsInfoPersistActionsType,
): UserActionsInfoPersistStateType => {
	switch (action.type) {
		case userActionsInfoPersistTypes.BASE_USER_ACTIONS_SECURITY_PASSWORD_ENTERED_INCORRECTLY: {
			return {
				...state,
				securityPasswordEnteredIncorrectly: {
					...state.securityPasswordEnteredIncorrectly,
					count: state.securityPasswordEnteredIncorrectly.count + 1,
				},
			};
		}

		case userActionsInfoPersistTypes.BASE_USER_ACTIONS_INITIAL_DATA: {
			return initialState;
		}

		default: {
			return state;
		}
	}
};
