import * as authPersistTypes from './constants';

export const authPersistLogout = () => ({
	type: authPersistTypes.AUTH_PERSIST_LOGOUT,
});

//--------------------------------------

export const authPersistLogin = (payload: {
	email: string,
	password: string,
}) => ({
	type: authPersistTypes.AUTH_PERSIST_LOGIN_SEND,
	payload,
});

export const authPersistLoginSuccess = (payload: {
	accessToken: string,
	refreshToken: string,
	isAuth: boolean,
}) => ({
	type: authPersistTypes.AUTH_PERSIST_LOGIN_SUCCESS,
	payload,
});

export const authPersistLoginError = (payload: { error: string }) => ({
	type: authPersistTypes.AUTH_PERSIST_LOGIN_ERROR,
	payload,
});

//--------------------------------------

export const authPersistRefreshToken = () => ({
	type: authPersistTypes.AUTH_PERSIST_REFRESH_TOKEN_SEND,
});

export const authPersistRefreshTokenSuccess = (payload: {
	accessToken: string,
	refreshToken: string,
	isAuth: boolean,
}) => ({
	type: authPersistTypes.AUTH_PERSIST_REFRESH_TOKEN_SUCCESS,
	payload,
});

export const authPersistRefreshTokenError = (payload: { error: string }) => ({
	type: authPersistTypes.AUTH_PERSIST_REFRESH_TOKEN_ERROR,
	payload,
});

//--------------------------------------

export const authPersistUpdateUserDataAction = (payload: {
	userData: {
		isConfirmedEmail?: boolean,
		isConfirmedPhone?: boolean,
		email?: string,
	},
}) => ({
	type: authPersistTypes.AUTH_PERSIST_UPDATE_USER_DATA,
	payload,
});

export default null;
