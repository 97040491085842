import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import { language_ru } from '@base/translations/ru';
import { language_ru } from '@base/translations/ru_new'; // импорт новых локальных переводов

i18next
	.use(initReactI18next) // bind react-i18next to the instance
	.init({
		fallbackLng: 'ru',
		interpolation: {
			// React already does escaping
			escapeValue: false,
		},
		// lng: 'en', // 'en' | 'es'
		// Using simple hardcoded resources for simple example
		resources: {
			...language_ru,
			// en: {
			// 	translation: {
			// 		home: { label: 'Home' },
			// 	},
			// },
		},
		react: {
			wait: true,
		},
		detection: {
			cache: ['cookie'],
			order: ['queryString', 'cookie'],
		},
	});

// i18next.changeLanguage();

export default i18next;
