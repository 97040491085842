import React from 'react';

import { TabsAdaptive } from '@base/components';
import { Redirect, Route, Switch } from 'react-router-dom';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import { getTabPaths } from '@base/utils/getTabPaths';
import { MetaTrader4 } from '@client_broker/containers/downloadTerminals/MetaTrader4';
import styles from './DownloadTerminals.module.scss';

type DownloadTerminalsProps = {};

const paths = getTabPaths(clientBrokerRoutes['download-terminals']);

// eslint-disable-next-line no-empty-pattern
export const DownloadTerminals = ({}: DownloadTerminalsProps) => (
	// const dispatch = useDispatch();
	// const { isLoading, error } = useSelector((state: BaseState) => state.data);
	<div className={styles.download_terminals}>
		<div className={styles.download_terminals__tabs}>
			<TabsAdaptive paths={paths} />
		</div>
		<div className={styles.download_terminals__content}>
			<Switch>
				<Route path={clientBrokerRoutes['download-terminals']['meta-trader-4'].root} component={MetaTrader4} />
				<Redirect to={clientBrokerRoutes['download-terminals']['meta-trader-4'].root} />
			</Switch>
		</div>
	</div>
);

export default null;
