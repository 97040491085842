import React from 'react';
import { Text } from '@base/components';
import { useSelector } from 'react-redux';
import { BaseState } from '@base/types';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import styles from './InfoModal.module.scss';

type AuthInfoModalProps = {};

// eslint-disable-next-line no-empty-pattern
export const InfoModal = ({}: AuthInfoModalProps) => {
	const { t } = useTranslation();
	const { pageInfo: { tKeyRoot } } = useSelector(({ base }: BaseState) => base);

	return (
		<div className={styles.info_modal}>
			<Text type="h2">
				{t(`${tKeyRoot}.TITLE`)}
			</Text>
			{/* <LabelForm */}
			{/*	className={styles.label} */}
			{/*	text={t(labelTKey)} */}
			{/* /> */}
			<div className={styles.content_wrap}>
				<Text type="h4">
					<ReactMarkdown>
						{t(`${tKeyRoot}.INFO`)}
					</ReactMarkdown>
				</Text>
			</div>
		</div>
	);
};
export default null;
