/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '@base/components';
import { DropDownMenu } from '@base/components/DropDownMenu';
import cx from 'classnames';
import hash from 'object-hash';
import { selectLanguageRequestAction } from '@base/store/TranslationsPersist/actions';
import { BaseState } from '@base/types';
import { useTranslation } from 'react-i18next';
import styles from './LanguagePanel.module.scss';

interface OwnProps {
}

type LanguagePanelProps = {
	// eslint-disable-next-line react/no-unused-prop-types
	moduleType?: 'auth' | 'client'
	className?: string,
} & OwnProps;

type ItemMenu = { label: string, value: { tag: string, flag: any } };

const itemsMenuDefault: any = {
	ru: { label: 'РУС', value: { tag: 'ru', flag: <>🇷🇺</> } },
	en: { label: 'ENG', value: { tag: 'en', flag: <>🇬🇧</> } },
	id: { label: 'IND', value: { tag: 'id', flag: <>🇮🇩</> } },
	tr: { label: 'TUR', value: { tag: 'tr', flag: <>🇹🇷</> } },
	es: { label: 'ESP', value: { tag: 'es', flag: <>🇪🇸</> } },
	// fr: { label: 'FRA', value: { tag: 'fr', flag: <>🇫🇷</> } },
	// az: { label: 'AZE', value: { tag: 'az', flag: <>🇦🇿</> } },
};

// eslint-disable-next-line no-empty-pattern
export const LanguagePanel = ({
	moduleType,
	className,
}: LanguagePanelProps) => {
	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { selectedLanguage, languageNames } = useSelector((
		state: BaseState,
	) => state.base.translationsPersist);

	const [showDropDownMenu, setShowDropDownMenu] = useState(false);

	const [selectedItemMenu, setSelectedItemMenu] = useState<ItemMenu | undefined>(undefined);
	const [itemsMenu, setItemsMenu] = useState<Array<ItemMenu> | undefined>(undefined);

	useEffect(() => {
		if (!selectedLanguage
			|| !languageNames
			|| !selectedLanguage.data
		) return;
		const itemsMenuNew = languageNames.map((
			value,
		) => itemsMenuDefault[value.tag]);

		setItemsMenu(itemsMenuNew.filter((value) => value));

		if (selectedLanguage.data.tag === 'keys') {
			setSelectedItemMenu(itemsMenuDefault.en);
		} else {
			setSelectedItemMenu(itemsMenuDefault[selectedLanguage.data.tag]);
		}
	}, [selectedLanguage, languageNames, t]);

	const onClick = (item: ItemMenu) => {
		setSelectedItemMenu(item);
		dispatch(selectLanguageRequestAction({
			label: item.label, tag: item.value.tag,
		}));
	};

	const colorLabel = useMemo(() => {
		switch (moduleType) {
			case 'auth':
				return 'black';
			case 'client':
				return 'gray-light';
			default:
				return 'gray-light';
		}
	}, [moduleType]);

	return (
		<>
			{
				(selectedItemMenu && itemsMenu) && (
					<div
						// @ts-ignore
						tabIndex="0"
						role="button"
						className={cx(
							className,
							styles.language_panel,
						)}
						onClick={() => setShowDropDownMenu(!showDropDownMenu)}
						onBlur={() => setShowDropDownMenu(false)}
					>
						<div className={styles.language_panel__item}>
							<div className={styles.symbol}>{selectedItemMenu.value.flag}</div>
							{' '}
							<Text
								className={styles.language_panel__item__text}
								type="h4-extra"
								color={colorLabel}
							>
								{selectedItemMenu.label}
							</Text>
							<div className={cx(
								styles.language_panel__triangle,
								{ [styles['language_panel__triangle--active']]: showDropDownMenu },
							)}
							/>
						</div>
						{showDropDownMenu
				&& (
					<DropDownMenu
						inlineBlock="right"
						triangle
					>
						{itemsMenu.map((item, index) => (
							<div key={hash(index)}>
								{item && item.value.tag !== selectedItemMenu.value.tag && (
									<>
										{/* eslint-disable-next-line max-len */}
										{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
										<div
											key={hash(index)}
											onClick={() => onClick(item)}
											className={styles.language_panel__item}
										>
											<div className={styles.symbol}>{item.value.flag}</div>
											{' '}
											<Text className={styles.language_panel__item__text} type="h4" color="gray-light">
												{item.label}
											</Text>
										</div>
										{(itemsMenu.length - 1 !== index && itemsMenu.length > 2) && <br />}
									</>
								)}
							</div>
						))}
					</DropDownMenu>
				)}
					</div>
				)
			}
		</>
	);
};

export default null;
