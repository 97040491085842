import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
	Button,
	LoaderSpinner,
	Input,
	ButtonLink,
	Text,
} from '@base/components';
import { object, string } from 'yup';
import authRoutes from '@auth/constants/authRoutes';
import { isValidPhoneNumber, formatIncompletePhoneNumber } from 'libphonenumber-js';
import { AuthState } from '@auth/types';
import {
	verificationPhoneMakeCallAction,
} from '@auth/store/VerificationPhone/actions';
import { authPersistLogout } from '@base/store/AuthPersist/actions';
import { useTranslation } from 'react-i18next';
import { getInfoInput } from '@base/utils/inputFunctions';
import ReactMarkdown from 'react-markdown';
import { ClientBrokerState } from '@client_broker/types';
import formats from '@base/utils/formats';
// import { systemAction } from '@base/store/System/actions';
import styles from './VerificationPhoneSendForm.module.scss';

type VerificationPhoneSendFormProps = {};

interface VerificationPhoneSendFormFieldsType {
	phoneNumber: string,
}

export const initialValues = {
	phoneNumber: '',
};

const secondDefault = 299;
// const secondDefault = 3;

// eslint-disable-next-line no-empty-pattern
export const VerificationPhoneSendForm = ({}: VerificationPhoneSendFormProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { phoneMakeCall, verifyPhone, callAttempt } = useSelector((
		state: AuthState,
	) => state.auth.verificationPhone);

	const { customer } = useSelector((
		state: ClientBrokerState,
	) => state.clientBroker);

	const [seconds, setSeconds] = useState(secondDefault);

	const [isEditingPhone, setIsEditingPhone] = useState(true);

	const validationSchema = object().shape({
		phoneNumber: string()
			.test('phoneNumber', t('AUTH.REGISTRATION.VERIFICATION_PHONE.PHONE_NUMBER_ERROR_VALIDATION'),
				(value) => ((value === '+' || value === undefined)
					? true : isValidPhoneNumber(`${value}`)))
			.required(t('OTHER.FIELD_IS_REQUIRED')),
	});

	const {
		handleChange,
		handleBlur,
		values,
		setValues,
		errors,
		touched,
		setTouched,
		// isValid,
	} = useFormik<VerificationPhoneSendFormFieldsType>({
		initialValues,
		onSubmit: () => {},
		validationSchema,
		validateOnMount: true,
	});

	// useEffect(() => {
	// 	dispatch(systemAction());
	// }, []);

	useEffect(() => {
		if (callAttempt.data?.phone) {
			setValues({
				...values,
				phoneNumber: formatIncompletePhoneNumber(
					`+${formats.formatPhoneNumber(callAttempt.data.phone)}`,
				),
			});
		}
	}, [callAttempt.data]);

	useEffect(() => {
		if (customer.info.data.phone) {
			setValues({
				...values,
				phoneNumber: customer.info.data.phone,
			});
			setIsEditingPhone(false);
		}
	}, [customer.info.data.phone]);

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	useEffect(() => {
		if (isEditingPhone) {
			setSeconds(secondDefault);
		}
	}, [isEditingPhone]);

	useEffect(() => {
		if (!phoneMakeCall.isLoading
			&& !phoneMakeCall.error
			&& values.phoneNumber !== '+'
			&& values.phoneNumber !== '') {
			setIsEditingPhone(true);
		}
	}, [phoneMakeCall.isLoading]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			let target = { name, value };
			switch (name) {
				case 'phoneNumber':
					target = { name, value: formats.formatPhoneNumber(value) };
					break;
				default:
					break;
			}
			handleChange({ target });
		},
		[
			handleChange,
		],
	);

	const onBlur = () => {
		const phoneNumber = formatIncompletePhoneNumber(values.phoneNumber);
		setValues({
			...values,
			phoneNumber: phoneNumber === '+' ? '' : phoneNumber,
		});
	};

	const onFocus = (event: React.SyntheticEvent<HTMLInputElement>) => {
		const phoneNumber = formatIncompletePhoneNumber(values.phoneNumber);
		setValues({
			...values,
			phoneNumber: phoneNumber === '' ? '+' : phoneNumber,
		});

		const element = event.target;
		window.requestAnimationFrame(() => {
			// @ts-ignore
			element.selectionStart = 99;
			// @ts-ignore
			element.selectionEnd = 99;
		});
	};

	const isLoading = useMemo(() => (
		phoneMakeCall.isLoading || verifyPhone.isLoading
	), [phoneMakeCall.isLoading, verifyPhone.isLoading]);

	return (
		<div className={styles.verification_phone_send_form}>
			{isLoading && <LoaderSpinner />}
			<>
				<Text type="h4">
					<ReactMarkdown>
						{t('AUTH.REGISTRATION.VERIFICATION_PHONE.DESCRIPTION_SEND')}
					</ReactMarkdown>
				</Text>
				<Input
					className={styles.block}
					label={t('AUTH.REGISTRATION.VERIFICATION_PHONE.PHONE_NUMBER_LABEL')}
					placeholder={t('AUTH.REGISTRATION.VERIFICATION_PHONE.PHONE_NUMBER_PLACEHOLDER')}
					name="phoneNumber"
					required
					value={values.phoneNumber}
					onChange={onChangeHandler}
					onBlur={(event) => {
						handleBlur(event);
						onBlur();
						setTouched({
							...touched,
							phoneNumber: false,
						});
					}}
					onFocus={(event) => {
						handleBlur(event);
						onFocus(event);
					}}
					info={getInfoInput({
						name: 'phoneNumber',
						// text: t('AUTH.REGISTRATION.VERIFICATION_PHONE.PHONE_NUMBER_HINT'),
						text: '', // нет подсказки
						touched,
						errors,
						values,
					})}
					disabled={isLoading}
				/>
				<Button
					size="large"
					type="button"
					theme="primary"
					onClick={() => {
						setSeconds(secondDefault);
						dispatch(
							verificationPhoneMakeCallAction({
								phone: values.phoneNumber.replace(/\s/g, ''),
							}),
						);
					}}
					disabled={
						errors.phoneNumber !== undefined
								|| isLoading
								|| (!isEditingPhone && seconds !== 0)
								|| !isValidPhoneNumber(values.phoneNumber)
					}
					className={styles.button}
				>
					{t('AUTH.REGISTRATION.VERIFICATION_PHONE.REQUEST_CALL_BUTTON')}
				</Button>
			</>
			<ButtonLink
				to={authRoutes.auth.root}
				type="submit"
				theme="secondary"
				size="large"
				className={styles.button}
				onClick={() => dispatch(authPersistLogout())}
			>
				{t('OTHER.CANCEL_BUTTON')}
			</ButtonLink>
		</div>
	);
};

export default null;
