import { call, put, takeLeading } from 'redux-saga/effects';

import * as transferHistoryTypes from '@client_broker/store/TransferHistory/constants';
import { TransferHistoryActionType } from '@client_broker/store/TransferHistory/types';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { PostTransferHistoryResp } from '@base/types';
import { transferHistoryFailAction, transferHistorySuccessAction } from '@client_broker/store/TransferHistory/actions';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';

function* transferHistorySaga({ payload }: TransferHistoryActionType) {
	try {
		const body = {
			...payload,
		};
		const resp: PostTransferHistoryResp = yield call(apiRequestClientBroker, 'postTransferHistory', { body });
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(transferHistorySuccessAction({ data: resp.data }));
	} catch (e) {
		console.log(e);
		yield put(transferHistoryFailAction({ error: e.message }));
	}
}

export function* transferHistoryWatcher() {
	yield takeLeading(
		transferHistoryTypes.CLIENT_BROKER_TRANSFER_HISTORY_REQUEST,
		transferHistorySaga,
	);
}
