import {
	call, put, takeLeading, all,
} from 'redux-saga/effects';

import {
	documentFilesSuccessAction,
	documentFilesFailAction,
	sendDocumentFilesSuccessAction,
	documentFilesAction,
	sendDocumentFilesFailAction,
} from '@client_broker/store/DocumentFiles/actions';
import {
	DOCUMENT_FILES_REQUEST,
	SEND_DOCUMENT_FILES_REQUEST,
} from '@client_broker/store/DocumentFiles/constants';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { SendDocumentFilesActionType } from '@client_broker/store/DocumentFiles/types';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';
import fileStatuses from '@base/constants/fileStatuses';
import fileTypes from '@base/constants/fileTypes';
import { VerificationStatusType } from '@client_broker/store/DocumentFiles/reducer';
import { modalCloseAction } from '@base/store/Modal/actions';
import { GetDocumentFilesResp, OthersType } from '@base/types';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';

function* documentFilesSaga() {
	const verificationStatus: VerificationStatusType = {
		address: fileStatuses.NOT_VERIFIED,
		identity: fileStatuses.NOT_VERIFIED,
	};

	try {
		const resp: GetDocumentFilesResp = yield call(apiRequestClientBroker, 'getDocumentFiles');
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		resp.data.forEach((value, index) => {
			if (!value.others_type) return;
			const others_type: OthersType = JSON.parse(`${value.others_type}`.replace(/'/g, '"'));
			switch (value.file_type) {
				case fileTypes.PROOF_OF_RESIDENCE:
					verificationStatus.address = others_type.status;
					// verificationStatus.address = fileStatuses.NOT_VERIFIED;
					// verificationStatus.address = fileStatuses.REJECTED;
					break;
				case fileTypes.PROOF_OF_IDENTITY:
					verificationStatus.identity = others_type.status;
					// verificationStatus.identity = fileStatuses.NOT_VERIFIED;
					// verificationStatus.identity = fileStatuses.REJECTED;
					break;
				default: break;
			}
			resp.data[index].others_type = others_type;
		});
		yield put(documentFilesSuccessAction({
			data: resp.data,
			verificationStatus,
		}));
	} catch (e) {
		console.log(e);
		yield put(documentFilesFailAction({ error: e.message }));
	}
}

function* sendDocumentFilesSaga({ payload }: SendDocumentFilesActionType) {
	try {
		const {
			files, file_type, others_type, is_identity,
		} = payload;
		// @ts-ignore
		const resp: any = yield all(
			yield files.map((value) => call(apiRequestClientBroker, 'putDocumentFiles', {
				req: {
					file: value,
					file_type,
					others_type,
					is_identity,
				},
			})),
		);
		if (!resp[0].success) {
			yield put(errorHandlerAction({
				error: resp[0].error,
				status: resp[0].status,
			}));
			throw new Error(resp.error);
		}
		yield put(sendDocumentFilesSuccessAction());
		yield put(documentFilesAction());
		yield put(modalCloseAction());
		yield put(notificationsModalOpenAction({
			messageTKey: 'CLIENT.DOCUMENTS.NOTIFICATION_UPLOADED_SUCCESSFULLY',
			type: 'success',
		}));
	} catch (e) {
		console.log(e);
		yield put(sendDocumentFilesFailAction({ error: e.message }));
	}
}

export function* documentFilesWatcher() {
	yield takeLeading(DOCUMENT_FILES_REQUEST, documentFilesSaga);
	yield takeLeading(SEND_DOCUMENT_FILES_REQUEST, sendDocumentFilesSaga);
}
