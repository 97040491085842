/* eslint-disable no-underscore-dangle */
import {
	put, takeLatest, call, select,
} from 'redux-saga/effects';
import {
	push,
} from 'connected-react-router';

import type {
	GetTokenResp, RefreshTokenResp,
} from '@base/types';
import {
	apiRequestClientBroker,
} from '@base/providers/sagas';
import { authPersistLoginActionType } from '@base/store/AuthPersist/types';
import {
	authPersistRefreshTokenError,
	authPersistRefreshTokenSuccess,
	authPersistLoginError,
	authPersistLoginSuccess,
	authPersistLogout,
	authPersistUpdateUserDataAction,
} from '@base/store/AuthPersist/actions';
import { BaseState } from '@base/types';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import { AuthNotificationDescriptionError } from '@base/containers/notificationsComponents';
import authRoutes from '@auth/constants/authRoutes';
import { OnClickParamOfNotificationsModalType } from '@base/store/NotificationsModal/types';
import { userActionsInfoInitialDataAction } from '@base/store/UserActionsInfoPersist/actions';
import * as authPersistTypes from './constants';

export function* authPersistReqLoginSaga({ payload }: authPersistLoginActionType) {
	try {
		const { pageInfo } = yield select(({ base }: BaseState) => base);
		const { email, password } = payload;
		const body = {
			language: 'ru',
			username: email,
			password,
		};
		const resp: GetTokenResp = yield call(
			apiRequestClientBroker,
			'postLogin',
			{
				body,
			},
		);
		if (!resp.success) {
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent: AuthNotificationDescriptionError,
				},
			}));
			throw new Error(resp.error);
		}
		const {
			access_token,
			refresh_token,
			activated,
			phone_confirmed,
		} = resp.data;

		const _authPersistUpdateUserDataAction = () => (
			authPersistUpdateUserDataAction({
				userData: {
					isConfirmedEmail: activated || false,
					isConfirmedPhone: phone_confirmed || false,
					email,
				},
			})
		);
		const _authPersistLoginSuccess = () => (
			authPersistLoginSuccess({
				accessToken: access_token,
				refreshToken: refresh_token,
				isAuth: activated && phone_confirmed,
			})
		);
		if (activated && phone_confirmed) {
			yield put(_authPersistUpdateUserDataAction());
			yield put(_authPersistLoginSuccess());

			return;
		}

		const messageTKey = !activated
			? 'AUTH.LOGIN.NOTIFICATION_EMAIL_CONFIRMED'
			: 'AUTH.LOGIN.NOTIFICATION_PHONE_CONFIRMED';
		yield put(notificationsModalOpenAction({
			labelTKey: pageInfo.labelTKey,
			messageTKey,
			type: 'error',
			onClick: ({ dispatch, history }: OnClickParamOfNotificationsModalType) => {
				dispatch(_authPersistUpdateUserDataAction());
				dispatch(_authPersistLoginSuccess());
				history.push(activated
					? authRoutes.auth.registration['verification-phone']
					: authRoutes.auth.registration['verification-email']);
			},
			onClickClose: ({ dispatch }: OnClickParamOfNotificationsModalType) => {
				dispatch(authPersistLogout());
			},
		}));
	} catch (e) {
		console.log(e);
		yield put(authPersistLoginError({
			error: (e as Error).message,
		}));
	}
}

export function* authPersistRefreshTokenSaga() {
	try {
		const tokenData: {
			accessToken: string, refreshToken: string
		} = yield select((state: BaseState) => state.base.authPersist.token);
		if (!tokenData) {
			yield put(authPersistLogout());
			return;
		}
		const body = {
			access_token: tokenData.accessToken,
			refresh_token: tokenData.refreshToken,
		};
		const resp: RefreshTokenResp = yield call(
			apiRequestClientBroker,
			'postRefreshToken',
			{
				body,
			},
		);
		if (!resp.success) {
			throw new Error(resp.error);
		}
		const {
			access_token,
			refresh_token,
			activated,
			phone_confirmed,
		} = resp.data;
		yield put(authPersistUpdateUserDataAction({
			userData: {
				isConfirmedEmail: activated || false,
				isConfirmedPhone: phone_confirmed || false,
			},
		}));
		yield put(authPersistRefreshTokenSuccess({
			accessToken: access_token,
			refreshToken: refresh_token,
			isAuth: activated && phone_confirmed,
		}));
	} catch (e) {
		console.log(e);
		yield put(authPersistRefreshTokenError({
			error: (e as Error).message,
		}));
		yield put(authPersistLogout());
	}
}

export function* authPersistLogOutSaga() {
	try {
		yield put(push(authRoutes.auth.root));
		yield put(userActionsInfoInitialDataAction());
	} finally {
		// always runs
	}
}

export function* authPersistWatcher() {
	yield takeLatest(authPersistTypes.AUTH_PERSIST_LOGIN_SEND, authPersistReqLoginSaga);
	yield takeLatest(authPersistTypes.AUTH_PERSIST_REFRESH_TOKEN_SEND, authPersistRefreshTokenSaga);
	yield takeLatest(authPersistTypes.AUTH_PERSIST_LOGOUT, authPersistLogOutSaga);
}
