import { call, put, takeLeading } from 'redux-saga/effects';

import {
	transferInternalSuccessAction,
	transferInternalFailAction,
} from '@client_broker/store/TransferInternal/actions';
import { CLIENT_BROKER_TRANSFER_INTERNAL_REQUEST } from '@client_broker/store/TransferInternal/constants';
import { TransferInternalActionType } from '@client_broker/store/TransferInternal/types';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { PostTransferInternalResp } from '@base/types';
import { modalCloseAction } from '@base/store/Modal/actions';
import { setNotificationAction } from '@base/store/Notifications/actions';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';

function* transferInternalSaga({ payload }: TransferInternalActionType) {
	try {
		const body = {
			...payload,
		};
		const resp: PostTransferInternalResp = yield call(
			apiRequestClientBroker, 'postTransferInternal', { body },
		);
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(transferInternalSuccessAction());
		yield put(modalCloseAction());
		yield put(setNotificationAction({
			message: 'Перевод прошёл успешно!',
			type: 'success',
		}));
	} catch (e) {
		console.log(e);
		yield put(transferInternalFailAction({ error: e.message }));
	}
}

export function* transferInternalWatcher() {
	yield takeLeading(
		CLIENT_BROKER_TRANSFER_INTERNAL_REQUEST,
		transferInternalSaga,
	);
}
