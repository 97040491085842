import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { TableDeprecated } from '@base/components';
import { ClientBrokerState } from '@client_broker/types';
import { customerAccountsAction } from '@client_broker/store/СustomerAccounts/actions';
import { LoaderSpinner, TableReact } from '@base/components';
import TableRow from '@client_broker/containers/accounts/TableRow/TableRow';
import { useTranslation } from 'react-i18next';

import styles from './Live.module.scss';

type LiveProps = {};

const getColumns = ({ t }: {t: any}) => [
	{
		accessor: 'mt_id',
		Header: t('CLIENT.ACCOUNTS.TABLE.HEADER.MT_ID'),
		// isSort: false,
		// classNameRow: styles.live__column,
	}, {
		accessor: 'currency',
		Header: t('CLIENT.ACCOUNTS.TABLE.HEADER.CURRENCY'),
		// isSort: false,
		// classNameRow: styles.live__column,
	}, {
		accessor: 'type_name',
		Header: t('CLIENT.ACCOUNTS.TABLE.HEADER.TYPE_NAME'),
		// isSort: false,
		// classNameRow: styles.live__column,
	}, {
		accessor: 'leverage',
		Header: t('CLIENT.ACCOUNTS.TABLE.HEADER.LEVERAGE'),
		// isSort: false,
		// classNameRow: styles.live__column,
	}, {
		accessor: 'balance',
		Header: t('CLIENT.ACCOUNTS.TABLE.HEADER.BALANCE'),
		// isSort: false,
		// classNameRow: styles.live__column,
	}, {
		accessor: 'history',
		Header: t('CLIENT.ACCOUNTS.TABLE.HEADER.HISTORY'),
		// isSort: false,
		// classNameRow: styles.live__column,
	}, {
		accessor: 'management',
		Header: t('CLIENT.ACCOUNTS.TABLE.HEADER.MANAGEMENT'),
		// isSort: false,
		// classNameRow: styles.live__column,
	},
];

// eslint-disable-next-line no-empty-pattern
export const Live = ({}: LiveProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const accounts = useSelector(
		(state: ClientBrokerState) => state.clientBroker.customerAccounts.accounts,
	);

	useEffect(() => {
		dispatch(customerAccountsAction());
	}, [customerAccountsAction]);

	return (
		<div className={styles.live}>
			{accounts.isLoading ? <LoaderSpinner />
				: (
					<TableReact
						columns={getColumns({ t })}
						data={accounts.live}
						tableRow={TableRow}
					/>
				)}
		</div>
	);
};

export default null;
