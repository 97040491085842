import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { Text } from '@base/components';
import correctIcon from '@base/assets/icons/correct.svg';
import wrongIcon from '@base/assets/icons/wrong.svg';
import waiting from '@base/assets/icons/waiting.svg';
import { TextColorsType } from '@base/components/Text';
import styles from './StatusIconAndText.module.scss';

interface StatusIconAndTextProps {
  className?: string;
  status: 'positive' | 'negative' | 'waiting';
	text: string,
	colorText?: TextColorsType,
}

export const StatusIconAndText = ({
	className, status, text, colorText,
}: StatusIconAndTextProps) => {
	const [icon, setIcon] = useState<typeof correctIcon>();

	useEffect(() => {
		switch (status) {
			case 'positive':
				setIcon(correctIcon);
				break;
			case 'negative':
				setIcon(wrongIcon);
				break;
			case 'waiting':
				setIcon(waiting);
				break;
			default:
				break;
		}
	}, [status]);

	return (
		<div className={cx(styles.status_icon_and_text, className)}>
			<img src={icon} alt="" />
			<Text type="h4" color={colorText}>{text}</Text>
		</div>
	);
};

export default null;
