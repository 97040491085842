/* eslint-disable no-unused-vars */
import {
	call, delay, put, select, takeLeading,
} from 'redux-saga/effects';

import {
	passwordRecoverySendEmailSuccessAction,
	passwordRecoverySendEmailFailAction,
	passwordRecoveryRestoreSuccessAction,
	passwordRecoveryRestoreFailAction,
	passwordRecoveryCheckPassTokenSuccessAction,
	passwordRecoveryCheckPassTokenFailAction,
} from '@auth/store/PasswordRecovery/actions';
import {
	PatchCustomerRestoreResp,
	PostCustomerCheckPassTokenResp,
	PostCustomerRestoreResp,
} from '@base/types/provider/clientBroker';
import { apiRequestClientBroker } from '@base/providers/sagas';
import {
	PasswordRecoveryCheckPassTokenActionType,
	PasswordRecoveryRestoreActionType,
	PasswordRecoverySendEmailActionType,
} from '@auth/store/PasswordRecovery/types';
import * as passwordRecoveryTypes from '@auth/store/PasswordRecovery/constants';
import { push } from 'connected-react-router';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import i18n from '@starter/i18n';
import { AuthNotificationDescriptionError } from '@base/containers/notificationsComponents';
import { authPersistLogout } from '@base/store/AuthPersist/actions';
import { OnClickParamOfNotificationsModalType } from '@base/store/NotificationsModal/types';
import authRoutes from '@auth/constants/authRoutes';
import { BaseState } from '@base/types';

function* passwordRecoverySendEmailSaga({ payload }: PasswordRecoverySendEmailActionType) {
	try {
		const { email } = payload;
		const body = {
			language: 'ru',
			email,
		};
		const resp: PostCustomerRestoreResp = yield call(
			apiRequestClientBroker,
			'postCustomerRestore',
			{
				body,
			},
		);

		if (!resp.success) {
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent: AuthNotificationDescriptionError,
				},
			}));
			throw new Error(resp.error);
		}
		yield put(passwordRecoverySendEmailSuccessAction());
	} catch (e) {
		console.log(e);
		yield put(passwordRecoverySendEmailFailAction({ error: (e as Error).message }));
	}
}

function* passwordRecoveryRestoreSaga({ payload }: PasswordRecoveryRestoreActionType) {
	try {
		const { pageInfo } = yield select(({ base }: BaseState) => base);
		const {
			password, confirm,
		} = payload;
		const body = {
			language: 'ru',
			password,
			confirm,
		};

		const resp: PatchCustomerRestoreResp = yield call(
			apiRequestClientBroker,
			'patchCustomerRestore',
			{
				body,
			},
		);

		if (!resp.success) {
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent: AuthNotificationDescriptionError,
				},
			}));
			throw new Error(resp.error);
		}
		yield put(notificationsModalOpenAction({
			labelTKey: pageInfo.labelTKey,
			messageTKey: 'AUTH.PASSWORD_RECOVERY.NOTIFICATIONS.PASSWORD_CHANGED_SUCCESS',
			descriptionTKey: 'AUTH.PASSWORD_RECOVERY.PASSWORD_CHANGED_DESCRIPTION',
			type: 'success',
			onClick: ({ history, dispatch }: OnClickParamOfNotificationsModalType) => {
				dispatch(authPersistLogout());
				history.push(authRoutes.auth.root);
			},
			onClickClose: ({ history, dispatch }: OnClickParamOfNotificationsModalType) => {
				dispatch(authPersistLogout());
				history.push(authRoutes.auth.root);
			},
		}));
		yield put(passwordRecoveryRestoreSuccessAction());
	} catch (e) {
		console.log(e);
		yield put(passwordRecoveryRestoreFailAction({ error: (e as Error).message }));
	}
}

function* passwordRecoveryCheckPassTokenSaga({
	payload,
}: PasswordRecoveryCheckPassTokenActionType) {
	try {
		const { token } = payload;
		const body = {
			token,
		};
		const resp: PostCustomerCheckPassTokenResp = yield call(
			apiRequestClientBroker,
			'postCustomerCheckPassToken',
			{
				body,
			},
		);
		if (!resp.success) {
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent: resp.error === 'CONFIRMATION_TOKEN_INCORRECT' ? undefined : AuthNotificationDescriptionError,
					descriptionTKey: 'AUTH.PASSWORD_RECOVERY.TOKEN_LIFETIME_EXPIRED',
					onClick: ({ history }: OnClickParamOfNotificationsModalType) => {
						history.push(authRoutes.auth['password-recovery'].root);
					},
					onClickClose: ({ dispatch }: OnClickParamOfNotificationsModalType) => {
						dispatch(authPersistLogout());
					},
				},
			}));
			throw new Error(resp.error);
		}
		yield put(passwordRecoveryCheckPassTokenSuccessAction());
	} catch (e) {
		console.log(e);
		yield put(passwordRecoveryCheckPassTokenFailAction({ error: (e as Error).message }));
	}
}

export function* passwordRecoveryWatcher() {
	yield takeLeading(
		passwordRecoveryTypes.PASSWORD_RECOVERY_SEND_EMAIL_REQ, passwordRecoverySendEmailSaga,
	);
	yield takeLeading(
		passwordRecoveryTypes.PASSWORD_RECOVERY_RESTORE_REQ, passwordRecoveryRestoreSaga,
	);
	yield takeLeading(
		passwordRecoveryTypes.PASSWORD_RECOVERY_CHECK_PASS_TOKEN_REQ,
		passwordRecoveryCheckPassTokenSaga,
	);
}
