import { Reducer } from 'react';
import {
	connectRouter,
} from 'connected-react-router';
import { combineReducers } from 'redux';
import { BaseState } from '@base/types';
import { BaseActionsType } from '@base/types/store/actions';
import { authPersistReducer } from '@base/store/AuthPersist/reducer';
import { translationsPersistReducer } from '@base/store/TranslationsPersist/reducer';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import history from '@base/utils/history';
import { coreReducer } from '@base/store/Core/reducer';
import { modalReducer } from '@base/store/Modal/reducer';
import { notificationReducer } from '@base/store/Notifications/reducer';
import { notificationsModalReducer } from '@base/store/NotificationsModal/reducer';
import { referralReducer } from '@base/store/Referral/reducer';
import { countriesReducer } from '@base/store/Countries/reducer';
import { pageInfoReducer } from '@base/store/PageInfo/reducer';
import { systemReducer } from '@base/store/System/reducer';
import { userActionsInfoPersistReducer } from '@base/store/UserActionsInfoPersist/reducer';
// import { setTransformIsAuth, setTransformToken } from '@base/store/persistTransforms';
// eslint-disable-next-line no-unused-vars
// import storageSession from 'redux-persist/lib/storage/session'

export const router = connectRouter(history);

const authPersistConfig = {
	key: 'auth-token',
	storage, // TODO: replace by custom storage
	whitelist: [
		'token',
		'isAuth',
		'userData',
	],
	transforms: [
		// setTransformToken,
		// setTransformIsAuth,
	],
};

const translationsPersistConfig = {
	key: 'translations-persist',
	storage,
	whitelist: [
		'downloadTranslationsData',
		'languageNames',
		'translations',
		'isTranslationsDownloaded',
		'selectedLanguage',
	],
};

const userActionsInfoPersistConfig = {
	key: 'user-action-info-persist-persist',
	storage,
	whitelist: [
		'securityPasswordEnteredIncorrectly',
	],
};

// @ts-ignore
export const baseRootReducer: Reducer<BaseState, BaseActionsType> = combineReducers({
	authPersist: persistReducer(authPersistConfig, authPersistReducer),
	translationsPersist: persistReducer(
		translationsPersistConfig, translationsPersistReducer,
	),
	userActionsInfoPersist: persistReducer(
		userActionsInfoPersistConfig, userActionsInfoPersistReducer,
	),
	modal: modalReducer,
	core: coreReducer,
	notification: notificationReducer,
	notificationsModal: notificationsModalReducer,
	referral: referralReducer,
	countries: countriesReducer,
	pageInfo: pageInfoReducer,
	system: systemReducer,
});
