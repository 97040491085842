import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from '@client_broker/containers/profile/ReferralCodeForm/ReferralCodeForm.module.scss';
import { useFormik } from 'formik';
import { object } from 'yup';
import { InputSend, Text, LoaderSpinner } from '@base/components';

import { ClientBrokerState } from '@client_broker/types';
import { BaseState } from '@base/types';
import { setReferralRequestsAction } from '@base/store/Referral/actions';
import { useTranslation } from 'react-i18next';
import { referralYup } from '@base/utils/yup';

interface OwnProps {}
interface DispatchProps {}
interface StateProps {}

type ReferralCodeFormProps = StateProps & DispatchProps & OwnProps;

interface PersonalDataFormFieldsType {
	referral: string,
}

export const initialValues = {
	referral: '',
};

// eslint-disable-next-line no-empty-pattern
export const ReferralCodeForm = ({}: ReferralCodeFormProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { info } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.customer,
	);
	const { referral } = useSelector(
		(state: BaseState) => state.base,
	);
	const validationSchema = object().shape({
		// referral: referralYup(undefined, 'AUTH.REGISTRATION'), // старая версия
		referral: referralYup(),
	});
	const {
		handleChange,
		handleBlur,
		values,
		errors,
		touched,
		isValid,
		setTouched,
	} = useFormik<PersonalDataFormFieldsType>({
		initialValues,
		onSubmit: () => {},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			let target = { name, value };
			// eslint-disable-next-line default-case
			switch (name) {
				case 'referral':
					if (value.length > 32) return;
					target = {	name,	value: value.replace(/[^\da-zA-Z]/g, '') };
					break;
			}
			const event = {
				target,
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);
	return (
		<div className={styles.referral_code_form}>
			{(info.isLoading || referral.setReferral.isLoading) ? <LoaderSpinner /> : (
				<>
					<div className={styles.personal_data_form__input}>
						<Text type="h3">{t('CLIENT.PROFILE.REFERRAL_CODE')}</Text>
						<InputSend
							name="referral"
							required
							value={values.referral}
							onChange={onChangeHandler}
							error={
								(touched.referral !== undefined && errors.referral !== undefined)
									? errors.referral || ''
									: undefined
							}
							onClick={() => {
								dispatch(setReferralRequestsAction({ ref_code: values.referral }));
							}}
							onBlur={(event) => {
								handleBlur(event);
								setTouched({
									...touched,
									referral: false,
								});
							}}
							onFocus={handleBlur}
							disabledBtn={info.isLoading || referral.setReferral.isLoading || !isValid}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default null;
