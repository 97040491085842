import ApiProviderDefault from '@base/providers/apiProviderDefault';
import {
	GetAccountsResp,
	PatchAccountReq,
	PatchAccountResp,
	PostAccountReq,
	PostAccountResp,
} from '@base/types';

export default class AccountsProvider extends ApiProviderDefault {
	static async getAccounts(parameters: {
		headers: Headers,
	}): Promise<GetAccountsResp> {
		let body;
		let headers;
		const query = {};
		const path = '/account';

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request(path, 'GET', query, body, headers);
	}

	static async patchAccount(parameters: {
		req: PatchAccountReq,
		headers: Headers,
	}): Promise<PatchAccountResp> {
		let body;
		let headers;
		const query = {};
		const path = `/account/${parameters.req.id}`;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		if (parameters.req.body !== undefined) {
			body = parameters.req.body;
		}

		return this.request(path, 'PATCH', query, body, headers);
	}

	static async postAccount(parameters: {
		body: PostAccountReq,
		headers: Headers,
	}) {
		const path = '/account';
		let headers;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const { body } = parameters;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<PostAccountReq, PostAccountResp>(path, 'POST', query, body, headers);
	}
}

// @ts-ignore
AccountsProvider.getAccounts.security = true;
// @ts-ignore
AccountsProvider.patchAccount.security = true;
// @ts-ignore
AccountsProvider.postAccount.security = true;
