import * as documentFilesTypes from '@client_broker/store/DocumentFiles/constants';
import type { DocumentFilesActionsType } from '@client_broker/store/DocumentFiles/types';
import { DocumentFilesType, RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';
import fileStatuses, { FileStatusesType } from '@base/constants/fileStatuses';

export type VerificationStatusType = {
	address: FileStatusesType
	identity: FileStatusesType
}

export type DocumentFilesState = {
	files: {
		data: Array<DocumentFilesType>;
	} & RequestInfoChildType;
	verificationStatus: VerificationStatusType,
	sendingFiles: {
	} & RequestInfoChildType;
}

export const initialDocumentFilesState: DocumentFilesState = {
	verificationStatus: {
		address: fileStatuses.NOT_VERIFIED,
		identity: fileStatuses.NOT_VERIFIED,
	},
	files: {
		data: [],
		...RequestInfoChildState,
		isLoading: true,
	},
	sendingFiles: {
		...RequestInfoChildState,
	},
};

export const documentFilesReducer = (
	state: DocumentFilesState = initialDocumentFilesState,
	action: DocumentFilesActionsType,
): DocumentFilesState => {
	switch (action.type) {
		case documentFilesTypes.DOCUMENT_FILES_REQUEST: {
			return {
				...state,
				files: {
					...state.files,
					isLoading: true,
				},
			};
		}

		case documentFilesTypes.DOCUMENT_FILES_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				files: {
					...state.files,
					data: payload.data,
					isLoading: false,
				},
				verificationStatus: {
					...state.verificationStatus,
					...payload.verificationStatus,
				},
			};
		}

		case documentFilesTypes.DOCUMENT_FILES_FAIL: {
			const { payload } = action;
			return {
				...state,
				files: {
					...state.files,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		case documentFilesTypes.SEND_DOCUMENT_FILES_REQUEST: {
			return {
				...state,
				sendingFiles: {
					...state.sendingFiles,
					isLoading: true,
				},
			};
		}

		case documentFilesTypes.SEND_DOCUMENT_FILES_SUCCESS: {
			return {
				...state,
				sendingFiles: {
					...state.sendingFiles,
					isLoading: false,
				},
			};
		}

		case documentFilesTypes.SEND_DOCUMENT_FILES_FAIL: {
			const { payload } = action;
			return {
				...state,
				sendingFiles: {
					...state.sendingFiles,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
