export const PASSWORD_RECOVERY_INIT_STATE: 'PASSWORD_RECOVERY_INIT_STATE' = 'PASSWORD_RECOVERY_INIT_STATE';

export const PASSWORD_RECOVERY_SEND_EMAIL_REQ: 'PASSWORD_RECOVERY_SEND_EMAIL_REQ' = 'PASSWORD_RECOVERY_SEND_EMAIL_REQ';
export const PASSWORD_RECOVERY_SEND_EMAIL_SUCCESS: 'PASSWORD_RECOVERY_SEND_EMAIL_SUCCESS' = 'PASSWORD_RECOVERY_SEND_EMAIL_SUCCESS';
export const PASSWORD_RECOVERY_SEND_EMAIL_FAIL: 'PASSWORD_RECOVERY_SEND_EMAIL_FAIL' = 'PASSWORD_RECOVERY_SEND_EMAIL_FAIL';

export const PASSWORD_RECOVERY_RESTORE_REQ: 'PASSWORD_RECOVERY_RESTORE_REQ' = 'PASSWORD_RECOVERY_RESTORE_REQ';
export const PASSWORD_RECOVERY_RESTORE_SUCCESS: 'PASSWORD_RECOVERY_RESTORE_SUCCESS' = 'PASSWORD_RECOVERY_RESTORE_SUCCESS';
export const PASSWORD_RECOVERY_RESTORE_FAIL: 'PASSWORD_RECOVERY_RESTORE_FAIL' = 'PASSWORD_RECOVERY_RESTORE_FAIL';

export const PASSWORD_RECOVERY_CHECK_PASS_TOKEN_REQ: 'PASSWORD_RECOVERY_CHECK_PASS_TOKEN_REQ' = 'PASSWORD_RECOVERY_CHECK_PASS_TOKEN_REQ';
export const PASSWORD_RECOVERY_CHECK_PASS_TOKEN_SUCCESS: 'PASSWORD_RECOVERY_CHECK_PASS_TOKEN_SUCCESS' = 'PASSWORD_RECOVERY_CHECK_PASS_TOKEN_SUCCESS';
export const PASSWORD_RECOVERY_CHECK_PASS_TOKEN_FAIL: 'PASSWORD_RECOVERY_CHECK_PASS_TOKEN_FAIL' = 'PASSWORD_RECOVERY_CHECK_PASS_TOKEN_FAIL';
