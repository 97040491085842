import React from 'react';

import { PasswordChangeForm } from '@client_broker/containers/security/PasswordChangeForm';
import { PageLayout } from '@client_broker/layouts/PageLayout';

export const Security = () => (
	<PageLayout>
		<PasswordChangeForm />
	</PageLayout>
);
export default null;
