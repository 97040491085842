import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { translationsPersistRequestAction } from '@base/store/TranslationsPersist/actions';
import { BaseRouter } from '@starter/routes';
import cx from 'classnames';
import { Notification } from '@base/components';
import { gettingNotificationAction } from '@base/store/Notifications/actions';
import { ClientBrokerState } from '@client_broker/types';
import { NotificationModal } from '@base/containers/NotificationModal';
import { ModalWindow } from '@base/containers/ModalWindow';
import { customerAction } from '@client_broker/store/Customer/actions';
import { BaseState } from '@base/types';
import { systemAction } from '@base/store/System/actions';
import styles from './App.module.scss';

interface OwnProps {}

type AppProps = {} & OwnProps;

// eslint-disable-next-line no-empty-pattern
export const App = ({}: AppProps) => {
	const dispatch = useDispatch();
	const { notification } = useSelector(
		(state: ClientBrokerState) => state.base,
	);
	const { userData, isAuth } = useSelector(
		(state: BaseState) => state.base.authPersist,
	);
	const [showNotification, setShowNotification] = useState(false);

	useEffect(() => {
		if (notification.isUpdate) {
			setShowNotification(true);
		}
	}, [notification]);

	useEffect(() => {
		dispatch(translationsPersistRequestAction());
		dispatch(systemAction());
	}, []);

	useEffect(() => {
		if (isAuth || userData.isConfirmedEmail || userData.isConfirmedPhone) {
			dispatch(customerAction());
		}
	}, [isAuth, userData]);

	return (
		<div className={styles.app}>
			<BaseRouter />
			<div className={cx(
				styles.notification,
				{
					[styles['notification--animation']]: showNotification,
				},
			)}
			>
				<Notification
					type={notification.type}
					message={notification.message}
					isUpdate={notification.isUpdate}
					onGettingNotification={() => {
						dispatch(gettingNotificationAction());
					}}
					onClose={() => { setShowNotification(false); }}
				/>
			</div>
			<NotificationModal />
			<ModalWindow />
		</div>
	);
};

export default null;
