import {
	combineReducers,
} from 'redux';

import { customerReducer } from '@client_broker/store/Customer/reducer';
import { documentFilesReducer } from '@client_broker/store/DocumentFiles/reducer';
import { customerAccountsReducer } from '@client_broker/store/СustomerAccounts/reducer';
import { transferInternalReducer } from '@client_broker/store/TransferInternal/reducer';
import { tradesReducer } from '@client_broker/store/Trades/reducer';
import { paymentsReducer } from '@client_broker/store/Payments/reducer';
import { transferHistoryReducer } from '@client_broker/store/TransferHistory/reducer';
import { profileVerificationReducer } from '@client_broker/store/ProfileVerification/reducer';

export const clientBrokerRootReducer = combineReducers({
	customer: customerReducer,
	documentFiles: documentFilesReducer,
	customerAccounts: customerAccountsReducer,
	transferInternal: transferInternalReducer,
	transferHistory: transferHistoryReducer,
	trades: tradesReducer,
	payments: paymentsReducer,
	profileVerification: profileVerificationReducer,
});
