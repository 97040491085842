import React from 'react';
import cx from 'classnames';

import styles from './DropDownInputInfo.module.scss';

export type InputInfoTypePropsType = 'error' | 'normal' | 'good';
interface DropDownInputInfoProps {
	className?: string;
	text: string;
	type?: InputInfoTypePropsType,
	float?: 'bottom',
}

export const DropDownInputInfo = ({
	className, text, type, float,
}: DropDownInputInfoProps) => (
	<div>
		<div className={cx(
			styles.drop_down_input_info,
			styles[`drop_down_input_info__${type}`],
			styles[`drop_down_input_info__float--${float}`],

			className,
		)}
		>
			<span
				className={cx(
					styles.span,
					styles[`span__${type}`],
					styles[`span__float--${float}`],
				)}
			/>
			<div
				className={cx(
					styles.text,
					styles[`text__${type}`],
				)}
			>
				{text}
			</div>
		</div>
	</div>
);

export default null;
