import * as transferHistoryTypes from '@client_broker/store/TransferHistory/constants';
import type { TransferHistoryActionsType } from '@client_broker/store/TransferHistory/types';
import { RequestInfoChildType, TransferHistory } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type TransferHistoryState = {
	data: Array<TransferHistory> | []
} & RequestInfoChildType;

const initialState: TransferHistoryState = {
	data: [],
	...RequestInfoChildState,
	isLoading: true,
};

export const transferHistoryReducer = (
	state: TransferHistoryState = initialState,
	action: TransferHistoryActionsType,
): TransferHistoryState => {
	switch (action.type) {
		case transferHistoryTypes.CLIENT_BROKER_TRANSFER_HISTORY_REQUEST: {
			return {
				...state,
				data: state.data,
				isLoading: true,
			};
		}

		case transferHistoryTypes.CLIENT_BROKER_TRANSFER_HISTORY_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				data: payload.data,
				isLoading: false,
			};
		}

		case transferHistoryTypes.CLIENT_BROKER_TRANSFER_HISTORY_FAIL: {
			const { payload } = action;
			return {
				...state,
				data: state.data,
				isLoading: false,
				error: payload.error,
			};
		}

		default: {
			return state;
		}
	}
};
