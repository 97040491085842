import React, { useEffect, useState } from 'react';
import {
	useFormik,
} from 'formik';
import {
	useDispatch, useSelector,
} from 'react-redux';
// import {
// 	object, string,
// } from 'yup';
import {
	object,
} from 'yup';

import {
	authPersistLogin,
} from '@base/store/AuthPersist/actions';

import { BaseState } from '@base/types';
import authRoutes from '@auth/constants/authRoutes';
import {
	Button, Checkbox, LoaderSpinner,
	Input, ButtonLink,
} from '@base/components';
import { useTranslation } from 'react-i18next';
import { emailYup, passwordYup } from '@base/utils/yup';
import { getInfoInput } from '@base/utils/inputFunctions';
import styles from './LoginForm.module.scss';

interface LoginFormFieldsType {
	email: string,
	password: string,
}

export const initialValues = {
	email: '',
	password: '',
};

export function LoginForm() {
	const { t } = useTranslation();
	const [isRememberUser, setIsRememberUser] = useState(false);

	const validationSchema = object().shape({
		email: emailYup(t, 'AUTH.LOGIN'),
		// password: string()
		// 	.required(t('OTHER.FIELD_IS_REQUIRED')),
		password: passwordYup(t, 'AUTH.LOGIN'),
	});

	const dispatch = useDispatch();
	const { isLoading } = useSelector(({ base }: BaseState) => base.authPersist);
	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<LoginFormFieldsType>({
		initialValues,
		onSubmit: (formData: any): any => dispatch(authPersistLogin(formData)),
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const event = {
				target: { name, value },
			};
			if (event.target.value.length <= 30) {
				handleChange(event);
			}
		},
		[
			handleChange,
		],
	);

	return (
		<form
			onSubmit={handleSubmit}
			className={styles.login_form}
		>
			{isLoading && <LoaderSpinner />}
			<Input
				className={styles.input}
				label={t('AUTH.LOGIN.EMAIL_LABEL')}
				// placeholder={t('AUTH.LOGIN.EMAIL_PLACEHOLDER')} не нужен для почты
				name="email"
				required
				value={values.email}
				onChange={onChangeHandler}
				onBlur={(event) => {
					handleBlur(event);
					setTouched({
						...touched,
						email: false,
					});
				}}
				onFocus={handleBlur}
				info={getInfoInput({
					name: 'email',
					text: t('AUTH.LOGIN.EMAIL_HINT'),
					touched,
					errors,
					values,
				})}
				disabled={isLoading}
			/>
			<Input
				className={styles.input}
				label={t('AUTH.LOGIN.PASSWORD_LABEL')}
				// placeholder={t('AUTH.LOGIN.PASSWORD_PLACEHOLDER')} не нужен для пароля
				name="password"
				required
				type="password"
				value={values.password}
				onChange={onChangeHandler}
				textAction={{
					text: t('AUTH.LOGIN.FORGOT_BUTTON'),
					value: authRoutes.auth['password-recovery'].root,
					type: 'link',
				}}
				onBlur={(event) => {
					handleBlur(event);
					setTouched({
						...touched,
						password: false,
					});
				}}
				onFocus={handleBlur}
				info={getInfoInput({
					name: 'password',
					text: t('AUTH.LOGIN.PASSWORD_HINT'),
					touched,
					errors,
					values,
				})}
				disabled={isLoading}
			/>
			<div className={styles.input}>
				<Checkbox
					label={t('AUTH.LOGIN.REMEMBER_BUTTON')}
					name="isRememberUser"
					value={isRememberUser}
					onChange={() => setIsRememberUser(!isRememberUser)}
					disabled={isLoading}
				/>
			</div>
			<Button
				size="large"
				type="submit"
				theme="primary"
				onClick={() => {}}
				disabled={isLoading || !isValid}
				className={styles.login_form__button}
			>
				{t('AUTH.LOGIN.LOGIN_BUTTON')}
			</Button>
			<ButtonLink
				to={authRoutes.auth.registration.root}
				type="button"
				theme="secondary"
				size="large"
				className={styles.login_form__button}
				onClick={() => {}}
			>
				{t('AUTH.LOGIN.REGISTRATION_BUTTON')}
			</ButtonLink>
		</form>
	);
}

export default null;
