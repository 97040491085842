import React from 'react';
import cx from 'classnames';

import styles from './Paragraph.module.scss';

interface ParagraphProps {
  className: string;
  value: string,
  children: React.ReactNode;
}

export const Paragraph = ({ className, children, value }: ParagraphProps) => (
	<div className={cx(styles.paragraph, className)}>
		<div className={styles.paragraph__value}>{value}</div>
		{children}
	</div>
);

Paragraph.defaultProps = {
	className: '',
};

export default null;
