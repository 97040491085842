import * as pageInfoTypes from '@base/store/PageInfo/constants';
import type { PageInfoActionsType } from '@base/store/PageInfo/types';

export type PageInfoState = {
	labelTKey: string,
	tKeyRoot: string,
};

const initialState: PageInfoState = {
	labelTKey: '',
	tKeyRoot: '',
};

export const pageInfoReducer = (
	state: PageInfoState = initialState,
	action: PageInfoActionsType,
): PageInfoState => {
	switch (action.type) {
		case pageInfoTypes.BASE_SET_PAGE_INFO_REQUEST: {
			const { payload } = action;
			return {
				...state,
				...payload,
			};
		}

		default: {
			return state;
		}
	}
};
