import * as translationsTypes from '@base/store/TranslationsPersist/constants';
import type {
	TranslationsPersistActionType,
	LanguageNameType,
	TranslationsType,
} from '@base/store/TranslationsPersist/types';
import { RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type TranslationsPersistStateType = {
	isTranslationsDownloaded: boolean;
	selectedLanguage: {
		data: LanguageNameType | undefined
	} & RequestInfoChildType;
	languageNames?: Array<LanguageNameType>;
	translations: {
		data: TranslationsType
	} & RequestInfoChildType;
	downloadTranslationsData: RequestInfoChildType,
};

const initialState: TranslationsPersistStateType = {
	isTranslationsDownloaded: false,
	selectedLanguage: {
		// data: { tag: 'ru', label: 'Русский' },
		data: undefined,
		...RequestInfoChildState,
	},
	languageNames: undefined,
	translations: {
		data: {},
		...RequestInfoChildState,
	},
	downloadTranslationsData: RequestInfoChildState,
};

export const translationsPersistReducer = (
	state: TranslationsPersistStateType = initialState,
	action: TranslationsPersistActionType,
): TranslationsPersistStateType => {
	switch (action.type) {
		// инициализация языка после загрузки страницы
		case translationsTypes.BASE_TRANSLATIONS_PERSIST_REQUEST: {
			return {
				...state,
				translations: {
					...state.translations,
					isLoading: true,
				},
			};
		}

		case translationsTypes.BASE_TRANSLATIONS_PERSIST_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				translations: {
					...state.translations,
					isLoading: false,
				},
				selectedLanguage: {
					...state.selectedLanguage,
					data: payload,
				},
			};
		}

		case translationsTypes.BASE_TRANSLATIONS_PERSIST_FAIL: {
			const { payload } = action;
			return {
				...state,
				translations: {
					...state.translations,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		// загрузка языка с бека
		case translationsTypes.BASE_TRANSLATIONS_PERSIST_DOWNLOAD_REQUEST: {
			return {
				...state,
				translations: {
					...state.translations,
					isLoading: true,
				},
				downloadTranslationsData: {
					...state.downloadTranslationsData,
					isLoading: true,
				},
			};
		}

		case translationsTypes.BASE_TRANSLATIONS_PERSIST_DOWNLOAD_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				isTranslationsDownloaded: true,
				translations: {
					...state.translations,
					data: {
						...state.translations.data,
						...payload.translations,
					},
					isLoading: false,
				},
				languageNames: payload.languageNames,
				downloadTranslationsData: {
					...state.downloadTranslationsData,
					isLoading: false,
				},
			};
		}

		case translationsTypes.BASE_TRANSLATIONS_PERSIST_DOWNLOAD_FAIL: {
			const { payload } = action;
			return {
				...state,
				downloadTranslationsData: {
					...state.downloadTranslationsData,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		// событие смены языка
		case translationsTypes.BASE_SELECT_LANGUAGE_REQUEST: {
			return {
				...state,
				selectedLanguage: {
					...state.selectedLanguage,
					isLoading: true,
				},
			};
		}

		case translationsTypes.BASE_SELECT_LANGUAGE_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				selectedLanguage: {
					...state.selectedLanguage,
					data: payload,
					isLoading: false,
				},
			};
		}

		case translationsTypes.BASE_SELECT_LANGUAGE_FAIL: {
			const { payload } = action;
			return {
				...state,
				selectedLanguage: {
					...state.selectedLanguage,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
