export const DOCUMENT_FILES_REQUEST: 'DOCUMENT_FILES_REQUEST' = 'DOCUMENT_FILES_REQUEST';
export const DOCUMENT_FILES_SUCCESS: 'DOCUMENT_FILES_SUCCESS' = 'DOCUMENT_FILES_SUCCESS';
export const DOCUMENT_FILES_FAIL: 'DOCUMENT_FILES_FAIL' = 'DOCUMENT_FILES_FAIL';

export const SEND_DOCUMENT_FILES_REQUEST:
	'SEND_DOCUMENT_FILES_REQUEST' = 'SEND_DOCUMENT_FILES_REQUEST';
export const SEND_DOCUMENT_FILES_SUCCESS:
	'SEND_DOCUMENT_FILES_SUCCESS' = 'SEND_DOCUMENT_FILES_SUCCESS';
export const SEND_DOCUMENT_FILES_FAIL:
	'SEND_DOCUMENT_FILES_FAIL' = 'SEND_DOCUMENT_FILES_FAIL';
