import React from 'react';
import {
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import authRoutes from '@auth/constants/authRoutes';
import { Login } from '@auth/pages/Login';
import { PasswordRecovery } from '@auth/pages/PasswordRecovery';
import { useSelector } from 'react-redux';
import { BaseState } from '@base/types';
import { RegistrationRouter } from '@auth/routes/RegistrationRouter';

export const AuthRouter = () => {
	const { token } = useSelector((
		state: BaseState,
	) => state.base.authPersist);
	return (
		<Switch>
			{/* <Route exact path={authRoutes.auth.root} component={Login} /> */}
			<Route
				exact
				path={authRoutes.auth.root}
				render={() => (token ? <Redirect to={authRoutes.auth.registration.root} /> : <Login />)}
			/>
			{!token && <Route path={authRoutes.auth['password-recovery'].root} component={PasswordRecovery} />}
			<Route path={authRoutes.auth.registration.root} component={RegistrationRouter} />
			<Redirect to={authRoutes.root} />
		</Switch>
	);
};

export default null;
