import * as userActionsInfoPersistTypes from '@base/store/UserActionsInfoPersist/constants';

export const securityPasswordEnteredIncorrectlyAction = () => ({
	type: userActionsInfoPersistTypes.BASE_USER_ACTIONS_SECURITY_PASSWORD_ENTERED_INCORRECTLY,
});

export const userActionsInfoInitialDataAction = () => ({
	type: userActionsInfoPersistTypes.BASE_USER_ACTIONS_INITIAL_DATA,
});
export default null;
