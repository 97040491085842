import { fork, all } from 'redux-saga/effects';

import { baseSagaRoot } from '@base/store/rootSaga';
import { authSagaRoot } from '@auth/store/rootSaga';
import { clientBrokerSagaRoot } from '@client_broker/store/rootSaga';

export default function* rootSaga() {
	try {
		yield all([
			fork(baseSagaRoot),
			fork(authSagaRoot),
			fork(clientBrokerSagaRoot),
		]);
	} finally {
		// always runs
	}
}
