import * as customerActionTypes from '@client_broker/store/Customer/constants';
import { Customer, CustomerPasswordChangeType } from '@base/types';

export const customerAction = () => ({
	type: customerActionTypes.CUSTOMER_REQUEST,
});

export const customerSuccessAction = (payload: Customer) => ({
	type: customerActionTypes.CUSTOMER_SUCCESS,
	payload,
});

export const customerFailAction = (payload: { error: string }) => ({
	type: customerActionTypes.CUSTOMER_FAIL,
	payload,
});

//-------------------------

export const customerUpdateAction = (
	payload: {[key: string]: string | number},
) => ({
	type: customerActionTypes.CUSTOMER_UPDATE_REQUEST,
	payload,
});

export const customerUpdateSuccessAction = () => ({
	type: customerActionTypes.CUSTOMER_UPDATE_SUCCESS,
});

export const customerUpdateFailAction = (payload: { error: string }) => ({
	type: customerActionTypes.CUSTOMER_UPDATE_FAIL,
	payload,
});

//-------------------------

export const customerPasswordChangeAction = (
	payload: CustomerPasswordChangeType,
) => ({
	type: customerActionTypes.CUSTOMER_PASSWORD_CHANGE_REQUEST,
	payload,
});

export const customerPasswordChangeSuccessAction = () => ({
	type: customerActionTypes.CUSTOMER_PASSWORD_CHANGE_SUCCESS,
});

export const customerPasswordChangeFailAction = (payload: { error: string }) => ({
	type: customerActionTypes.CUSTOMER_PASSWORD_CHANGE_FAIL,
	payload,
});

//-------------------------

export default null;
