export const AccountDefault = {
	account_type_group: '',
	balance: '',
	created_at: '',
	currency: '',
	equity: '',
	group: '',
	id: 0,
	is_broker: false,
	is_demo: false,
	is_live: false,
	is_ramm: false,
	is_ramm_strategy: false,
	leverage: '',
	mt_id: 0,
	ramm_id: null,
	ramm_login: null,
	report: false,
	reward_percent: '',
	strategy: '',
	type_name: '',
};
