import * as partnerRegistrationTypes from '@auth/store/PartnerRegistration/constants';
import { PostPartnerReq } from '@base/types/provider/clientBroker';

export const partnerRegistrationAction = (payload: PostPartnerReq) => ({
	type: partnerRegistrationTypes.PARTNER_REGISTRATION_REQUEST,
	payload,
});

export const partnerRegistrationSuccessAction = () => ({
	type: partnerRegistrationTypes.PARTNER_REGISTRATION_SUCCESS,
});

export const partnerRegistrationFailAction = (payload: { error: string }) => ({
	type: partnerRegistrationTypes.PARTNER_REGISTRATION_FAIL,
	payload,
});

export default null;
