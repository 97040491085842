import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import cx from 'classnames';
import hash from 'object-hash';

import { Text, Pagination } from '@base/components';

import TableItemDeprecated from '@base/components/TableDeprecated/TableItemDeprecated';
import TableThTableDeprecated from '@base/components/TableDeprecated/TableThTableDeprecated';
import type {
	OrderType,
} from '@base/types';

import styles from './TableDeprecated.module.scss';

export type AnyObject = { [key: string]: any }
export type TableColumn<T> = {
	id: string,
	header: React.ReactNode,
	// eslint-disable-next-line no-unused-vars
	customComponent?: (item: T, id: string) => React.ReactNode,
	classNameRow?: string,
	isSort: boolean,
	isExternal?: boolean,
}

export type TableData<T> = Array<T>

export type TableProps = {
	type?: 'default' | 'frameless' | 'no_header',
	columns: Array<TableColumn<any>>,
	data: TableData<any>,
	children?: React.ReactNode,
	namePage: string,
	nameSort: string,
	nameOrder: string,
	valuePage: number,
	valueSort: string,
	valueOrder: OrderType,
	valueCount: number,
	valueSelect?: {
		name: string,
		value: string,
		id: string,
	},
	// eslint-disable-next-line no-unused-vars
	onChangePage: (obj: {
		name: string,
		value: number,
	}) => void,
	// eslint-disable-next-line no-unused-vars
	onChangeSort: (obj: {
		name: string,
		value: string,
	}) => void,
	// eslint-disable-next-line no-unused-vars
	onChangeOrder: (obj: {
		name: string,
		value: OrderType,
	}) => void,
	// eslint-disable-next-line no-unused-vars
	onChangeSelect?: (obj: {
		value: string,
		id: string,
		item?: any,
	}) => void,
	pageSize: number,
	isLoading: boolean,
	className?: string,
	classNameTable?: string,
	classNameTableRows?: string,
	disable?: {
		id: string,
		value: Array<number>,
		field: string,
		valueField: Array<string>,
	},
	noDataMessage?: string,
	// eslint-disable-next-line no-unused-vars
	onScroll?: (event: React.UIEvent<HTMLDivElement>) => void
};

export const TableDeprecated = ({
	className,
	columns,
	data,
	children: TableRow,
	namePage,
	nameSort,
	nameOrder,
	valuePage,
	valueSort,
	valueOrder,
	valueCount,
	pageSize,
	isLoading,
	onChangePage,
	onChangeSort,
	onChangeOrder,
	valueSelect,
	onChangeSelect,
	classNameTable,
	classNameTableRows,
	disable,
	type,
	noDataMessage = 'no data found',
}: TableProps) => {
	const [columnLength, setColumnLength] = useState<number>(0);
	const [lastComponent, setLastComponent] = useState<number>(0);

	const [dataWithHash, setDataWithHash] = useState<TableData<any>>([]);

	// const [valueHover, setValueHover] = useState<{hash: number | undefined, isHover: boolean}>(
	// 	{ hash: undefined, isHover: false },
	// );

	useEffect(() => {
		if (data) {
			const dataNew = data.map((item, index) => ({
				...item,
				// hash: hash(item),
				hash: hash(index),
			}));
			setDataWithHash(dataNew);
		}
	}, [
		data,
		setDataWithHash,
	]);

	const handleSetSort = useCallback((id: string, orderNew: 'asc' | 'desc') => {
		onChangeSort({
			name: nameSort || '',
			value: id,
		});
		onChangeOrder({
			name: nameOrder || '',
			value: orderNew,
		});
	}, [
		onChangeSort,
		onChangeOrder,
		nameSort,
		nameOrder,
	]);

	const handlePageChange = useCallback(({
		name,
		value,
	}) => {
		onChangePage({
			name,
			value,
		});
	}, [
		onChangePage,
	]);

	const handleSelectChange = useCallback((id: string, value: string, item: any) => {
		if (onChangeSelect) {
			onChangeSelect({
				id,
				value,
				item,
			});
		}
	}, [
		onChangeSelect,
	]);

	useEffect(() => {
		if (columns) {
			let length = 0;
			let last = -1;

			columns.forEach(({
				id,
				isExternal,
			}, index) => {
				const externalIsFirst = isExternal === true && index === 0;
				if (id !== '') {
					length += 1;
				}
				if (isExternal !== true || externalIsFirst) {
					last += 1;
				}
			});

			setLastComponent(last);
			setColumnLength(length);
		}
	}, [
		columns,
	]);

	// useEffect(() => {
	// 	if (((valueSelect && !valueSelect.id) || !valueSelect)
	// 		&& onChangeSelect
	// 		&& dataWithHash.length > 0
	// 	) {
	// 		console.log('useEffect');
	// eslint-disable-next-line max-len
	// 		handleSelectChange(dataWithHash[0].hash, valueSelect ? dataWithHash[0][valueSelect.name] : '', dataWithHash[0]);
	// 	}
	// }, [
	// 	onChangeSelect,
	// 	dataWithHash,
	// 	columns,
	// 	handleSelectChange,
	// 	valueSelect,
	// ]);

	let nowIndex = 0;

	// const setIsTableItemHover = () => {
	//
	// };

	return (
		<div className={cx(
			styles.table,
			className,
		)}
		>
			<div
				id="table"
				className={cx(
					styles.table__box,
					{
						[styles[`table__box--${type || ''}`]]: type,
					},
				)}
			>
				<div
					className={cx(
						styles.table__content,
						classNameTable,
						{
							[styles['table__content--default']]: data.length === 0,
						},
					)}
					role="table"
					style={{
						gridTemplateColumns: `repeat(${columnLength}, auto)`,
					}}
				>
					{type !== 'no_header' && columns.map(({
						header,
						id,
						isSort,
						classNameRow,
						isExternal,
					}, index) => {
						nowIndex += 1;
						return (
							<TableThTableDeprecated
								key={nowIndex}
								id={id}
								header={header}
								isSort={isSort}
								className={classNameRow}
								onSetSort={handleSetSort}
								isExternal={isExternal}
								valueOrder={valueOrder}
								valueSort={valueSort}
								isFirstItem={index === 0}
								isLastItem={index === (columns.length - 1)}
								type={type}
							/>
						);
					})}
					{data.length === 0 && (
						<div
							className={styles.table__default}
							style={{
								gridColumn: `span ${lastComponent + 1}`,
							}}
						>
							<Text type="h3" color="gray">{noDataMessage}</Text>
						</div>

					)}
					{dataWithHash.map((item) => (
						// <div
						// 	className={cx(
						// 		styles.table__content,
						// 		classNameTable,
						// 		{
						// 			[styles['table__content--default']]: data.length === 0,
						// 		},
						// 	)}
						// 	style={{
						// 		gridTemplateColumns: `repeat(${columnLength}, auto)`,
						// 	}}
						// >
						// 	{
						columns.map(({
							id,
							customComponent,
							isExternal,
						}, index, array) => {
							nowIndex += 1;
							if (id === '') {
								return null;
							}
							return (
								<div
									id={item.hash}
									role="tablist"
									// @ts-ignore
									tabIndex="-1"
									key={nowIndex}
									onClick={
										() => handleSelectChange(item.hash, valueSelect
											? item[valueSelect.name] : '', item)
									}
									onKeyPress={() => {}}
									// onMouseEnter={(event) => {
									// 	setValueHover({ hash: item.hash, isHover: true });
									// }}
									// onMouseLeave={(event) => {
									// 	setValueHover({ hash: undefined, isHover: false });
									// }}
									className={cx(styles.table__item,
										classNameTableRows,
										{
											[styles[`table__item--${type || ''}`]]: type,

											[styles['table__item--click']]: onChangeSelect,

											[styles['table__item--first']]: array[0].isExternal ? index === 1 : index === 0,
											[
											styles[`table__item--first--${type || ''}`]
											]: type && array[0].isExternal ? index === 1 : index === 0,

											[styles['table__item--last']]: index === lastComponent,
											[styles[`table__item--last--${type || ''}`]]: type && index === lastComponent,

											[styles['table__item--external']]: isExternal,
											[styles['table__item--second']]: item.rowType === 'second',
											[styles['table__item--active']]: valueSelect && valueSelect.id === item.hash,
											// eslint-disable-next-line max-len
											// [styles['table__item--hover']]: valueHover.isHover && valueHover.hash === item.hash,
											[styles['table__item--disable']]: disable
														&& Object.keys(item).includes(disable.id)
														&& Object.keys(item).includes(disable.field)
														&& !disable.value.includes(item[disable.id])
														&& disable.valueField.includes(item[disable.field]),
										})}
								>
									<TableItemDeprecated
										id={id}
										item={item}
										TableRow={TableRow}
										isExternal={isExternal}
										customComponent={customComponent}
									/>
								</div>
							);
						})
						// }
						// </div>
					))}
				</div>
			</div>
			<div className={styles.table__pagination}>
				<Pagination
					count={valueCount}
					name={namePage}
					page={valuePage}
					pageSize={pageSize}
					onChange={handlePageChange}
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
};

TableDeprecated.defaultProps = {
	pageSize: 5,
	isLoading: false,
	className: '',
	children: null,
	classNameTable: '',
};

export default null;
