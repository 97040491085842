import React from 'react';

import { Tabs, TabsMobile } from '@base/components';
import styles from './TabsAdaptive.module.scss';

type TabsPaths = {
  path: string,
  label: string
}

type Props = {
  paths: Array<TabsPaths>,
};

export const TabsAdaptive = ({ paths }: Props) => (
	<>
		<Tabs paths={paths} className={styles.tabs_adaptive__desktop} />
		<TabsMobile paths={paths} className={styles.tabs_adaptive__mobile} />
	</>
);
