import ApiProviderDefault from '@base/providers/apiProviderDefault';
import {
	PostPartnerReq,
	PostPartnerResp,
} from '@base/types';

export default class PartnerProvider extends ApiProviderDefault {
	static async postPartner(parameters: {
		body: PostPartnerReq,
		headers: Headers,
	}) {
		const path = '/partner';
		let headers;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const { body } = parameters;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<PostPartnerReq, PostPartnerResp>(path, 'POST', query, body, headers);
	}
}

// @ts-ignore
PartnerProvider.postPartner.security = true;
