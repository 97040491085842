import React from 'react';
import {
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import authRoutes from '@auth/constants/authRoutes';
import { useSelector } from 'react-redux';
import { BaseState } from '@base/types';
import { useTranslation } from 'react-i18next';
import { AuthFormLayout } from '@auth/layouts/AuthFormLayout';
import { RegistrationForm } from '@auth/containers/registration/RegistrationForm';
import { VerificationEmailForm } from '@auth/containers/registration/VerificationEmailForm';
import { VerificationPhone } from '@auth/pages/registration/VerificationPhone';

export const RegistrationRouter = () => {
	const { t } = useTranslation();
	const { userData, token } = useSelector((
		state: BaseState,
	) => state.base.authPersist);

	return (
		// <div className={styles.registration}>
		<Switch>
			<Route
				exact
				path={authRoutes.auth.registration.root}
				render={() => (token
					? <Redirect to={authRoutes.auth.registration['verification-email'].root} />
					: (
						<AuthFormLayout text={t('AUTH.REGISTRATION.TITLE')}>
							<RegistrationForm />
						</AuthFormLayout>
					)
				)}
			/>

			{/*---------------------------------*/}

			<Route
				exact
				path={authRoutes.auth.registration['verification-email'].root}
				render={() => (token && !userData.isConfirmedEmail
					? (
						<AuthFormLayout text={t('AUTH.REGISTRATION.VERIFICATION_EMAIL.TITLE')}>
							<VerificationEmailForm />
						</AuthFormLayout>
					)

					: <Redirect to={authRoutes.auth.registration['verification-phone'].root} />
				)}
			/>

			{/*---------------------------------*/}

			<Route
				exact
				path={authRoutes.auth.registration['verification-phone'].root}
				render={() => (token && userData.isConfirmedEmail && !userData.isConfirmedPhone
					? (
						<AuthFormLayout text={t('AUTH.REGISTRATION.VERIFICATION_PHONE.TITLE')}>
							<VerificationPhone />
						</AuthFormLayout>
					)
					: <Redirect to={authRoutes.auth.root} />
				)}
			/>
			<Redirect to={token ? authRoutes.auth.registration.root : authRoutes.auth.root} />
		</Switch>
		// </div>
	);
};

export default null;
