import React, { useEffect } from 'react';
import logo_header_auth from '@base/assets/images/logo_header_auth.svg';
import { LanguagePanel } from '@base/containers/header/LanguagePanel';
import ReactMarkdown from 'react-markdown';
import { Text } from '@base/components';
import { useTranslation } from 'react-i18next';
import styles from './AuthLayout.module.scss';

interface AuthLayoutProps {
	children: React.ReactNode,
}

export function AuthLayout({
	children,
}: AuthLayoutProps) {
	const { t } = useTranslation();

	useEffect(() => {
		if (document.body) {
			document.body.style.overflow = 'auto';
		}
	}, []);

	return (
		<div className={styles.auth_layout}>
			<header className={styles.auth_layout__header}>
				<div className={styles.auth_layout__header__child}>
					<img src={logo_header_auth} alt="" className={styles.auth_layout__header__logo} />
				</div>
				<div className={styles.auth_layout__header__child__plug} />
				<div className={styles.auth_layout__header__child}>
					<LanguagePanel moduleType="auth" />
				</div>
			</header>
			<main className={styles.auth_layout__main}>
				{children}
			</main>
			<footer className={styles.auth_layout__footer}>
				<Text type="h5">
					<ReactMarkdown>
						{t('AUTH.FOOTER.BASE')}
					</ReactMarkdown>
				</Text>
			</footer>
		</div>
	);
}

export default null;
