// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, {
	useState,
	useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cx from 'classnames';

import useScrollData from '@base/hooks/useScrollData';
import type { BaseState, MenuLink } from '@base/types';
import { ClientBrokerState } from '@client_broker/types';

import { MainMenu } from '@client_broker/containers/MainMenu';
import logo_header_menu from '@base/assets/images/logo_header_menu.svg';
import wallet from '@base/assets/icons/header/wallet.svg';
import letter from '@base/assets/icons/header/letter.svg';
import { HeaderIcons } from '@base/assets/icons/header';
import { Text, Notification, ButtonText } from '@base/components';
import { screenTypes } from '@base/constants/screenTypes';
import { Colors } from '@base/assets/styles/colors';
import { ReplenishBalance } from '@client_broker/containers/header/ReplenishBalance';
import { LanguagePanel } from '@base/containers/header/LanguagePanel';
import { ProfileButton } from '@client_broker/containers/header/ProfileButton';
import { gettingNotificationAction } from '@base/store/Notifications/actions';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
// import info2 from '@base/assets/icons/info2.svg';
import styles from './MainLayout.module.scss';

interface OwnProps {
	children?: any,
	menuLinks: Array<MenuLink>,
}

type MainLayoutProps = {} & OwnProps

const ButtonShowMenu = ({ onClick, color }: {onClick: () => void, color?: string}) => {
	const { open_menu: IconOpenMenu } = HeaderIcons;
	return (
		// eslint-disable-next-line max-len
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div className={styles['icon-show-menu-wrap']}>
			<IconOpenMenu
				onClick={onClick}
				className={styles['icon-show-menu-wrap__icon']}
				color={color}
			/>
		</div>
	);
};

export function MainLayout({
	children,
	menuLinks,
}: MainLayoutProps) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { pathname } = useSelector(
		(state: BaseState) => state.router.location,
	);
	const { customer } = useSelector(
		(state: ClientBrokerState) => state.clientBroker,
	);

	const { notification } = useSelector(
		(state: ClientBrokerState) => state.base,
	);

	const [showNotification, setShowNotification] = useState(false);

	useEffect(() => {
		if (notification.isUpdate) {
			setShowNotification(true);
		}
	}, [notification]);

	// useEffect(() => {
	// 	dispatch(customerAction());
	// }, []);

	const [
		isOpen,
		setIsOpen,
	] = useState<boolean>(false);
	const {
		direction,
		y,
	} = useScrollData();

	useEffect(() => {
		setIsOpen(false);
	}, [pathname]);

	useEffect(() => {
		if (isOpen) {
			if (document.body) {
				document.body.style.overflow = 'hidden';
			}
		} else if (document.body) {
			document.body.style.overflow = 'auto';
		}
	}, [
		isOpen,
	]);

	return (
		<div className={styles.main_layout}>
			{/* mobile */}
			<>
				<header
					className={cx(
						styles.main_layout__mobile__header,
						{ [styles['main_layout__mobile__header--down']]: direction === 'down' && y > 30 },
					)}
				>
					<ButtonShowMenu onClick={() => setIsOpen(!isOpen)} color={Colors.white} />
					<div />
					<div className={styles.main_layout__mobile__header__right}>
						<NavLink
							className={styles.main_layout__mobile__header__right__child}
							to={clientBrokerRoutes.wallet.root}
						>
							<img
								src={wallet}
								alt=""
							/>
						</NavLink>
						<img
							className={styles.main_layout__mobile__header__right__child}
							src={letter}
							alt=""
							onClick={() => window.location.assign(`mailto:${t('AUTH.SUPPORT.EMAIL')}`)}
						/>
						<LanguagePanel
							className={styles.main_layout__mobile__header__right__child}
						/>
						<ProfileButton
							className={styles.main_layout__mobile__header__right__child}
							type={screenTypes.mobile}
						/>
					</div>
				</header>
				<div
					className={cx(
						styles.main_layout__mobile__menu,
						{ [styles['main_layout__mobile__menu--open']]: isOpen },
					)}
					role="menu"
				>
					<header className={styles.main_layout__mobile__menu__header}>
						<img src={logo_header_menu} alt="" />
					</header>
					<MainMenu menuLinks={menuLinks} />
					<div className={styles.main_layout__mobile__menu__footer}>
						Footer menu mobile
					</div>
				</div>
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/control-has-associated-label,jsx-a11y/interactive-supports-focus */}
				<div
					role="button"
					className={cx(
						styles['main_layout__mobile__block-shadow'],
						{ [styles['main_layout__mobile__block-shadow--open']]: isOpen },
					)}
					onClick={() => setIsOpen(false)}
				/>
			</>

			{/* desktop */}
			<>
				{/* aside-menu */}
				<>
					{/* <aside */}
					<div
						className={cx(
							styles['main_layout__desktop__aside-menu'],
							{ [styles['main_layout__desktop__aside-menu--open']]: isOpen },
						)}
					>
						<header className={styles['main_layout__desktop__aside-menu__header']}>
							<img src={logo_header_menu} alt="" />
						</header>
						<MainMenu menuLinks={menuLinks} />
						<footer className={styles['main_layout__desktop__aside-menu__footer']}>
							{customer.info.data.id !== 0
									&& (
										<Text>
											{`Акаунт № ${customer.info.data.id}`}
										</Text>
									)}
							<ButtonText
								className={styles['main_layout__desktop__aside-menu__footer__support']}
								color="white"
								onClick={() => window.location.assign(`mailto:${t('AUTH.SUPPORT.EMAIL')}`)}
							>
								{t('AUTH.SUPPORT.EMAIL')}
							</ButtonText>
						</footer>
					</div>
				</>
				{/* main */}
				<>
					<main className={styles.main_layout__desktop__main}>
						{/* header */}
						<header className={styles.main_layout__desktop__main__header}>
							<div className={styles['main_layout__desktop__main__header__button-wrap']}>
								<ButtonShowMenu onClick={() => setIsOpen(!isOpen)} color={Colors.black} />
							</div>
							<div className={styles['main_layout__desktop__main__header__child-wrapper-left']}>
								{/* <div className={styles['main_layout__desktop__main__header__child']}> */}
								{/*	<BalanceInfo */}
								{/*		className={styles['main_layout__desktop__main__header__balance_info']} */}
								{/*	/> */}
								{/* </div> */}
								<div className={styles.main_layout__desktop__main__header__child}>
									<ReplenishBalance
										className={styles.main_layout__desktop__main__header__replenish_balance}
									/>
								</div>
							</div>
							<div className={styles['main_layout__desktop__main__header__child-wrapper']}>
								<div className={styles.main_layout__desktop__main__header__child}>
									<LanguagePanel moduleType="client" />
								</div>
								<div className={styles.main_layout__desktop__main__header__child}>
									<ButtonText
										className={styles.main_layout__desktop__main__header__support}
										color="gray"
										onClick={() => window.location.assign(`mailto:${t('AUTH.SUPPORT.EMAIL')}`)}
									>
										{t('AUTH.SUPPORT.EMAIL')}
									</ButtonText>
								</div>
								<div className={styles.main_layout__desktop__main__header__child}>
									<ProfileButton type={screenTypes.desktop} />
								</div>
							</div>
						</header>
						<div className={styles['main_layout__desktop__main__page-wrapper']}>
							{/* <div className={styles.icon_wrap}> */}
							{/*	<img src={info2} alt="" className={styles.icon_info} /> */}
							{/* </div> */}
							{children}
						</div>
						<div className={cx(
							styles.main_layout__desktop__main__notification,
							{
								[styles['main_layout__desktop__main__notification--animation']]: showNotification,
							},
						)}
						>
							<Notification
								type={notification.type}
								message={notification.message}
								isUpdate={notification.isUpdate}
								onGettingNotification={() => {
									dispatch(gettingNotificationAction());
								}}
								onClose={() => { setShowNotification(false); }}
							/>
						</div>
						<footer className={styles.main_layout__desktop__main__footer}>
							<div className={styles.main_layout__desktop__main__footer__content}>
								<Text type="h5" className={styles.main_layout__desktop__main__footer__content__text}>
									{t('CLIENT.FOOTER.DESCRIPTIONS')}
									<br />
									<br />
									{t('CLIENT.FOOTER.ALL_RIGHTS_RESERVED')}
								</Text>
							</div>
						</footer>
					</main>
				</>
			</>
		</div>
	);
}

export default null;
