import * as passwordRecoveryTypes from '@auth/store/PasswordRecovery/constants';
import type { PasswordRecoveryActionsType } from '@auth/store/PasswordRecovery/types';
import { RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type PasswordRecoveryState = {
	sendEmail: {
		isSent: boolean;
	} & RequestInfoChildType,
	restore: {
		isSent: boolean;
	} & RequestInfoChildType,
	checkPassToken: {
	} & RequestInfoChildType,
};

const initialState: PasswordRecoveryState = {
	sendEmail: {
		isSent: false,
		...RequestInfoChildState,
	},
	restore: {
		isSent: false,
		...RequestInfoChildState,
	},
	checkPassToken: {
		...RequestInfoChildState,
		isLoading: true,
	},
};

export const passwordRecoveryReducer = (
	state: PasswordRecoveryState = initialState,
	action: PasswordRecoveryActionsType,
): PasswordRecoveryState => {
	switch (action.type) {
		case passwordRecoveryTypes.PASSWORD_RECOVERY_INIT_STATE: {
			return initialState;
		}

		case passwordRecoveryTypes.PASSWORD_RECOVERY_SEND_EMAIL_REQ: {
			return {
				...state,
				sendEmail: {
					...state.sendEmail,
					isLoading: true,
				},
			};
		}

		case passwordRecoveryTypes.PASSWORD_RECOVERY_SEND_EMAIL_SUCCESS: {
			return {
				...state,
				sendEmail: {
					...state.sendEmail,
					isSent: true,
					isLoading: false,
				},
			};
		}

		case passwordRecoveryTypes.PASSWORD_RECOVERY_SEND_EMAIL_FAIL: {
			const { payload } = action;
			return {
				...state,
				sendEmail: {
					...state.sendEmail,
					isSent: false,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		// ---------------------------------

		case passwordRecoveryTypes.PASSWORD_RECOVERY_RESTORE_REQ: {
			return {
				...state,
				restore: {
					...state.restore,
					isLoading: true,
				},
			};
		}

		case passwordRecoveryTypes.PASSWORD_RECOVERY_RESTORE_SUCCESS: {
			return {
				...state,
				restore: {
					...state.restore,
					isSent: true,
					isLoading: false,
				},
			};
		}

		case passwordRecoveryTypes.PASSWORD_RECOVERY_RESTORE_FAIL: {
			const { payload } = action;
			return {
				...state,
				restore: {
					...state.restore,
					isSent: false,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		// ---------------------------------

		case passwordRecoveryTypes.PASSWORD_RECOVERY_CHECK_PASS_TOKEN_REQ: {
			return {
				...state,
				checkPassToken: {
					...state.checkPassToken,
					isLoading: true,
				},
			};
		}

		case passwordRecoveryTypes.PASSWORD_RECOVERY_CHECK_PASS_TOKEN_SUCCESS: {
			return {
				...state,
				checkPassToken: {
					...state.checkPassToken,
					isLoading: false,
				},
			};
		}

		case passwordRecoveryTypes.PASSWORD_RECOVERY_CHECK_PASS_TOKEN_FAIL: {
			const { payload } = action;
			return {
				...state,
				checkPassToken: {
					...state.restore,
					isLoading: false,
					error: payload.error,
				},
			};
		}
		default: {
			return state;
		}
	}
};
