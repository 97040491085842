export const getAndDeleteQueryParamByName = (
	name: string, location: any, history: any,
): string | undefined => {
	const queryParams = new URLSearchParams(location.search);
	const param = queryParams.get(name);

	if (param) {
		queryParams.delete(name);
		history.replace({
			search: queryParams.toString(),
		});
		return param;
	}
	return undefined;
};

export const getAndDeleteQueryParamsByNames = (
	names: Array<string>, location: any, history: any,
): {[key: string]: string} => {
	const queryParams = new URLSearchParams(location.search);
	let params: {[key: string]: string} = {};

	names.forEach((value) => {
		const param = queryParams.get(value);
		if (param) {
			params = {
				...params,
				[value]: param,
			};
		}
	});

	if (Object.keys(params).length !== 0) {
		Object.keys(params).forEach((value) => {
			queryParams.delete(value);
		});
		history.replace({
			search: queryParams.toString(),
		});
		return params;
	}
	return {};
};
