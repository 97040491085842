import React from 'react';

import cx from 'classnames';
import { TransferHistory } from '@base/types';
import correctIcon from '@base/assets/icons/correct.svg';
import wrongIcon from '@base/assets/icons/wrong.svg';
import waitingIcon from '@base/assets/icons/waiting.svg';
import styles from './TableRow.module.scss';

type OwnProps = {};

type TableRowProps = {
	id: string,
	item: TransferHistory | any
} & OwnProps;

const TableRow = ({
	id,
	item,
}: TableRowProps) => {
	const ItemWrapper = ({
		className,
		children,
	}: { children: React.ReactNode, className?: string}) => (
		<div className={cx(className, styles.table_row_wrapper)}>
			{children}
		</div>
	);

	const ItemDefault = () => (
		<ItemWrapper>
			{
				item[id] !== undefined
				&& item[id] !== ''
				&& item[id] !== null
				&& item[id]
					? item[id] : '__'
			}
		</ItemWrapper>
	);

	const ActionRow = () => (
		<ItemWrapper>
			<div className={cx(
				styles[`action__${item.action}`],
			)}
			>
				{item.action === 'withdraw' && 'Снятие'}
				{item.action === 'deposit' && 'Внесение'}
			</div>
		</ItemWrapper>
	);
	const StatusRow = () => (
		<ItemWrapper>
			<div className={styles.status_flex}>
				<div className={styles.status}>
					<div className={cx(
						styles[`status__${item.status}`],
					)}
					>
						{item.status === 'declined' && 'Отмена'}
						{item.status === 'pending' && 'На подтверждении'}
						{item.status === 'approved' && 'Успешно'}
					</div>
					{item.status === 'approved' && <img src={correctIcon} alt="" />}
					{item.status === 'declined' && <img src={wrongIcon} alt="" />}
					{item.status === 'pending' && <img src={waitingIcon} alt="" />}
				</div>
			</div>
		</ItemWrapper>
	);

	switch (id) {
		case 'action':
			return <ActionRow />;
		case 'status':
			return <StatusRow />;
		default:
			return (<ItemDefault />);
	}
};

TableRow.defaultProps = {
	item: {
		accountName: 'testName',
	},
};

export default TableRow;
