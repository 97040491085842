import * as verificationPhoneTypes from '@auth/store/VerificationPhone/constants';
import type { VerificationPhoneActionsType } from '@auth/store/VerificationPhone/types';
import { CallAttemptResp, RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type VerificationPhoneState = {
	// phoneSendCode: RequestInfoChildType,
	phoneMakeCall: RequestInfoChildType,
	verifyPhone: RequestInfoChildType,
	callAttempt: {
		data?: CallAttemptResp,
	} & RequestInfoChildType,
};

const initialState: VerificationPhoneState = {
	// phoneSendCode: RequestInfoChildState,
	phoneMakeCall: RequestInfoChildState,
	verifyPhone: RequestInfoChildState,
	callAttempt: {
		data: undefined,
		...RequestInfoChildState,
	},
};

export const verificationPhoneReducer = (
	state: VerificationPhoneState = initialState,
	action: VerificationPhoneActionsType,
): VerificationPhoneState => {
	switch (action.type) {
		// case verificationPhoneTypes.VERIFICATION_PHONE_SEND_CODE_REQUEST: {
		// 	return {
		// 		...state,
		// 		phoneSendCode: {
		// 			...state.phoneSendCode,
		// 			isLoading: true,
		// 		},
		// 	};
		// }
		//
		// case verificationPhoneTypes.VERIFICATION_PHONE_SEND_CODE_SUCCESS: {
		// 	return {
		// 		...state,
		// 		phoneSendCode: {
		// 			...state.phoneSendCode,
		// 			isLoading: false,
		// 		},
		// 	};
		// }
		//
		// case verificationPhoneTypes.VERIFICATION_PHONE_SEND_CODE_FAIL: {
		// 	const { payload } = action;
		// 	return {
		// 		...state,
		// 		phoneSendCode: {
		// 			...state.phoneSendCode,
		// 			isLoading: false,
		// 			error: payload.error,
		// 		},
		// 	};
		// }

		// =======================================

		case verificationPhoneTypes.VERIFICATION_PHONE_MAKE_CALL_REQUEST: {
			return {
				...state,
				phoneMakeCall: {
					...state.phoneMakeCall,
					isLoading: true,
				},
			};
		}

		case verificationPhoneTypes.VERIFICATION_PHONE_MAKE_CALL_SUCCESS: {
			return {
				...state,
				phoneMakeCall: {
					...state.phoneMakeCall,
					isLoading: false,
					error: null,
				},
			};
		}

		case verificationPhoneTypes.VERIFICATION_PHONE_MAKE_CALL_FAIL: {
			const { payload } = action;
			return {
				...state,
				phoneMakeCall: {
					...state.phoneMakeCall,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		// =======================================

		case verificationPhoneTypes.VERIFICATION_PHONE_REQUEST: {
			return {
				...state,
				verifyPhone: {
					...state.verifyPhone,
					isLoading: true,
				},
			};
		}

		case verificationPhoneTypes.VERIFICATION_PHONE_SUCCESS: {
			return {
				...state,
				verifyPhone: {
					...state.verifyPhone,
					isLoading: false,
					error: null,
				},
			};
		}

		case verificationPhoneTypes.VERIFICATION_PHONE_FAIL: {
			const { payload } = action;
			return {
				...state,
				verifyPhone: {
					...state.verifyPhone,
					isLoading: false,
					error: payload.error,
				},
			};
		}
		// =======================================

		case verificationPhoneTypes.VERIFICATION_PHONE_CALL_ATTEMPT_REQUEST: {
			return {
				...state,
				callAttempt: {
					...state.callAttempt,
					isLoading: true,
				},
			};
		}

		case verificationPhoneTypes.VERIFICATION_PHONE_CALL_ATTEMPT_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				callAttempt: {
					...state.callAttempt,
					data: payload,
					isLoading: false,
					error: null,
				},
			};
		}

		case verificationPhoneTypes.VERIFICATION_PHONE_CALL_ATTEMPT_FAIL: {
			const { payload } = action;
			return {
				...state,
				callAttempt: {
					...state.callAttempt,
					isLoading: false,
					error: payload.error,
				},
			};
		}
		default: {
			return state;
		}
	}
};
