import generatorPass from 'generate-password';

const generateMultiplePassword = () => generatorPass.generateMultiple(1, {
	length: 10,
	uppercase: true,
	lowercase: true,
	numbers: true,
	symbols: true,
	strict: true,
	exclude: '\\/№',
});

export default {
	generateMultiplePassword,
};
