// eslint-disable-next-line max-len
export const getTabPaths = (routes: any): any => {
	// @ts-ignore
	const tabPaths = [];
	Object.keys(routes).forEach((key) => {
		if (routes[key].isInMenu === false) return;
		let label: string = '';
		if (routes[key] && routes[key].root !== undefined) {
			if (routes[key].title !== undefined) {
				label = String(routes[key].title);
			}
			const path = String(routes[key].root);
			tabPaths.push({
				label,
				path,
			});
		}
	});

	// @ts-ignore
	return tabPaths;
};

export const getMenuPaths = (routes: any) => {
	// @ts-ignore
	const menuPaths = [];
	Object.keys(routes).forEach((key) => {
		if (routes[key].isInMenu === false) return;

		let tKeyRoot;
		let label: string = '';
		let icon: string = 'icon-calendar';
		if (routes[key] && routes[key].root !== undefined) {
			if (routes[key].title !== undefined) {
				label = String(routes[key].title);
			}
			if (routes[key].icon !== undefined) {
				icon = String(routes[key].icon);
			}
			if (routes[key].tKeyRoot !== undefined) {
				tKeyRoot = String(routes[key].tKeyRoot);
			}
			const path = String(routes[key].root);
			menuPaths.push({
				label,
				path,
				icon,
				tKeyRoot,
			});
		}
	});

	// @ts-ignore
	return menuPaths;
};

export default null;
