import {
	all, call, put, takeLeading,
} from 'redux-saga/effects';

import {
	profileVerificationSuccessAction,
	profileVerificationFailAction,
} from '@client_broker/store/ProfileVerification/actions';
import * as profileVerificationTypes from '@client_broker/store/ProfileVerification/constants';
import { PatchCustomerResp } from '@base/types';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { ProfileVerificationActionType } from '@client_broker/store/ProfileVerification/types';
import { modalCloseAction } from '@base/store/Modal/actions';
import { documentFilesAction } from '@client_broker/store/DocumentFiles/actions';
import { customerAction } from '@client_broker/store/Customer/actions';

function* profileVerificationSaga({ payload }: ProfileVerificationActionType) {
	try {
		const {
			first_name,
			last_name,
			birthday,
			zipcode,
			address,
			country_code,
			city,
			is_identity,
		} = payload;

		const body = first_name ? {
			first_name,
			last_name,
			birthday,
		} : {
			zipcode,
			address,
			country_code,
			city,
		};
		const respUpdateCustomer: PatchCustomerResp = yield call(
			apiRequestClientBroker,
			'patchCustomer',
			{
				body,
			},
		);
		if (!respUpdateCustomer.success
			&& respUpdateCustomer.error !== null
			&& respUpdateCustomer.error !== undefined
			&& respUpdateCustomer.error !== '') {
			throw new Error(respUpdateCustomer.error);
		} else if (!respUpdateCustomer.success) {
			throw new Error('Unknown error');
		}
		const {
			files, file_type, others_type,
		} = payload;

		// @ts-ignore
		const respSendDocuments: any = yield all(
			yield files.map((value) => call(apiRequestClientBroker, 'putDocumentFiles', {
				req: {
					file: value,
					file_type,
					others_type,
					is_identity,
				},
			})),
		);

		if (respSendDocuments[0] && respSendDocuments[0].success) {
			yield put(profileVerificationSuccessAction());
			yield put(documentFilesAction());
			yield put(customerAction());
			yield put(modalCloseAction());
		} else if (
			respSendDocuments.error !== null
      && respSendDocuments.error !== undefined
      && respSendDocuments.error !== ''
		) {
			throw new Error(respSendDocuments.error);
		} else {
			throw new Error('Unknown error');
		}
	} catch (err) {
		yield put(profileVerificationFailAction({ error: err.message }));
	}
}

export function* profileVerificationWatcher() {
	yield takeLeading(
		profileVerificationTypes.CLIENT_BROKER_PROFILE_VERIFICATION_REQUEST,
		profileVerificationSaga,
	);
}
