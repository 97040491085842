import React from 'react';
import cx from 'classnames';

import { ButtonSend } from '@base/components';

import { Input, InputProps } from '@base/components/Input';
import styles from './InputSend.module.scss';

type InputSendProps = {
	// eslint-disable-next-line no-unused-vars
	onChange: (obj: {
		name: string,
		value: string | number,
		event?: React.SyntheticEvent<HTMLInputElement>,
	}) => void,
	onClick: () => void,
	placeholder?: string,
	className?: string,
	disabledBtn?: boolean,
	label?: string,
} & InputProps;

export const InputSend = ({
	className,
	name,
	value,
	placeholder,
	onChange,
	onClick,
	disabledBtn,
	onFocus,
	onBlur,
	info,
	label,
}: InputSendProps) => (
	<div className={cx(styles.input_send, className)}>
		<Input
			className={styles.input_send__input}
			label={label}
			type="text"
			name={name}
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			info={info}
		/>
		<ButtonSend
			className={styles.input_send__button_send}
			onClick={onClick}
			disabled={disabledBtn}
		/>
	</div>
);

export default null;
