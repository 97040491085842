import * as errorHandlerTypes from '@base/store/ErrorHandler/constants';
import React from 'react';
import { OnClickParamOfNotificationsModalType } from '@base/store/NotificationsModal/types';

export const errorHandlerAction = (payload: {
	status?: number,
	error?: string,
	notificationData?: {
		descriptionComponent?: React.ReactNode,
		descriptionTKey?: string,
		// eslint-disable-next-line no-unused-vars
		onClick?: (obj: OnClickParamOfNotificationsModalType) => any,
		// eslint-disable-next-line no-unused-vars
		onClickClose?: (obj: OnClickParamOfNotificationsModalType) => any,
	}
}) => ({
	type: errorHandlerTypes.BASE_ERROR_HANDLER,
	payload,
});

export default null;
