import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '@auth/types';
import { verificationPhoneCallAttemptAction } from '@auth/store/VerificationPhone/actions';
import { VerificationPhoneCheckForm } from '@auth/containers/registration/verificationPhone/VerificationPhoneCheckForm';
import { VerificationPhoneSendForm } from '@auth/containers/registration/verificationPhone/VerificationPhoneSendForm';
import { LoaderSpinner } from '@base/components';
import styles from './VerificationPhone.module.scss';

type VerificationPhoneProps = {};

// eslint-disable-next-line no-empty-pattern
export const VerificationPhone = ({}: VerificationPhoneProps) => {
	const dispatch = useDispatch();
	const { callAttempt } = useSelector((
		state: AuthState,
	) => state.auth.verificationPhone);

	const [isShowSendForm, setIsShowSendForm] = useState(true);

	useEffect(() => {
		dispatch(verificationPhoneCallAttemptAction());
	}, []);

	useEffect(() => {
		if (callAttempt.data?.phone && callAttempt.data?.phone !== '') {
			setIsShowSendForm(false);
		}
	}, [callAttempt]);

	return (
		// const dispatch = useDispatch();
		// const { isLoading, error } = useSelector((state: BaseState) => state.data);
		<div className={styles.verification_phone}>
			{isShowSendForm
				? (
					<>
						{callAttempt.isLoading
							? (
								<div className={styles.loader}>
									<LoaderSpinner />
								</div>
							) : <VerificationPhoneSendForm />}
					</>
				)
				: <VerificationPhoneCheckForm setIsShowSendForm={setIsShowSendForm} />}
		</div>
	);
};

export default null;
