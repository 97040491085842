import * as systemTypes from '@base/store/System/constants';

export const systemAction = () => ({
	type: systemTypes.BASE_SYSTEM_REQUEST,
});

export const systemSuccessAction = () => ({
	type: systemTypes.BASE_SYSTEM_SUCCESS,
});

export const systemFailAction = (payload: { error: string }) => ({
	type: systemTypes.BASE_SYSTEM_FAIL,
	payload,
});

export default null;
