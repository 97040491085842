import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import { Button, Text, Input } from '@base/components';
import { modalCloseAction } from '@base/store/Modal/actions';
import { useDispatch } from 'react-redux';
import { object, ref, string } from 'yup';
import errorMessages from '@base/constants/errorMessages';

import { useFormik } from 'formik';
import { updateCustomerAccountAction } from '@client_broker/store/СustomerAccounts/actions';
import { UpdateAccount } from '@base/types';
import { passwordYup } from '@base/utils/yup';
import { useTranslation } from 'react-i18next';
import styles from './ChangeAccountPasswordModal.module.scss';

type ChangeAccountPasswordModalProps = {
	id: number,
	mt_id: number,
	type: 'investor' | 'trading' | 'phone',
};

type ChangeAccountPasswordModalFieldsType = {
	password: string,
	password_confirm: string,
}

const initialValues = {
	password: '',
	password_confirm: '',
};

export const ChangeAccountPasswordModal = ({
	id, mt_id, type,
}: ChangeAccountPasswordModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const validationSchema = object().shape({
		password: passwordYup(t, ''),
		password_confirm: string()
			.oneOf([ref('password')], 'Пароли не совпадают')
			.required(errorMessages.FIELD_IS_REQUIRED),
	});

	const getText = () => {
		switch (type) {
			case 'investor':
				return `Изменить инвесторский пароль для счета # ${mt_id}`;
			case 'trading':
				return `Изменить торговый пароль для счета # ${mt_id}`;
			case 'phone':
				return `Телефонный пароль для счета # ${mt_id}`;
			default:
				return '';
		}
	};

	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<ChangeAccountPasswordModalFieldsType>({
		initialValues,
		onSubmit: (formData: any): any => {
			let payload: UpdateAccount;
			// eslint-disable-next-line default-case
			switch (type) {
				case 'investor':
					payload = { id, body: { password: formData.password } };
					break;
				case 'trading':
					payload = { id, body: { investor: true, password: formData.password } };
					break;
				case 'phone':
					payload = { id, body: { phone_password: formData.password } };
					break;
			}
			if (payload) {
				dispatch(
					updateCustomerAccountAction(payload),
				);
			}
		},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const event = {
				target: {	name,	value	},
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	return (
		// const { isLoading, error } = useSelector((state: BaseState) => state.data);
		<div className={styles.change_account_password}>
			<Text type="h2" className={styles.change_account_password__text}>
				{getText()}
			</Text>
			<div className={styles.change_account_password__input}>
				<Input
					label="Пароль"
					name="password"
					required
					type="password"
					value={values.password}
					onChange={onChangeHandler}
					onBlur={handleBlur}
					error={
						(touched.password !== undefined && errors.password !== undefined)
							? errors.password || ''
							: undefined
					}
				/>
			</div>
			<div className={styles.change_account_password__input}>
				<Input
					label="Подтвердить пароль"
					name="password_confirm"
					required
					type="password"
					value={values.password_confirm}
					onChange={onChangeHandler}
					onBlur={handleBlur}
					error={
						(touched.password_confirm !== undefined && errors.password_confirm !== undefined)
							? errors.password_confirm || ''
							: undefined
					}
				/>
			</div>
			<div className={styles.change_account_password__btns}>
				<Button
					type="button"
					theme="secondary"
					size="large"
					onClick={() => handleSubmit()}
					disabled={!isValid}
				>
					Сохранить пароль
				</Button>
				<Button
					type="button"
					theme="secondary"
					size="large"
					onClick={() => { dispatch(modalCloseAction()); }}
				>
					Отмена
				</Button>
			</div>
		</div>
	);
};

export default null;
