import { call, put, takeLeading } from 'redux-saga/effects';

import {
	tradesSuccessAction,
	tradesFailAction,
} from '@client_broker/store/Trades/actions';
import { CLIENT_BROKER_TRADES_REQUEST } from '@client_broker/store/Trades/constants';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { TradesActionType } from '@client_broker/store/Trades/types';
import { PostTradesResp } from '@base/types';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';

function* tradesSaga({ payload }: TradesActionType) {
	try {
		const resp: PostTradesResp = yield call(
			apiRequestClientBroker, 'postTrades', { params: payload },
		);
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(tradesSuccessAction(resp.data));
	} catch (e) {
		console.log(e);
		yield put(tradesFailAction({ error: e.message }));
	}
}

export function* tradesWatcher() {
	yield takeLeading(CLIENT_BROKER_TRADES_REQUEST, tradesSaga);
}
