import React, { useEffect, useMemo, useState } from 'react';

import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import hash from 'object-hash';

import { AuthState } from '@auth/types';
// import {
// 	emailYup, firstnameYup, lastnameYup, passwordYup,
// } from '@base/utils/yup';
import {
	emailYup, firstnameYup, lastnameYup, passwordYup, referralYup,
} from '@base/utils/yup';
import { useFormik } from 'formik';
import { registrationAction } from '@auth/store/Registration/actions';
import { partnerRegistrationAction } from '@auth/store/PartnerRegistration/actions';
import {
	Button, Checkbox, Text, ButtonText, Input, Textarea, LoaderSpinner,
	ButtonLink,
} from '@base/components';

import partnerRegistrationRoutes from '@auth/constants/partnersRegistrationRoutes';
import cx from 'classnames';
import { SocialIcons, SocialNetworks, SocialNetworksType } from '@base/assets/icons/social_icons/SocialIcons';
import { Colors } from '@base/assets/styles/colors';
import { ClientBrokerState } from '@client_broker/types';
import { getInfoInput } from '@base/utils/inputFunctions';
// import { referralCodeAction } from '@base/store/Referral/actions';
import styles from './PartnerRegForm.module.scss';

type PartnerRegFormProps = {
	programName: 'ib' | 'multilevel'
};

export interface PartnerRegFormNoIsAuthFieldsType {
	first_name?: string,
	last_name?: string,
	email?: string,
	password?: string,
	referral: string,
	website: string,
	customer_experience: string,
	customer_base: boolean,
	program_name: string,
	facebook: string,
	instagram: string,
	skype: string,
	telegram: string,
	viber: string,
	vk: string,
	whatsapp: string,
	isCustomerAgreementAccepted: boolean,
}

export const initialValuesNoIsAuth = {
	first_name: '',
	last_name: '',
	email: '',
	password: '',
	referral: '',
	website: '',
	customer_base: false,
	leverage_id: 3,
	account_type_id: 23,
	customer_experience: '',
	program_name: '',
	facebook: '',
	instagram: '',
	skype: '',
	telegram: '',
	viber: '',
	vk: '',
	whatsapp: '',
	isCustomerAgreementAccepted: false,
};

export const initialValuesIsAuth = {
	referral: '',
	website: '',
	customer_base: false,
	leverage_id: 3,
	account_type_id: 23,
	customer_experience: '',
	program_name: '',
	isCustomerAgreementAccepted: false,
};

export const PartnerRegForm = ({
	programName,
}: PartnerRegFormProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { registration } = useSelector((
		state: AuthState,
	) => state.auth.registration);

	const { partnerRegistration } = useSelector((
		state: AuthState,
	) => state.auth.partnerRegistration);

	const {
		authPersist: { userData, token },
		referral: { referralCode },
	} = useSelector((
		state: AuthState,
	) => state.base);

	const { customer } = useSelector(
		(state: ClientBrokerState) => state.clientBroker,
	);
	const [isAuth, setIsAuth] = useState(false);

	useEffect(() => {
		if (userData.isConfirmedEmail && token) {
			setIsAuth(true);
		} else {
			setIsAuth(false);
		}
	}, [userData, token]);

	// useEffect(() => {
	// 	dispatch(referralCodeAction());
	// }, []);

	const [showInputReferralCode, setShowInputReferralCode] = useState(false);
	// eslint-disable-next-line no-unused-vars
	// const [isHaveReferral, setIsHaveReferral] = useState(false);
	// const [isCustomerAgreementAccepted, setIsCustomerAgreementAccepted] = useState(false);
	const [socialNetworkName, setSocialNetworkName] = useState<SocialNetworksType | ''>('');

	const validationSchema = object().shape({
		// возможно, передавать REGISTRATION,
		// т.к. нет нужных ключей в PARTNER_REG или добавить их в PARTNER_REG
		first_name: firstnameYup(t, 'PARTNER_REG'),
		last_name: lastnameYup(t, 'PARTNER_REG'),
		email: emailYup(t, 'PARTNER_REG'),
		password: passwordYup(t, 'PARTNER_REG'),
		referral: string(),
		website: string(),
	});

	const validationSchemaIsAuth = object().shape({
		// referral: referralYup(t, 'MODULES.PARTNER_REG.REG.INPUTS'),
		referral: referralYup(),
		website: string(),
	});

	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<PartnerRegFormNoIsAuthFieldsType>({
		// @ts-ignore
		initialValues: isAuth ? initialValuesIsAuth : initialValuesNoIsAuth,
		onSubmit: (formData: PartnerRegFormNoIsAuthFieldsType): void => {
			if (isAuth) {
				// @ts-ignore
				dispatch(partnerRegistrationAction({
					...formData,
					program_name: programName,
					customer_id: customer.info.data.id,
				}));
			} else {
				// @ts-ignore
				dispatch(registrationAction({
					...formData,
					program_name: programName,
				}));
			}
		},
		validationSchema: isAuth ? validationSchemaIsAuth : validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	useEffect(() => {
		if (referralCode.isHave) {
			setValues({
				...values,
				referral: referralCode.ref_code,
			});
		}
	}, [referralCode.ref_code, referralCode.isHave]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			let target = { name, value };
			// eslint-disable-next-line default-case
			switch (name) {
				case 'referral':
					if (value.length > 30) return;
					target = {	name,	value: value.replace(/[^\da-zA-Z]/g, '') };
			}
			const event = {
				target,
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	const isLoading = useMemo(() => (
		registration.isLoading || partnerRegistration.isLoading
	), [registration.isLoading, partnerRegistration.isLoading]);

	return (
		<form
			className={styles.form}
			onSubmit={handleSubmit}
		>
			{isLoading && <LoaderSpinner />}
			<Text type="h3">
				Регистрация партнера
			</Text>
			{!isAuth && (
				<Input
					className={styles.input}
					label={t('AUTH.REGISTRATION.FIRSTNAME_LABEL')}
					name="first_name"
					required
					value={values.first_name || ''}
					onChange={onChangeHandler}
					onBlur={(event) => {
						handleBlur(event);
						setTouched({
							...touched,
							first_name: false,
						});
					}}
					onFocus={handleBlur}
					info={getInfoInput({
						name: 'first_name',
						text: t('AUTH.REGISTRATION.FIRSTNAME_HINT'),
						touched,
						errors,
						values,
					})}
					disabled={isLoading}
				/>
			)}
			{!isAuth && (
				<Input
					className={styles.input}
					label={t('AUTH.REGISTRATION.LASTNAME_LABEL')}
					name="last_name"
					required
					value={values.last_name || ''}
					onChange={onChangeHandler}
					onBlur={(event) => {
						handleBlur(event);
						setTouched({
							...touched,
							last_name: false,
						});
					}}
					onFocus={handleBlur}
					info={getInfoInput({
						name: 'last_name',
						text: t('AUTH.REGISTRATION.LASTNAME_HINT'),
						touched,
						errors,
						values,
					})}
					disabled={isLoading}
				/>
			)}
			{!isAuth && (
				<Input
					className={styles.input}
					label={t('AUTH.REGISTRATION.EMAIL_LABEL')}
					name="email"
					required
					value={values.email || ''}
					onChange={onChangeHandler}
					onBlur={(event) => {
						handleBlur(event);
						setTouched({
							...touched,
							email: false,
						});
					}}
					onFocus={handleBlur}
					info={getInfoInput({
						name: 'email',
						text: t('AUTH.REGISTRATION.EMAIL_HINT'),
						touched,
						errors,
						values,
					})}
					disabled={isLoading}
				/>
			)}
			{!isAuth && (
				<Input
					className={styles.input}
					label={t('AUTH.REGISTRATION.PASSWORD_LABEL')}
					name="password"
					required
					type="password"
					value={values.password || ''}
					onChange={onChangeHandler}
					onBlur={(event) => {
						handleBlur(event);
						setTouched({
							...touched,
							password: false,
						});
					}}
					onFocus={handleBlur}
					info={getInfoInput({
						name: 'password',
						text: t('AUTH.REGISTRATION.PASSWORD_HINT'),
						touched,
						errors,
						values,
					})}
					disabled={isLoading}
				/>
			)}
			<Input
				className={styles.input}
				label={t('AUTH.REGISTRATION.WEBSITE_LABEL')}
				name="website"
				value={values.website}
				onChange={onChangeHandler}
				onBlur={(event) => {
					handleBlur(event);
					setTouched({
						...touched,
						website: false,
					});
				}}
				onFocus={handleBlur}
				info={getInfoInput({
					name: 'website',
					text: t('AUTH.REGISTRATION.WEBSITE_HINT'),
					touched,
					errors,
					values,
				})}
				disabled={isLoading}
			/>
			<Textarea
				className={styles.input}
				label={t('PARTNER_REG.CUSTOMER_EXPERIENCE_LABEL')}
				value={values.customer_experience}
				onChange={onChangeHandler}
				name="customer_experience"
				disabled={isLoading}
			/>

			<div className={cx(
				styles.form__checkbox_wrap,
				styles.form__checkbox_wrap__customer_base,
			)}
			>
				<Text>{t('PARTNER_REG.CUSTOMER_BASE')}</Text>
				<Checkbox
					label={t('PARTNER_REG.CUSTOMER_BASE_LABEL')}
					name="customer_base"
					value={values.customer_base}
					onChange={onChangeHandler}
					disabled={isLoading}
				/>
			</div>
			<div className={styles.add_contact_info}>
				<Text>{t('PARTNER_REG.ADD_CONTACT_INFO')}</Text>
				<div className={styles.add_contact_info__icons}>
					{Object.values(SocialNetworks).map((value: string) => (
						<SocialIcons
							key={hash(value)}
							className={styles.add_contact_info__icons__icon}
							// @ts-ignore
							iconName={value}
							isActive={socialNetworkName === value}
							color={Colors.primary}
							// @ts-ignore
							onClick={() => setSocialNetworkName(value)}
						/>
					))}
				</div>
				{socialNetworkName !== '' && (
					<Input
						className={styles.input}
						label={`${t('PARTNER_REG.CONTACT_LABEL')} ${socialNetworkName}`}
						name={socialNetworkName}
						value={values[socialNetworkName]}
						onChange={onChangeHandler}
						onBlur={(event) => {
							handleBlur(event);
							setTouched({
								...touched,
								[socialNetworkName]: false,
							});
						}}
						onFocus={handleBlur}
						info={getInfoInput({
							name: socialNetworkName,
							text: t('PARTNER_REG.CONTACT_HINT'),
							touched,
							errors,
							values,
						})}
						disabled={isLoading}
					/>
				)}
			</div>
			{referralCode.isHave === false && (showInputReferralCode ? (
				<Input
					className={styles.input}
					label={t('AUTH.REGISTRATION.REFERRAL_LABEL')}
					name="referral"
					value={values.referral}
					onChange={onChangeHandler}
					onBlur={(event) => {
						handleBlur(event);
						setTouched({
							...touched,
							referral: false,
						});
					}}
					onFocus={handleBlur}
					// info={getInfoInput({
					// 	name: 'referral',
					// 	text: t('OTHER.INPUTS.REFERRAL.HINTS.DEFAULT'),
					// 	touched,
					// 	errors,
					// 	values,
					// })}
					info={getInfoInput({
						name: 'referral',
						text: '', // пустая строка для валидации ts
						touched,
						errors,
						values,
					})}
					disabled={isLoading}
				/>
			) : (
				<ButtonText
					onClick={() => setShowInputReferralCode(true)}
					className={styles.form__text}
					text={t('AUTH.REGISTRATION.THERE_IS_REFERRAL_CODE')}
					underscore
				/>
			))}
			<div className={styles.form__checkbox_wrap}>
				<Checkbox
					label=""
					name="isCustomerAgreementAccepted"
					value={values.isCustomerAgreementAccepted}
					onChange={onChangeHandler}
					disabled={isLoading}
				/>
				<Text className={styles.form__checkbox_wrap__text}>
					Я подтверждаю, что ознакомился, понял и согласен с Партнерским соглашением
				</Text>
			</div>
			<Button
				size="large"
				type="submit"
				theme="primary"
				onClick={() => {}}
				disabled={
					!values.isCustomerAgreementAccepted
					|| !isValid
					|| registration.isLoading
					|| partnerRegistration.isLoading
				}
				className={styles.form__button}
			>
				{t('PARTNER_REG.BECOME_PARTNER_BUTTON')}
			</Button>
			<ButtonLink
				to={partnerRegistrationRoutes['partner-registration'].root}
				type="submit"
				theme="secondary"
				size="large"
				className={styles.form__button}
				onClick={() => {}}
			>
				{t('OTHER.CANCEL_BUTTON')}
			</ButtonLink>
		</form>
	);
};

export default null;
