/* eslint-disable */
import React, { useCallback, useRef, useState } from 'react';
import cx from 'classnames';

import { Text } from '@base/components';
import styles from './Textarea.module.scss';

interface TextareaProps {
	name: string,
	value: string | number,
	label?: string,
	className: string,
	placeholder?: string,
	info?: any,
	floatInfo? : 'bottom',
	// eslint-disable-next-line no-unused-vars
	onChange: (event: {
		name: string,
		value: string | number,
		event?: React.SyntheticEvent<HTMLTextAreaElement>,
	}) => void | Promise<void>,
	// eslint-disable-next-line no-unused-vars
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void,
	// eslint-disable-next-line no-unused-vars
	onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void,
	error?: string,
	disabled?: boolean,
	required?: boolean,
}

export const Textarea = ({
	className,
	name,
	value,
	label = '',
	onChange,
	onBlur,
	onFocus,
	disabled = false,
	error,
	required = false,
}: TextareaProps) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [showError, setShowError] = useState<boolean>(true);

	const setFocus = () => {
		if (inputRef.current !== null) {
			inputRef.current.focus();
		}
	};
	const errorClickHandler = () => {
		if (showError) {
			setShowError(false);
			setFocus();
		}
	};

	const onKeyDownHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'ArrowUp') {
			event.preventDefault();
		}
		if (event.key === 'ArrowDown') {
			event.preventDefault();
		}
	};

	const handleChange = useCallback((event: React.SyntheticEvent<HTMLTextAreaElement>) => {
		const eventValue = event.currentTarget.value;
		onChange({
			name,
			value: eventValue,
			event,
		});
	}, [
		onChange,
		name,
	]);

	const blurHandler = (e: React.FocusEvent<HTMLTextAreaElement>) => {
		// if click of arrow in input number prevent blur
		const isButtonClicked = [
			'stepUp',
			'stepDown',
			// @ts-ignore
		].includes(e.relatedTarget ? e.relatedTarget.id : '');

		if (!isButtonClicked) {
			setShowError(true);
			if (onBlur !== undefined) {
				onBlur(e);
			}
		}
	};

	const onFocusHandler = (event: any) => {
		if (onFocus) {
			onFocus(event);
		}
	};
	return (
		<div className={cx(className)}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				{label.length > 0 && (
					<Text
						type="h4"
						className={cx(
							styles.label,
							{
								[styles.required]: required,
							},
						)}
					>
						{label}
						{' '}
					</Text>
				)}
			</div>
			<span
				className={cx(
					styles.textarea_wrap,
				)}
				role="presentation"
				onClick={errorClickHandler}
			>
				<textarea
					value={value}
					onChange={handleChange}
					autoComplete="off"
					onBlur={blurHandler}
					onFocus={onFocusHandler}
					onKeyDown={onKeyDownHandler}
					disabled={disabled}
				/>
			</span>
			{error !== undefined && error.length > 0 && (
				<div
					className={styles.input__error}
					style={{
						visibility: 'visible',
					}}
				>
					{error}
				</div>
			)}
		</div>
	);
};

Textarea.defaultProps = {
	className: '',
};

export default null;
