import * as partnerRegistrationTypes from '@auth/store/PartnerRegistration/constants';
import type { PartnerRegistrationActionsType } from '@auth/store/PartnerRegistration/types';
import { RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type PartnerRegistrationState = {
  partnerRegistration: RequestInfoChildType;
};

const initialState: PartnerRegistrationState = {
	partnerRegistration: {
		...RequestInfoChildState,
	},
};

export const partnerRegistrationReducer = (
	state: PartnerRegistrationState = initialState,
	action: PartnerRegistrationActionsType,
): PartnerRegistrationState => {
	switch (action.type) {
		case partnerRegistrationTypes.PARTNER_REGISTRATION_REQUEST: {
			return {
				...state,
				partnerRegistration: {
					...state.partnerRegistration,
					isLoading: true,
				},
			};
		}

		case partnerRegistrationTypes.PARTNER_REGISTRATION_SUCCESS: {
			return {
				...state,
				partnerRegistration: {
					...state.partnerRegistration,
					isLoading: false,
				},
			};
		}

		case partnerRegistrationTypes.PARTNER_REGISTRATION_FAIL: {
			const { payload } = action;
			return {
				...state,
				partnerRegistration: {
					...state.partnerRegistration,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
