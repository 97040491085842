import * as translationsTypes from '@base/store/TranslationsPersist/constants';
import {
	DownloadTranslationsDataSucType, LanguageNameType,
} from '@base/store/TranslationsPersist/types';

// инициализация языка после загрузки страницы
export const translationsPersistRequestAction = () => ({
	type: translationsTypes.BASE_TRANSLATIONS_PERSIST_REQUEST,
});

export const translationsPersistSuccessAction = (payload: { tag: string, label: string }) => ({
	type: translationsTypes.BASE_TRANSLATIONS_PERSIST_SUCCESS,
	payload,
});

export const translationsPersistFailAction = (payload: { error: string }) => ({
	type: translationsTypes.BASE_TRANSLATIONS_PERSIST_FAIL,
	payload,
});

// загрузка языка с бека
export const translationsPersistDownloadRequestAction = () => ({
	type: translationsTypes.BASE_TRANSLATIONS_PERSIST_DOWNLOAD_REQUEST,
});

export const translationsPersistDownloadSuccessAction = (
	payload: DownloadTranslationsDataSucType,
) => ({
	type: translationsTypes.BASE_TRANSLATIONS_PERSIST_DOWNLOAD_SUCCESS,
	payload,
});

export const translationsPersistDownloadFailAction = (payload: { error: string }) => ({
	type: translationsTypes.BASE_TRANSLATIONS_PERSIST_DOWNLOAD_FAIL,
	payload,
});

// событие смены языка
export const selectLanguageRequestAction = (payload: LanguageNameType) => ({
	type: translationsTypes.BASE_SELECT_LANGUAGE_REQUEST,
	payload,
});

export const selectLanguageSuccessAction = (payload: LanguageNameType) => ({
	type: translationsTypes.BASE_SELECT_LANGUAGE_SUCCESS,
	payload,
});

export const selectLanguageFailAction = (payload: { error: string }) => ({
	type: translationsTypes.BASE_SELECT_LANGUAGE_FAIL,
	payload,
});

export default null;
