import React from 'react';

import {
	AuthLayout,
} from '@auth/layouts/AuthLayout';
import {
	AuthRouter,
} from '@auth/routes/AuthRouter';
import authRoutes from '@auth/constants/authRoutes';

import { usePageInfo } from '@base/hooks/usePageInfo';

export const AuthLayoutWithRouter = () => {
	usePageInfo(authRoutes);

	return (
		<AuthLayout>
			<AuthRouter />
		</AuthLayout>
	);
};

export default null;
