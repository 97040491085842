import ApiProviderDefault from '@base/providers/apiProviderDefault';
import {
	PostTransferInternalReq,
	PostTransferInternalResp,
	PostTransferHistoryReq,
	PostTransferHistoryResp,
} from '@base/types';

export default class TransfersProvider extends ApiProviderDefault {
	static async postTransferInternal(parameters: {
		body: PostTransferInternalReq,
		headers: Headers,
	}) {
		const path = '/transfer/internal';
		let headers;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const { body } = parameters;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<PostTransferInternalReq, PostTransferInternalResp>(path, 'POST', query, body, headers);
	}

	static async postTransferHistory(parameters: {
		body: PostTransferHistoryReq,
		headers: Headers,
	}) {
		const path = '/transfer';
		let headers;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const { body } = parameters;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<PostTransferHistoryReq, PostTransferHistoryResp>(path, 'POST', query, body, headers);
	}
}

// @ts-ignore
TransfersProvider.postTransferInternal.security = true;
// @ts-ignore
TransfersProvider.postTransferHistory.security = true;
