export const conv_size = (b: number) => {
	const fsizekb = b / 1024;
	const fsizemb = fsizekb / 1024;
	const fsizegb = fsizemb / 1024;
	const fsizetb = fsizegb / 1024;

	let fsize;

	if (fsizekb <= 1024) {
		fsize = `${fsizekb.toFixed(3)} кб`;
	} else if (fsizekb >= 1024 && fsizemb <= 1024) {
		fsize = `${fsizemb.toFixed(3)} мб`;
	} else if (fsizemb >= 1024 && fsizegb <= 1024) {
		fsize = `${fsizegb.toFixed(3)} гб`;
	} else {
		fsize = `${fsizetb.toFixed(3)} тб`;
	}

	return fsize;
};
