import * as countriesTypes from '@base/store/Countries/constants';
import type { CountriesActionsType } from '@base/store/Countries/types';
import { RequestInfoChildState } from '@base/statesDefault';
import { OptionType, RequestInfoChildType, CountryType } from '@base/types';

export type CountriesState = {
	data: Array<CountryType>
	options: Array<OptionType<CountryType>>
} & RequestInfoChildType;

const initialState: CountriesState = {
	data: [],
	options: [],
	...RequestInfoChildState,
};

export const countriesReducer = (
	state: CountriesState = initialState,
	action: CountriesActionsType,
): CountriesState => {
	switch (action.type) {
		case countriesTypes.BASE_COUNTRIES_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}

		case countriesTypes.BASE_COUNTRIES_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				data: payload.data,
				options: payload.options,
				isLoading: false,
			};
		}

		case countriesTypes.BASE_COUNTRIES_FAIL: {
			const { payload } = action;
			return {
				...state,
				isLoading: false,
				error: payload.error,
			};
		}

		default: {
			return state;
		}
	}
};
