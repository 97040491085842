const OTHER: 0 = 0;
const PROOF_OF_IDENTITY: 1 = 1;
const PROOF_OF_RESIDENCE: 2 = 2;

export default {
	PROOF_OF_IDENTITY,
	PROOF_OF_RESIDENCE,
	OTHER,
};

export type FileTypesType = 0 | 1 | 2;
