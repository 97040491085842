import * as documentFilesTypes from '@client_broker/store/DocumentFiles/constants';
import { VerificationStatusType } from '@client_broker/store/DocumentFiles/reducer';
import { DocumentFilesType, PutDocumentFiles } from '@base/types';

export const documentFilesAction = () => ({
	type: documentFilesTypes.DOCUMENT_FILES_REQUEST,
});

export const documentFilesSuccessAction = (
	payload: {
		data: Array<DocumentFilesType>,
		verificationStatus: VerificationStatusType,
	},
) => ({
	type: documentFilesTypes.DOCUMENT_FILES_SUCCESS,
	payload,
});

export const documentFilesFailAction = (payload: { error: string }) => ({
	type: documentFilesTypes.DOCUMENT_FILES_FAIL,
	payload,
});

export const sendDocumentFilesAction = (payload: PutDocumentFiles) => ({
	type: documentFilesTypes.SEND_DOCUMENT_FILES_REQUEST,
	payload,
});

export const sendDocumentFilesSuccessAction = () => ({
	type: documentFilesTypes.SEND_DOCUMENT_FILES_SUCCESS,
});

export const sendDocumentFilesFailAction = (payload: { error: string }) => ({
	type: documentFilesTypes.SEND_DOCUMENT_FILES_FAIL,
	payload,
});

export default null;
