import {
	combineReducers,
} from 'redux';

import { passwordRecoveryReducer } from '@auth/store/PasswordRecovery/reducer';
import { registrationReducer } from '@auth/store/Registration/reducer';
import { verificationPhoneReducer } from '@auth/store/VerificationPhone/reducer';
import { verificationEmailReducer } from '@auth/store/VerificationEmail/reducer';
import { partnerRegistrationReducer } from '@auth/store/PartnerRegistration/reducer';

export const authRootReducer = combineReducers({
	passwordRecovery: passwordRecoveryReducer,
	registration: registrationReducer,
	verificationPhone: verificationPhoneReducer,
	verificationEmail: verificationEmailReducer,
	partnerRegistration: partnerRegistrationReducer,
});
