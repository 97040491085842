export const getModulesRoutes = (baseURL: string) => {
	const url = baseURL !== '' ? `${baseURL}/` : '';
	return ({
		root: `/${url}`,
		title: 'Main',
		client: {
			root: `/${url}client`,
			title: 'Client',
		},
	});
};

const modulesRoutes = getModulesRoutes('');

export default modulesRoutes;
