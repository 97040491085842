/* eslint-disable max-len */
export const BASE_REFERRAL_SET_REQ: 'BASE_REFERRAL_SET_REQ' = 'BASE_REFERRAL_SET_REQ';
export const BASE_REFERRAL_SET_SUCCESS: 'BASE_REFERRAL_SET_SUCCESS' = 'BASE_REFERRAL_SET_SUCCESS';
export const BASE_REFERRAL_SET_FAIL: 'BASE_REFERRAL_SET_FAIL' = 'BASE_REFERRAL_SET_FAIL';

export const BASE_SET_REFERRAL_REQUESTS_REQ: 'BASE_SET_REFERRAL_REQUESTS_REQ' = 'BASE_SET_REFERRAL_REQUESTS_REQ';
export const BASE_SET_REFERRAL_REQUESTS_SUCCESS: 'BASE_SET_REFERRAL_REQUESTS_SUCCESS' = 'BASE_SET_REFERRAL_REQUESTS_SUCCESS';
export const BASE_SET_REFERRAL_REQUESTS_FAIL: 'BASE_SET_REFERRAL_REQUESTS_FAIL' = 'BASE_SET_REFERRAL_REQUESTS_FAIL';

// export const BASE_REFERRAL_REQUESTS_REQ: 'BASE_REFERRAL_REQUESTS_REQ' = 'BASE_REFERRAL_REQUESTS_REQ';
// export const BASE_REFERRAL_REQUESTS_SUCCESS: 'BASE_REFERRAL_REQUESTS_SUCCESS' = 'BASE_REFERRAL_REQUESTS_SUCCESS';
// export const BASE_REFERRAL_REQUESTS_FAIL: 'BASE_REFERRAL_REQUESTS_FAIL' = 'BASE_REFERRAL_REQUESTS_FAIL';

export const BASE_REFERRAL_GET_REQ: 'BASE_REFERRAL_GET_REQ' = 'BASE_REFERRAL_GET_REQ';
export const BASE_REFERRAL_GET_SUCCESS: 'BASE_REFERRAL_GET_SUCCESS' = 'BASE_REFERRAL_GET_SUCCESS';
export const BASE_REFERRAL_GET_FAIL: 'BASE_REFERRAL_GET_FAIL' = 'BASE_REFERRAL_GET_FAIL';

export const BASE_SET_REFERRAL_CODE: 'BASE_SET_REFERRAL_CODE' = 'BASE_SET_REFERRAL_CODE';
