import { call, put, takeLeading } from 'redux-saga/effects';

import {
	partnerRegistrationSuccessAction,
	partnerRegistrationFailAction,
} from '@auth/store/PartnerRegistration/actions';
import * as partnerRegistrationTypes from '@auth/store/PartnerRegistration/constants';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { PartnerRegistrationActionType } from '@auth/store/PartnerRegistration/types';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import { PostPartnerResp } from '@base/types/provider/clientBroker';

function* partnerRegistrationSaga({ payload }: PartnerRegistrationActionType) {
	try {
		const body = {
			...payload,
		};
		const resp: PostPartnerResp = yield call(
			apiRequestClientBroker,
			'postPartner',
			{
				body,
			},
		);
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(partnerRegistrationSuccessAction());
	} catch (e) {
		console.log(e);
		yield put(partnerRegistrationFailAction({ error: e.message }));
	}
}

export function* partnerRegistrationWatcher() {
	yield takeLeading(
		partnerRegistrationTypes.PARTNER_REGISTRATION_REQUEST,
		partnerRegistrationSaga,
	);
}
