// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Button, Input, LoaderSpinner, Select, Text,
	AttachButtonDeprecated,
	AttachedImage,
	Paragraph, StatusIconAndText, ImageFlipper,
} from '@base/components';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import regex from '@base/constants/regex';

import hash from 'object-hash';

import { modalCloseAction } from '@base/store/Modal/actions';
import { ClientBrokerState } from '@client_broker/types';
import { getInfoInput } from '@base/utils/inputFunctions';
import fileTypes from '@base/constants/fileTypes';
import { profileVerificationAction } from '@client_broker/store/ProfileVerification/actions';
import { CountryType, OptionType } from '@base/types';
import { getCountryOptionByAlpha2 } from '@base/utils/countries';
import { useTranslation } from 'react-i18next';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';
import ReactMarkdown from 'react-markdown';
import correct_1 from '@base/assets/images/photosOfAddressVerificationExamples/correct_1.svg';
import wrong_1 from '@base/assets/images/photosOfAddressVerificationExamples/wrong_1.svg';
import wrong_2 from '@base/assets/images/photosOfAddressVerificationExamples/wrong_2.svg';
import wrong_3 from '@base/assets/images/photosOfAddressVerificationExamples/wrong_3.svg';
import wrong_4 from '@base/assets/images/photosOfAddressVerificationExamples/wrong_4.svg';
import wrong_5 from '@base/assets/images/photosOfAddressVerificationExamples/wrong_5.svg';
import wrong_6 from '@base/assets/images/photosOfAddressVerificationExamples/wrong_6.jpg';

import { ImagePreview } from '@client_broker/modals/profile/ImagePreview';
import { imageFlipperFunction, imageFlipperOnScroll } from '@base/components/ImageFlipper';
import i18n from '@starter/i18n';
import { FileType } from '@base/components/AttachedFileName';
import cx from 'classnames';
// import { Arrow } from '@base/assets/icons/arrow/arrow';
// import { Colors } from '@base/assets/styles/colors';
import styles from './AddressVerificationModal.module.scss';

type AddressVerificationModalProps = {};

interface AddressVerificationModalFieldsType {
	zipcode: string,
	address: string,
	countryOption: OptionType<CountryType> | undefined,
	city: string,
	files: Array<any>,
}

export const initialValues = {
	zipcode: '',
	address: '',
	countryOption: undefined,
	city: '',
	files: [],
};

// eslint-disable-next-line no-empty-pattern
export const AddressVerificationModal = ({}: AddressVerificationModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { countries } = useSelector(
		(state: ClientBrokerState) => state.base,
	);
	const { profileVerification, customer: { info } } = useSelector(
		(state: ClientBrokerState) => state.clientBroker,
	);
	const [filesRemoved, setFilesRemoved] = useState<Array<any>>([]);
	const [openFiles, setOpenFiles] = useState<Array<any>>([]);
	const [openIndexPhotoWrong, setOpenIndexPhotoWrong] = useState<number>(0);
	const [indexPhotoWrongScroll, setIndexPhotoWrongScroll] = useState<number>(0);

	const photosWrong = [
		{ img: wrong_1, name: t('CLIENT.PROFILE.IMAGES.1') },
		{ img: wrong_2, name: t('CLIENT.PROFILE.IMAGES.2') },
		{ img: wrong_3, name: t('CLIENT.PROFILE.IMAGES.3') },
		{ img: wrong_4, name: t('CLIENT.PROFILE.IMAGES.4') },
		{ img: wrong_5, name: t('CLIENT.PROFILE.IMAGES.5') },
		{ img: wrong_6, name: t('CLIENT.PROFILE.IMAGES.6') },
	];
	// const [files, setFiles] = useState([]);

	const validationSchema = object().shape({
		zipcode: string()
			.min(3, i18n.t('CLIENT.PROFILE.ZIPCODE_VALIDATION_ERROR'))
			.max(10, i18n.t('CLIENT.PROFILE.ZIPCODE_VALIDATION_ERROR'))
			.matches(
				regex.zipcode,
				t('CLIENT.PROFILE.ZIPCODE_VALIDATION_ERROR'),
			),
		address: string()
			.min(10, i18n.t('CLIENT.PROFILE.ADDRESS_VALIDATION_ERROR'))
			.max(250, i18n.t('CLIENT.PROFILE.ADDRESS_VALIDATION_ERROR'))
			.required(t('OTHER.FIELD_IS_REQUIRED'))
			.matches(
				regex.address,
				t('CLIENT.PROFILE.ADDRESS_VALIDATION_ERROR'),
			),
		countryOption: object()
			.required(t('OTHER.FIELD_IS_REQUIRED')),
		city: string()
			.min(1, i18n.t('CLIENT.PROFILE.CITY_VALIDATION_ERROR'))
			.max(100, i18n.t('CLIENT.PROFILE.CITY_VALIDATION_ERROR'))
			.required(t('OTHER.FIELD_IS_REQUIRED'))
			.matches(
				regex.city,
				t('CLIENT.PROFILE.CITY_VALIDATION_ERROR'),
			),
	});

	const {
		handleChange,
		handleBlur,
		setTouched,
		values,
		setValues,
		errors,
		touched,
		isValid,
	} = useFormik<AddressVerificationModalFieldsType>({
		initialValues,
		onSubmit: (): any => {},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	useEffect(() => {
		let countryOption: OptionType<CountryType> | undefined;

		if (info.data && countries.options) {
			countryOption = getCountryOptionByAlpha2(countries.options, info.data.country_code);
		}
		const { zipcode, address, city } = info.data;
		setValues({
			...values,
			zipcode: zipcode || '',
			address: address || '',
			city: city || '',
			countryOption,
		});
	}, [info.data, countries.options]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const target = {	name,	value	};
			// switch (name) {
			// 	case 'zipcode':
			// 		target = {	name,	value: value.replace(/[^\d]/g, '') };
			// 		break;
			// 	default: break;
			// }
			const event = {
				target,
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	// eslint-disable-next-line no-unused-vars
	const handleImageChange = (e: any) => {
		e.preventDefault();

		// const reader = new FileReader();
		const file = e.target.files[0];

		if (!file) return;
		if (file.size > 15000000) {
			dispatch(notificationsModalOpenAction({
				messageTKey: t('OTHER.MAXIMUM_FILE_SIZE_EXCEEDED'),
				type: 'error',
			}));
			return;
		}

		switch (file.type) {
			case 'image/png':
			case 'image/jpeg':
			case 'application/pdf':
				break;
			default:
				dispatch(notificationsModalOpenAction({
					messageTKey: t('OTHER.INVALID_FILE_TYPE'),
					type: 'error',
				}));
				return;
		}

		// console.log('file.size', file.size);
		// console.log('file.type', file.type);
		// @ts-ignore
		setValues({
			...values,
			files: [...values.files, file],
		});		// reader.onloadend = () => {
		// 	this.setState({
		// 		file,
		// 		imagePreviewUrl: reader.result,
		// 	});
		// };

		// reader.readAsDataURL(file);
	};

	const deleteFileByIndex = (index: number) => {
		setFilesRemoved([
			...filesRemoved,
			values.files[0],
		]);
		values.files.splice(index, 1);
		setValues({
			...values,
			files: [...values.files],
		});
	};

	const sendData = () => {
		const {
			zipcode, address, city, countryOption, files,
		} = values;
		dispatch(profileVerificationAction({
			zipcode,
			address,
			city,
			files: [...files, ...filesRemoved],
			country_code: countryOption?.value.alpha2,
			file_type: `${fileTypes.PROOF_OF_RESIDENCE}`,
			others_type: '',
			is_identity: true,
		}));
	};

	const openPhotoWrong = (openIndex: number) => {
		setOpenIndexPhotoWrong(openIndex);
		setOpenFiles(photosWrong);
	};

	const VerificationButtons = ({
		className,
	}: {
		className?: string
	}) => (
		<div className={cx(className)}>
			{values.files.length > 0 ? (
				<div className={styles.attached_files_name}>
					{values.files.map((file: FileType, index) => (
						<AttachedImage
							className={styles.attached_file_name}
							key={hash(index)}
							file={file}
							name={file.name}
							size={file.size}
							onClickOpen={() => {
								setOpenIndexPhotoWrong(0);
								setOpenFiles([{
									img: URL.createObjectURL(file),
									name: file.name,
								}]);
							}}
							onClickClose={() => deleteFileByIndex(index)}
							type="max"
						/>
					))}
				</div>
			) : <></>}
			{values.files.length < 3 && (
				<AttachButtonDeprecated
					// className={styles.address_verification__add_file}
					text={values.files.length === 0
						? t('CLIENT.PROFILE.ATTACH_FILE_BUTTON')
						: t('CLIENT.PROFILE.ADD_FILE_BUTTON')}
					onClick={() => {
						// @ts-ignore
						document.getElementById('selectImage').click();
					}}
				/>
			)}
			<div className={styles.address_verification__btns}>
				<input
					id="selectImage"
					hidden
					type="file"
					accept="application/pdf, image/jpeg, image/png"
					onChange={(e) => handleImageChange(e)}
				/>
				<Button
					type="button"
					theme="primary"
					size="large"
					onClick={sendData}
					disabled={values.files.length < 3 || profileVerification.isLoading || !isValid}
				>
					{t('CLIENT.PROFILE.SEND_FOR_VERIFICATION_BUTTON')}
				</Button>
				<Button
					className={styles.address_verification__btns__cancel}
					type="button"
					theme="secondary"
					size="large"
					onClick={() => { dispatch(modalCloseAction()); }}
					disabled={profileVerification.isLoading}
				>
					{t('OTHER.CANCEL_BUTTON')}
				</Button>
			</div>
		</div>
	);

	return (
		<>
			{openFiles.length > 0 && (
				<ImagePreview
					onClickClose={() => setOpenFiles([])}
					images={openFiles}
					indexSelected={openIndexPhotoWrong}
				/>
			)}
			{openFiles.length === 0 && (
				<div className={styles.address_verification}>
					{(profileVerification.isLoading) && <LoaderSpinner className={styles.loader_spinner} />}
					<div>
						<Text type="h2">{t('CLIENT.PROFILE.ADDRESS_VERIFICATION')}</Text>
						<Paragraph className={styles.address_verification__paragraph} value="1">
							<Text type="h4">{t('CLIENT.PROFILE.FILL_IN_THE_FIELDS')}</Text>
						</Paragraph>
						<div className={styles.address_verification__inputs}>
							<Select
								labelName={t('CLIENT.PROFILE.COUNTRY_LABEL')}
								options={countries.options}
								required
								value={values.countryOption?.value}
								name="countryOption"
								onChange={onChangeHandler}
								disabled={profileVerification.isLoading}
							/>
							{/* <Arrow color={Colors.gray} /> */}

							<Input
								label={t('CLIENT.PROFILE.CITY_LABEL')}
								name="city"
								required
								value={values.city}
								onChange={onChangeHandler}
								onBlur={(event) => {
									handleBlur(event);
									setTouched({
										...touched,
										city: false,
									});
								}}
								onFocus={handleBlur}
								info={getInfoInput({
									name: 'city',
									text: t('CLIENT.PROFILE.CITY_HINT'),
									touched,
									errors,
									values,
								})}
								floatInfo="bottom"
								disabled={profileVerification.isLoading}
							/>
							<Input
								label={t('CLIENT.PROFILE.ZIPCODE_LABEL')}
								name="zipcode"
								value={values.zipcode}
								onChange={onChangeHandler}
								onBlur={(event) => {
									handleBlur(event);
									setTouched({
										...touched,
										zipcode: false,
									});
								}}
								onFocus={handleBlur}
								info={getInfoInput({
									name: 'zipcode',
									text: t('CLIENT.PROFILE.ZIPCODE_HINT'),
									touched,
									errors,
									values,
								})}
								floatInfo="bottom"
								disabled={profileVerification.isLoading}
							/>

							<Input
								label={t('CLIENT.PROFILE.ADDRESS_LABEL')}
								name="address"
								required
								value={values.address}
								onChange={onChangeHandler}
								onBlur={(event) => {
									handleBlur(event);
									setTouched({
										...touched,
										address: false,
									});
								}}
								onFocus={handleBlur}
								info={getInfoInput({
									name: 'address',
									text: t('CLIENT.PROFILE.ADDRESS_HINT'),
									touched,
									errors,
									values,
								})}
								floatInfo="bottom"
								disabled={profileVerification.isLoading}
							/>
						</div>

						<div className={styles.address_verification__description}>
							<Paragraph className={styles.address_verification__paragraph} value="2">
								<Text type="h4">{t('CLIENT.PROFILE.ATTACH_DOCUMENT')}</Text>
							</Paragraph>
							<div className={styles.address_verification__description__text}>
								<Text type="h5">
									<ReactMarkdown>
										{t('CLIENT.PROFILE.IDENTITY_VERIFICATION_DESC')}
									</ReactMarkdown>
								</Text>
							</div>
							<VerificationButtons className={styles.buttons__width_1} />
						</div>
					</div>
					<div className={styles.address_verification__photo}>
						<>
							<StatusIconAndText
								status="positive"
								text={t('CLIENT.PROFILE.CORRECT_SCAN_DOCUMENT')}
							/>
							<div className={styles.address_verification__photo__correct}>
								<img alt="" src={correct_1} />
							</div>
						</>
						<>
							<StatusIconAndText
								status="negative"
								text={t('CLIENT.PROFILE.INCORRECT_SCAN_DOCUMENT')}
							/>
							<div
								className={styles.address_verification__photo__wrong}
								id="scrollElement"
								onScroll={() => imageFlipperOnScroll({
									scrollElementId: 'scrollElement',
									setImgIndexSelected: setIndexPhotoWrongScroll,
								})}
							>
								{photosWrong.map((photoWrong, index) => (
									<div id={`imgElement${index}`} className={styles.img_wrap} key={hash(index)}>
										<img alt="" src={photoWrong.img} onClick={() => openPhotoWrong(index)} />
										<Text type="h5" className={styles.img_wrap__text}>
											{photoWrong.name}
										</Text>
									</div>
								))}
							</div>
							<ImageFlipper
								className={styles.image_flipper}
								index={indexPhotoWrongScroll}
								setIndex={(imgIndex) => {
									imageFlipperFunction({
										scrollElementId: 'scrollElement',
										imgElementId: 'imgElement1',
										imgIndex,
										imgIndexSelected: indexPhotoWrongScroll,
										setImgIndexSelected: setIndexPhotoWrongScroll,
									});
								}}
								length={
									// @ts-ignore
									document.getElementById('scrollElement')?.children.length || 0
								}
							/>
						</>
					</div>
					<VerificationButtons className={styles.buttons__width_2} />
				</div>
			)}
		</>
	);
};

export default null;
