import React from 'react';

import cx from 'classnames';
import { TransferHistory } from '@base/types';
import { Button } from '@base/components';
import styles from './TableRow.module.scss';

type OwnProps = {};

type TableRowProps = {
	id: string,
	item: TransferHistory | any
} & OwnProps;

const TableRow = ({
	id,
	item,
}: TableRowProps) => {
	const ItemWrapper = ({
		classNameRowWrapper,
		classNameRow,
		children,
	}: { children: React.ReactNode, classNameRowWrapper?: string, classNameRow?: string }) => (
		<div className={cx(classNameRowWrapper, styles.table_row_wrapper)}>
			<div className={cx(classNameRow, styles.table_row)}>
				{children}
			</div>
		</div>
	);

	const ItemDefault = () => (
		<ItemWrapper>
			{
				item[id] !== undefined
				&& item[id] !== ''
				&& item[id] !== null
				&& item[id]
					? item[id] : '__'
			}
		</ItemWrapper>
	);

	const SystemNameRow = () => (
		<ItemWrapper classNameRowWrapper={styles.table_row_wrapper__left_align}>
			<div className={cx(
				styles.left_align,
			)}
			>
				{item.system_name}
			</div>
		</ItemWrapper>
	);

	const NumberRow = () => (
		<ItemWrapper classNameRowWrapper={styles.table_row_wrapper__left_align}>
			<div className={cx(
				styles.left_align,
			)}
			>
				{item.number}
			</div>
		</ItemWrapper>
	);

	const VerificationRow = () => (
		<ItemWrapper classNameRowWrapper={styles.table_row_wrapper__left_align}>
			<div className={cx(
				styles.left_align,
			)}
			>
				Верификация:
				{' '}
				{item.verification}
			</div>
		</ItemWrapper>
	);

	const SettingRow = () => (
		<ItemWrapper>
			<Button theme="secondary" onClick={() => {}}>{item.setting}</Button>
		</ItemWrapper>
	);

	switch (id) {
		case 'system_name':
			return <SystemNameRow />;
		case 'number':
			return <NumberRow />;
		case 'verification':
			return <VerificationRow />;
		case 'setting':
			return <SettingRow />;
		// case 'status':
		// 	return <StatusRow />;
		default:
			return (<ItemDefault />);
	}
};

TableRow.defaultProps = {
	item: {
		accountName: 'testName',
	},
};

export default TableRow;
