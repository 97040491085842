import React from 'react';
import cx from 'classnames';
import hash from 'object-hash';

import { NavLink } from 'react-router-dom';
import { Text } from '@base/components';
import styles from './TabsMobile.module.scss';

type TabsPaths = {
  path: string,
  label: string
}

type Props = {
  paths: Array<TabsPaths>,
  className?: string,
};

export const TabsMobile = ({ className, paths }: Props) => (
	<nav
		className={cx(
			styles.tabs_mobile,
			className,
		)}
	>
		{paths.map(({
			path, label,
		}, index) => (
			<NavLink
				key={hash(index)}
				activeClassName={styles['tabs_mobile__nav_link--active']}
				className={styles.tabs_mobile__nav_link}
				to={path}
			>
				<Text>{label}</Text>
			</NavLink>
		))}
	</nav>
);
export default null;
