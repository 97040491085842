import * as passwordRecoveryTypes from '@auth/store/PasswordRecovery/constants';
import {
	PatchCustomerRestoreReq,
	PostCustomerCheckPassTokenReq,
	PostCustomerRestoreReq,
} from '@base/types/provider/clientBroker';

export const passwordRecoveryInitStateAction = () => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_INIT_STATE,
});

// ---------------------------------

export const passwordRecoverySendEmailAction = (payload: PostCustomerRestoreReq) => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_SEND_EMAIL_REQ,
	payload,
});

export const passwordRecoverySendEmailSuccessAction = () => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_SEND_EMAIL_SUCCESS,
});

export const passwordRecoverySendEmailFailAction = (payload: { error: string }) => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_SEND_EMAIL_FAIL,
	payload,
});

// ---------------------------------

export const passwordRecoveryRestoreAction = (payload: PatchCustomerRestoreReq) => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_RESTORE_REQ,
	payload,
});

export const passwordRecoveryRestoreSuccessAction = () => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_RESTORE_SUCCESS,
});

export const passwordRecoveryRestoreFailAction = (payload: { error: string }) => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_RESTORE_FAIL,
	payload,
});

// ---------------------------------

export const passwordRecoveryCheckPassTokenAction = (payload: PostCustomerCheckPassTokenReq) => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_CHECK_PASS_TOKEN_REQ,
	payload,
});

export const passwordRecoveryCheckPassTokenSuccessAction = () => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_CHECK_PASS_TOKEN_SUCCESS,
});

export const passwordRecoveryCheckPassTokenFailAction = (payload: { error: string }) => ({
	type: passwordRecoveryTypes.PASSWORD_RECOVERY_CHECK_PASS_TOKEN_FAIL,
	payload,
});

export default null;
