/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { NavLink } from 'react-router-dom';
import { Button } from '@base/components';
import { ButtonProps } from '@base/components/Button';
import cx from 'classnames';
import styles from './ButtonLink.module.scss';

type ButtonLinkProps = {
  classNameButtonLink?: string;
  className?: string;
  to: string;
} & ButtonProps;

export const ButtonLink = ({
	className, classNameButtonLink, to, children, ...args
}: ButtonLinkProps) => (
	<NavLink
		to={to}
		className={cx(styles.button_link, classNameButtonLink)}
	>
		<Button
			{...args}
			className={cx(styles.button, className)}
		>
			{children}
		</Button>
	</NavLink>
);

export default null;
