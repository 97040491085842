import React, { useEffect, useMemo } from 'react';

import { ReferralCodeForm } from '@client_broker/containers/profile/ReferralCodeForm';
import { PersonalDataForm } from '@client_broker/containers/profile/PersonalDataForm';
import { useDispatch, useSelector } from 'react-redux';
import { ClientBrokerState } from '@client_broker/types';
import { VerificationForm } from '@client_broker/containers/profile/VerificationForm';
import { customerAction } from '@client_broker/store/Customer/actions';
import { documentFilesAction } from '@client_broker/store/DocumentFiles/actions';
import { countriesAction } from '@base/store/Countries/actions';
import { DateValues } from '@base/constants/dateValues';
import { PageLayout } from '@client_broker/layouts/PageLayout';
import styles from './Profile.module.scss';

interface CurrentUserProfileOwnProps {}
interface CurrentUserProfileDispatchProps {}
interface CurrentUserProfileStateProps {}

type CurrentUserProfileProps = CurrentUserProfileStateProps &
  CurrentUserProfileDispatchProps &
  CurrentUserProfileOwnProps;

// eslint-disable-next-line no-empty-pattern
export const Profile = ({}: CurrentUserProfileProps) => {
	const dispatch = useDispatch();

	const { info } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.customer,
	);

	useEffect(() => {
		dispatch(customerAction());
		dispatch(documentFilesAction());
		dispatch(countriesAction());
	}, []);

	const isShowReferralCodeForm = useMemo(() => info.data.underp === false && (
		new Date().getTime() - new Date(info.data.created_at).getTime()
	) < DateValues.day * 14, [info]);

	return (
		<PageLayout>
			<div className={styles.profile_info}>
				<PersonalDataForm />
				<div className={styles.profile_info__left}>
					<VerificationForm />
					{isShowReferralCodeForm ? <ReferralCodeForm /> : <div />}
				</div>
			</div>
		</PageLayout>
	);
};

export default null;
