// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { Button, LoaderSpinner, Text } from '@base/components';
import { modalOpenAction } from '@base/store/Modal/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UploadDocumentModal } from '@client_broker/modals/documents/UploadDocumentModal';
import wrong_1 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_1.svg';

import large_magnifier from '@base/assets/icons/large_magnifier.svg';
import { ImagePreview } from '@client_broker/modals/profile/ImagePreview';
import { documentFilesAction } from '@client_broker/store/DocumentFiles/actions';
import { ClientBrokerState } from '@client_broker/types';
import hash from 'object-hash';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@base/constants/dateFormat';
import { conv_size } from '@base/utils/fileSizeDeterminer';
import { PageLayout } from '@client_broker/layouts/PageLayout';
import cx from 'classnames';
import styles from './Documents.module.scss';

type DocumentsProps = {};

// eslint-disable-next-line no-empty-pattern
export const Documents = ({}: DocumentsProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	// eslint-disable-next-line no-unused-vars
	const { files } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.documentFiles,
	);
	const [imagesPreview, setImagesPreview] = useState<Array<{ img: any, name: string }>>([
		{ img: wrong_1, name: t('CLIENT.PROFILE.TEXTS.IMAGES.1') },
		// не трогал ключ, т.к. не меняли еще профиль
	]);
	// eslint-disable-next-line no-unused-vars
	const [openIndexPhoto, setOpenIndexPhoto] = useState<number>(0);

	useEffect(() => {
		dispatch(documentFilesAction());
	}, []);

	useEffect(() => {
		setImagesPreview(files.data.map((file) => ({
			img: file.url,
			name: file.name,
		})));
	}, [files.data]);

	return (
		<PageLayout>
			<div className={cx(
				styles.container,
				{
					[styles.container__loading]: files.isLoading,
				},
			)}
			>
				{files.isLoading ? <LoaderSpinner /> : (
					<>
						<Button
							type="button"
							theme="primary"
							className={styles.upload_btn}
							onClick={() => dispatch(modalOpenAction(<UploadDocumentModal />))}
						>
							{t('CLIENT.DOCUMENTS.UPLOAD_DOCUMENT_BUTTON')}
						</Button>
						{files.data.length > 0 && (
							<div className={styles.imgs_wrap}>
								{files.data.map((file, index) => (
									<div
										key={hash(index)}
										className={styles.img_preview_wrap}
									>
										<div
											className={cx(
												styles.img_preview,
												{
													[styles.img_preview__cursor]: file.extension !== 'PDF',
												},
											)}
											onClick={() => {
												if (file.extension === 'PDF') return;
												dispatch(modalOpenAction(
													<ImagePreview
														onClickClose={() => setImagesPreview([])}
														images={[imagesPreview[index]]}
														isBackArrow={false}
													/>,
												));
											}}
										>
											<img
												className={styles.img_preview__photo}
												src={file.url}
											/>
											{file.extension !== 'PDF' && (
												<img
													alt=""
													src={large_magnifier}
													className={styles.img_preview__icon}
												/>
											)}
										</div>
										<Text className={styles.img_preview__img_name} type="h4-extra-1">
											{file.name}
										</Text>
										<Text type="h4-extra-1" color="gray-2">
											{`${format(new Date(file.created_at), DATE_FORMAT)},
										 ${file.extension}  ${conv_size(file.size)}`.toUpperCase()}
										</Text>
									</div>
								))}
								<button
									className={styles.button_add}
									onClick={() => dispatch(modalOpenAction(<UploadDocumentModal />))}
								>
									+
								</button>
							</div>
						)}
					</>
				)}
			</div>
		</PageLayout>
	);
};

export default null;
