import { combineReducers } from 'redux';
import { baseRootReducer, router } from '@base/store/rootReducer';
import { clientBrokerRootReducer } from '@client_broker/store/rootReducer';
import { authRootReducer } from '@auth/store/rootReducer';

export default () => combineReducers({
	router,
	base: baseRootReducer,
	auth: authRootReducer,
	clientBroker: clientBrokerRootReducer,
});
