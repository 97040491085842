/* eslint-disable no-case-declarations,no-param-reassign,no-return-assign */
import {
	call,
	select,
} from 'redux-saga/effects';
import { BaseState } from '@base/types';
import { authPersistRefreshTokenSaga } from '@base/store/AuthPersist/sagas';
import ApiProvider from '@base/providers/ApiProvider';

export function* handlerResponse({
	response,
	parameters,
	Api,
	method,
	numberOfReq = 0,
}: {
	response: any,
	parameters: any,
	Api: any,
	method: any
	numberOfReq?: number
}): any {
	// console.log('handlerResponse1, numberOfReq', numberOfReq);
	// console.log('handlerResponse2, response.status', response.status);
	try {
		if (response.status === 403) {
			const { isAuth } = yield select(({ base }: BaseState) => base.authPersist);
			if (isAuth === undefined) return response;
			yield call(authPersistRefreshTokenSaga);
			const { token: tokenNew } = yield select(({ base }: BaseState) => base.authPersist);
			parameters.headers.Authorization = `Bearer ${tokenNew.accessToken}`;
			return yield call([
				Api,
				method,
			], parameters);
		}
		if (response.status === 504 && numberOfReq < 3) {
			// console.log('handlerResponse3, response.status === 504 && countReq < 3');
			response = yield call([
				Api,
				method,
			], parameters);
			// console.log('handlerResponse4, response');
			return yield call(handlerResponse, {
				response,
				parameters,
				Api,
				method,
				numberOfReq: numberOfReq + 1,
			});
		}

		return response;
	} catch (e) {
		console.log(e);
		return null;
	}
}

export const getApiRequestSaga = <T extends BaseState>(Api: any) => function* apiRequestSagaDefault(
	apiMethod: string,
	options?: any,
): any {
	const parameters = { ...options };
	const method = Api[apiMethod];
	// console.log('getApiRequestSaga0.1');
	if (method && method.security) {
		try {
			const {
				accessToken,
			} = yield select(({
				base,
			}: T) => base.authPersist.token);
			if (parameters.headers === null || parameters.headers === undefined) {
				parameters.headers = {};
			}

			parameters.headers.Authorization = `Bearer ${accessToken}`;
		} finally {
			// always runs
		}
	}
	try {
		// console.log('getApiRequestSaga1, method= ', method);
		// console.log('getApiRequestSaga1, Api= ', Api);
		const response = yield call([
			Api,
			method,
		], parameters);
		// console.log('getApiRequestSaga2');
		return yield call(handlerResponse, {
			response,
			parameters,
			Api,
			method,
		});
	} catch (e) {
		console.log(e);
		return null;
	} finally {
		// always runs
	}
};

export const apiRequestClientBroker = getApiRequestSaga<BaseState>(ApiProvider);

export default null;
