import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Select, TableReact, Text, LoaderSpinner,
} from '@base/components';
import { transferHistoryAction } from '@client_broker/store/TransferHistory/actions';
import { ClientBrokerState } from '@client_broker/types';
import { Account, OptionType, TransferHistory } from '@base/types';

import TableRow from '@client_broker/containers/wallet/WalletPaymentHistory/TableRow/TableRow';
import { customerAccountsAction } from '@client_broker/store/СustomerAccounts/actions';

import styles from './WalletPaymentHistory.module.scss';

interface OwnProps {}

type WalletPaymentHistoryProps = {} & OwnProps;

const columns = [
	{
		accessor: 'system_name',
		Header: 'Платежная система',
		// isSort: false,
	},	{
		accessor: 'action',
		Header: 'Действие',
		// isSort: false,
	},	{
		accessor: 'amount',
		Header: 'Сумма',
		// isSort: false,
	},	{
		accessor: 'currency',
		Header: 'Валюта',
		// isSort: false,
	},	{
		accessor: 'created_at',
		Header: 'Дата операции',
		// isSort: false,
	},	{
		accessor: 'status',
		Header: 'Статус',
		// isSort: false,
	},
];

// eslint-disable-next-line no-empty-pattern
export const WalletPaymentHistory = ({}: WalletPaymentHistoryProps) => {
	const dispatch = useDispatch();
	const { transferHistory } = useSelector(
		(state: ClientBrokerState) => state.clientBroker,
	);
	const {
		accounts,
	} = useSelector((state: ClientBrokerState) => state.clientBroker.customerAccounts);

	const [data, setData] = useState<Array<TransferHistory>>([]);
	const [accountSelect, setAccountSelect] = useState<Account>();
	const [accountOptions, setAccountOptions] = useState<Array<OptionType<Account>>>([]);

	useEffect(() => {
		setData(transferHistory.data);
	}, [transferHistory]);

	useEffect(() => {
		dispatch(customerAccountsAction());
	}, [customerAccountsAction]);

	useEffect(() => {
		const accountOptionsNew = accounts.live.map((accountNew) => ({
			label: `${accountNew.mt_id} | ${accountNew.balance} | ${accountNew.currency}`,
			value: accountNew,
		}));

		if (
			accountOptionsNew.length > 0
			// && (!accountSelect
			// || (accountSelect && hash(accountSelect) !== hash(accountOptionsNew[0].value)))
		) {
			setAccountSelect(accountOptionsNew[0].value);
		}
		setAccountOptions(accountOptionsNew);
	}, [accounts.live]);

	// Получаем список истории платежей по событию выбора номера счета
	useEffect(() => {
		if (accountSelect && accountSelect.id) {
			dispatch(transferHistoryAction({
				account_id: accountSelect.id,
				account_type: 'live',
				rows: 100,
				since_id: 0,
			}));
		}
	}, [accountSelect]);

	return (
		<div className={styles.wallet_payment_history}>
			<Text type="h3" className={styles.wallet_payment_history__label}>История платёжных операций</Text>
			<div className={styles.wallet_payment_history__content}>
				{accounts.isLoading ? <LoaderSpinner />
					: (
						<>
							<Select
								required
								labelName="Счёт"
								name="account"
								options={accountOptions}
								value={accountSelect}
								className={styles.select}
								onChange={(event) => {
									setAccountSelect(event.value.value);
								}}
							/>
							<div className={styles.wallet_payment_history__table_wrap}>
								{transferHistory.isLoading ? <LoaderSpinner />
									: (
										// <>
										// 	<TableDeprecated
										// 		nameSort="sort"
										// 		valueSort=""
										// 		onChangeSort={() => {}}
										// 		nameOrder="order"
										// 		valueOrder="asc"
										// 		onChangeOrder={() => {}}
										// 		namePage="page"
										// 		valuePage={1}
										// 		pageSize={99}
										// 		onChangePage={() => {}}
										// 		columns={columns}
										// 		data={data}
										// 		isLoading={false}
										// 		valueCount={data.length}
										// 	>
										// 		{/* @ts-ignore */}
										// 		{(props) => (
										// 			<TableRow
										// 				// eslint-disable-next-line react/jsx-props-no-spreading
										// 				{...props}
										// 			/>
										// 		)}
										// 	</TableDeprecated>
										// </>
										<TableReact
											columns={columns}
											data={data}
											tableRow={TableRow}
										/>
									)}
							</div>
						</>
					)}
			</div>
		</div>
	);
};

export default null;
