/* eslint-disable */
const password = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[_!"#$%&'()*+,.:;<=>?@^`\][{|}~-])^[A-Za-z0-9_!"#$%&'()*+,.:;<=>?@^`\][{|}~-]{8,30}$/g;
const passwordForLogin = /^.{1,30}$/g
// const password = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[_!"#$%&'()*+,./:;<=>?@^`\][{|}~-])^[A-Za-z0-9_!"#$%&'()*+,./:;<=>?@^`\][{|}~-]$/g;

// const uppercaseCharIsRequired = /^(?=.*[A-Z])/g;
// const lowercaseCharIsRequired = /^(?=.*[a-z])/g;
// const numberIsRequired = /^(?=.*[0-9])/g;
// const symbolIsRequired = /^(?=.*[_!"#$%&'()*+,./:;<=>?@^`\][{|}~-])/g;

// const passwordAllowedValues = /^[A-Za-z0-9_!"#$%&'()*+,./:;<=>?@^`\][{|}~-]/g;

// const email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const email = /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/;
// const fullName = /(?=.*[A-Za-z])^(?=[A-Za-z -]{0,40}$)/g;
const firstname = /(?=.*[A-Za-z])^(?=[A-Za-z -]{0,40}$)/g;
const lastname = /(?=.*[A-Za-z])^(?=[A-Za-z -]{0,40}$)/g;
const accountNumber = /$/;
const phoneNumber = /$/;
// const referralCode = /^(\d){6}$/;
const referralCode = /[A-Za-z0-9]$/g;
// const zipcode = /[A-Za-z0-9-]$/g;
const zipcode = /[A-Za-z0-9-]$/g;
const address = /^[a-zA-Z0-9-,/\\.\s]+$/g;
const city = /[A-Za-z-]$/g;
const document_text = /[A-Za-z0-9-!"#$%&(),-.:;@_ ]$/g;
const paymentCardNumber = /$/;
const walletNumber = /$/;
const codeEmail = /$/;
const amount = /^\d+(\.\d{1,2})?$/;

export default {
	// uppercaseCharIsRequired,
	// lowercaseCharIsRequired,
	// numberIsRequired,
	// symbolIsRequired,

	// passwordAllowedValues,

	password,
	email,
	// fullName,
	firstname,
	lastname,
	accountNumber,
	phoneNumber,
	referralCode,
	zipcode,
	address,
	city,
	document_text,
	paymentCardNumber,
	walletNumber,
	codeEmail,
	amount,
	passwordForLogin

};
