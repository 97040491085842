import React, {
	useState,
	useCallback,
	useEffect,
} from 'react';
import cx from 'classnames';
import {
	format,
	formatISO,
} from 'date-fns';
import ReactDatePicker from 'react-datepicker';

import { DATE_FORMAT, DATE_TIME_FORMAT } from '@base/constants/dateFormat';
import useWindowDimensions from '@base/hooks/useWindowDimensions';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerDouble.scss';
import { CalendarIcon } from '@base/assets/icons/calendar';
import { Colors } from '@base/assets/styles/colors';
import styles from './DatePickerDouble.module.scss';

type DatePickerDoubleProps = {
	nameStart: string,
	nameEnd: string,
	valueStart: string,
	valueEnd: string,
	valueFormat?: string,
	// eslint-disable-next-line no-unused-vars
	onChangeStart: (event: {
		name: string,
		value: string,
	}) => void,
	// eslint-disable-next-line no-unused-vars
	onChangeEnd: (event: {
		name: string,
		value: string,
	}) => void,
	className: string,
	isTime: boolean,
};
// type DateButtonProps = {
// 	onClick: any,
// 	value: string,
// 	placeholderText: string,
// }
//
// const DateButton = ({
// 	onClick, value, placeholderText,
// }: DateButtonProps) => (
// 	<button
// 		type="button"
// 		className={styles.date_picker_double__button}
// 		onClick={onClick}
// 		value={value}
// 	>
// 		{value || placeholderText}
// 	</button>
// );
//
// DateButton.defaultProps = {
// 	value: '',
// 	onClick: () => {},
// 	placeholderText: '',
// };

export const DatePickerDouble = ({
	className,
	nameStart,
	nameEnd,
	valueStart,
	valueEnd,
	valueFormat,
	onChangeStart,
	onChangeEnd,
	isTime,
}: DatePickerDoubleProps) => {
	const [
		startDate,
		setStartDate,
	] = useState<any>();
	const [
		endDate,
		setEndDate,
	] = useState<any>();
	const {
		width,
	} = useWindowDimensions();

	useEffect(() => {
		if (valueStart) {
			setStartDate(new Date(valueStart));
		}
	}, [
		valueStart,
	]);

	useEffect(() => {
		if (valueEnd) {
			setEndDate(new Date(valueEnd));
		}
	}, [
		valueEnd,
	]);

	const handleStartChange = useCallback((date) => {
		setStartDate(date);
		const value = valueFormat !== undefined ? format(date, valueFormat)
			: formatISO(date, {
				representation: isTime ? 'complete' : 'date',
			});
		onChangeStart({
			name: nameStart,
			value,
		});
	}, [
		onChangeStart,
		nameStart,
		isTime,
		valueFormat,
	]);

	const handleEndChange = useCallback((date) => {
		setEndDate(date);
		const value = valueFormat !== undefined ? format(date, valueFormat)
			: formatISO(date, {
				representation: isTime ? 'complete' : 'date',
			});
		onChangeEnd({
			name: nameEnd,
			value,
		});
	}, [
		onChangeEnd,
		nameEnd,
		isTime,
		valueFormat,
	]);
	return (
		<div className={cx(styles.date_picker_double, className)}>
			<div className={styles.date_picker_double__icon}>
				<CalendarIcon color={Colors.primary} />
			</div>
			<div className={styles.date_picker_double__content}>
				<div className={cx(
					styles.date_picker_double__date,
					{
						[styles['date_picker_double__date--time']]: isTime,
					},
				)}
				>
					<ReactDatePicker
						selected={startDate}
						onChange={handleStartChange}
						selectsStart
						startDate={startDate}
						endDate={endDate}
						maxDate={new Date()}
						timeIntervals={60}
						showTimeSelect={isTime}
						dateFormat={isTime ? DATE_TIME_FORMAT : DATE_FORMAT}
						// customInput={<DateButton placeholderText="Start Date" />}
						withPortal={width <= 500}
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						// popperPlacement="bottom-start"
					/>
				</div>
				<span className={styles.date_picker_double__text}>
					to
				</span>
				<div className={cx(
					styles.date_picker_double__date,
					{
						[styles['date_picker_double__date--time']]: isTime,
					},
				)}
				>
					<ReactDatePicker
						selected={endDate}
						onChange={handleEndChange}
						selectsEnd
						startDate={startDate}
						endDate={endDate}
						minDate={startDate}
						timeIntervals={60}
						maxDate={new Date()}
						showTimeSelect={isTime}
						dateFormat={isTime ? DATE_TIME_FORMAT : DATE_FORMAT}
						// customInput={<DateButton placeholderText="End Date" />}
						withPortal={width <= 500}
						popperPlacement="bottom-end"
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
					/>
				</div>
			</div>
		</div>
	);
};

DatePickerDouble.defaultProps = {
	className: '',
	nameStart: '',
	nameEnd: '',
	valueStart: '',
	valueEnd: '',
	isTime: false,
	onChangeStart: () => {},
	onChangeEnd: () => {},
};

export default null;
