import React,
{
	useCallback,
} from 'react';
import cx from 'classnames';

import { Text } from '@base/components';
import styles from './Checkbox.module.scss';

export type CheckboxProps = {
	label: string,
	name: string,
	value: boolean,
	// eslint-disable-next-line no-unused-vars
	onChange: (obj: {
		name: string,
		value: boolean,
		event: React.SyntheticEvent<HTMLInputElement>
	}) => void,
	// theme?: 'primary' | 'secondary',
	className?: string,
	disabled?: boolean,
};

export const Checkbox = ({
	label,
	name,
	value,
	onChange,
	// theme,
	className,
	disabled,
}: CheckboxProps) => {
	const onChangeHandler = useCallback((event: React.SyntheticEvent<HTMLInputElement>) => {
		const {
			checked: inputValue,
		} = event.currentTarget;

		onChange({
			name,
			value: inputValue,
			event,
		});
	}, [
		onChange,
		name,
	]);

	return (
		<span
			className={cx(
				className,
				styles.checkbox,
				styles.checkbox__theme,
				{
					[styles['checkbox--checked']]: value,
				},
			)}
		>
			<label htmlFor={name} className={styles.checkbox__cursor}>
				<span
					className={cx(
						styles.checkbox__box,
						{
							[styles['checkbox__box--checked']]: value,
						},
					)}
				>
					<span
						className={cx(
							styles.checkbox__checked,
							{
								[styles['checkbox__checked--action']]: value,
							},
						)}
					/>
				</span>
				<Text type="h5">
					{label}
				</Text>
			</label>
			<input
				id={name}
				name={name}
				type="checkbox"
				checked={value}
				onChange={onChangeHandler}
				disabled={disabled}
			/>
		</span>
	);
};

// Checkbox.defaultProps = {
// 	theme: undefined,
// };

export default null;
