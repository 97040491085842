import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object } from 'yup';
import styles
	from '@auth/containers/passwordRecovery/SendEmailForm/SendEmailForm.module.scss';

import { AuthState } from '@auth/types';
import {
	LoaderSpinner, Text, Input, ButtonLink,
} from '@base/components';
import { Button } from '@base/components/Button';
import { passwordRecoveryInitStateAction, passwordRecoverySendEmailAction } from '@auth/store/PasswordRecovery/actions';
import authRoutes from '@auth/constants/authRoutes';

import { useFormik } from 'formik';

import { useTranslation } from 'react-i18next';
import { emailYup } from '@base/utils/yup';
import { getInfoInput } from '@base/utils/inputFunctions';
import cx from 'classnames';

type MailSubmissionFormProps = {};

interface LoginFormFieldsType {
	email: string,
}

export const initialValues = {
	email: '',
};

const secondDefault = 299;

// eslint-disable-next-line no-empty-pattern
export const SendEmailForm = ({}: MailSubmissionFormProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { sendEmail } = useSelector(
		(state: AuthState) => state.auth.passwordRecovery,
	);
	// eslint-disable-next-line no-unused-vars
	const [seconds, setSeconds] = useState(secondDefault);

	useEffect(() => {
		if (sendEmail.isSent) {
			setSeconds(secondDefault);
		}
	}, [sendEmail.isSent, setSeconds]);

	const validationSchema = object().shape({
		email: emailYup(t, 'AUTH.PASSWORD_RECOVERY.SEND_EMAIL'),
	});

	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<LoginFormFieldsType>({
		initialValues,
		onSubmit: (formData: LoginFormFieldsType): any => {
			dispatch(passwordRecoverySendEmailAction(formData));
		},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			// eslint-disable-next-line default-case
			switch (name) {
				case 'email':
					if (value.length > 320) return;
					break;
			}
			const event = {
				target: { name, value },
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	return (

		<form
			className={styles.send_email_form}
			onSubmit={handleSubmit}
		>
			{sendEmail.isLoading && <LoaderSpinner />}

			{!sendEmail.isSent ? (
				<>
					<Text type="h5" className={styles.send_email_form__text}>
						{t('AUTH.PASSWORD_RECOVERY.SEND_EMAIL.INSTRUCTION_1')}
					</Text>
					<Input
						autoComplete="on"
						className={styles.input}
						label={t('AUTH.PASSWORD_RECOVERY.SEND_EMAIL.EMAIL_LABEL')}
						placeholder={t('AUTH.PASSWORD_RECOVERY.SEND_EMAIL.EMAIL_PLACEHOLDER')}
						name="email"
						required
						value={values.email}
						onChange={onChangeHandler}
						onBlur={(event) => {
							handleBlur(event);
							setTouched({
								...touched,
								email: false,
							});
						}}
						onFocus={handleBlur}
						info={getInfoInput({
							name: 'email',
							text: t('AUTH.PASSWORD_RECOVERY.SEND_EMAIL.EMAIL_HINT'),
							touched,
							errors,
							values,
						})}
						disabled={sendEmail.isLoading}
					/>
					<input type="password" style={{ display: 'none' }} />
					<Button
						size="large"
						type="submit"
						theme="primary"
						onClick={() => {}}
						disabled={!isValid || sendEmail.isLoading}
						className={styles.send_email_form__button}
					>
						{t('AUTH.PASSWORD_RECOVERY.SEND_EMAIL.SEND_LETTER_BUTTON')}
					</Button>
				</>
			) : (
				<div className={cx(styles.description)}>
					<Text type="h4">
						{t('AUTH.PASSWORD_RECOVERY.SEND_EMAIL.INSTRUCTION_2_1')}
						{' '}
						<Text type="h4-extra-1" className={styles.description__text}>
							{values.email}
						</Text>
						{' '}
						{t('AUTH.PASSWORD_RECOVERY.SEND_EMAIL.INSTRUCTION_2_2')}
					</Text>
				</div>

			)}
			<ButtonLink
				to={authRoutes.auth.root}
				size="large"
				type="submit"
				theme="secondary"
				onClick={() => {
					dispatch(passwordRecoveryInitStateAction());
				}}
				className={styles.send_email_form__button}
			>
				{t('OTHER.CANCEL_BUTTON')}
			</ButtonLink>
		</form>
	);
};

export default null;
