import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { Text } from '@base/components';

import { MenuLink } from '@base/types';
import { Colors } from '@base/assets/styles/colors';
import { MenuIcons } from '@base/assets/icons/menu';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import { useSelector } from 'react-redux';
import { ClientBrokerState } from '@client_broker/types';
import styles from './MainMenu.module.scss';

interface OwnProps {
	className?: string,
	menuLinks: Array<MenuLink>,
}

type MainMenuProps = {} & OwnProps

export const MainMenu = ({
	className,
	menuLinks,
}: MainMenuProps) => {
	const { info } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.customer,
	);
	const [pathActive, setPathActive] = useState('');
	const [paths, setPaths] = useState<Array<MenuLink>>([]);
	const location = useLocation();

	useEffect(() => {
		setPathActive(location.pathname);
	}, [location]);

	useEffect(() => {
		setPaths(menuLinks.filter((value) => {
			switch (value.path) {
				case clientBrokerRoutes['partner-registration'].root:
					return info.data.is_partner === false;

				case clientBrokerRoutes['partner-cabinet'].root:
					return info.data.is_partner;

				default:
					return true;
			}
		}));
	}, [menuLinks, info]);

	const isActive = useCallback((path: string) => {
		if (!pathActive) setPathActive(global.location.pathname);
		return pathActive.includes(path);
	}, [
		pathActive, setPathActive,
	]);

	// const menutem filter = (path: string): boolean => {
	// 	switch (path) {
	// 		case clientBrokerRoutes['partner-registration'].root:
	// 			return true;
	// 		default:
	// 			return true;
	// 	}
	// };

	let nowIndex = 0;
	return (
		<div className={cx(styles['main-menu'], className)}>
			<div className={styles['main-menu__list']}>
				{paths.map(({
					path,
					label,
					icon,
				}) => {
					nowIndex += 1;
					const IconMenu = MenuIcons[icon];

					return (
						<div
							className={
								styles['main-menu__item']
							}
							key={nowIndex}
						>
							<NavLink
								activeClassName={styles['main-menu__link--active']}
								className={styles['main-menu__link']}
								to={path}
							>
								{IconMenu ? (
									<IconMenu
										className={styles['main-menu__link__icon']}
										color={isActive(path) ? Colors.primary : Colors.white}
									/>
								) : (<div className={styles['main-menu__link__icon']} />)}
								<Text
									type="h3"
									className={styles['main-menu__link__text']}
								>
									{label}
								</Text>
							</NavLink>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default null;
