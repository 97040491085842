import ApiProviderDefault from '@base/providers/apiProviderDefault';
import { GetSystemLimitsReq, GetSystemLimitsResp } from '@base/types/provider/base/system';

export default class SystemProvider extends ApiProviderDefault {
	static async getSystemLimits(parameters: {
		headers: Headers,
	}): Promise<GetSystemLimitsReq> {
		let body;
		let headers;
		const query = {};
		const path = '/monolith/system-limits';

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<GetSystemLimitsReq, GetSystemLimitsResp>(path, 'GET', query, body, headers);
	}
}

// @ts-ignore
SystemProvider.getSystemLimits.security = true;
