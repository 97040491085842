/* eslint-disable jsx-a11y/control-has-associated-label */
import React,
{
	useCallback,
	useEffect,
} from 'react';
import {
	useDispatch, useSelector,
} from 'react-redux';

import type {
	BaseState,
} from '@base/types';
import {
	modalCloseAction,
} from '@base/store/Modal/actions';
import styles from '@base/containers/ModalWindow/ModalWindow.module.scss';

const SymbolComponent = ({ onClick }: { onClick: () => void }) => (
	<div className={styles.symbol_wrap}>
		{/* eslint-disable-next-line max-len */}
		{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
		<span
			className={styles.symbol}
			onClick={() => onClick && onClick()}
		>
			&#215;
		</span>
	</div>
);

type OwnProps = {};

type ModalWindowProps = {} & OwnProps;

// eslint-disable-next-line no-empty-pattern
export const ModalWindow = ({}: ModalWindowProps) => {
	const dispatch = useDispatch();
	const {
		base: {
			modal: {
				isOpen, modalComponent,
			},
		},
		router: {
			location: {
				pathname,
			},
		},
	} = useSelector((state: BaseState) => state);

	useEffect(() => {
		const overflow = isOpen ? 'hidden' : 'auto';
		document.body.style.overflow = overflow;
	}, [isOpen]);

	const handleKeyPress = useCallback((event) => {
		if (event.keyCode === 27) {
			dispatch(modalCloseAction());
		}
	}, []);

	useEffect(() => {
		dispatch(modalCloseAction());
	}, [
		pathname,
	]);

	if (!isOpen) {
		return (<></>);
	}

	return (
		<div
			className={styles.modal_window}
			role="button"
			tabIndex={-1}
			onKeyDown={handleKeyPress}
		>
			<div
				className={styles.modal_window__box}
				// onClick={onModalClose}
				// role="button"
				tabIndex={-1}
				// onKeyDown={handleKeyPress}
			/>
			<div className={styles.modal_window__content}>
				<SymbolComponent onClick={() => dispatch(modalCloseAction())} />
				<div className={styles.modal_window__content__wrap}>
					{modalComponent}
				</div>
			</div>
		</div>
	);
};

export default null;
