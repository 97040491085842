import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import { Text } from '@base/components';
import styles from './Timer.module.scss';

interface TimerProps {
  className?: string;
  seconds: number;
	// eslint-disable-next-line no-unused-vars
	setCurrentSeconds?: (seconds: number) => void
}

export const Timer = ({ className, seconds, setCurrentSeconds }: TimerProps) => {
	const [time, setTime] = useState(0);

	useEffect(() => {
		if (seconds !== 0 && seconds !== time) {
			setTime(seconds);
		}
	}, [seconds]);

	useEffect(() => {
		if (time !== 0) {
			setTimeout(() => {
				setTime(time - 1);
				if (setCurrentSeconds) setCurrentSeconds(time - 1);
			}, 1000);
		}
	}, [time, setTime]);

	const getTime = useMemo(() => {
		const minutes = Math.floor(time / 60);
		const sec = Math.floor(time % 60);
		return `${minutes} : ${sec > 9 ? sec : `0${sec}`}`;
	}, [time]);

	return (
		<Text className={cx(styles.wrapper, className)} type="h4" color="black">
			{getTime}
		</Text>
	);
};

export default null;
