import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import {
	Button, Select, Text, Input, LoaderSpinner,
} from '@base/components';
import { modalCloseAction } from '@base/store/Modal/actions';
import { useDispatch, useSelector } from 'react-redux';

import { object, string } from 'yup';
import errorMessages from '@base/constants/errorMessages';
import { useFormik } from 'formik';
import regex from '@base/constants/regex';
import { Account, OptionType } from '@base/types';
import { ClientBrokerState } from '@client_broker/types';
// import { transferInternalAction } from '@client_broker/store/TransferInternal/actions';
import { useTranslation } from 'react-i18next';
import styles from './WithdrawalFromAccount.module.scss';

type WithdrawalFromAccountModalProps = {};

interface WithdrawalFromAccountModalFieldsType {
	account: OptionType<Account> | undefined,
	currency: OptionType<string> | undefined,
	amount: string,
}

export const initialValues = {
	// account: { label: '', value: AccountDefault },
	account: undefined,
	currency: undefined,
	amount: '',
};

const validationSchema = object().shape({
	amount: string()
		.required(errorMessages.FIELD_IS_REQUIRED)
		.matches(
			regex.amount,
			errorMessages.INVALID_AMOUNT,
		),
});

// eslint-disable-next-line no-empty-pattern
export const WithdrawalFromAccountModal = ({}: WithdrawalFromAccountModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [accountOptions, setAccountOptions] = useState<Array<OptionType<Account>>>([]);
	const [currenciesOptions, setCurrenciesOptions] = useState<Array<OptionType<string>>>([]);

	const {
		accounts,
	} = useSelector((state: ClientBrokerState) => state.clientBroker.customerAccounts);
	const {
		transferInternal,
	} = useSelector((state: ClientBrokerState) => state.clientBroker.transferInternal);

	const {
		handleChange,
		handleBlur,
		// eslint-disable-next-line no-unused-vars
		handleSubmit,
		values,
		// eslint-disable-next-line no-unused-vars
		setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<WithdrawalFromAccountModalFieldsType>({
		initialValues,
		onSubmit: (
			// eslint-disable-next-line no-unused-vars
			formData: WithdrawalFromAccountModalFieldsType,
		): any => () => {},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	useEffect(() => {
		const accountOptionsNew = accounts.live.map((account) => ({
			label: `${account.mt_id} | ${account.balance} | ${account.currency}`,
			value: account,
		}));
		if (accountOptionsNew.length !== 0) {
			setValues({
				...values,
				account: accountOptionsNew[0],
				currency: {
					value: accountOptionsNew[0].value.currency,
					label: accountOptionsNew[0].value.currency,
				},
			});
		}
		setAccountOptions(accountOptionsNew);
	}, [accounts.live]);

	useEffect(() => {
		if (values.account) {
			const currenciesOptionsNew: Array<OptionType<string>> = [{
				label: values.account.value.currency,
				value: values.account.value.currency,
			}];
			setValues({
				...values,
				currency: {
					value: values.account.value.currency,
					label: values.account.value.currency,
				},
			});
			setCurrenciesOptions(currenciesOptionsNew);
		}
	}, [values.account]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const event = {
				target: {	name,	value	},
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);
	return (
		<div className={styles.withdrawal_from_account}>
			<Text type="h3">Снятие средств со счёта</Text>
			<div className={styles.withdrawal_from_account__content}>
				{accounts.isLoading || transferInternal.isLoading ? <LoaderSpinner />
					: (
						<>
							<Select
								labelName="№ счета"
								name="account"
								options={accountOptions}
								value={values.account?.value}
								className={styles.withdrawal_from_account__content__select}
								onChange={onChangeHandler}
							/>
							<Select
								labelName="Валюта"
								name="currencies"
								options={currenciesOptions}
								value={values.currency?.value}
								className={styles.withdrawal_from_account__content__select}
								onChange={onChangeHandler}
								disabled={currenciesOptions.length < 2}
							/>
							<Input
								label="Сумма"
								name="amount"
								required
								value={values.amount}
								onChange={onChangeHandler}
								onBlur={handleBlur}
								error={
									(touched.amount !== undefined && errors.amount !== undefined)
										? errors.amount || ''
										: undefined
								}
							/>
						</>
					)}
			</div>
			<div className={styles.withdrawal_from_account__btns}>
				<Button
					className={styles.withdrawal_from_account__btns__cancel}
					type="button"
					theme="secondary"
					size="large"
					onClick={() => { dispatch(modalCloseAction()); }}
				>
					Отмена
				</Button>
				<Button
					type="button"
					theme="primary"
					size="large"
					onClick={() => handleSubmit()}
					disabled={!isValid || accounts.isLoading || transferInternal.isLoading}
				>
					Снять средства
				</Button>
			</div>
		</div>
	);
};
export default null;
