// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	authPersistLogout,
} from '@base/store/AuthPersist/actions';

import headerIconsDefault from '@base/assets/icons/header';
import { DropDownMenu } from '@base/components/DropDownMenu';
import hash from 'object-hash';
import { Text } from '@base/components';
import { NavLink } from 'react-router-dom';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import { OptionType } from '@base/types';
import { useTranslation } from 'react-i18next';
import { ClientBrokerState } from '@client_broker/types';
import cx from 'classnames';
import styles from './ProfileButton.module.scss';

interface OwnProps {
	type: string | 'mobile' | 'desktop'
}

type ProfileButtonProps = {
	className?: string,
} & OwnProps

const ItemLink = ({
	link, label, element, className, isItemElement = false,
}: {
	link: string,
	label: string,
	element?: React.ReactNode,
	className?: string,
	isItemElement?: boolean,
}) => (
	<NavLink
		activeClassName={styles.profile_button__item__text}
		className={cx(
			className,
			styles.profile_button__item__link,
			styles.profile_button__item__grid,
			{
				[styles.profile_button__item__border_round]: isItemElement,
				[styles['profile_button__item__border_round--show']]: element,
			},
		)}
		to={link}
	>
		{isItemElement && (element || <div />)}
		<Text
			type="h5"
			color="gray-light"
			className={styles.profile_button__item__text}
		>
			{label}
		</Text>
	</NavLink>
);

const Item = ({
	label, element, className, isItemElement = false,
}: {
	label: string,
	element?: React.ReactNode,
	className?: string,
	isItemElement?: boolean,
}) => (
	<div
		className={cx(
			className,
			styles.profile_button__item__grid,
			{
				[styles.profile_button__item__border_round]: isItemElement,
				[styles['profile_button__item__border_round--show']]: element,
			},
		)}
	>
		{isItemElement && (element || <div />)}
		<Text
			type="h5"
			color="gray-light"
			className={styles.profile_button__item__text}
		>
			{label}
		</Text>
	</div>
);

// eslint-disable-next-line no-unused-vars
export const ProfileButton = ({ type, className }: ProfileButtonProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { info } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.customer,
	);

	const [showDropDownMenu, setShowDropDownMenu] = useState(false);
	const [onMouseOut, setOnMouseOut] = useState(false);

	const menuValues = [
		{
			value: 'personal_data',
			label:
				info.data.verified
					? t('CLIENT.HEADER.PROFILE.PERSONAL_DATA')
					: t('CLIENT.HEADER.PROFILE.IDENTITY_VERIFICATION'),
		},
		{ value: 'documents', label: t('CLIENT.HEADER.PROFILE.DOCUMENTS') },
		{ value: 'security', label: t('CLIENT.HEADER.PROFILE.SECURITY') },
		{ value: 'logout', label: t('CLIENT.HEADER.PROFILE.LOGOUT') },
	];

	const onClick = ({ option }: { option: OptionType<string> }) => {
		if (option.value === 'logout') dispatch(authPersistLogout());
	};

	const isItemElement = useMemo(() => !info.data.verified, [
		info,
	]);

	const getItem = (option: OptionType<any>) => {
		switch (option.value) {
			case 'personal_data':
				return (
					<ItemLink
						isItemElement={isItemElement}
						link={clientBrokerRoutes.profile.root}
						label={option.label}
						element={!info.data.verified && (
							<Text color="primary" className={styles.profile_button__item__link__mark}>!</Text>
						)}
					/>
				);
			case 'documents':
				return (
					<ItemLink
						isItemElement={isItemElement}
						link={clientBrokerRoutes.documents.root}
						label={option.label}
					/>
				);
			case 'security':
				return (
					<ItemLink
						isItemElement={isItemElement}
						link={clientBrokerRoutes.security.root}
						label={option.label}
					/>
				);
			default:
				return (
					<Item
						isItemElement={isItemElement}
						label={option.label}
					/>
				);
		}
	};

	return (
		<div className={cx(
			className,
			styles.container,
		)}
		>
			<div
				// @ts-ignore
				tabIndex="0"
				role="button"
				className={styles.profile_button}
				onClick={() => setShowDropDownMenu(!showDropDownMenu)}
				onMouseOut={() => setOnMouseOut(false)}
				onMouseOver={() => setOnMouseOut(true)}
				onBlur={() => setShowDropDownMenu(onMouseOut)}
			>
				<div className={cx(
					{
						[styles.icon_round_wrap]: !info.data.verified,
					},
				)}
				>
					<div className={styles.icon_round}>
						<img
							className={styles[`profile_button__icon--${type}`]}
							src={type === 'mobile' ? headerIconsDefault.profile_mobile : headerIconsDefault.profile_desktop}
							alt=""
						/>
					</div>
				</div>

				{showDropDownMenu
				&& (
					<DropDownMenu
						onClick={onClick}
						inlineBlock="right"
						triangle
					>
						{menuValues.map((option, index) => (
							<div key={hash(index)} onClick={() => onClick({ option })}>
								{getItem(option)}
								{menuValues.length - 1 !== index && <br />}
							</div>
						))}
					</DropDownMenu>
				)}
			</div>
		</div>
	);
};

export default null;
