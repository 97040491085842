import ApiProviderDefault from '@base/providers/apiProviderDefault';
import { GetDocumentFilesResp, PutDocumentFilesReq, PutDocumentFilesResp } from '@base/types';

export default class FileProvider extends ApiProviderDefault {
	static async getDocumentFiles(parameters: {
		headers: Headers,
	}): Promise<GetDocumentFilesResp> {
		let body;
		let headers;
		const query = {};
		const path = '/file';

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request(path, 'GET', query, body, headers);
	}

	static async putDocumentFiles(parameters: {
		req: PutDocumentFilesReq,
		headers: Headers,
	}): Promise<PutDocumentFilesResp> {
		let body;
		let headers;
		const query = {};
		const path = '/file';
		if (parameters.req !== undefined) {
			const {
				file, file_type, others_type, is_identity,
			} = parameters.req;
			body = new FormData();
			body.append('file', file);
			body.append('file_type', String(file_type));
			body.append('is_identity', String(is_identity));
			body.append('others_type', String(others_type));
		}
		if (parameters.headers !== undefined) {
			// @ts-ignore
			headers = { ...parameters.headers, 'Content-Type': 'multipart/form-data' };
		}
		if (parameters.headers === undefined) {
			throw new Error('Missing required  parameter: headers');
		}

		return this.request(path, 'PUT', query, body, headers);
	}
}

// @ts-ignore
FileProvider.getDocumentFiles.security = true;
// @ts-ignore
FileProvider.putDocumentFiles.security = true;
