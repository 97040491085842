import ApiProviderDefault from '@base/providers/apiProviderDefault';
import {
	GetReferralGetReq,
	GetReferralGetResp,
	// GetReferralRequestsReq,
	// GetReferralRequestsResp,
	GetReferralSetReq,
	GetReferralSetResp,
	PostAssignParentReq,
	PostAssignParentResp,
} from '@base/types';

export default class ReferralProvider extends ApiProviderDefault {
	static async postAssignParent(parameters: {
		body: PostAssignParentReq,
		headers: Headers,
	}) {
		const path = '/assign-parent';
		let headers;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const { body } = parameters;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<PostAssignParentReq, PostAssignParentResp>(path, 'POST', query, body, headers);
	}

	// static async getReferralRequests() {
	// 	const query = {};
	// 	const path = '/ref/requests';
	//
	// 	return this.request<GetReferralRequestsReq, GetReferralRequestsResp>(path, 'GET', query);
	// }

	static async getReferralGet(parameters: GetReferralGetReq) {
		let query = {};
		if (parameters && parameters.fingerprint) {
			query = {
				fingerprint: parameters.fingerprint,
			};
		}
		const path = '/referral/get';

		return this.request<GetReferralGetReq, GetReferralGetResp>(path, 'GET', query);
	}

	static async getReferralSet(parameters: {
		params: GetReferralSetReq,
	}) {
		if (parameters.params === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		// console.log('getReferralSet, parameters= ', parameters);
		const {
			ref_code, bid, origin, utm, fingerprint,
		} = parameters.params;
		const query = {
			ref_code,
			utm,
			origin,
			bid,
			fingerprint,
		};
		const path = '/referral/set';

		return this.request<GetReferralSetReq, GetReferralSetResp>(path, 'GET', query);
	}
}

// @ts-ignore
ReferralProvider.postAssignParent.security = true;
