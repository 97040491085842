import React from 'react';
import cx from 'classnames';
import {
	NavLink,
} from 'react-router-dom';

import styles from './Tabs.module.scss';

type TabsPaths = {
	path: string,
	label: string
}

type Props = {
	paths: Array<TabsPaths>,
	type: 'select',
	className?: string,
};

export const Tabs = ({
	type,
	paths,
	className,
}: Props) => {
	let nowIndex = 0;

	return (
		<nav
			className={cx(
				styles.tabs,
				styles['tabs--select'],
				[
					styles[`tabs--theme--${type}`],
				],
				className,
			)}
		>
			<ul
				className={cx(styles.tabs__box)}
			>
				{paths.map(({
					path, label,
				}) => {
					nowIndex += 1;
					return (
						<li
							key={nowIndex}
							className={styles.tabs__item}
						>
							<NavLink
								activeClassName={styles['tabs__link--active']}
								className={styles.tabs__link}
								to={path}
							>
								<span className={styles['tabs__link-text']}>
									{label}
								</span>
								<div className={styles.tabs__border} />
							</NavLink>
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

Tabs.defaultProps = {
	type: 'select',
};

export default null;
