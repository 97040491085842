import * as registrationTypes from '@auth/store/Registration/constants';
import { RegistrationReq } from '@base/types';

export const registrationAction = (payload: RegistrationReq) => ({
	type: registrationTypes.REGISTRATION_REQUEST,
	payload,
});

export const registrationSuccessAction = () => ({
	type: registrationTypes.REGISTRATION_SUCCESS,
});

export const registrationFailAction = (payload: { error: string }) => ({
	type: registrationTypes.REGISTRATION_FAIL,
	payload,
});

export default null;
