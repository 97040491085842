import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { push } from 'connected-react-router';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import {
	DatePickerDouble,
	Link,
	TableDeprecated,
	Text,
	LoaderSpinner,
} from '@base/components';
import { useDispatch, useSelector } from 'react-redux';
import { ClientBrokerState } from '@client_broker/types';
import { tradesAction } from '@client_broker/store/Trades/actions';

import { TradingVolumes } from '@base/types';
import { DateValues } from '@base/constants/dateValues';
import TableRow from '@client_broker/containers/AccountHistoryContainer/TableRow/TableRow';
import cx from 'classnames';
import styles from './AccountHistoryContainer.module.scss';

interface ParamTypes {
	accountId: string,
}

type AccountHistoryProps = {
	accountType: 'live' | 'demo'
};
const columns = [
	{
		id: 'order',
		header: 'Номер тикета/операции',
		isSort: false,
	}, {
		id: 'open_time',
		header: 'Время открытия ордера',
		isSort: false,
	}, {
		id: 'close_time',
		header: 'Время закрытия ордера',
		isSort: false,
	}, {
		id: 'open_price',
		header: 'Цена открытия ордера',
		isSort: false,
	}, {
		id: 'close_price',
		header: 'Цена закрытия ордера',
		isSort: false,
	}, {
		id: 'cmd',
		header: 'Тип операции',
		isSort: false,
	}, {
		id: 'symbol',
		header: 'Наименование инструмента ордера',
		isSort: false,
	}, {
		id: 'volume',
		header: 'Объем сделки',
		isSort: false,
	}, {
		id: 'SL',
		header: 'Установленный стоп лосс для ордера',
		isSort: false,
	}, {
		id: 'profit',
		header: 'Установленный тейк профит для ордера',
		isSort: false,
	}, {
		id: 'commission',
		header: 'Комиссия',
		isSort: false,
	},
];

const today = new Date();

export const AccountHistoryContainer = ({ accountType }: AccountHistoryProps) => {
	const dispatch = useDispatch();
	const { accountId } = useParams<ParamTypes>();
	const trades = useSelector(
		(state: ClientBrokerState) => state.clientBroker.trades.trades,
	);
	const [accountsRoute, setAccountsRoute] = useState('');
	const [tradingVolumes, setTradingVolumes] = useState<Array<TradingVolumes>>([]);

	const [startDate, setStartDate] = useState<any>(new Date(today.getTime() - DateValues.week));
	const [endDate, setEndDate] = useState<any>(today);

	useEffect(() => {
		if (typeof Number(accountId) === 'number') {
			dispatch(tradesAction({
				accountType,
				accountId: Number(accountId),
				// time_from: 1622505600,
				time_from: new Date(startDate).getTime() / 1000,
				// time_to: 1624320099,
				time_to: new Date(endDate).getTime() / 1000,
			}));
			setTradingVolumes([]);
		}
	}, [accountId, startDate, endDate]);

	useEffect(() => {
		if (trades.data && trades.data.trading_volumes) {
			setTradingVolumes(trades.data.trading_volumes);
		}
	}, [trades]);

	useEffect(() => {
		if (!accountId) push(clientBrokerRoutes['live-accounts'].root);
	}, [accountId]);

	useEffect(() => {
		if (accountType === 'live') {
			setAccountsRoute(clientBrokerRoutes['live-accounts'].root);
		} else {
			setAccountsRoute(clientBrokerRoutes['demo-accounts'].root);
		}
	}, [accountType]);

	return (
		<div className={styles.live_account_history}>
			<Link className={styles.element} to={accountsRoute}>{'< Вернуться к счетам'}</Link>
			<Text className={styles.element} type="h2">
				История сделок по счёту #
				{accountId}
			</Text>
			<DatePickerDouble
				className={styles.element}
				valueStart={startDate}
				valueEnd={endDate}
				nameStart="start"
				nameEnd="end"
				onChangeStart={({
					value,
				}) => {
					setStartDate(value);
				}}
				onChangeEnd={({
					value,
				}) => setEndDate(value)}
			/>
			<div className={cx(styles.live_account_history__table, styles.element)}>
				{trades.isLoading ? <LoaderSpinner />
					: (
						<TableDeprecated
							nameSort="sort"
							valueSort=""
							onChangeSort={() => {}}
							nameOrder="order"
							valueOrder="asc"
							onChangeOrder={() => {}}
							namePage="page"
							valuePage={0}
							pageSize={22}
							onChangePage={() => {}}
							columns={columns}
							data={tradingVolumes}
							isLoading={false}
							valueCount={tradingVolumes.length}
						>
							{/* @ts-ignore */}
							{(props) => (
								<TableRow
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...props}
								/>
							)}
						</TableDeprecated>
					)}
			</div>
		</div>
	);
};

export default null;
