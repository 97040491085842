/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import styles from '@client_broker/pages/Wallet/Wallet.module.scss';
import { Redirect, Route, Switch } from 'react-router-dom';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import { WalletPaymentHistory }
	from '@client_broker/containers/wallet/WalletPaymentHistory';
import { WalletVerification } from '@client_broker/containers/wallet/WalletVerification';
import { TabsAdaptive } from '@base/components';
import { getTabPaths } from '@base/utils/getTabPaths';
import { WalletInputOutput } from '@client_broker/containers/wallet/WalletInputOutput';

// interface OwnProps {}
// type WalletProps = {} & OwnProps;

const paths = getTabPaths(clientBrokerRoutes.wallet);

// export const Wallet = ({}: WalletProps) => (
export const Wallet = () => (
	<div className={styles.wallet}>
		<div className={styles.wallet__tabs}>
			<TabsAdaptive paths={paths} />
		</div>
		<div className={styles.wallet__content}>
			<Switch>
				<Route
					path={clientBrokerRoutes.wallet.input.root}
					render={(props) => (
						<WalletInputOutput {...props} paymentsType="deposit" />
					)}
				/>
				<Route
					path={clientBrokerRoutes.wallet.output.root}
					render={(props) => (
						<WalletInputOutput {...props} paymentsType="withdraw" />
					)}
				/>
				<Route
					path={clientBrokerRoutes.wallet['payment-history'].root}
					component={WalletPaymentHistory}
				/>
				<Route
					path={clientBrokerRoutes.wallet.verification.root}
					component={WalletVerification}
				/>
				<Redirect to={clientBrokerRoutes.wallet.input.root} />
			</Switch>
		</div>
	</div>
);

export default null;
