import ApiProviderDefault from '@base/providers/apiProviderDefault';
import { GetCountriesReq, GetCountriesResp } from '@base/types';

export default class CountriesProvider extends ApiProviderDefault {
	static async getCountries(parameters: {
		headers: Headers,
	}): Promise<GetCountriesReq> {
		let body;
		let headers;
		const query = {};
		const path = '/countries';

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<GetCountriesReq, GetCountriesResp>(path, 'GET', query, body, headers);
	}
}
