import React from 'react';
import cx from 'classnames';

interface OwnProps {
	onClick?: () => void,
	onMouseMove?: () => void,
	onMouseLeave?: () => void,
	className?: string,
	color?: string,
	isActive?: boolean,
}

type OpenMenuIconProps = OwnProps

export const CardsIcon = ({
	color, isActive, className, onClick, onMouseMove, onMouseLeave,
}: OpenMenuIconProps) => (
	<svg
		className={cx(className)}
		onClick={onClick}
		onMouseMove={onMouseMove}
		onMouseLeave={onMouseLeave}
	>
		<circle cx="27.5" cy="27.5" r="27" fill={isActive ? color : 'white'} stroke={isActive ? 'white' : color} />
		<g clipPath="url(#clip0)">
			<path d="M31.25 29C31.0511 29 30.8603 29.079 30.7197 29.2197C30.579 29.3603 30.5 29.5511 30.5 29.75C30.5 29.9489 30.579 30.1397 30.7197 30.2803C30.8603 30.421 31.0511 30.5 31.25 30.5H34.75C34.9489 30.5 35.1397 30.421 35.2803 30.2803C35.421 30.1397 35.5 29.9489 35.5 29.75C35.5 29.5511 35.421 29.3603 35.2803 29.2197C35.1397 29.079 34.9489 29 34.75 29H31.25Z" fill={isActive ? 'white' : color} />
			<path fillRule="evenodd" clipRule="evenodd" d="M17.75 18C17.2859 18 16.8408 18.1844 16.5126 18.5126C16.1844 18.8408 16 19.2859 16 19.75V34.25C16 35.216 16.784 36 17.75 36H38.25C38.7141 36 39.1592 35.8156 39.4874 35.4874C39.8156 35.1592 40 34.7141 40 34.25V19.75C40 19.2859 39.8156 18.8408 39.4874 18.5126C39.1592 18.1844 38.7141 18 38.25 18H17.75ZM17.5 19.75C17.5 19.6837 17.5263 19.6201 17.5732 19.5732C17.6201 19.5263 17.6837 19.5 17.75 19.5H38.25C38.3163 19.5 38.3799 19.5263 38.4268 19.5732C38.4737 19.6201 38.5 19.6837 38.5 19.75V23.5H17.5V19.75ZM17.5 25V34.25C17.5 34.388 17.612 34.5 17.75 34.5H38.25C38.3163 34.5 38.3799 34.4737 38.4268 34.4268C38.4737 34.3799 38.5 34.3163 38.5 34.25V25H17.5Z" fill={isActive ? 'white' : color} />
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="24" height="24" fill={isActive ? 'white' : color} transform="translate(16 15)" />
			</clipPath>
		</defs>
	</svg>
);
