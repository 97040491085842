import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import { object } from 'yup';
import { AuthState } from '@auth/types';
import {
	passwordRecoveryRestoreAction,
} from '@auth/store/PasswordRecovery/actions';
import {
	Button, LoaderSpinner, Input, ButtonLink, CheckMarkWithText, Text,
} from '@base/components';
import { passwordConfirmYup, passwordYup } from '@base/utils/yup';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getInfoInput } from '@base/utils/inputFunctions';
import authRoutes from '@auth/constants/authRoutes';
import { authPersistLogout } from '@base/store/AuthPersist/actions';
import generate from '@base/utils/generate';
import styles from './RestoreForm.module.scss';

interface OwnProps {
}

type PasswordCreationFormProps = {} & OwnProps;

interface PasswordCreationFormFieldsType {
	password: string,
	password_confirm: string,
}

export const initialValues = {
	password: '',
	password_confirm: '',
	confirm: true,
};

// eslint-disable-next-line no-empty-pattern
export const RestoreForm = ({}: PasswordCreationFormProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { token } = useParams<{ token: string }>();

	const { restore } = useSelector(
		({ auth }: AuthState) => auth.passwordRecovery,
	);

	// TODO рефакторинг passwordConfirmYup
	const validationSchema = object().shape({
		password: passwordYup(t, 'AUTH.PASSWORD_RECOVERY'),
		password_confirm: passwordConfirmYup(t, 'AUTH.PASSWORD_RECOVERY'),
	});

	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<PasswordCreationFormFieldsType>({
		initialValues,
		onSubmit: (formData: any): any => dispatch(passwordRecoveryRestoreAction({
			...formData,
			confirm: token,
		})),
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			// eslint-disable-next-line default-case
			switch (name) {
				case 'password':
				case 'password_confirm':
					if (value.length > 30) return;
					break;
			}
			const event = {
				target: { name, value },
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	const generatePassword = () => {
		const password: any = generate.generateMultiplePassword();
		setValues({
			...values,
			password: `${password}`,
		});
	};

	return (
		<div className={styles.restore_form}>
			{restore.isLoading && <LoaderSpinner />}
			<Text type="h4-bold" className={styles.password_requirements_title}>
				{t('AUTH.PASSWORD_RECOVERY.PASSWORD_REQUIREMENTS')}
			</Text>
			<div className={styles.password_requirements}>
				<CheckMarkWithText text={t('AUTH.PASSWORD_RECOVERY.REQUIRED_VALUES.1')} />
				<CheckMarkWithText text={t('AUTH.PASSWORD_RECOVERY.REQUIRED_VALUES.2')} />
				<CheckMarkWithText text={t('AUTH.PASSWORD_RECOVERY.REQUIRED_VALUES.3')} />
				<CheckMarkWithText text={t('AUTH.PASSWORD_RECOVERY.REQUIRED_VALUES.4')} />
				<CheckMarkWithText text={t('AUTH.PASSWORD_RECOVERY.REQUIRED_VALUES.5')} />
				<CheckMarkWithText text={t('AUTH.PASSWORD_RECOVERY.REQUIRED_VALUES.6')} />
			</div>
			<Input
				className={styles.input}
				label={t('AUTH.PASSWORD_RECOVERY.PASSWORD_LABEL')}
				// placeholder={t('AUTH.PASSWORD_RECOVERY.PASSWORD_PLACEHOLDER')}
				name="password"
				required
				type="password"
				value={values.password}
				onChange={onChangeHandler}
				onBlur={(event) => {
					handleBlur(event);
					setTouched({
						...touched,
						password: false,
					});
				}}
				onFocus={handleBlur}
				textAction={{
					text: t('AUTH.PASSWORD_RECOVERY.GENERATE_BUTTON'),
					value: '',
					type: 'button',
					onClick: () => generatePassword(),
				}}
				info={getInfoInput({
					name: 'password',
					text: t('AUTH.PASSWORD_RECOVERY.PASSWORD_HINT'),
					touched,
					errors,
					values,
				})}
				disabled={restore.isLoading}
			/>
			<Input
				className={styles.input}
				label={t('AUTH.PASSWORD_RECOVERY.PASSWORD_CONFIRM_LABEL')}
				// placeholder={t('AUTH.PASSWORD_RECOVERY.PASSWORD_CONFIRM_PLACEHOLDER')}
				name="password_confirm"
				required
				type="password"
				value={values.password_confirm}
				onChange={onChangeHandler}
				onBlur={(event) => {
					handleBlur(event);
					setTouched({
						...touched,
						password_confirm: false,
					});
				}}
				onFocus={handleBlur}
				info={getInfoInput({
					name: 'password_confirm',
					text: t('AUTH.PASSWORD_RECOVERY.PASSWORD_CONFIRM_HINT'),
					touched,
					errors,
					values,
				})}
				disabled={restore.isLoading}
			/>
			<Button
				size="large"
				type="submit"
				theme="primary"
				onClick={() => handleSubmit()}
				disabled={restore.isLoading || !isValid}
				className={styles.restore_form__button}
			>
				{t('AUTH.PASSWORD_RECOVERY.CHANGE_PASSWORD_BUTTON')}
			</Button>
			<ButtonLink
				className={styles.restore_form__button}
				to={authRoutes.auth.root}
				type="submit"
				theme="secondary"
				size="large"
				onClick={() => dispatch(authPersistLogout())}
			>
				{t('OTHER.CANCEL_BUTTON')}
			</ButtonLink>
		</div>
	);
};

export default null;
