import * as referralTypes from '@base/store/Referral/constants';
import type { ReferralActionsType } from '@base/store/Referral/types';
import { RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';
// import { ReferralRequest } from '@base/types';

export type ReferralState = {
	send: RequestInfoChildType;
	setReferral: {} & RequestInfoChildType;
	// referralRequests: {
	// 	data: Array<ReferralRequest>,
	// } & RequestInfoChildType;
	referralCode: {
		isHave: boolean | null,
		ref_code: string,
	} & RequestInfoChildType;
};

const initialState: ReferralState = {
	send: RequestInfoChildState,
	setReferral: {
		...RequestInfoChildState,
	},
	// referralRequests: {
	// 	data: [],
	// 	...RequestInfoChildState,
	// },
	referralCode: {
		isHave: null,
		ref_code: '',
		...RequestInfoChildState,
	},
};

export const referralReducer = (
	state: ReferralState = initialState,
	action: ReferralActionsType,
): ReferralState => {
	switch (action.type) {
		case referralTypes.BASE_REFERRAL_SET_REQ: {
			return {
				...state,
				send: {
					...state.send,
					isLoading: true,
				},
			};
		}

		case referralTypes.BASE_REFERRAL_SET_SUCCESS: {
			return {
				...state,
				send: {
					...state.send,
					isLoading: false,
				},
			};
		}

		case referralTypes.BASE_REFERRAL_SET_FAIL: {
			const { payload } = action;
			return {
				...state,
				send: {
					...state.send,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		//------------------------------------------

		case referralTypes.BASE_SET_REFERRAL_REQUESTS_REQ: {
			return {
				...state,
				setReferral: {
					...state.setReferral,
					isLoading: true,
				},
			};
		}

		case referralTypes.BASE_SET_REFERRAL_REQUESTS_SUCCESS: {
			return {
				...state,
				setReferral: {
					...state.setReferral,
					isLoading: false,
				},
			};
		}

		case referralTypes.BASE_SET_REFERRAL_REQUESTS_FAIL: {
			const { payload } = action;
			return {
				...state,
				setReferral: {
					...state.setReferral,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		//------------------------------------------

		// case referralTypes.BASE_REFERRAL_REQUESTS_REQ: {
		// 	return {
		// 		...state,
		// 		referralRequests: {
		// 			...state.referralRequests,
		// 			isLoading: true,
		// 		},
		// 	};
		// }
		//
		// case referralTypes.BASE_REFERRAL_REQUESTS_SUCCESS: {
		// 	const { payload } = action;
		// 	return {
		// 		...state,
		// 		referralRequests: {
		// 			...state.referralRequests,
		// 			data: payload.data,
		// 			isLoading: false,
		// 		},
		// 	};
		// }
		//
		// case referralTypes.BASE_REFERRAL_REQUESTS_FAIL: {
		// 	const { payload } = action;
		// 	return {
		// 		...state,
		// 		referralRequests: {
		// 			...state.referralRequests,
		// 			isLoading: false,
		// 			error: payload.error,
		// 		},
		// 	};
		// }

		//------------------------------------------

		case referralTypes.BASE_REFERRAL_GET_REQ: {
			return {
				...state,
				referralCode: {
					...state.referralCode,
					isHave: null,
					isLoading: true,
				},
			};
		}

		case referralTypes.BASE_REFERRAL_GET_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				referralCode: {
					...state.referralCode,
					isHave: payload.isHave,
					ref_code: payload.ref_code,
					isLoading: false,
				},
			};
		}

		case referralTypes.BASE_REFERRAL_GET_FAIL: {
			const { payload } = action;
			return {
				...state,
				referralCode: {
					...state.referralCode,
					isLoading: false,
					isHave: false,
					error: payload.error,
				},
			};
		}

		//------------------------------------------

		case referralTypes.BASE_SET_REFERRAL_CODE: {
			const { payload } = action;
			return {
				...state,
				referralCode: {
					...state.referralCode,
					isHave: payload.isHave,
					ref_code: payload.ref_code,
				},
			};
		}
		default: {
			return state;
		}
	}
};
