/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type {
	BaseState,
} from '@base/types';
import { Button, LabelForm, Text } from '@base/components';
import { notificationsModalCloseAction } from '@base/store/NotificationsModal/actions';
import cx from 'classnames';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import crossIcon from '@base/assets/icons/cross.svg';
import checkMarkIcon from '@base/assets/icons/check_mark.svg';

import { useTranslation } from 'react-i18next';
import styles from './NotificationModal.module.scss';

const SymbolComponent = ({ onClick, to }: { onClick?: () => void, to?: string }) => {
	const getSymbolComponent = () => (
		<div className={styles.symbol_wrap}>
			<span
				className={styles.symbol}
				onClick={() => onClick && onClick()}
			>
				&#215;
			</span>
		</div>
	);

	return (
		to ? (
			<NavLink to={to}>
				{getSymbolComponent()}
			</NavLink>
		) : getSymbolComponent()
	);
};

type OwnProps = {};

type NotificationModalProps = {} & OwnProps;

// eslint-disable-next-line no-empty-pattern
export const NotificationModal = ({}: NotificationModalProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const {
		isOpen,
		messageTKey,
		descriptionTKey,
		type,
		onClick,
		onClickClose,
		descriptionComponent: DescriptionComponent,
		labelTKey,
	} = useSelector((state: BaseState) => state.base.notificationsModal);
	const { pathname } = useSelector((state: BaseState) => state.router.location);
	const {
		translationsPersist: {
			selectedLanguage,
		},
	} = useSelector((state: BaseState) => state.base);

	const handleKeyPress = useCallback((event) => {
		if (event.keyCode === 27) {
			dispatch(notificationsModalCloseAction());
		}
	}, []);

	useEffect(() => {
		dispatch(notificationsModalCloseAction());
	}, [pathname]);

	if (!isOpen) {
		return (<></>);
	}

	const getColor = () => {
		switch (type) {
			case 'success':
				return 'green';
			case 'error':
				return 'red';
			default:
				return 'black';
		}
	};

	const getSymbol = () => {
		switch (type) {
			case 'success':
				return (<img src={checkMarkIcon} alt="" />);
			case 'error':
				return (<img src={crossIcon} alt="" />);
			default:
				return <></>;
		}
	};

	const getMessage = () => (
		selectedLanguage.data?.tag === 'keys'
			? t(messageTKey)
			: t(messageTKey, t('ERRORS.DEFAULT_ERROR'))
	);
	return (
		<div
			className={styles.notification_modal}
			role="button"
			tabIndex={-1}
			onKeyDown={handleKeyPress}
		>
			<div
				className={styles.notification_modal__box}
				// role="button"
				tabIndex={-1}
				// onKeyDown={handleKeyPress}
			/>

			<div className={styles.notification_modal__wrapper}>
				<>
					<SymbolComponent
						onClick={() => {
							dispatch(notificationsModalCloseAction());
							if (onClickClose) {
								onClickClose({ dispatch, history, location });
							}
						}}
					/>
				</>
				<div className={styles.content}>
					{labelTKey && (
						<div className={styles.label_wrap}>
							<LabelForm className={styles.label} text={t(labelTKey)} isShowIcon={false} />
						</div>
					)}

					<div className={styles.info}>
						<div
							className={cx(
								styles.info__round,
								{
									[styles[`info__round--${type}`]]: type,
								},
							)}
						>
							{getSymbol()}
						</div>
						<div className={styles.info__text}>
							<Text className={styles.info__text__message} type="h5-extra" color={getColor()}>{getMessage()}</Text>
							{descriptionTKey
								? <Text className={styles.info__text__description}>{t(descriptionTKey)}</Text>
								// @ts-ignore
								: DescriptionComponent && <DescriptionComponent />}
						</div>
					</div>
					<Button
						className={styles.content__button}
						theme="primary"
						size="large"
						onClick={() => {
							dispatch(notificationsModalCloseAction());
							if (onClick) {
								onClick({ dispatch, history, location });
							}
						}}
					>
						OK
					</Button>
				</div>
			</div>
		</div>
	);
};

export default null;
