import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { DropDownMenu, DropDownMenuEventType } from '@base/components/DropDownMenu';
import { OptionType } from '@base/types';
import styles from './DropdownButton.module.scss';

interface OwnProps {
	className?: string;
	options?: Array<OptionType<any>>,
	inlineBlock: 'right' | 'left'
	align?: 'left' | 'center' | 'right',
	// eslint-disable-next-line no-unused-vars
	onClick?: (obj: DropDownMenuEventType) => void,
	children?: React.ReactNode,
	showMenu?: boolean,
}

type DropdownButtonProps = OwnProps;

// TODO вынести общую логику из этого компонента и компонента DropDownMenu
export const DropdownButton = ({
	className,
	options,
	inlineBlock,
	align,
	onClick: onClickDropdownButton,
	children,
	showMenu,
}: DropdownButtonProps) => {
	const [active, setActive] = useState(false);
	const [onMouseOut, setOnMouseOut] = useState(false);
	const [style, setStyle] = useState({});

	// Отключение полосы прокрутки
	useEffect(() => {
		const overflow = active ? 'hidden' : 'auto';
		// document.body.style.overflow = overflow;
		document.body.style.overflowX = overflow;
		const div = document.getElementById('table');
		// @ts-ignore
		div.style.overflow = overflow;
	}, [active]);

	useEffect(() => {
		if (showMenu === false) {
			setActive(false);
			setOnMouseOut(false);
		}
	}, [showMenu]);

	const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		setActive(!active);
		setOnMouseOut(!onMouseOut);
		setStyle({
			// right: `${window.innerWidth - e.clientX}px`,
			right: `${window.innerWidth - e.clientX - 65}px`,
			// top: `${e.clientY}px`,
			// position: 'fixed',
			position: 'absolute',
		});
	};

	const onClickDropDownMenu = (evn: DropDownMenuEventType) => {
		setActive(false);
		if (onClickDropdownButton) {
			onClickDropdownButton(evn);
		}
	};

	return (
		<div className={cx(className, styles.dropdown_button)}>
			{/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
			<button
				onClick={onClick}
				onMouseOut={() => setOnMouseOut(false)}
				onMouseOver={() => setOnMouseOut(true)}
				onBlur={() => setActive(onMouseOut)}
				className={cx(
					styles.dropdown_button__button,
					{
						[styles['dropdown_button__button--active']]: active,
					},
				)}
			>
				{active
				&& (
					children
						? (
							<DropDownMenu
								style={style}
								className={styles.dropdown_button__dropdown_menu}
								inlineBlock={inlineBlock}
								align={align}
							>
								{children}
							</DropDownMenu>
						)
						: (
							<DropDownMenu
								style={style}
								className={styles.dropdown_button__dropdown_menu}
								onClick={onClickDropDownMenu}
								options={options}
								inlineBlock={inlineBlock}
								align={align}
							/>
						)
				)}
				<div className={cx(
					styles.dropdown_button__button__icon,
					{
						[styles['dropdown_button__button__icon--active']]: active,
					},
				)}
				/>
			</button>
		</div>
	);
};

export default null;
