import {
	all,
	fork,
} from 'redux-saga/effects';
import { customerWatcher } from '@client_broker/store/Customer/sagas';
import { documentFilesWatcher } from '@client_broker/store/DocumentFiles/sagas';
import { customerAccountsWatcher } from '@client_broker/store/СustomerAccounts/sagas';
import { transferInternalWatcher } from '@client_broker/store/TransferInternal/sagas';
import { tradesWatcher } from '@client_broker/store/Trades/sagas';
import { paymentsWatcher } from '@client_broker/store/Payments/sagas';
import { transferHistoryWatcher } from '@client_broker/store/TransferHistory/sagas';
import { profileVerificationWatcher } from '@client_broker/store/ProfileVerification/sagas';

export function* clientBrokerSagaRoot() {
	try {
		yield all([
			fork(customerWatcher),
			fork(documentFilesWatcher),
			fork(customerAccountsWatcher),
			fork(transferInternalWatcher),
			fork(transferHistoryWatcher),
			fork(tradesWatcher),
			fork(paymentsWatcher),
			fork(profileVerificationWatcher),
		]);
	} finally {
		// always runs
	}
}
