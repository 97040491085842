import { put, select, takeLeading } from 'redux-saga/effects';

import * as errorHandlerTypes from '@base/store/ErrorHandler/constants';
import { ErrorHandlerActionType } from '@base/store/ErrorHandler/types';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';
import { BaseState } from '@base/types';
import i18next from 'i18next';

function* errorHandlerSaga({ payload }: ErrorHandlerActionType) {
	const {
		pageInfo,
	} = yield select(({ base }: BaseState) => base);
	console.log(pageInfo);
	const {
		status, error, notificationData = {},
	} = payload;
	const {
		onClick, onClickClose, descriptionComponent, descriptionTKey,
	} = notificationData;
	try {
		const { isAuth, token } = yield select(({ base }: BaseState) => base.authPersist);
		if (isAuth && token && status === 403) return;
		if (status && error) {
			const errorLowerCase = error;
			// const errorLowerCase = error;
			const messageTKey = i18next.t(`${pageInfo.labelTKey}.ERRORS.${errorLowerCase}.NAME`).includes(errorLowerCase)
				? `ERRORS.${errorLowerCase}` : `${pageInfo.labelTKey}.ERRORS.${errorLowerCase}.NAME`;
			yield put(notificationsModalOpenAction({
				labelTKey: pageInfo.labelTKey,
				messageTKey,
				descriptionTKey,
				type: 'error',
				descriptionComponent,
				onClick,
				onClickClose,
			}));
		} else {
			throw new Error(error || '');
		}
	} catch (e) {
		console.log(e);
		yield put(notificationsModalOpenAction({
			labelTKey: pageInfo.labelTKey,
			messageTKey: `ERRORS.${(e as Error).message}`,
			type: 'error',
			descriptionComponent: notificationData ? notificationData.descriptionComponent : undefined,
			onClick,
		}));
	}
}

export function* errorHandlerWatcher() {
	yield takeLeading(
		errorHandlerTypes.BASE_ERROR_HANDLER,
		errorHandlerSaga,
	);
}
