import * as profileVerificationTypes from '@client_broker/store/ProfileVerification/constants';
import { PutDocumentFiles } from '@base/types';

export const profileVerificationAction = (payload: {
	first_name?: string,
	last_name?: string,
	birthday?: any,
	zipcode?: string,
	address?: string,
	country_code?: string,
	city?: string,
} & PutDocumentFiles) => ({
	type: profileVerificationTypes.CLIENT_BROKER_PROFILE_VERIFICATION_REQUEST,
	payload,
});

export const profileVerificationSuccessAction = () => ({
	type: profileVerificationTypes.CLIENT_BROKER_PROFILE_VERIFICATION_SUCCESS,
});

export const profileVerificationFailAction = (payload: { error: string }) => ({
	type: profileVerificationTypes.CLIENT_BROKER_PROFILE_VERIFICATION_FAIL,
	payload,
});

export default null;
