import { InputInfoPropsType, InputInfoTypePropsType } from '@base/components/Input';

export const getInfoInput = ({
	name,
	text,
	touched,
	errors,
	// eslint-disable-next-line no-unused-vars
	values,
}: {
	name: string,
	text: string,
	touched: any,
	errors: any,
	values: any,
}): InputInfoPropsType | undefined => {
	// @ts-ignore
	let type: InputInfoTypePropsType | '';

	// @ts-ignore
	// if (touched[name] && !errors[name]) {
	// 	type = 'good';
	// } else
	// @ts-ignore
	if (touched[name]) {
		// @ts-ignore
		// type = errors[name] && values[name] ? 'error' : 'normal';
		type = 'normal';
		// @ts-ignore
	} else if (touched[name] === undefined) {
		type = '';
	// } else
	// // @ts-ignore
	// if (!errors[name]) {
	// 	type = 'good';
	} else {
		// @ts-ignore
		type = errors[name] ? 'error' : 'normal';
	}

	// @ts-ignore
	let initText = '';
	switch (type) {
		case 'error':
			// @ts-ignore
			initText = errors[name];
			break;
		case 'normal':
			// @ts-ignore
			initText = touched[name] ? text : '';
			break;
		// case 'good':
		// 	break;
		default:
			type = '';
			break;
	}
	return type ? {
		type,
		text: initText,
	} : undefined;
};
