import { call, put, takeLeading } from 'redux-saga/effects';

import {
	registrationSuccessAction,
	registrationFailAction,
} from '@auth/store/Registration/actions';
import * as registrationTypes from '@auth/store/Registration/constants';
import { RegistrationResp } from '@base/types';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { RegistrationActionType } from '@auth/store/Registration/types';
import { authPersistLoginSuccess, authPersistUpdateUserDataAction } from '@base/store/AuthPersist/actions';
import authRoutes from '@auth/constants/authRoutes';
import { push } from 'connected-react-router';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import { AuthNotificationDescriptionError } from '@base/containers/notificationsComponents';

function* registrationSaga({ payload }: RegistrationActionType) {
	try {
		const { email, password } = payload;
		const body = {
			...payload,
			password_confirm: password,
			phone: null,
		};
		const resp: RegistrationResp = yield call(
			apiRequestClientBroker,
			'postRegistration',
			{
				body,
			},
		);
		if (!resp.success) {
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent: AuthNotificationDescriptionError,
				},
			}));
			throw new Error(resp.error);
		}
		const {
			access_token,
			refresh_token,
			phone_confirmed,
			activated,
			// @ts-ignore
		} = resp.data;
		yield put(authPersistUpdateUserDataAction({
			userData: {
				isConfirmedEmail: activated || false,
				isConfirmedPhone: phone_confirmed || false,
				email,
			},
		}));
		yield put(authPersistLoginSuccess({
			accessToken: access_token,
			refreshToken: refresh_token,
			isAuth: false,
		}));
		yield put(registrationSuccessAction());
		yield put(push(authRoutes.auth.registration['verification-email'].root));
	} catch (e) {
		console.log(e);
		yield put(registrationFailAction({ error: (e as Error).message }));
	}
}

export function* registrationWatcher() {
	yield takeLeading(registrationTypes.REGISTRATION_REQUEST, registrationSaga);
}
