/* eslint-disable max-len,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Button, LoaderSpinner, Text, DatePicker, Input,
	AttachButtonDeprecated,
	AttachedImage,
	Paragraph,
	StatusIconAndText, ImageFlipper,
} from '@base/components';

import hash from 'object-hash';

import { ClientBrokerState } from '@client_broker/types';

import { modalCloseAction } from '@base/store/Modal/actions';
import fileTypes from '@base/constants/fileTypes';
import { object } from 'yup';
import { useFormik } from 'formik';
import { registrationAction } from '@auth/store/Registration/actions';
import { useTranslation } from 'react-i18next';
import { getInfoInput } from '@base/utils/inputFunctions';
import { profileVerificationAction } from '@client_broker/store/ProfileVerification/actions';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';
import { firstnameYup, lastnameYup } from '@base/utils/yup';
import ReactMarkdown from 'react-markdown';
import correct_1 from '@base/assets/images/photosOfIdentityVerificationExamples/correct_1.svg';
import correct_2 from '@base/assets/images/photosOfIdentityVerificationExamples/correct_2.svg';
import wrong_1 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_1.svg';
import wrong_2 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_2.svg';
import wrong_3 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_3.svg';
import wrong_4 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_4.svg';
import wrong_5 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_5.svg';
import wrong_6 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_6.jpg';

import { ImagePreview } from '@client_broker/modals/profile/ImagePreview';
import { imageFlipperFunction, imageFlipperOnScroll } from '@base/components/ImageFlipper';
import cx from 'classnames';
import { FileType } from '@base/components/AttachedFileName';
import styles from './IdentityVerificationModal.module.scss';

type IdentityVerificationModalProps = {};

export interface RegistrationFormFieldsType {
	first_name: string,
	last_name: string,
	birthday: any,
	files: Array<any>,
}

export const initialValues = {
	first_name: '',
	last_name: '',
	birthday: null,
	files: [],
};

// eslint-disable-next-line no-empty-pattern
export const IdentityVerificationModal = ({}: IdentityVerificationModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { info } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.customer,
	);

	const { profileVerification } = useSelector(
		(state: ClientBrokerState) => state.clientBroker,
	);
	const [filesRemoved, setFilesRemoved] = useState<Array<any>>([]);
	// eslint-disable-next-line no-unused-vars
	// const [showImage, setShowImage] = useState<boolean>(false);
	const [openFiles, setOpenFiles] = useState<Array<{ img: any, name: string }>>([]);
	const [openIndexPhotoWrong, setOpenIndexPhotoWrong] = useState<number>(0);
	// eslint-disable-next-line no-unused-vars
	const [indexPhotoWrongScroll, setIndexPhotoWrongScroll] = useState<number>(0);

	const photosWrong = [
		{ img: wrong_1, name: t('CLIENT.PROFILE.IMAGES.1') },
		{ img: wrong_2, name: t('CLIENT.PROFILE.IMAGES.2') },
		{ img: wrong_3, name: t('CLIENT.PROFILE.IMAGES.3') },
		{ img: wrong_4, name: t('CLIENT.PROFILE.IMAGES.4') },
		{ img: wrong_5, name: t('CLIENT.PROFILE.IMAGES.5') },
		{ img: wrong_6, name: t('CLIENT.PROFILE.IMAGES.6') },
	];

	const validationSchema = object().shape({
		first_name: firstnameYup(t, 'AUTH.REGISTRATION'),
		last_name: lastnameYup(t, 'AUTH.REGISTRATION'),
	});

	const {
		handleChange,
		handleBlur,
		values,
		setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<RegistrationFormFieldsType>({
		initialValues,
		onSubmit: (formData: any): any => dispatch(registrationAction(formData)),
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const event = {
				target: { name, value },
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	useEffect(() => {
		const { first_name, last_name } = info.data;
		setValues({
			...values,
			first_name: first_name || '',
			last_name: last_name || '',
			birthday: new Date(info.data.birthday),
		});
	}, [info.data]);

	// useEffect(() => {
	// 	if (birthday && info.data.birthday
	// 		&& new Date(birthday).toLocaleDateString()
	// 		!== new Date(info.data.birthday).toLocaleDateString()) {
	// 		dispatch(customerUpdateAction(
	// 			{ birthday: new Date(birthday).toLocaleDateString() },
	// 		));
	// 	}
	// }, [birthday]);

	// eslint-disable-next-line no-unused-vars
	const handleImageChange = (e: any) => {
		e.preventDefault();

		// const reader = new FileReader();
		const file = e.target.files[0];

		if (!file) return;

		if (file.size > 15000000) {
			dispatch(notificationsModalOpenAction({
				messageTKey: t('OTHER.MAXIMUM_FILE_SIZE_EXCEEDED'),
				type: 'error',
			}));
			return;
		}

		switch (file.type) {
			case 'image/png':
			case 'image/jpeg':
			case 'application/pdf':
				break;
			default:
				dispatch(notificationsModalOpenAction({
					messageTKey: t('OTHER.FILE_TYPE'),
					type: 'error',
				}));
				return;
		}
		// @ts-ignore
		// setFiles([...files, file]);
		setValues({
			...values,
			files: [...values.files, file],
		});
		// reader.onloadend = () => {
		// 	this.setState({
		// 		file,
		// 		imagePreviewUrl: reader.result,
		// 	});
		// };

		// reader.readAsDataURL(file);
	};

	const deleteFileByIndex = (index: number) => {
		setFilesRemoved([
			...filesRemoved,
			values.files[0],
		]);
		values.files.splice(index, 1);
		setValues({
			...values,
			files: [...values.files],
		});
	};

	const sendData = () => {
		const {
			last_name, first_name, birthday, files,
		} = values;
		dispatch(profileVerificationAction({
			last_name,
			first_name,
			birthday,
			files: [...files, ...filesRemoved],
			file_type: `${fileTypes.PROOF_OF_IDENTITY}`,
			others_type: '',
			is_identity: true,
		}));
	};

	// useEffect(() => {
	// 	if (update.isUpdated) {
	// 		dispatch(sendDocumentFilesAction({
	// 			files,
	// 			file_type: `${fileTypes.PROOF_OF_IDENTITY}`,
	// 			others_type: '',
	// 			is_identity: true,
	// 		}));
	// 	}
	// }, [update.isUpdated]);

	// const onSave = () => {
	// console.log('birthday', birthday);
	// dispatch(customerUpdateAction(
	// 	{ birthday: birthday.toLocaleDateString() },
	// ));
	// };

	const openPhotoWrong = (openIndex: number) => {
		setOpenIndexPhotoWrong(openIndex);
		setOpenFiles(photosWrong);
	};

	// const scrollRef = React.useRef(null);
	// const scroll = useScroll(scrollRef);

	// eslint-disable-next-line no-unused-vars
	// const btn = document.querySelector('.btn');
	//
	// // eslint-disable-next-line no-unused-vars
	// const handleButtonClick = () => {
	// 	// @ts-ignore
	// 	hiddenElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
	// };

	const VerificationButtons = ({
		className,
	}: {
		className?: string
	}) => (
		<div className={cx(className)}>
			{values.files.length > 0 ? (
				<div className={styles.attached_files_name}>
					{values.files.map((file: FileType, index) => (
						<AttachedImage
							className={styles.attached_file_name}
							key={hash(index)}
							file={file}
							name={file.name}
							size={file.size}
							onClickOpen={() => {
								setOpenIndexPhotoWrong(0);
								setOpenFiles([{
								// @ts-ignore
									img: URL.createObjectURL(file),
									name: file.name,
								}]);
							}}
							onClickClose={() => deleteFileByIndex(index)}
							type="max"
						/>
					))}
				</div>
			) : <></>}
			{values.files.length < 3 && (
				<AttachButtonDeprecated
					// className={styles.identity_verification_modal__add_file}
					text={values.files.length === 0
						? t('CLIENT.PROFILE.ATTACH_FILE_BUTTON')
						: t('CLIENT.PROFILE.ADD_FILE_BUTTON')}
					onClick={() => {
						// @ts-ignore
						document.getElementById('selectImage').click();
					}}
				/>
			)}
			<div className={styles.identity_verification_modal__btns}>
				<input
					id="selectImage"
					hidden
					type="file"
					accept="application/pdf, image/jpeg, image/png"
					onChange={(e) => handleImageChange(e)}
				/>
				<Button
					type="button"
					theme="primary"
					size="large"
					onClick={sendData}
					disabled={values.files.length < 3 || profileVerification.isLoading || !isValid}
				>
					{t('CLIENT.PROFILE.SEND_FOR_VERIFICATION_BUTTON')}
				</Button>
				<Button
					className={styles.identity_verification_modal__btns__cancel}
					type="button"
					theme="secondary"
					size="large"
					onClick={() => { dispatch(modalCloseAction()); }}
					disabled={profileVerification.isLoading}
				>
					{t('OTHER.CANCEL_BUTTON')}
				</Button>
			</div>
		</div>
	);

	return (
		<>
			{openFiles.length > 0 && (
				<ImagePreview
					onClickClose={() => setOpenFiles([])}
					images={openFiles}
					indexSelected={openIndexPhotoWrong}
				/>
			)}
			{openFiles.length === 0 && (
				<div className={styles.identity_verification_modal}>
					{(profileVerification.isLoading) && <LoaderSpinner className={styles.loader_spinner} />}
					<div>
						<Text type="h2">{t('CLIENT.PROFILE.IDENTITY_VERIFICATION')}</Text>
						<Paragraph className={styles.identity_verification_modal__paragraph} value="1">
							<Text type="h4">{t('CLIENT.PROFILE.FILL_IN_THE_FIELDS')}</Text>
						</Paragraph>
						<div className={styles.identity_verification_modal__inputs}>
							<Input
								label={t('CLIENT.PROFILE.FIRSTNAME_LABEL')}
								name="first_name"
								required
								value={values.first_name}
								onChange={onChangeHandler}
								onBlur={(event) => {
									handleBlur(event);
									setTouched({
										...touched,
										first_name: false,
									});
								}}
								onFocus={handleBlur}
								info={
									getInfoInput({
										name: 'first_name',
										text: t('CLIENT.PROFILE.FIRSTNAME_HINT'),
										touched,
										errors,
										values,
									})
								}
								floatInfo="bottom"
								disabled={profileVerification.isLoading}
							/>
							<Input
								label={t('CLIENT.PROFILE.LASTNAME_LABEL')}
								name="last_name"
								required
								value={values.last_name}
								onChange={onChangeHandler}
								onBlur={(event) => {
									handleBlur(event);
									setTouched({
										...touched,
										last_name: false,
									});
								}}
								onFocus={handleBlur}
								info={getInfoInput({
									name: 'last_name',
									text: t('CLIENT.PROFILE.LASTNAME_HINT'),
									touched,
									errors,
									values,
								})}
								floatInfo="bottom"
								disabled={profileVerification.isLoading}
							/>
							<DatePicker
								className={styles.input}
								label={t('CLIENT.PROFILE.BIRTHDAY_LABEL')}
								placeholder={t('CLIENT.PROFILE.BIRTHDAY_PLACEHOLDER')}
								value={values.birthday}
								name="birthday"
								onChange={({
									value,
								}) => {
									// setBirthday(value);
									onChangeHandler({ name: 'birthday', value });
								}}
								// onBlur={() => onSave()}
								required
								disabled={profileVerification.isLoading}
							/>
						</div>

						<div className={styles.identity_verification_modal__description}>
							<Paragraph className={styles.identity_verification_modal__paragraph} value="2">
								<Text type="h4">{t('CLIENT.PROFILE.ATTACH_DOCUMENT')}</Text>
							</Paragraph>
							<div className={styles.identity_verification_modal__description__text}>
								<Text type="h5">
									<ReactMarkdown>
										{t('CLIENT.PROFILE.IDENTITY_VERIFICATION_DESC')}
									</ReactMarkdown>
								</Text>
							</div>
							<VerificationButtons className={styles.buttons__width_1} />
						</div>
					</div>
					<div className={styles.identity_verification_modal__photo}>
						<>
							<StatusIconAndText
								status="positive"
								text={t('CLIENT.PROFILE.CORRECT_SCAN_DOCUMENT')}
							/>
							<div className={styles.identity_verification_modal__photo__correct}>
								<img alt="" src={correct_1} />
								<img alt="" src={correct_2} />
							</div>
						</>
						<>
							<StatusIconAndText
								status="negative"
								text={t('CLIENT.PROFILE.INCORRECT_SCAN_DOCUMENT')}
							/>
							<div
								className={styles.identity_verification_modal__photo__wrong}
								id="scrollElement"
								onScroll={() => imageFlipperOnScroll({
									scrollElementId: 'scrollElement',
									setImgIndexSelected: setIndexPhotoWrongScroll,
								})}
							>
								{photosWrong.map((photoWrong, index) => (
									<div id={`imgElement${index}`} className={styles.img_wrap} key={hash(index)}>
										<img alt="" src={photoWrong.img} onClick={() => openPhotoWrong(index)} />
										<Text type="h5" className={styles.img_wrap__text}>
											{photoWrong.name}
										</Text>
									</div>
								))}
							</div>
							<ImageFlipper
								className={styles.image_flipper}
								index={indexPhotoWrongScroll}
								setIndex={(imgIndex) => {
									imageFlipperFunction({
										scrollElementId: 'scrollElement',
										imgElementId: 'imgElement1',
										imgIndex,
										imgIndexSelected: indexPhotoWrongScroll,
										setImgIndexSelected: setIndexPhotoWrongScroll,
									});
								}}
								length={
									// @ts-ignore
									document.getElementById('scrollElement')?.children.length || 0
								}
							/>
						</>
					</div>
					<VerificationButtons className={styles.buttons__width_2} />
				</div>
			)}
		</>
	);
};

export default null;
