import * as transferHistoryTypes from '@client_broker/store/TransferHistory/constants';
import { PostTransferHistoryReq, TransferHistory } from '@base/types';

export const transferHistoryAction = (payload :PostTransferHistoryReq) => ({
	type: transferHistoryTypes.CLIENT_BROKER_TRANSFER_HISTORY_REQUEST,
	payload,
});

export const transferHistorySuccessAction = (payload: { data: Array<TransferHistory> }) => ({
	type: transferHistoryTypes.CLIENT_BROKER_TRANSFER_HISTORY_SUCCESS,
	payload,
});

export const transferHistoryFailAction = (payload: { error: string }) => ({
	type: transferHistoryTypes.CLIENT_BROKER_TRANSFER_HISTORY_FAIL,
	payload,
});

export default null;
