// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/alt-text,jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';
import cx from 'classnames';

import { conv_size } from '@base/utils/fileSizeDeterminer';
import { Text } from '@base/components';
import enlarge_image from '@base/assets/icons/enlarge_image.svg';
import large_magnifier from '@base/assets/icons/large_magnifier.svg';
import styles from './AttachedFileName.module.scss';

export type FileType = {
	lastModified: number,
	lastModifiedDate: string,
	name: string,
	size: number,
	type: string,
	webkitRelativePath: string,
}

interface AttachedImageProps {
	className?: string;
	name: string;
	size: number,
	file?: FileType,
	onClickClose: () => void,
	// eslint-disable-next-line no-unused-vars
	onClickOpen?: (file: any) => void,
	type?: 'mini' | 'max',
	disabled?: boolean,
}

export const AttachedImage = ({
	// eslint-disable-next-line no-unused-vars
	className, name, size, file, onClickClose, onClickOpen, type = 'mini', disabled = false,
}: AttachedImageProps) => {
	const isPdf = useMemo(() => file?.type.includes('pdf'), [file]);

	const thisOnClickOpen = () => {
		if (file && onClickOpen && !disabled && !isPdf) {
			onClickOpen(file);
		}
	};

	return (
		<div className={cx(styles.attached_image, className)}>
			<div className={styles.attached_image__max}>
				<div
					className={cx(
						styles.img_preview,
						{
							[styles.cursor_initial]: isPdf,
						},
					)}
					onClick={thisOnClickOpen}
				>
					<img
						className={cx(
							styles.img_preview__photo,
							{
								[styles.cursor_initial]: isPdf,
							},
						)}
						src={URL.createObjectURL(file)}
					/>
					{isPdf && (
						<img
							alt=""
							src={large_magnifier}
							className={styles.img_preview__icon}
						/>
					)}
				</div>
				<div className={styles.attached_image__max__info}>
					<Text
						onClick={thisOnClickOpen}
						type="h5"
						className={cx(
							styles.name,
							{
								[styles.cursor_initial]: isPdf,
							},
						)}
					>
						{name}
					</Text>
					{' '}
					<Text type="h5" color="gray-light" className={styles.size}>{conv_size(size)}</Text>
					<span onClick={onClickClose} className={styles.delete}>&#215;</span>
				</div>
			</div>
			<div className={styles.attached_image__mini}>
				<img
					onClick={thisOnClickOpen}
					alt=""
					src={enlarge_image}
					className={cx(
						styles.icon,
						{
							[styles.cursor_initial]: isPdf,
						},
					)}
				/>

				<Text
					onClick={thisOnClickOpen}
					type="h5"
					className={cx(
						styles.name,
						{
							[styles.cursor_initial]: isPdf,
						},
					)}
				>
					{name}
				</Text>
				{' '}
				<Text type="h5" color="gray-light" className={styles.size}>{conv_size(size)}</Text>
				<span onClick={onClickClose} className={styles.delete}>&#215;</span>
			</div>
		</div>
	);
};

export default null;
