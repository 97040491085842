import * as notificationsModalTypes from '@base/store/NotificationsModal/constants';
import {
	NotificationsModalActionsType,
	OnClickParamOfNotificationsModalType,
} from '@base/store/NotificationsModal/types';
import { TypeNotification } from '@base/types';
import React from 'react';

export type NotificationsModalState = {
	labelTKey?: string,
	messageTKey: string,
	descriptionTKey?: string,
	descriptionComponent?: React.ReactNode,
	type: TypeNotification,
	isOpen: boolean,
	// eslint-disable-next-line no-unused-vars
	onClick?: (obj: OnClickParamOfNotificationsModalType) => any,
	// eslint-disable-next-line no-unused-vars
	onClickClose?: (obj: OnClickParamOfNotificationsModalType) => any,
};

const initialState: NotificationsModalState = {
	labelTKey: undefined,
	messageTKey: '',
	descriptionComponent: undefined,
	type: 'normal',
	isOpen: false,
	// eslint-disable-next-line no-unused-vars
	onClick: (obj) => {},
	// eslint-disable-next-line no-unused-vars
	onClickClose: (obj) => {},
};

export const notificationsModalReducer = (
	state: NotificationsModalState = initialState,
	action: NotificationsModalActionsType,
): NotificationsModalState => {
	switch (action.type) {
		case notificationsModalTypes.BASE_NOTIFICATIONS_MODAL_OPEN: {
			const { payload } = action;
			return {
				...state,
				...payload,
				isOpen: true,
			};
		}

		case notificationsModalTypes.BASE_NOTIFICATIONS_MODAL_CLOSE: {
			return {
				...initialState,
			};
		}

		default: {
			return state;
		}
	}
};
