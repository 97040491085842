import React from 'react';
import cx from 'classnames';
import check_mark from '@base/assets/icons/check_mark_mini.svg';

import { Text } from '@base/components';
import styles from './CheckMarkWithText.module.scss';

interface CheckMarkWithTextProps {
	className?: string,
	text: string,
}

export const CheckMarkWithText = ({
	className,
	text,
}: CheckMarkWithTextProps) => (
	<div className={cx(styles.check_mark_with_text, className)}>
		<div className={styles.icon_wrap}>
			<img className={styles.icon} src={check_mark} alt="" />
		</div>
		<Text className={styles.text} type="h4">{text}</Text>
	</div>
);

export default null;
