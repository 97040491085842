import React from 'react';

import { Button, TabsAdaptive, Text } from '@base/components';
import { getTabPaths } from '@base/utils/getTabPaths';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { modalOpenAction } from '@base/store/Modal/actions';
import { useDispatch } from 'react-redux';
import { CreateAccountModal } from '@client_broker/modals/accounts/CreateAccountModal';
import { Live } from '@client_broker/containers/accounts/liveAccounts/Live';
// import { Archival } from '@client_broker/containers/accounts/liveAccounts/Archival';
import { useTranslation } from 'react-i18next';
import styles from './LiveAccounts.module.scss';

type LiveAccountsProps = {};

const paths = getTabPaths(clientBrokerRoutes['live-accounts']);

// eslint-disable-next-line no-empty-pattern
export const LiveAccounts = ({}: LiveAccountsProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<div className={styles.live_accounts}>
			<Text type="h2">{t('CLIENT.ACCOUNTS.LIVE.TITLE')}</Text>
			<Text type="h4" className={styles.live_accounts__description}>
				{t('CLIENT.ACCOUNTS.LIVE.DESCRIPTION')}
			</Text>
			<div className={styles.live_accounts__tabs}>
				<TabsAdaptive paths={paths} />
			</div>
			<div className={styles.live_accounts__content}>
				<Switch>
					<Route path={clientBrokerRoutes['live-accounts'].live.root} component={Live} />
					{/* <Route */}
					{/*	path={clientBrokerRoutesDefault['live-accounts'].archival.root} */}
					{/*	component={Archival} */}
					{/* /> */}
					<Redirect to={clientBrokerRoutes['live-accounts'].live.root} />
				</Switch>
			</div>

			<Button
				className={styles.live_accounts__btn}
				size="large"
				theme="primary"
				onClick={() => dispatch(modalOpenAction(<CreateAccountModal accountsType="is_live" />))}
			>
				{t('CLIENT.ACCOUNTS.LIVE.OPEN_ACCOUNT_BUTTON')}
			</Button>
		</div>
	);
};

export default null;
