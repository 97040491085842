import React from 'react';
import cx from 'classnames';

interface OwnProps {
	className?: string,
	color?: string,
}

type LiveAccountsIconProps = OwnProps

export const LiveAccountsIcon = ({ color, className }: LiveAccountsIconProps) => (
	<svg className={cx(className)}>
		<path d="M12 14.6819C13.1046 14.6819 14 13.705 14 12.5001C14 11.2951 13.1046 10.3182 12 10.3182C10.8954 10.3182 10 11.2951 10 12.5001C10 13.705 10.8954 14.6819 12 14.6819Z" fill={color} />
		<path d="M8.73484 17.3902C6.16185 14.5833 6.16185 10.0324 8.73484 7.22553M5.24075 20.843C0.91975 16.1292 0.91975 8.48656 5.24075 3.77274M15.3814 7.2255C17.9544 10.0324 17.9544 14.5833 15.3814 17.3902M18.8755 3.77271C23.1965 8.48652 23.1965 16.1291 18.8755 20.8429" stroke={color} strokeWidth="1.39763" strokeLinecap="round" />
	</svg>
);
