export const CUSTOMER_ACCOUNTS_REQUEST: 'CUSTOMER_ACCOUNTS_REQUEST' = 'CUSTOMER_ACCOUNTS_REQUEST';
export const CUSTOMER_ACCOUNTS_SUCCESS: 'CUSTOMER_ACCOUNTS_SUCCESS' = 'CUSTOMER_ACCOUNTS_SUCCESS';
export const CUSTOMER_ACCOUNTS_FAIL: 'CUSTOMER_ACCOUNTS_FAIL' = 'CUSTOMER_ACCOUNTS_FAIL';

export const UPDATE_CUSTOMER_ACCOUNT_REQUEST: 'UPDATE_CUSTOMER_ACCOUNT_REQUEST' = 'UPDATE_CUSTOMER_ACCOUNT_REQUEST';
export const UPDATE_CUSTOMER_ACCOUNT_SUCCESS: 'UPDATE_CUSTOMER_ACCOUNT_SUCCESS' = 'UPDATE_CUSTOMER_ACCOUNT_SUCCESS';
export const UPDATE_CUSTOMER_ACCOUNT_FAIL: 'UPDATE_CUSTOMER_ACCOUNT_FAIL' = 'UPDATE_CUSTOMER_ACCOUNT_FAIL';

export const CREATE_CUSTOMER_ACCOUNT_REQUEST: 'CREATE_CUSTOMER_ACCOUNT_REQUEST' = 'CREATE_CUSTOMER_ACCOUNT_REQUEST';
export const CREATE_CUSTOMER_ACCOUNT_SUCCESS: 'CREATE_CUSTOMER_ACCOUNT_SUCCESS' = 'CREATE_CUSTOMER_ACCOUNT_SUCCESS';
export const CREATE_CUSTOMER_ACCOUNT_FAIL: 'CREATE_CUSTOMER_ACCOUNT_FAIL' = 'CREATE_CUSTOMER_ACCOUNT_FAIL';
