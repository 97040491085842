/* eslint-disable */
// export const VERIFICATION_PHONE_SEND_CODE_REQUEST: 'VERIFICATION_PHONE_SEND_CODE_REQUEST' = 'VERIFICATION_PHONE_SEND_CODE_REQUEST';
// export const VERIFICATION_PHONE_SEND_CODE_SUCCESS: 'VERIFICATION_PHONE_SEND_CODE_SUCCESS' = 'VERIFICATION_PHONE_SEND_CODE_SUCCESS';
// export const VERIFICATION_PHONE_SEND_CODE_FAIL: 'VERIFICATION_PHONE_SEND_CODE_FAIL' = 'VERIFICATION_PHONE_SEND_CODE_FAIL';

export const VERIFICATION_PHONE_MAKE_CALL_REQUEST: 'VERIFICATION_PHONE_MAKE_CALL_REQUEST' = 'VERIFICATION_PHONE_MAKE_CALL_REQUEST';
export const VERIFICATION_PHONE_MAKE_CALL_SUCCESS: 'VERIFICATION_PHONE_MAKE_CALL_SUCCESS' = 'VERIFICATION_PHONE_MAKE_CALL_SUCCESS';
export const VERIFICATION_PHONE_MAKE_CALL_FAIL: 'VERIFICATION_PHONE_MAKE_CALL_FAIL' = 'VERIFICATION_PHONE_MAKE_CALL_FAIL';

export const VERIFICATION_PHONE_REQUEST: 'VERIFICATION_PHONE_REQUEST' = 'VERIFICATION_PHONE_REQUEST';
export const VERIFICATION_PHONE_SUCCESS: 'VERIFICATION_PHONE_SUCCESS' = 'VERIFICATION_PHONE_SUCCESS';
export const VERIFICATION_PHONE_FAIL: 'VERIFICATION_PHONE_FAIL' = 'VERIFICATION_PHONE_FAIL';

export const VERIFICATION_PHONE_CALL_ATTEMPT_REQUEST: 'VERIFICATION_PHONE_CALL_ATTEMPT_REQUEST' = 'VERIFICATION_PHONE_CALL_ATTEMPT_REQUEST';
export const VERIFICATION_PHONE_CALL_ATTEMPT_SUCCESS: 'VERIFICATION_PHONE_CALL_ATTEMPT_SUCCESS' = 'VERIFICATION_PHONE_CALL_ATTEMPT_SUCCESS';
export const VERIFICATION_PHONE_CALL_ATTEMPT_FAIL: 'VERIFICATION_PHONE_CALL_ATTEMPT_FAIL' = 'VERIFICATION_PHONE_CALL_ATTEMPT_FAIL';
