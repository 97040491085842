import React from 'react';
import cx from 'classnames';

interface OwnProps {
	onClick?: () => void,
	className?: string,
	color?: string,
}

type AttachButtonIconProps = OwnProps

export const AttachButtonIcon = ({ color, className, onClick }: AttachButtonIconProps) => (
	<svg className={cx(className)} onClick={onClick}>
		<path
			d="M16 6.75024V16.3302C16 18.4202 14.47 20.2802 12.39 20.4802C11.8345 20.535 11.2737 20.4728 10.7437 20.2976C10.2137 20.1225 9.72622 19.8384 9.31269 19.4634C8.89915 19.0885 8.5687 18.6312 8.34262 18.1208C8.11653 17.6105 7.99982 17.0584 8 16.5002V6.14024C8 4.83024 8.94 3.64024 10.24 3.51024C10.5886 3.47379 10.941 3.51105 11.2743 3.61963C11.6076 3.7282 11.9144 3.90565 12.1746 4.14046C12.4349 4.37526 12.6429 4.66218 12.7851 4.98258C12.9273 5.30298 13.0005 5.64971 13 6.00024V15.5002C13 16.0502 12.55 16.5002 12 16.5002C11.45 16.5002 11 16.0502 11 15.5002V6.75024C11 6.34024 10.66 6.00024 10.25 6.00024C9.84 6.00024 9.5 6.34024 9.5 6.75024V15.3602C9.5 16.6702 10.44 17.8602 11.74 17.9902C12.0886 18.0267 12.441 17.9894 12.7743 17.8809C13.1076 17.7723 13.4144 17.5948 13.6746 17.36C13.9349 17.1252 14.1429 16.8383 14.2851 16.5179C14.4273 16.1975 14.5005 15.8508 14.5 15.5002V6.17024C14.5 4.08024 12.97 2.22024 10.89 2.02024C10.3345 1.96551 9.7737 2.02771 9.24369 2.20283C8.71369 2.37795 8.22622 2.66212 7.81269 3.03704C7.39915 3.41195 7.0687 3.86931 6.84262 4.37966C6.61653 4.89001 6.49982 5.44205 6.5 6.00024V16.2702C6.5 19.1402 8.6 21.7102 11.46 21.9802C14.75 22.2802 17.5 19.7202 17.5 16.5002V6.75024C17.5 6.34024 17.16 6.00024 16.75 6.00024C16.34 6.00024 16 6.34024 16 6.75024Z"
			fill={color}
		/>
	</svg>
);
