import * as customerActionTypes from '@client_broker/store/Customer/constants';
import { RequestInfoChildType, Customer } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';
import { CustomerActionsType } from '@client_broker/store/Customer/types';

export type CustomerState = {
	info: {
		data: Customer
	}	& RequestInfoChildType
	update: {
	}	& RequestInfoChildType
	passwordChange: {
	}	& RequestInfoChildType
};

export const initialCustomerState: CustomerState = {
	info: {
		data: {
			account_limit_broker: null,
			account_limit_demo: null,
			account_limit_live: null,
			address: '',
			auto_approve_transfers: false,
			avatar: null,
			birthday: '',
			card_verified: false,
			chat: null,
			city: '',
			claws_horns_available: null,
			country_code: '',
			created_at: '',
			email: '',
			first_name: '',
			full_name: '',
			id: '',
			is_corporate: null,
			is_partner: null,
			language: '',
			last_name: '',
			phone: '',
			platform_name: '',
			prefix_name: null,
			updated_at: '',
			verified: false,
			underp: null,
			zipcode: '',
		},
		...RequestInfoChildState,
		isLoading: true,
	},
	update: {
		...RequestInfoChildState,
	},
	passwordChange: {
		...RequestInfoChildState,
	},
};

export const customerReducer = (
	state: CustomerState = initialCustomerState,
	action: CustomerActionsType,
): CustomerState => {
	switch (action.type) {
		case customerActionTypes.CUSTOMER_REQUEST: {
			return {
				...state,
				info: {
					...state.info,
					isLoading: true,
				},
			};
		}

		case customerActionTypes.CUSTOMER_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				info: {
					...state.info,
					data: payload,
					isLoading: false,
				},
			};
		}

		case customerActionTypes.CUSTOMER_FAIL: {
			const { payload } = action;
			return {
				...state,
				info: {
					...state.info,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		//-------------------------

		case customerActionTypes.CUSTOMER_UPDATE_REQUEST: {
			return {
				...state,
				update: {
					...state.update,
					isLoading: true,
				},
			};
		}

		case customerActionTypes.CUSTOMER_UPDATE_SUCCESS: {
			return {
				...state,
				update: {
					...state.update,
					isLoading: false,
				},
			};
		}

		case customerActionTypes.CUSTOMER_UPDATE_FAIL: {
			const { payload } = action;
			return {
				...state,
				update: {
					...state.update,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		//-------------------------

		case customerActionTypes.CUSTOMER_PASSWORD_CHANGE_REQUEST: {
			return {
				...state,
				passwordChange: {
					...state.passwordChange,
					isLoading: true,
				},
			};
		}

		case customerActionTypes.CUSTOMER_PASSWORD_CHANGE_SUCCESS: {
			return {
				...state,
				passwordChange: {
					...state.passwordChange,
					isLoading: false,
				},
			};
		}

		case customerActionTypes.CUSTOMER_PASSWORD_CHANGE_FAIL: {
			const { payload } = action;
			return {
				...state,
				passwordChange: {
					...state.passwordChange,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		//-------------------------
		default: {
			return state;
		}
	}
};
