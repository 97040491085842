import { OptionType, CountryType } from '@base/types';

export const getCountryOptionByAlpha2 = (
	countriesOptions: Array<OptionType<CountryType>>,
	alpha2: string,
): OptionType<CountryType> | undefined => {
	let countryOption: OptionType<CountryType> | undefined;

	countriesOptions.forEach((
		value,
	) => {
		if (value.value.alpha2 === alpha2) {
			countryOption = value;
		}
	});

	return countryOption;
};
