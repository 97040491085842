import modulesRoutes from '@base/constants/routesModules';
import partnersRegistrationRoutes from '@auth/constants/partnersRegistrationRoutes';
import i18n from '@starter/i18n';

const getClientBrokerRoutes = (
	// eslint-disable-next-line no-unused-vars
	{ baseURL }: {baseURL: string},
) => {
	const url = baseURL !== '' ? `${baseURL}/` : '/';
	return ({
		root: `${url}`,
		title: '',
		wallet: {
			root: `${url}wallet`,
			title: i18n.t('CLIENT.MENU.MY_WALLET'),
			icon: 'my_wallet',
			input: {
				root: `${url}wallet/input`,
				title: i18n.t('CLIENT.WALLET.INPUT'),
			},
			output: {
				root: `${url}wallet/output`,
				title: i18n.t('CLIENT.WALLET.OUTPUT'),
			},
			'payment-history': {
				root: `${url}wallet/payment-history`,
				title: i18n.t('CLIENT.WALLET.PAYMENT_HISTORY'),
			},
			verification: {
				root: `${url}wallet/verification`,
				title: i18n.t('CLIENT.WALLET.VERIFICATION'),
			},
		},
		'live-accounts': {
			root: `${url}live-accounts`,
			title: i18n.t('CLIENT.MENU.LIVE_ACCOUNTS'),
			icon: 'live_accounts',
			live: {
				root: `${url}live-accounts/live`,
				title: i18n.t('CLIENT.ACCOUNTS.LIVE.OPENED_ACCOUNTS'),
			},
			// archival: {
			// 	root: `${url}live-accounts/archival`,
			// 	title: 'Скрытые счета',
			// },
			history: {
				root: `${url}live-accounts/history`,
				rootParam: `${url}live-accounts/history/:accountId`,
				title: i18n.t('CLIENT.ACCOUNTS.LIVE.HISTORY'),
				isInMenu: false,
			},
		},
		'demo-accounts': {
			root: `${url}demo-accounts`,
			title: i18n.t('CLIENT.MENU.DEMO_ACCOUNTS'),
			icon: 'demo_accounts',
			demo: {
				root: `${url}demo-accounts/demo`,
				title: i18n.t('CLIENT.ACCOUNTS.DEMO.OPENED_ACCOUNTS'),
			},
			// archival: {
			// 	root: `${url}demo-accounts/archival`,
			// 	title: 'Скрытые счета',
			// },
			history: {
				root: `${url}demo-accounts/history`,
				rootParam: `${url}demo-accounts/history/:accountId`,
				title: i18n.t('CLIENT.ACCOUNTS.DEMO.HISTORY'),
				isInMenu: false,
			},
		},
		'ramm-accounts': {
			root: `${url}ramm-accounts`,
			title: 'Ramm / Copytrading', // добавить ключи
			icon: 'ramm_copytrading',
			isInMenu: false,
			'open-ramm-accountss': {
				root: `${url}ramm-accounts/open-ramm-accountss`,
				title: 'Open Ramm Account', // добавить ключи
			},
			// archival: {
			// 	root: `${url}ramm-accounts/archival`,
			// 	title: 'Archived accounts',
			// },
			'history-of-transactions': {
				root: `${url}ramm-accounts/history-of-transactions`,
				title: 'History of transactions', // добавить ключи
			},
		},
		'download-terminals': {
			root: `${url}download-terminals`,
			title: i18n.t('CLIENT.MENU.DOWNLOAD_TERMINAL'),
			icon: 'download_terminals',
			'meta-trader-4': {
				root: `${url}download-terminals/meta-trader-4`,
				title: i18n.t('CLIENT.DOWNLOAD_TERMINAL.META_TRADER_4'),
			},
		},
		'partner-cabinet': {
			root: `${url}partner-cabinet`,
			title: i18n.t('CLIENT.MENU.PARTNER_CABINET'),
			icon: 'partner_cabinet',
		},
		profile: {
			root: `${url}profile`,
			title: 'Profile',
			tKeyRoot: 'CLIENT.PROFILE', // пока не трогал
			isInMenu: false,
		},
		security: {
			root: `${url}security`,
			title: 'Security',
			tKeyRoot: 'CLIENT.SECURITY',
			isInMenu: false,
		},
		documents: {
			root: `${url}documents`,
			title: 'Documents',
			tKeyRoot: 'CLIENT.DOCUMENTS',
			isInMenu: false,
		},
		'partner-registration': {
			...partnersRegistrationRoutes['partner-registration'],
		},
	});
};

export const clientBrokerRoutes = getClientBrokerRoutes({
	baseURL: modulesRoutes.client.root,
});
