import React, {
	useState,
	useCallback,
	useEffect,
} from 'react';
import cx from 'classnames';
import {
	format,
	formatISO,
} from 'date-fns';
import ReactDatePicker from 'react-datepicker';

import { DATE_FORMAT, DATE_TIME_FORMAT } from '@base/constants/dateFormat';
import useWindowDimensions from '@base/hooks/useWindowDimensions';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';
import { CalendarIcon } from '@base/assets/icons/calendar';
import { Colors } from '@base/assets/styles/colors';
import { Text } from '@base/components';
import styles from './DatePicker.module.scss';

type DatePickerProps = {
	name: string,
	value: string,
	placeholder?: string,
	valueFormat?: string,
	// eslint-disable-next-line no-unused-vars
	onChange: (event: {
		name: string,
		value: string,
	}) => void,
	// eslint-disable-next-line no-unused-vars
	onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void,
	// eslint-disable-next-line no-unused-vars
	onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void,
	className?: string,
	label?: string,
	isTime?: boolean,
	required?: boolean,
	disabled?: boolean,
};

export const DatePicker = ({
	className,
	name,
	value,
	placeholder,
	valueFormat,
	onChange,
	onBlur,
	onFocus,
	isTime,
	required,
	disabled,
	label,
}: DatePickerProps) => {
	const [
		date,
		setDate,
	] = useState<any>();
	const {
		width,
	} = useWindowDimensions();

	useEffect(() => {
		if (value) {
			setDate(new Date(value));
		}
	}, [
		value,
	]);

	const handleChange = useCallback((dateNew) => {
		if (!dateNew) return;
		setDate(dateNew);
		const valueNew = valueFormat !== undefined ? format(dateNew, valueFormat)
			: formatISO(dateNew, {
				representation: isTime ? 'complete' : 'date',
			});
		onChange({
			name,
			value: valueNew,
		});
	}, [
		onChange,
		name,
		isTime,
		valueFormat,
	]);

	return (
		<div className={cx(className)}>
			<Text
				type="h3"
				className={cx(
					styles.label,
					{
						[styles.required]: required,
					},
				)}
			>
				{label}
				{' '}
			</Text>
			<div className={styles.date_picker}>
				<div className={styles.date_picker__content}>
					<div className={cx(
						styles.date_picker__date,
						{
							[styles['date_picker__date--time']]: isTime,
						},
					)}
					>
						<ReactDatePicker
							placeholderText={placeholder}
							selected={date}
							onChange={handleChange}
							maxDate={new Date()}
							timeIntervals={60}
							showTimeSelect={isTime}
							dateFormat={isTime ? DATE_TIME_FORMAT : DATE_FORMAT}
							withPortal={width <= 500}
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							disabled={disabled}
							onBlur={onBlur}
							onFocus={onFocus}
						/>
					</div>
				</div>
				<div className={styles.date_picker__icon}>
					<CalendarIcon color={Colors.primary} />
				</div>
			</div>
		</div>
	);
};

// DatePicker.defaultProps = {
// 	className: '',
// 	name: '',
// 	value: '',
// 	isTime: false,
// 	onChange: () => {},
// };

export default null;
