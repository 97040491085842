import {
	call, put, select, takeLeading,
} from 'redux-saga/effects';

import {
	verificationEmailCheckFailAction,
	verificationEmailCheckSuccessAction,
	verificationEmailResendFailAction,
	verificationEmailResendSuccessAction,
} from '@auth/store/VerificationEmail/actions';
import * as verificationEmailTypes from '@auth/store/VerificationEmail/constants';
import { BaseState, RegistrationResp } from '@base/types';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { VerificationEmailActionType } from '@auth/store/VerificationEmail/types';
import { authPersistLogout, authPersistUpdateUserDataAction } from '@base/store/AuthPersist/actions';
import { Dispatch } from 'redux';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import { AuthNotificationDescriptionError } from '@base/containers/notificationsComponents';

function* verificationEmailCheckSaga({ payload }: VerificationEmailActionType) {
	try {
		const {
			code,
		} = payload;
		const body = {
			code,
		};
		const resp: RegistrationResp = yield call(
			apiRequestClientBroker,
			'postConfirmMail',
			{
				body,
			},
		);
		if (!resp.success) {
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent: AuthNotificationDescriptionError,
					onClick: ({ dispatch }: { dispatch: Dispatch }) => {
						if (resp.error === 'CUSTOMER_BLOCKED') {
							dispatch(authPersistLogout());
						}
					},
				},
			}));
			throw new Error(resp.error);
		}
		yield put(authPersistUpdateUserDataAction({
			userData: {
				isConfirmedEmail: true,
			},
		}));
		yield put(verificationEmailCheckSuccessAction());
	} catch (e) {
		console.log(e);
		yield put(verificationEmailCheckFailAction({ error: e.message }));
	}
}

function* verificationEmailResendSaga() {
	try {
		const { email } = yield select(({ base }: BaseState) => base.authPersist.userData);

		const resp: RegistrationResp = yield call(
			apiRequestClientBroker,
			'postConfirm',
			{
				body: { email },
			},
		);
		if (!resp.success) {
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent: AuthNotificationDescriptionError,
				},
			}));
			throw new Error(resp.error);
		}
		yield put(verificationEmailResendSuccessAction());
	} catch (e) {
		console.log(e);
		yield put(verificationEmailResendFailAction({ error: e.message }));
	}
}

export function* verificationEmailWatcher() {
	yield takeLeading(
		verificationEmailTypes.VERIFICATION_EMAIL_CHECK_REQUEST,
		verificationEmailCheckSaga,
	);
	yield takeLeading(
		verificationEmailTypes.VERIFICATION_EMAIL_RESEND_REQUEST,
		verificationEmailResendSaga,
	);
}
