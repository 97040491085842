import { call, put, takeLeading } from 'redux-saga/effects';

import * as paymentsTypes from '@client_broker/store/Payments/constants';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { GetPaymentsResp, PostPaymentDepositResp } from '@base/types';
import {
	paymentsDepositFailAction, paymentsDepositSendFailAction, paymentsDepositSendSuccessAction,
	paymentsDepositSuccessAction,
	paymentsWithdrawFailAction,
	paymentsWithdrawSuccessAction,
} from '@client_broker/store/Payments/actions';
import { PaymentsDepositSendActionType } from '@client_broker/store/Payments/types';
import { modalCloseAction } from '@base/store/Modal/actions';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';

function* paymentsWithdrawSaga() {
	try {
		const payload = {
			paymentType: 'withdraw',
		};
		const resp: GetPaymentsResp = yield call(
			apiRequestClientBroker, 'getPayments', { params: payload },
		);
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(paymentsWithdrawSuccessAction(resp.data));
	} catch (e) {
		console.log(e);
		yield put(paymentsWithdrawFailAction({ error: e.message }));
	}
}

function* paymentsDepositSaga() {
	try {
		const payload = {
			paymentType: 'deposit',
		};
		const resp: GetPaymentsResp = yield call(
			apiRequestClientBroker, 'getPayments', { params: payload },
		);
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(paymentsDepositSuccessAction(resp.data));
	} catch (e) {
		console.log(e);
		yield put(paymentsDepositFailAction({ error: e.message }));
	}
}

function* paymentsDepositSendSaga({ payload }: PaymentsDepositSendActionType) {
	try {
		const resp: PostPaymentDepositResp = yield call(
			apiRequestClientBroker, 'postPaymentDeposit', { params: payload },
		);
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(paymentsDepositSendSuccessAction());
		// global.open(`http://stage.fxmaster.tech${resp.data.external_url}`);
		global.open(`${resp.data.external_url}`);
		yield put(modalCloseAction());
	} catch (e) {
		console.log(e);
		yield put(paymentsDepositSendFailAction({ error: e.message }));
	}
}

export function* paymentsWatcher() {
	yield takeLeading(paymentsTypes.PAYMENTS_WITHDRAW_REQUEST, paymentsWithdrawSaga);
	yield takeLeading(paymentsTypes.PAYMENTS_DEPOSIT_REQUEST, paymentsDepositSaga);

	yield takeLeading(paymentsTypes.PAYMENTS_DEPOSIT_SEND_REQUEST, paymentsDepositSendSaga);
}
