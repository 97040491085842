import React, { useMemo } from 'react';
import cx from 'classnames';

import { Text } from '@base/components';
import info from '@base/assets/icons/info.svg';
import styles from './LabelForm.module.scss';

interface LabelFormProps {
	className?: string;
	text: string;
	isShowIcon?: boolean,
	onClick?: () => void,
}

export const LabelForm = ({
	className, text, isShowIcon, onClick,
}: LabelFormProps) => {
	const word = text.split(' ').pop() || '';
	const textNew = text.replace(word, '');

	const showIcon = useMemo(() => (
		isShowIcon !== false && onClick
	), [onClick, isShowIcon, text]);

	return (
		<div className={cx(styles.label_auth, className)}>
			<Text type="h2">
				{textNew}
				<span className={styles.span}>
					{word}
					{
						showIcon && (
							<button onClick={onClick} className={styles.button}>
								<img src={info} alt="" className={styles.img} />
							</button>
						)
					}
				</span>
			</Text>
		</div>
	);
};

export default null;
