/* eslint-disable no-unused-vars */
import {
	createStore,
	applyMiddleware,
	compose,
} from 'redux';
import type { Dispatch } from 'redux';
import type { History } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { BaseActionsType, BaseState } from '@base/types';
import { AuthStateClear } from '@auth/types';
import { ClientBrokerStateClear } from '@client_broker/types';
import { AuthActionsClearType } from '@auth/types/store/actions';
import { ClientBrokerActionsClearType } from '@client_broker/types/store/actions';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export type ReduxState = BaseState & AuthStateClear & ClientBrokerStateClear;
export type ReduxActions = | BaseActionsType | AuthActionsClearType | ClientBrokerActionsClearType;
export type ReduxDispatch = Dispatch<ReduxActions>;

export default function configureStore(
	initialState: ReduxState,
	history: History,
) {
	// @ts-ignore
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose || compose;

	const sagaMiddleware = createSagaMiddleware();

	const middlewares = [
		// socketMiddleware(),
		sagaMiddleware,
		routerMiddleware(history),
	];

	const enhancers = [
		applyMiddleware(...middlewares),
	];
	const composedEnhancers = composeEnhancers(...enhancers);

	const store = createStore<ReduxState, ReduxActions, ReduxDispatch, null>(
		rootReducer(),
		initialState,
		composedEnhancers,
		// @ts-ignore
		null,
	);

	const persistor = persistStore(store);
	sagaMiddleware.run(rootSaga);

	return {
		store,
		persistor,
	};
}
