import React, { useEffect } from 'react';

import {
	Button, Text, Input, LoaderSpinner, CheckMarkWithText,
} from '@base/components';

import { useFormik } from 'formik';
import { object, ref, string } from 'yup';
import { customerPasswordChangeAction } from '@client_broker/store/Customer/actions';
import { useDispatch, useSelector } from 'react-redux';

import { ClientBrokerState } from '@client_broker/types';
import { useTranslation } from 'react-i18next';
import { passwordYup } from '@base/utils/yup';
import { getInfoInput } from '@base/utils/inputFunctions';
import generate from '@base/utils/generate';
import i18n from '@starter/i18n';
import regex from '@base/constants/regex';
import styles from './PasswordChangeForm.module.scss';

type PasswordChangeFormProps = {};

type PasswordCreationFormFieldsType = {
	password: string,
	password_new: string,
	password_new_confirm: string,
}

const initialValues = {
	password: '',
	password_new: '',
	password_new_confirm: '',
};

// eslint-disable-next-line no-empty-pattern
export const PasswordChangeForm = ({}: PasswordChangeFormProps) => {
	const { t } = useTranslation();

	const validationSchema = object().shape({
		password: passwordYup(t, 'CLIENT.SECURITY'),
		// password_new: passwordNewYup(t, 'MODULES.CLIENT.SECURITY.INPUTS')
		password_new: string()
			.required(i18n.t('OTHER.FIELD_IS_REQUIRED'))
			.matches(
				regex.password,
				i18n.t('CLIENT.SECURITY.NEW_PASSWORD_ERROR_VALIDATION'),
			).notOneOf([ref('password')], t('CLIENT.SECURITY.NEW_PASSWORD_ERROR_NEW_WITH_CURRENT')),
		// password_new_confirm: passwordNewConfirmYup(t, 'MODULES.CLIENT.SECURITY.INPUTS'),
		password_new_confirm: string()
			.required(t('OTHER.FIELD_IS_REQUIRED'))
			// .matches(
			// 	regex.password,
			// 	t(`MODULES.CLIENT.SECURITY.INPUTS.PASSWORD_NEW_CONFIRM.ERRORS.VALIDATION`),
			// )
			.oneOf([ref('password_new')],
				t('CLIENT.SECURITY.NEW_PASSWORD_CONFIRM_ERROR_PASSWORD_MISMATCH')),
	});

	const dispatch = useDispatch();
	const { passwordChange } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.customer,
	);

	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<PasswordCreationFormFieldsType>({
		initialValues,
		onSubmit: (formData: PasswordCreationFormFieldsType): any => {
			dispatch(customerPasswordChangeAction({
				password: formData.password,
				password_new: formData.password_new,
				password_new_again: formData.password_new_confirm,
			}));
			setValues({ ...initialValues });
		},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const event = {
				target: {	name,	value	},
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	const generatePassword = () => {
		const password: any = generate.generateMultiplePassword();
		setValues({
			...values,
			password_new: `${password}`,
		});
	};

	return (
		<div className={styles.password_change_form}>
			<Text type="h3-bold">{t('CLIENT.SECURITY.CHANGE_PASSWORD')}</Text>
			{passwordChange.isLoading ? <LoaderSpinner /> : (
				<>
					<Input
						size="large"
						className={styles.input}
						label={t('CLIENT.SECURITY.PASSWORD_LABEL')}
						// placeholder={t('CLIENT.SECURITY.PASSWORD._LACEHOLDER')}
						placeholder="" // нет плейсхолдера
						name="password"
						required
						type="password"
						value={values.password}
						onChange={onChangeHandler}
						onBlur={(event) => {
							handleBlur(event);
							setTouched({
								...touched,
								password: false,
							});
						}}
						onFocus={handleBlur}
						info={getInfoInput({
							name: 'password',
							text: t('CLIENT.SECURITY.PASSWORD_HINT'),
							touched,
							errors,
							values,
						})}
						disabled={passwordChange.isLoading}
					/>
					<Text type="h4-bold" className={styles.password_requirements_title}>
						{t('CLIENT.SECURITY.PASSWORD_REQUIREMENTS')}
					</Text>
					<div className={styles.password_requirements}>
						<CheckMarkWithText text={t('CLIENT.SECURITY.REQUIRED_VALUE_1')} />
						<CheckMarkWithText text={t('CLIENT.SECURITY.REQUIRED_VALUE_2')} />
						<CheckMarkWithText text={t('CLIENT.SECURITY.REQUIRED_VALUE_3')} />
						<CheckMarkWithText text={t('CLIENT.SECURITY.REQUIRED_VALUE_4')} />
						<CheckMarkWithText text={t('CLIENT.SECURITY.REQUIRED_VALUE_5')} />
						<CheckMarkWithText text={t('CLIENT.SECURITY.REQUIRED_VALUE_6')} />
					</div>
					<Input
						size="large"
						className={styles.input}
						label={t('CLIENT.SECURITY.NEW_PASSWORD_LABEL')}
						placeholder={t('CLIENT.SECURITY.NEW_PASSWORD_PLACEHOLDER')}
						name="password_new"
						required
						type="password"
						value={values.password_new}
						onChange={onChangeHandler}
						onBlur={(event) => {
							handleBlur(event);
							setTouched({
								...touched,
								password_new: false,
							});
						}}
						onFocus={handleBlur}
						info={getInfoInput({
							name: 'password_new',
							text: t('CLIENT.SECURITY.NEW_PASSWORD_HINT'),
							touched,
							errors,
							values,
						})}
						textAction={{
							text: t('CLIENT.SECURITY.GENERATE_BUTTON'),
							value: '',
							type: 'button',
							onClick: () => generatePassword(),
						}}
						disabled={passwordChange.isLoading}
					/>
					<Input
						size="large"
						className={styles.input}
						label={t('CLIENT.SECURITY.NEW_PASSWORD_CONFIRM_LABEL')}
						// placeholder={t('CLIENT.SECURITY.NEW_PASSWORD_CONFIRM_PLACEHOLDER')}
						placeholder="" // нет плейсхолдера
						name="password_new_confirm"
						required
						type="password"
						value={values.password_new_confirm}
						onChange={onChangeHandler}
						onBlur={(event) => {
							handleBlur(event);
							setTouched({
								...touched,
								password_new_confirm: false,
							});
						}}
						onFocus={handleBlur}
						info={getInfoInput({
							name: 'password_new_confirm',
							text: t('CLIENT.SECURITY.NEW_PASSWORD_CONFIRM_HINT'),
							touched,
							errors,
							values,
						})}
						disabled={passwordChange.isLoading
						|| (!values.password_new && !values.password_new_confirm)}
					/>
					<Button
						size="large"
						type="button"
						theme="primary"
						onClick={() => {
							handleSubmit();
						}}
						disabled={!isValid || passwordChange.isLoading}
						className={styles.button}
					>
						{t('CLIENT.SECURITY.CHANGE_PASSWORD_BUTTON')}
					</Button>
				</>
			)}
		</div>
	);
};

export default null;
