/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// eslint-disable-next-line no-unused-vars
import cx from 'classnames';

import { useTable } from 'react-table';
// eslint-disable-next-line no-unused-vars
import { Text } from '@base/components';
import styles from './TableReact.module.scss';

export type AnyObject = {[key: string]: any}
export type TableData<T> = Array<T>

export type TableColumn = {
	Header: string,
	accessor: string,
}

interface TableReactProps {
	className?: string,
	data: TableData<AnyObject>,
	columns: Array<TableColumn>,
	tableRow?: React.ReactNode,
	type?: 'default' | 'frameless' | 'no_header',
	noDataMessage?: string,
	// eslint-disable-next-line no-unused-vars
	onClickRow?: (obj: {
		accessor: string,
		item?: any,
	}) => void,
}

export const TableReact = ({
	className,
	data,
	columns,
	tableRow: TableRow,
	type = 'default',
	noDataMessage = 'no data found',
	onClickRow,
}: TableReactProps) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		// @ts-ignore
	} = useTable({ columns, data });

	const thisOnClickRow = (row: any) => {
		if (onClickRow) {
			onClickRow({
				item: row.original,
				accessor: row.id,
			});
		}
	};

	return (
		<div className={cx(
			styles.wrap,
			className,
			{
				[styles.wrap__default]: type === 'default',
				[styles.wrap__frameless]: type === 'frameless',
			},
		)}
		>
			<table
				id="table"
				{...getTableProps()}
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							className={cx(
								styles.frameless,
							)}
						>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps()}
								>
									<div
										className={cx(
											styles.th_child,
											{
												[styles.th_child__default]: type === 'default',
												[styles.th_child__frameless]: type === 'frameless',
											},
										)}
									>
										<div className={styles.text}>
											{column.render('Header')}
										</div>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								onClick={() => thisOnClickRow(row)}
							>
								{row.cells.map((cell) => (
									<td {...cell.getCellProps()}>
										<div className={cx(
											styles.td_child,
											{
												[styles.td_child__default]: type === 'default',
												[styles.td_child__frameless]: type === 'frameless',
												[styles.td_child__frameless__cursor_pointer]: onClickRow,
											},
										)}
										>
											{TableRow
											// @ts-ignore
												? (<TableRow id={cell.column.id} item={cell.row.original} />)
												: (
													<div className={styles.text}>
														{cell.render('Cell')}
													</div>
												)}
										</div>
									</td>
								))}
							</tr>
						);
					})}
				</tbody>

			</table>
			{
				data.length === 0
	&& (
		<div className={styles.wrap__data_not_found}>
			<Text type="h3" color="gray">{noDataMessage}</Text>
		</div>
	)
			}
		</div>
	);
};
export default null;
