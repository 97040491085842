/* eslint-disable */
import {
	put,
	select,
	takeLeading,
} from 'redux-saga/effects';

import * as userActionsInfoPersistTypes from '@base/store/UserActionsInfoPersist/constants';
import { BaseState } from '@base/types';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';
import { OnClickParamOfNotificationsModalType } from '@base/store/NotificationsModal/types';
import { authPersistLogout } from '@base/store/AuthPersist/actions';

function* securityPasswordEnteredIncorrectlySaga() {
	console.log('securityPasswordEnteredIncorrectlySaga');
	try {
		// console.log('securityPasswordEnteredIncorrectlySaga2');
		// const { pageInfo } = yield select(({ base }: BaseState) => base);
		// console.log('securityPasswordEnteredIncorrectlySaga3');
		// const {
		// 	securityPasswordEnteredIncorrectly: { count },
		// } = yield select((state: BaseState) => state.base.userActionsInfoPersist);
		// console.log('securityPasswordEnteredIncorrectlySaga, count= ', count);
		//
		// if (count > 1) {
		// 	yield put(notificationsModalOpenAction({
		// 		labelTKey: pageInfo.labelTKey,
		// 		messageTKey: 'MODULES.AUTH.LOGIN.NOTIFICATIONS.EMAIL_CONFIRMED',
		// 		type: 'error',
		// 		onClick: ({ dispatch }: OnClickParamOfNotificationsModalType) => {
		// 			dispatch(authPersistLogout());
		// 		},
		// 		onClickClose: ({ dispatch }: OnClickParamOfNotificationsModalType) => {
		// 			dispatch(authPersistLogout());
		// 		},
		// 	}));
		// }
	} catch (err) {
		// yield put(userActionsInfoPersistFailAction({ error: err.message }));
	}
}

export function* userActionsInfoPersistWatcher() {
	yield takeLeading(
		userActionsInfoPersistTypes.BASE_USER_ACTIONS_SECURITY_PASSWORD_ENTERED_INCORRECTLY,
		securityPasswordEnteredIncorrectlySaga,
	);
}
