import * as profileVerificationTypes from '@client_broker/store/ProfileVerification/constants';
import type { ProfileVerificationActionsType } from '@client_broker/store/ProfileVerification/types';
import { RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type ProfileVerificationState = {
} & RequestInfoChildType;

const initialState: ProfileVerificationState = {
	...RequestInfoChildState,
};

export const profileVerificationReducer = (
	state: ProfileVerificationState = initialState,
	action: ProfileVerificationActionsType,
): ProfileVerificationState => {
	switch (action.type) {
		case profileVerificationTypes.CLIENT_BROKER_PROFILE_VERIFICATION_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}

		case profileVerificationTypes.CLIENT_BROKER_PROFILE_VERIFICATION_SUCCESS: {
			return {
				...state,
				isLoading: false,
			};
		}

		case profileVerificationTypes.CLIENT_BROKER_PROFILE_VERIFICATION_FAIL: {
			const { payload } = action;
			return {
				...state,
				isLoading: false,
				error: payload.error,
			};
		}

		default: {
			return state;
		}
	}
};
