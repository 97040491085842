import modulesRoutes from '@base/constants/routesModules';
import i18n from '@starter/i18n';

const getAuthRoutes = (baseURL: string): any => {
	const url = baseURL !== '' ? `${baseURL}` : '/';
	return {
		root: `${url}`,
		title: 'Main',
		auth: {
			root: `${url}auth`,
			title: i18n.t('AUTH.LOGIN_LABEL'),
			tKeyRoot: 'AUTH.LOGIN',
			'password-recovery': {
				root: `${url}auth/password-recovery`,
				title: i18n.t('AUTH.PASSWORD_RECOVERY.SEND_EMAIL.TITLE'),
				tKeyRoot: 'AUTH.PASSWORD_RECOVERY.SEND_EMAIL',
				restore: {
					root: `${url}auth/password-recovery/restore`,
					rootParam: `${url}auth/password-recovery/restore/:token`,
					title: 'Restore',
					tKeyRoot: 'AUTH.PASSWORD_RECOVERY',
				},
			},
			registration: {
				root: `${url}auth/registration`,
				title: i18n.t('AUTH.REGISTRATION.TITLE'),
				tKeyRoot: 'AUTH.REGISTRATION',
				'verification-phone': {
					root: `${url}auth/registration/verification-phone`,
					title: i18n.t('.AUTH.REGISTRATION.VERIFICATION_PHONE.TITLE'),
					tKeyRoot: 'AUTH.REGISTRATION.VERIFICATION_PHONE',
				},
				'verification-email': {
					root: `${url}auth/registration/verification-email`,
					title: i18n.t('AUTH.REGISTRATION.VERIFICATION_EMAIL.TITLE'),
					tKeyRoot: 'AUTH.REGISTRATION.VERIFICATION_EMAIL',
				},
			},
			// 'partner-registration': {
			// 	root: `${url}auth/partner-registration`,
			// 	title: 'Стать партнёром',
			// },
		},
	};
};

export const getAuthRoutesUpdated: any = () => getAuthRoutes(modulesRoutes.root);

const authRoutes: any = getAuthRoutes(modulesRoutes.root);

export default authRoutes;
