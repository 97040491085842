import React from 'react';
import cx from 'classnames';

interface OwnProps {
	className?: string,
	color?: string,
}

type MyWalletIconProps = OwnProps

export const MyWalletIcon = ({ color, className }: MyWalletIconProps) => (
	<svg className={cx(className)}>
		<path d="M21.805 5.49481H21.5064V2.59482C21.5064 1.3845 20.5217 0.39978 19.3113 0.39978H3.39063C1.63377 0.39978 0.185231 1.74358 0.0180168 3.45723C-0.00752011 3.57832 0.00157327 2.49936 0.00157327 19.2111C0.00157327 21.0798 1.52187 22.6001 3.39058 22.6001H21.8049C23.0153 22.6001 24 21.6154 24 20.4051V7.68985C24 6.47954 23.0153 5.49481 21.805 5.49481ZM3.39063 1.90754H19.3113C19.6903 1.90754 19.9986 2.21587 19.9986 2.59487V5.49486H3.39063C2.38267 5.49486 1.55753 4.69799 1.51155 3.7012C1.55749 2.70437 2.38263 1.90754 3.39063 1.90754ZM21.805 21.0924H3.39063C2.35327 21.0924 1.50933 20.2484 1.50933 19.2111V6.43119C2.04792 6.79196 2.6951 7.00252 3.39063 7.00252H21.805C22.184 7.00252 22.4923 7.31085 22.4923 7.68985V10.7461H16.8192C14.8871 10.7461 13.3152 12.3179 13.3152 14.25C13.3152 16.1821 14.8871 17.7539 16.8192 17.7539H22.4923V20.4051C22.4923 20.7841 22.184 21.0924 21.805 21.0924ZM22.4923 16.2463H16.8192C15.7184 16.2463 14.8229 15.3508 14.8229 14.25C14.8229 13.1493 15.7184 12.2538 16.8192 12.2538H22.4923V16.2463Z" fill={color} />
	</svg>
);
