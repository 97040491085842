import {
	call, put, takeLeading,
} from 'redux-saga/effects';

import {
	referralGetFailAction,
	referralGetSuccessAction,
	// referralRequestsFailAction,
	// referralRequestsSuccessAction,
	referralSetFailAction,
	referralSetSuccessAction,
	setReferralRequestsFailAction,
	setReferralRequestsSuccessAction,
} from '@base/store/Referral/actions';
import * as referralTypes from '@base/store/Referral/constants';
import { apiRequestClientBroker } from '@base/providers/sagas';
import {
	GetReferralGetResp,
	// GetReferralRequestsResp,
	GetReferralSetResp,
	PostAssignParentResp,
} from '@base/types';
import {
	ReferralSetActionType,
	SetReferralRequestsActionType,
} from '@base/store/Referral/types';
import { customerAction } from '@client_broker/store/Customer/actions';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import { FingerprintJS } from '@base/libs/fp.min';

function* referralSetSaga({ payload }: ReferralSetActionType) {
	try {
		let fingerprint;
		// Initialize an agent at application startup.
		// @ts-ignore
		const fpPromise = FingerprintJS.load();
		yield fpPromise
			.then((fp: any) => fp.get())
			.then((result: any) => {
				// This is the visitor identifier:
				const { visitorId } = result;
				fingerprint = visitorId;
				console.log(visitorId);
			});

		// console.log('referralSetSaga, fingerprint= ', fingerprint);

		if (!fingerprint) return;

		const { origin } = window.location;
		const resp: GetReferralSetResp = yield call(
			apiRequestClientBroker, 'getReferralSet', {
				params: {
					...payload,
					utm: Math.trunc(new Date().getTime() / 1000),
					origin,
					fingerprint,
				},
			},
		);
		if (!resp.success) {
			throw new Error(resp.error);
		}

		yield put(referralSetSuccessAction());
		// yield put(referralCodeAction());
	} catch (e) {
		console.log(e);
		yield put(referralSetFailAction({ error: e.message }));
	}
}

function* setReferralRequestsSaga({ payload }: SetReferralRequestsActionType) {
	try {
		const resp: PostAssignParentResp = yield call(
			apiRequestClientBroker, 'postAssignParent', { body: payload },
		);
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(setReferralRequestsSuccessAction());
		yield put(customerAction());
	} catch (e) {
		console.log(e);
		yield put(setReferralRequestsFailAction({ error: e.message }));
	}
}

// function* referralRequestsSaga() {
// 	try {
// 		const resp: GetReferralRequestsResp = yield call(
// 			apiRequestClientBroker, 'getReferralRequests',
// 		);
// 		if (!resp.success) {
// 			yield put(errorHandlerAction({ ...resp }));
// 			throw new Error(resp.error);
// 		}
// 		yield put(referralRequestsSuccessAction({
// 			data: resp.data.list,
// 		}));
// 	} catch (e) {
// 		console.log(e);
// 		yield put(referralRequestsFailAction({ error: e.message }));
// 	}
// }

// function teat() {
// 	const fpPromise = import('https://openfpcdn.io/fingerprintjs/v3')
// 		.then((FingerprintJS) => FingerprintJS.load());
// }

function* referralGetSaga() {
	try {
		let fingerprint;
		// Initialize an agent at application startup.
		// @ts-ignore
		const fpPromise = FingerprintJS.load();
		yield fpPromise
			.then((fp: any) => fp.get())
			.then((result: any) => {
				// This is the visitor identifier:
				const { visitorId } = result;
				fingerprint = visitorId;
				console.log(visitorId);
			});

		// console.log('referralSetSaga, fingerprint= ', fingerprint);

		if (!fingerprint) return;

		const resp: GetReferralGetResp = yield call(
			apiRequestClientBroker, 'getReferralGet', { fingerprint },
		);
		// console.log('referralGetSaga, resp= ', resp);
		if (!resp.success) {
			throw new Error(resp.error);
		}

		// console.log('referralGetSaga, resp.data= ', resp.data);
		const { ref_code } = resp.data;

		yield put(referralGetSuccessAction({
			isHave: !!ref_code,
			ref_code: ref_code || '',
		}));
	} catch (e) {
		console.log(e);
		yield put(referralGetFailAction({ error: e.message }));
	}
}

export function* referralWatcher() {
	yield takeLeading(referralTypes.BASE_REFERRAL_SET_REQ, referralSetSaga);
	yield takeLeading(referralTypes.BASE_SET_REFERRAL_REQUESTS_REQ, setReferralRequestsSaga);
	// yield takeLeading(referralTypes.BASE_REFERRAL_REQUESTS_REQ, referralRequestsSaga);
	yield takeLeading(referralTypes.BASE_REFERRAL_GET_REQ, referralGetSaga);
}
