import React, { useMemo } from 'react';
import cx from 'classnames';

import { Text } from '@base/components';
import info2 from '@base/assets/icons/info2.svg';
import styles from './LabelWrapForPage.module.scss';

interface LabelWrapForPageProps {
	className?: string,
	labelText?: string,
	onClick?: () => void
	isShowIcon?: boolean
}

export const LabelWrapForPage = ({
	className,
	labelText,
	onClick,
	isShowIcon,
}: LabelWrapForPageProps) => {
	const showIcon = useMemo(() => (
		isShowIcon !== false && onClick
	), [onClick, isShowIcon, labelText]);
	return (
		<div className={cx(styles.label_wrap_for_page, className)}>
			<Text className={styles.label} type="h2-bold-2">
				{labelText}
			</Text>
			{showIcon && (
				<button onClick={onClick} className={styles.button}>
					<img src={info2} alt="" className={styles.icon_info} />
				</button>
			)}
		</div>
	);
};

export default null;
