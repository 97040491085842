import {
	call, put, select, takeLeading,
} from 'redux-saga/effects';

import {
	verificationPhoneSuccessAction,
	verificationPhoneFailAction,
	verificationPhoneMakeCallSuccessAction,
	verificationPhoneMakeCallFailAction,
	verificationPhoneCallAttemptSuccessAction,
	verificationPhoneCallAttemptFailAction,
	verificationPhoneCallAttemptAction,
} from '@auth/store/VerificationPhone/actions';
import * as verificationPhoneFormTypes from '@auth/store/VerificationPhone/constants';
import {
	BaseState, GetCallAttemptResp,
	PostPhoneMakeCallResp,
	// PostPhoneSendCodeResp,
	PostVerifyPhoneResp,
} from '@base/types';
import { apiRequestClientBroker } from '@base/providers/sagas';
import {
	VerificationPhoneActionType,
	VerificationPhoneMakeCallActionType,
	// VerificationPhoneSendCodeActionType,
} from '@auth/store/VerificationPhone/types';
import { push } from 'connected-react-router';
import modulesRoutes from '@base/constants/routesModules';
import { authPersistLoginSuccess, authPersistUpdateUserDataAction } from '@base/store/AuthPersist/actions';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import {
	AuthNotificationDescPROCESSING_ERROR,
	AuthNotificationDescriptionError,
} from '@base/containers/notificationsComponents';
import React from 'react';

// function* verificationPhoneSendCodeSaga({ payload }: VerificationPhoneSendCodeActionType) {
// 	try {
// 		const {
// 			phone,
// 		} = payload;
// 		const body = {
// 			phone,
// 		};
// 		const resp: PostPhoneSendCodeResp = yield call(
// 			apiRequestClientBroker,
// 			'postPhoneSendCode',
// 			{
// 				body,
// 			},
// 		);
// 		if (!resp.success) {
// 			yield put(errorHandlerAction({ ...resp }));
// 			throw new Error(resp.error);
// 		}
// 		yield put(verificationPhoneSendCodeSuccessAction());
//
// 		// TODO Удалить перед прод
// 		yield put(
// 			verificationPhoneAction({
// 				phone,
// 				code: '123',
// 			}),
// 		);
// 	} catch (e) {
// 		console.log(e);
// 		yield put(verificationPhoneSendCodeFailAction({ error: e.message }));
// 	}
// }

function* verificationPhoneMakeCallSaga({ payload }: VerificationPhoneMakeCallActionType) {
	try {
		const {
			phone,
		} = payload;
		const body = {
			phone,
		};
		const resp: PostPhoneMakeCallResp = yield call(
			apiRequestClientBroker,
			'postPhoneMakeCall',
			{
				body,
			},
		);
		if (!resp.success) {
			let descriptionComponent: React.ReactNode = AuthNotificationDescriptionError;
			if (resp.error === 'PROCESSING_ERROR') {
				descriptionComponent = AuthNotificationDescPROCESSING_ERROR;
			}
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent,
				},
			}));
			throw new Error(resp.error);
		}
		yield put(verificationPhoneCallAttemptAction());
		yield put(verificationPhoneMakeCallSuccessAction());
	} catch (e) {
		console.log(e);
		yield put(verificationPhoneMakeCallFailAction({ error: e.message }));
	}
}

function* verificationPhoneSaga({ payload }: VerificationPhoneActionType) {
	try {
		const { phone, code } = payload;
		const resp: PostVerifyPhoneResp = yield call(
			apiRequestClientBroker,
			'postVerifyPhone',
			{
				body: {
					phone, code,
				},
			},
		);
		if (!resp.success) {
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent: AuthNotificationDescriptionError,
				},
			}));
			throw new Error(resp.error);
		}
		const {
			accessToken, refreshToken,
		} = yield select(({
			base,
		}: BaseState) => base.authPersist.token);
		yield put(verificationPhoneSuccessAction());
		yield put(authPersistLoginSuccess({
			accessToken,
			refreshToken,
			isAuth: true,
		}));
		yield put(authPersistUpdateUserDataAction({
			userData: {
				isConfirmedPhone: true,
			},
		}));
		yield put(push(modulesRoutes.root));
	} catch (e) {
		console.log(e);
		yield put(verificationPhoneFailAction({ error: e.message }));
	}
}

function* verificationPhoneCallAttemptSaga() {
	try {
		const resp: GetCallAttemptResp = yield call(
			apiRequestClientBroker,
			'getCallAttempt',
		);
		if (!resp.success) {
			yield put(errorHandlerAction({
				...resp,
				notificationData: {
					descriptionComponent: AuthNotificationDescriptionError,
				},
			}));
			throw new Error(resp.error);
		}
		yield put(verificationPhoneCallAttemptSuccessAction(resp.data));
	} catch (e) {
		console.log(e);
		yield put(verificationPhoneCallAttemptFailAction({ error: e.message }));
	}
}

export function* verificationPhoneWatcher() {
	// yield takeLeading(
	// 	verificationPhoneFormTypes.VERIFICATION_PHONE_SEND_CODE_REQUEST,
	// 	verificationPhoneSendCodeSaga,
	// );
	yield takeLeading(
		verificationPhoneFormTypes.VERIFICATION_PHONE_MAKE_CALL_REQUEST,
		verificationPhoneMakeCallSaga,
	);
	yield takeLeading(
		verificationPhoneFormTypes.VERIFICATION_PHONE_REQUEST,
		verificationPhoneSaga,
	);
	yield takeLeading(
		verificationPhoneFormTypes.VERIFICATION_PHONE_CALL_ATTEMPT_REQUEST,
		verificationPhoneCallAttemptSaga,
	);
}
