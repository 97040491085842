import React from 'react';
import cx from 'classnames';

interface OwnProps {
	onClick?: () => void,
	className?: string,
	color?: string,
}

type OpenMenuIconProps = OwnProps

export const CalendarIcon = ({ color, className, onClick }: OpenMenuIconProps) => (
	<svg className={cx(className)} onClick={onClick}>
		<path fillRule="evenodd" clipRule="evenodd" d="M5.0625 0C5.21168 0 5.35476 0.0592632 5.46025 0.164752C5.56574 0.270242 5.625 0.413316 5.625 0.5625V2.25H12.375V0.5625C12.375 0.413316 12.4343 0.270242 12.5398 0.164752C12.6452 0.0592632 12.7883 0 12.9375 0C13.0867 0 13.2298 0.0592632 13.3352 0.164752C13.4407 0.270242 13.5 0.413316 13.5 0.5625V2.25H15.5625C16.287 2.25 16.875 2.838 16.875 3.5625V15.5625C16.875 15.9106 16.7367 16.2444 16.4906 16.4906C16.2444 16.7367 15.9106 16.875 15.5625 16.875H2.4375C2.0894 16.875 1.75556 16.7367 1.50942 16.4906C1.26328 16.2444 1.125 15.9106 1.125 15.5625V3.5625C1.125 2.838 1.713 2.25 2.4375 2.25H4.5V0.5625C4.5 0.413316 4.55926 0.270242 4.66475 0.164752C4.77024 0.0592632 4.91332 0 5.0625 0V0ZM2.4375 3.375C2.38777 3.375 2.34008 3.39475 2.30492 3.42992C2.26975 3.46508 2.25 3.51277 2.25 3.5625V6H15.75V3.5625C15.75 3.51277 15.7302 3.46508 15.6951 3.42992C15.6599 3.39475 15.6122 3.375 15.5625 3.375H2.4375ZM15.75 7.125H2.25V15.5625C2.25 15.666 2.334 15.75 2.4375 15.75H15.5625C15.6122 15.75 15.6599 15.7302 15.6951 15.6951C15.7302 15.6599 15.75 15.6122 15.75 15.5625V7.125Z" fill={color} />
	</svg>
);
