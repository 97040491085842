import * as customerAccountsActionsTypes from '@client_broker/store/СustomerAccounts/constants';
import { Account, PostAccountReq, UpdateAccount } from '@base/types';

export const customerAccountsAction = () => ({
	type: customerAccountsActionsTypes.CUSTOMER_ACCOUNTS_REQUEST,
});

export const customerAccountsSuccessAction = (
	payload: { live: Array<Account>, demo: Array<Account> },
) => ({
	type: customerAccountsActionsTypes.CUSTOMER_ACCOUNTS_SUCCESS,
	payload,
});

export const customerAccountsFailAction = (payload: { error: string }) => ({
	type: customerAccountsActionsTypes.CUSTOMER_ACCOUNTS_FAIL,
	payload,
});

//-----------------------------

// export const updateCustomerAccountAction = (payload: { id: number, leverage: string }) => ({
export const updateCustomerAccountAction = (payload: UpdateAccount) => ({
	type: customerAccountsActionsTypes.UPDATE_CUSTOMER_ACCOUNT_REQUEST,
	payload,
});

export const updateCustomerAccountSuccessAction = () => ({
	type: customerAccountsActionsTypes.UPDATE_CUSTOMER_ACCOUNT_SUCCESS,
});

export const updateCustomerAccountFailAction = (payload: { error: string }) => ({
	type: customerAccountsActionsTypes.UPDATE_CUSTOMER_ACCOUNT_FAIL,
	payload,
});

//-----------------------------

export const createCustomerAccountAction = (
	payload: PostAccountReq,
) => ({
	type: customerAccountsActionsTypes.CREATE_CUSTOMER_ACCOUNT_REQUEST,
	payload,
});

export const createCustomerAccountSuccessAction = () => ({
	type: customerAccountsActionsTypes.CREATE_CUSTOMER_ACCOUNT_SUCCESS,
});

export const createCustomerAccountFailAction = (payload: { error: string }) => ({
	type: customerAccountsActionsTypes.CREATE_CUSTOMER_ACCOUNT_FAIL,
	payload,
});

export default null;
