/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {
	useCallback, useMemo,
	useRef,
	useState,
} from 'react';
import cx from 'classnames';

import { getValueForNumberInput } from '@base/utils/functions';
import textStyles from '@base/components/Text/Text.module.scss';
import Pattern from '@base/constants/pattern';
import showPassword from '@base/assets/icons/show-password.svg';
import hidePassword from '@base/assets/icons/hide-password.svg';

// eslint-disable-next-line no-unused-vars
import {
	ButtonText, DropDownInputInfo, Link, Text,
} from '@base/components';

import styles from './Input.module.scss';

export type InputInfoTypePropsType = 'error' | 'normal' | 'good';
export type InputInfoPropsType = {
	type: InputInfoTypePropsType,
	text: string
};

export type InputProps = {
	name: string,
	value: string | number,
	label?: string,
	placeholder?: string,
	type?: 'text' | 'password' | 'integer' | 'float',
	size?: 'small' | 'large',
	numberAfterPoint?: number,
	className?: string,
	// eslint-disable-next-line no-unused-vars
	onChange: (obj: {
		name: string,
		value: string | number,
		event?: React.SyntheticEvent<HTMLInputElement>,
	}) => void | Promise<void>,
	// eslint-disable-next-line no-unused-vars
	onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void,
	// eslint-disable-next-line no-unused-vars
	onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void,
	error?: string,
	info?: InputInfoPropsType,
	floatInfo?: 'bottom'
	disabled?: boolean,
	min?: void | number,
	max?: void | number,
	step?: void | number,
	align?: 'left' | 'center' | '',
	required?: boolean,
	// link?: { text: string, value: string },
	textAction?: {
		text: string,
		value: string,
		type: 'link' | 'button'
		onClick?: () => void,
	},
	autoComplete?: 'off' | 'on',
	// infoComponent?: React.ReactNode,
};

export const Input = ({
	name,
	value,
	label = '',
	type = 'text',
	placeholder,
	onChange,
	onBlur,
	onFocus,
	disabled = false,
	className,
	// error,
	info,
	floatInfo,
	min = 0,
	max = 10000,
	step = 1,
	size = 'large',
	align,
	required = false,
	// link,
	textAction,
	numberAfterPoint,
	autoComplete = 'off',
	// infoComponent,
}: InputProps) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [isShowPass, setShowPass] = useState(false);
	const [showError, setShowError] = useState<boolean>(true);
	const [isFocus, setIsFocus] = useState<boolean>(false);
	const typeIsNumber: boolean = type === 'float' || type === 'integer';
	let pattern: RegExp | void;
	if (type === 'float') {
		pattern = Pattern.onlyFloat;
	} else if (type === 'integer') {
		pattern = Pattern.onlyInteger;
	}

	const setFocus = () => {
		if (inputRef.current !== null) {
			inputRef.current.focus();
		}
	};
	const errorClickHandler = () => {
		if (showError) {
			setShowError(false);
			setFocus();
		}
	};
	const onToggleShow = useCallback(
		() => setShowPass((currentState) => !currentState),
		[
			setShowPass,
		],
	);

	const stepBase = (newValue: number | string, condition: boolean, down?: boolean) => {
		setFocus();
		if (Number(newValue) < min || value === '') {
			const getValue = () => {
				if (down === true && Number(value) - step < min) {
					return Number(value);
				}
				return min > step ? min : step;
			};
			onChange({
				name,
				value: getValue(),
			});
		} else if (Number(newValue) > max) {
			onChange({
				name,
				value: max,
			});
		} else if (condition) {
			onChange({
				name,
				value: newValue,
			});
		}
	};
	const stepUp = () => {
		if (typeof value === 'string') {
			stepBase(
				getValueForNumberInput(parseFloat(value) + step, numberAfterPoint || 0),
				!Number.isNaN(parseFloat(value)) && parseFloat(value) < max,
			);
		}
	};
	const stepDown = () => {
		if (typeof value === 'string') {
			stepBase(
				getValueForNumberInput(parseFloat(value) - step, numberAfterPoint || 0),
				!Number.isNaN(parseFloat(value)) && parseFloat(value) > min,
				true,
			);
		}
	};

	// const onKeyDownHandler = (event: React.SyntheticEvent<HTMLInputElement>) => {
	const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'ArrowUp') {
			event.preventDefault();
			stepUp();
		}
		if (event.key === 'ArrowDown') {
			event.preventDefault();
			stepDown();
		}
	};
	const handleChange = useCallback((event: React.SyntheticEvent<HTMLInputElement>) => {
		const eventValue = event.currentTarget.value;
		const newInputValue = typeIsNumber ? eventValue.replace(',', '.') : eventValue;
		if (pattern === undefined || pattern.test(newInputValue)) {
			if (typeIsNumber) {
				onChange({
					name,
					value: getValueForNumberInput(newInputValue, numberAfterPoint || 0),
					event,
				});
			} else {
				onChange({
					name,
					value: newInputValue,
					event,
				});
			}
		}
	}, [
		onChange,
		name,
		pattern,
		numberAfterPoint,
		typeIsNumber,
	]);
	const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
		// setTimeout(() => {
		setIsFocus(!isFocus);
		// if click of arrow in input number prevent blur
		const isButtonClicked = [
			'stepUp',
			'stepDown',
			// @ts-ignore
		].includes(e.relatedTarget ? e.relatedTarget.id : '');

		if (!isButtonClicked) {
			setShowError(true);
			if (onBlur !== undefined) {
				onBlur(e);
			}
		}
		// }, 111);
	};

	const onFocusHandler = (event: any) => {
		// setTimeout(() => {
		setIsFocus(!isFocus);
		if (onFocus) {
			onFocus(event);
		}
		// }, 111);
	};

	const isShowInfoText = useMemo(() => {
		if (!info || !info.text) return false;
		if (isFocus && info.type === 'normal') return true;
		// if (info.type === 'error') return true;
		if (!isFocus && info.type === 'error') return type;
		if (info.type === 'good') return true;
		return false;
	}, [info, isFocus]);

	return (
		<div className={cx(className)}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				{label.length > 0 && size === 'large' && (
					<Text
						type="h3"
						className={cx(
							styles.label,
							{
								[styles.required]: required,
								[styles['label--error']]: info && info.type === 'error',
							},
						)}
					>
						{label}
						{' '}
					</Text>
				)}

				{textAction && textAction.type === 'link' && (
					<Link className={styles.text_link} to={textAction.value} underlined>
						{textAction.text}
					</Link>
				)}
				{textAction && textAction.type === 'button' && (
					<ButtonText
						onClick={() => {
							const { onClick } = textAction;
							if (onClick) {
								onClick();
							}
						}}
						className={styles.text_link}
						text={textAction.text}
						disabled={disabled}
						underscore
					/>
				)}
			</div>
			<div className={cx(
				styles.wrap,
				styles[`wrap__float_info--${floatInfo}`],
			)}
			>

				<span
					className={cx(
						styles.input_wrap,
						textStyles.text,
						styles[`input_wrap--${size}`],
						// className,
						{
							// eslint-disable-next-line max-len
							// [styles['input_wrap--error_deprecated']]: error !== '' && error !== undefined && showError,
							[styles['input_wrap--error']]: info && info.type === 'error',
							[styles['input_wrap--normal']]: info && info.type === 'normal',
							[styles['input_wrap--good']]: info && info.type === 'good',
							[styles['input_wrap--disabled']]: disabled,
						},
					)}
					role="presentation"
					onClick={errorClickHandler}
				>
					<input
						ref={inputRef}
						id={name}
						name={name}
						placeholder={isFocus ? '' : placeholder}
						type={isShowPass || typeIsNumber ? 'text' : type}
						value={
							typeIsNumber
								? getValueForNumberInput(value, numberAfterPoint || 0)
								: value
						}
						onChange={handleChange}
						autoComplete={autoComplete}
						onBlur={blurHandler}
						onFocus={onFocusHandler}
						onKeyDown={onKeyDownHandler}
						disabled={disabled}
						min={min}
						max={max}
						step={step}
						className={cx(styles.position, {
							[styles[`position--align--${align || ''}`]]: align,
							[styles['input--error']]: info && info.type === 'error',
						})}
					/>
					{(type === 'password' && !disabled) && (
						<button
							type="button"
							onClick={(event) => {
								// @ts-ignore
								onToggleShow(event);
								// @ts-ignore
								// document.getElementById(name).focus();
							}}
							className={styles['input_wrap__eye-btn']}
						>
							{isShowPass ? (
								<>
									<img src={hidePassword} alt="" />
								</>
							) : (
								<>
									<img src={showPassword} alt="" />
								</>
							)}
						</button>
					)}
				</span>
				{info && isShowInfoText && (
					<DropDownInputInfo text={info.text} type={info.type} float={floatInfo} />
				)}
			</div>

			{/* /!* TODO DEPRECATED use info *!/ */}
			{/* {error !== undefined && error.length > 0 && ( */}
			{/*	<div */}
			{/*		className={styles.input_wrap__error} */}
			{/*		style={{ */}
			{/*			// visibility: showError ? 'visible' : 'hidden', */}
			{/*			visibility: 'visible', */}
			{/*		}} */}
			{/*	> */}
			{/*		{error} */}
			{/*	</div> */}
			{/* )} */}
			{info && isShowInfoText && (
				<div
					className={cx(
						styles.input_wrap__info,
						styles[`input_wrap__info__${info.type}`],
					)}
				>
					{info.text}
				</div>
			)}
			{/* {infoComponent} */}
		</div>

	);
};

Input.propTypes = {
// @ts-ignore
	numberAfterPoint(props) {
		const { type, numberAfterPoint } = props;
		if (type === 'float' && numberAfterPoint === undefined) {
			return new Error('Please provide a numberAfterPoint value with float type in Input component');
		}
		return null;
	},
};

export default null;
