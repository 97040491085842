import * as coreTypes from '@base/store/Core/constants';
import type { CoreActionsType } from '@base/store/Core/types';
import { Core, RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';
import { CoreOptionsObjectsType } from '@base/types/base/core';

export type CoreState = {
	data: Core;
	optionsOfData: CoreOptionsObjectsType,
} & RequestInfoChildType;

const initialState: CoreState = {
	data: {
		account_leverages: {
			is_broker: [],
			is_demo: [],
			is_live: [],
		},
		account_types: {
			is_broker: [],
			is_demo: [],
			is_live: [],
			is_report: [],
		},
		default_account_type_id: 0,
		partner_programs: [],
		trading_platforms: [],
	},
	optionsOfData: {
		account_leverages: {
			is_broker: [],
			is_demo: [],
			is_live: [],
		},
		account_type_groups: [],
	},
	...RequestInfoChildState,
};

export const coreReducer = (
	state: CoreState = initialState,
	action: CoreActionsType,
): CoreState => {
	switch (action.type) {
		case coreTypes.BASE_CORE_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}

		case coreTypes.BASE_CORE_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				data: payload.data,
				optionsOfData: payload.optionsOfData,
				isLoading: false,
			};
		}

		case coreTypes.BASE_CORE_FAIL: {
			const { payload } = action;
			return {
				...state,
				isLoading: false,
				error: payload.error,
			};
		}

		default: {
			return state;
		}
	}
};
