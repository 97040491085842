const FIELD_IS_REQUIRED = 'Field is required';
const INVALID_EMAIL = 'Invalid email';
const INVALID_PASSWORD = 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character';
const INVALID_NAME = 'Invalid name';
const INVALID_LASTNAME = 'Invalid lastname';
const INVALIDPARTNER_REFERRAL_CODE = 'Invalid partner referral code';
const INVALID_CUSTOMER_ACCOUNT_NUMBER = 'Invalid account number';
const INVALID_PHONE_NUMBER = 'Invalid phone number';
const INVALID_REFERRAL_CODE = 'Invalid referral code';
const INVALID_INDEX = 'Invalid index';
const INVALID_ADDRESS = 'Invalid address';
const INVALID_AMOUNT = 'Invalid amount';

export default {
	FIELD_IS_REQUIRED,
	INVALID_EMAIL,
	INVALID_PASSWORD,
	INVALID_NAME,
	INVALID_LASTNAME,
	INVALIDPARTNER_REFERRAL_CODE,
	INVALID_CUSTOMER_ACCOUNT_NUMBER,
	INVALID_PHONE_NUMBER,
	INVALID_REFERRAL_CODE,
	INVALID_INDEX,
	INVALID_ADDRESS,
	INVALID_AMOUNT,
};
