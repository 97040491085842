import React, { useEffect, useState } from 'react';

import {
	Redirect, Route, Switch,
} from 'react-router-dom';
import partnerRegistrationRoutes from '@auth/constants/partnersRegistrationRoutes';
import { ProgramSelection } from '@auth/containers/partnerRegistration/ProgramSelection';
import { PartnerRegForm } from '@auth/containers/partnerRegistration/PartnerRegForm';
import { useSelector } from 'react-redux';
import { AuthState } from '@auth/types';
import { usePageInfo } from '@base/hooks/usePageInfo';
import styles from './PartnerRegistration.module.scss';

type PartnerRegistrationProps = {};

// eslint-disable-next-line no-empty-pattern
export const PartnerRegistration = ({}: PartnerRegistrationProps) => {
	usePageInfo(partnerRegistrationRoutes);

	const { userData, token } = useSelector((
		state: AuthState,
	) => state.base.authPersist);
	const [isAuthAndReg, setIsAuthAndReg] = useState(false);

	useEffect(() => {
		if (userData.isConfirmedEmail && token) {
			setIsAuthAndReg(true);
		} else {
			setIsAuthAndReg(false);
		}
	}, [userData, token]);

	return (
		<div className={styles.partner_registration}>
			<Switch>
				<Route
					exact
					path={partnerRegistrationRoutes['partner-registration'].root}
					component={ProgramSelection}
				/>
				{!isAuthAndReg && (
					<Route
						exact
						path={partnerRegistrationRoutes['partner-registration'].ib.root}
						render={() => <PartnerRegForm programName="ib" />}
					/>
				)}
				<Route
					exact
					path={partnerRegistrationRoutes['partner-registration'].multilevel.root}
					render={() => <PartnerRegForm programName="multilevel" />}
				/>

				<Route component={() => <Redirect to={partnerRegistrationRoutes['partner-registration'].root} />} />
			</Switch>
		</div>
	);
};
export default null;
