import React from 'react';
import cx from 'classnames';

interface OwnProps {
	className?: string,
	color?: string,
}

type DemoAccountsIconProps = OwnProps

export const DemoAccountsIcon = ({ color, className }: DemoAccountsIconProps) => (
	<svg className={cx(className)}>
		<g clipPath="url(#clip0)">
			<path d="M22.1347 9.95525C22.5693 9.53164 22.7227 8.90993 22.5352 8.33276C22.3476 7.75559 21.8581 7.34285 21.2575 7.25562L16.0061 6.49254C15.903 6.47759 15.8139 6.41285 15.7678 6.31939L13.4193 1.56087C13.1508 1.01665 12.6069 0.678589 12 0.678589C11.3931 0.678589 10.8493 1.01665 10.5807 1.56087L8.23225 6.31943C8.18608 6.41285 8.09702 6.47759 7.99389 6.49259L2.74258 7.25562C2.14202 7.34285 1.6525 7.75559 1.46491 8.33276C1.27732 8.90993 1.43079 9.53164 1.86541 9.95525L5.66533 13.6593C5.73991 13.732 5.77399 13.8368 5.75636 13.9395L4.85932 19.1696C4.75675 19.7678 4.99797 20.3609 5.48894 20.7176C5.97991 21.0744 6.61872 21.1205 7.15582 20.8382L11.8527 18.3688C11.945 18.3203 12.0551 18.3203 12.1474 18.3688L16.8444 20.8382C17.078 20.961 17.3308 21.0217 17.5824 21.0216C17.9091 21.0216 18.2337 20.9193 18.5111 20.7176C19.0021 20.3608 19.2433 19.7676 19.1407 19.1696L18.2437 13.9394C18.2261 13.8367 18.2601 13.732 18.3347 13.6592L22.1347 9.95525ZM16.9958 14.1536L17.8929 19.3838C17.9229 19.5588 17.8148 19.6586 17.767 19.6933C17.7191 19.728 17.5907 19.8001 17.4336 19.7174L12.7366 17.2481C12.506 17.1269 12.2531 17.0662 12.0001 17.0662C11.7471 17.0662 11.4941 17.1269 11.2636 17.248L6.56669 19.7173C6.40947 19.7999 6.28118 19.728 6.23332 19.6932C6.18546 19.6585 6.07736 19.5587 6.10741 19.3837L7.00446 14.1536C7.09253 13.64 6.92243 13.1164 6.54925 12.7527L2.74933 9.04864C2.62216 8.9246 2.65094 8.78037 2.66927 8.72412C2.6875 8.66782 2.74905 8.53418 2.92479 8.50868L8.17605 7.7456C8.69163 7.6707 9.13718 7.34707 9.3678 6.87978L11.7163 2.12121C11.7949 1.96198 11.941 1.94478 12.0002 1.94478C12.0593 1.94478 12.2053 1.96193 12.284 2.12121V2.12126L14.6325 6.87982C14.8631 7.34712 15.3086 7.67074 15.8241 7.74565L21.0755 8.50873C21.2512 8.53423 21.3127 8.66787 21.331 8.72417C21.3493 8.78046 21.3781 8.92474 21.2509 9.04868L17.451 12.7527C17.0778 13.1164 16.9077 13.64 16.9958 14.1536Z" fill={color} />
			<path d="M19.396 1.56051C19.1131 1.35501 18.7173 1.41773 18.5118 1.70058L17.8397 2.62551C17.6342 2.90836 17.6969 3.30431 17.9798 3.50981C18.0922 3.59142 18.2224 3.63075 18.3514 3.63075C18.5472 3.63075 18.7402 3.54023 18.8641 3.3698L19.5361 2.44486C19.7416 2.16197 19.6789 1.76601 19.396 1.56051Z" fill={color} />
			<path d="M6.15768 2.62179L5.48563 1.69685C5.28018 1.4141 4.88427 1.35138 4.60133 1.55678C4.31849 1.76228 4.25577 2.15824 4.46127 2.44108L5.13332 3.36602C5.25721 3.5366 5.45024 3.62707 5.64609 3.62707C5.77514 3.62707 5.90535 3.58774 6.01767 3.50608C6.30051 3.30063 6.36323 2.90468 6.15768 2.62179Z" fill={color} />
			<path d="M2.32274 14.8826C2.21469 14.5501 1.85755 14.3681 1.52497 14.4761L0.437616 14.8294C0.105037 14.9375 -0.0768845 15.2947 0.0311624 15.6272C0.118069 15.8948 0.366272 16.0649 0.633131 16.0649C0.697959 16.0649 0.763912 16.0548 0.828928 16.0337L1.91629 15.6803C2.24882 15.5723 2.43079 15.2151 2.32274 14.8826Z" fill={color} />
			<path d="M12.0003 21.9119C11.6506 21.9119 11.3672 22.1953 11.3672 22.545V23.6883C11.3672 24.038 11.6506 24.3214 12.0003 24.3214C12.3499 24.3214 12.6334 24.038 12.6334 23.6883V22.545C12.6334 22.1953 12.35 21.9119 12.0003 21.9119Z" fill={color} />
			<path d="M23.5623 14.8304L22.4749 14.477C22.1425 14.3691 21.7852 14.551 21.6772 14.8835C21.5691 15.2161 21.751 15.5733 22.0836 15.6813L23.171 16.0347C23.236 16.0557 23.3019 16.0658 23.3667 16.0658C23.6336 16.0658 23.8818 15.8957 23.9687 15.6281C24.0768 15.2956 23.8948 14.9384 23.5623 14.8304Z" fill={color} />
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="24" height="24" fill={color} transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
);
