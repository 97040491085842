import React from 'react';

import cx from 'classnames';
import { Payment } from '@base/types';
import { useDispatch } from 'react-redux';
import styles from './TableRow.module.scss';

type OwnProps = {};

type TableRowProps = {
	id: string,
	item: Payment | any
} & OwnProps;

const TableRow = ({
	id,
	item,
}: TableRowProps) => {
	// eslint-disable-next-line no-unused-vars
	const dispatch = useDispatch();

	const ItemWrapper = ({
		classNameRowWrapper,
		classNameRow,
		children,
	}: { children: React.ReactNode, classNameRow?: string, classNameRowWrapper?: string }) => (
		<div className={cx(classNameRowWrapper, styles.table_row_wrapper)}>
			<div className={cx(classNameRow, styles.table_row)}>
				{children}
			</div>
		</div>
	);

	const ItemDefault = () => (
		<ItemWrapper>
			{
				item[id] !== undefined
				&& item[id] !== ''
				&& item[id] !== null
				&& item[id]
					? item[id] : '__'
			}
		</ItemWrapper>
	);

	const TimeOfCompletionRow = () => (
		<ItemWrapper>
			{
				`${item['time-period_min'] / 24
				} - ${
					item['time-period_max'] / 24}`
			}
			{' дня / дней'}
		</ItemWrapper>
	);

	const LimitationsExtendedRow = () => (
		<ItemWrapper classNameRow={styles.limitations_extended_wrap}>
			<div className={styles.limitations_extended_wrap__value}>
				<div>
					{item.limitations_extended.USD && `${item.limitations_extended.USD.min}$ /`}
				</div>

				<div>
					{item.limitations_extended.USD && `${item.limitations_extended.USD.max}$`}
				</div>
			</div>
			<div className={styles.limitations_extended_wrap__value}>
				<div>
					{item.limitations_extended.EUR && `${item.limitations_extended.EUR.min}€ /`}
				</div>
				<div>
					{item.limitations_extended.EUR && `${item.limitations_extended.EUR.max}€ `}
				</div>
			</div>
			<div className={styles.limitations_extended_wrap__value}>
				<div>
					{item.limitations_extended.RUB && `${item.limitations_extended.RUB.min}₽ /`}
				</div>
				<div>
					{item.limitations_extended.RUB && `${item.limitations_extended.RUB.max}₽ `}
				</div>
			</div>
		</ItemWrapper>
	);

	const LimitationsRow = () => (
		<ItemWrapper>
			{item.limitations_extended.USD && 'USD'}
			{item.limitations_extended.EUR && <br />}
			{item.limitations_extended.EUR && 'EUR'}
			{item.limitations_extended.RUB && <br />}
			{item.limitations_extended.RUB && 'RUB'}
		</ItemWrapper>
	);

	switch (id) {
		case 'time-period_min':
			return <TimeOfCompletionRow />;
		case 'limitations_extended':
			return <LimitationsExtendedRow />;
		case 'limitations':
			return <LimitationsRow />;
		default:
			return (<ItemDefault />);
	}
};

TableRow.defaultProps = {
	item: {
		accountName: 'testName',
	},
};

export default TableRow;
