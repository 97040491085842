export const PAYMENTS_WITHDRAW_REQUEST: 'PAYMENTS_WITHDRAW_REQUEST' = 'PAYMENTS_WITHDRAW_REQUEST';
export const PAYMENTS_WITHDRAW_SUCCESS: 'PAYMENTS_WITHDRAW_SUCCESS' = 'PAYMENTS_WITHDRAW_SUCCESS';
export const PAYMENTS_WITHDRAW_FAIL: 'PAYMENTS_WITHDRAW_FAIL' = 'PAYMENTS_WITHDRAW_FAIL';

export const PAYMENTS_DEPOSIT_REQUEST: 'PAYMENTS_DEPOSIT_REQUEST' = 'PAYMENTS_DEPOSIT_REQUEST';
export const PAYMENTS_DEPOSIT_SUCCESS: 'PAYMENTS_DEPOSIT_SUCCESS' = 'PAYMENTS_DEPOSIT_SUCCESS';
export const PAYMENTS_DEPOSIT_FAIL: 'PAYMENTS_DEPOSIT_FAIL' = 'PAYMENTS_DEPOSIT_FAIL';

export const PAYMENTS_DEPOSIT_SEND_REQUEST: 'PAYMENTS_DEPOSIT_SEND_REQUEST' = 'PAYMENTS_DEPOSIT_SEND_REQUEST';
export const PAYMENTS_DEPOSIT_SEND_SUCCESS: 'PAYMENTS_DEPOSIT_SEND_SUCCESS' = 'PAYMENTS_DEPOSIT_SEND_SUCCESS';
export const PAYMENTS_DEPOSIT_SEND_FAIL: 'PAYMENTS_DEPOSIT_SEND_FAIL' = 'PAYMENTS_DEPOSIT_SEND_FAIL';
