import React from 'react';
import { useSelector } from 'react-redux';

import styles from '@client_broker/containers/profile/PersonalDataForm/PersonalDataForm.module.scss';
import { Text, Input, LoaderSpinner } from '@base/components';

import { ClientBrokerState } from '@client_broker/types';
import { useTranslation } from 'react-i18next';

type PersonalDataFormProps = {};

// eslint-disable-next-line no-empty-pattern
export const PersonalDataForm = ({}: PersonalDataFormProps) => {
	const { t } = useTranslation();
	const { info } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.customer,
	);

	return (
		<div className={styles.personal_data_form}>
			{info.isLoading ? <LoaderSpinner /> : (
				<>
					<Text type="h2-bold">{t('CLIENT.PROFILE.PERSONAL_DATA')}</Text>
					<div className={styles.personal_data_form__input}>
						<Input
							label={t('CLIENT.PROFILE.ACCOUNT_NUMBER_LABEL')}
							placeholder={t('CLIENT.PROFILE.INPUTS_PLACEHOLDER_ACCOUNT_NUMBER')}
							name="id"
							value={info.data.id}
							onChange={() => {}}
							disabled
						/>
					</div>
					<div className={styles.personal_data_form__input}>
						<Input
							label={t('CLIENT.PROFILE.FIRSTNAME_LABEL')}
							placeholder={t('CLIENT.PROFILE.FIRSTNAME_PLACEHOLDER')}
							name="first_name"
							value={info.data.first_name}
							onChange={() => {}}
							disabled
						/>
					</div>
					<div className={styles.personal_data_form__input}>
						<Input
							label={t('CLIENT.PROFILE.LASTNAME_LABEL')}
							placeholder={t('CLIENT.PROFILE.LASTNAME_PLACEHOLDER')}
							name="last_name"
							value={info.data.last_name}
							onChange={() => {}}
							disabled
						/>
					</div>
					<div className={styles.personal_data_form__input}>
						<Input
							label={t('CLIENT.PROFILE.EMAIL_LABEL')}
							placeholder={t('CLIENT.PROFILE.EMAIL_PLACEHOLDER')}
							name="email"
							value={info.data.email}
							onChange={() => {}}
							disabled
						/>
					</div>
					<div className={styles.personal_data_form__input}>
						<Input
							label={t('CLIENT.PROFILE.PHONE_NUMBER_LABEL')}
							placeholder={t('CLIENT.PROFILE.PHONE_NUMBER_PLACEHOLDER')}
							name="phone"
							value={info.data.phone}
							onChange={() => {}}
							disabled
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default null;
