import modulesRoutes from '@base/constants/routesModules';

const getPartnerRegistrationRoutes = (baseURL: string): any => {
	const url = baseURL !== '' ? `${baseURL}` : '/';
	return {
		root: `${url}`,
		title: 'Partner registration',
		'partner-registration': {
			root: `${url}partner-registration`,
			title: 'Стать партнёром', // добавить ключ
			icon: 'partner_registration',
			ib: {
				root: `${url}partner-registration/ib`,
				title: 'ib',
			},
			multilevel: {
				root: `${url}partner-registration/multilevel`,
				title: 'multilevel',
			},
		},
	};
};

const partnerRegistrationRoutes: any = getPartnerRegistrationRoutes(modulesRoutes.root);

export default partnerRegistrationRoutes;
