import { call, put, takeLeading } from 'redux-saga/effects';

import { coreSuccessAction, coreFailAction } from '@base/store/Core/actions';
import { BASE_CORE_REQUEST } from '@base/store/Core/constants';
import { GetCoreResp } from '@base/types';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import { leverageSort } from '@base/utils/listSorts';

function* coreSaga() {
	try {
		const resp: GetCoreResp = yield call(apiRequestClientBroker, 'getCore');
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		// eslint-disable-next-line no-unused-vars
		const { account_leverages, account_types } = resp.data;

		const leverageLiveOptions = leverageSort(account_leverages.is_live.map((value) => ({
			label: value.name, value: value.id,
		})));

		const leverageDemoOptions = leverageSort(account_leverages.is_demo.map((value) => ({
			label: value.name, value: value.id,
		})));

		// const accountTypeGroups = account_types.is_live.map((value: AccountType) => (
		// 	value.account_type_group
		// )).filter(onlyUnique); // remove duplicate values
		//
		// const accountTypeGroupOptions: any = [];

		// accountTypeGroups.forEach((typeGroup: string) => {
		// 	const currencies = account_types.is_live.filter(
		// 		(value) => value.account_type_group === typeGroup,
		// 	).map(
		// 		(value) => (
		// 			{ value, label: value.currency }
		// 		),
		// 	);
		// 	accountTypeGroupOptions.push({
		// 		label: typeGroup,
		// 		value: { currencies },
		// 	});
		// });
		yield put(coreSuccessAction({
			data: resp.data,
			optionsOfData: {
				account_leverages: {
					is_live: leverageLiveOptions,
					is_demo: leverageDemoOptions,
					is_broker: [],
				},
				// account_type_groups: accountTypeGroupOptions,
				account_type_groups: [],
			},
		}));
	} catch (e) {
		console.log(e);
		yield put(coreFailAction({ error: e.message }));
	}
}

export function* coreWatcher() {
	yield takeLeading(BASE_CORE_REQUEST, coreSaga);
}
