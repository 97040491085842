import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Button, Text, LoaderSpinner, StatusIconAndText,
} from '@base/components';
import { modalOpenAction } from '@base/store/Modal/actions';
import { ClientBrokerState } from '@client_broker/types';

import { useTranslation } from 'react-i18next';
import { IdentityVerificationModal } from '@client_broker/modals/profile/IdentityVerificationModal';
import { AddressVerificationModal } from '@client_broker/modals/profile/AddressVerificationModal';
import fileStatuses, { FileStatusesType } from '@base/constants/fileStatuses';
import { OptionType, CountryType } from '@base/types';
import { getCountryOptionByAlpha2 } from '@base/utils/countries';
import styles from './VerificationForm.module.scss';

type VerificationFormProps = {};

// eslint-disable-next-line no-empty-pattern
export const VerificationForm = ({}: VerificationFormProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { info } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.customer,
	);
	const { verificationStatus, files } = useSelector(
		(state: ClientBrokerState) => state.clientBroker.documentFiles,
	);
	const { countries } = useSelector(
		(state: ClientBrokerState) => state.base,
	);

	const addressData = useMemo(() => {
		let countryOption: OptionType<CountryType> | undefined;

		if (info.data && countries.options) {
			countryOption = getCountryOptionByAlpha2(countries.options, info.data.country_code);
		}
		const { city, address, zipcode } = info.data;
		return `${countryOption?.value.name}, ${city}, ${address}, ${zipcode}`;
	}, [info, countries]);

	const showStatusIconAndText = useCallback((status: FileStatusesType) => {
		switch (status) {
			case fileStatuses.PROCESSING:
			case fileStatuses.ACCEPTED:
				return true;
			default:
				return false;
		}
	}, [verificationStatus]);

	const getStatusForStatusIconAndText = useCallback((status: FileStatusesType) => {
		switch (status) {
			case fileStatuses.PROCESSING:
				return 'waiting';
			case fileStatuses.ACCEPTED:
				return 'positive';
			default:
				return 'negative';
		}
	}, [verificationStatus]);

	return (
		<div className={styles.verification_form}>
			{info.isLoading || files.isLoading ? <LoaderSpinner /> : (
				<>
					<Text type="h2-bold">{t('CLIENT.PROFILE.VERIFICATION')}</Text>

					<div className={styles.verification_form__btn_wrap}>
						<Text type="h3">{t('CLIENT.PROFILE.IDENTITY_VERIFICATION')}</Text>
						{showStatusIconAndText(verificationStatus.identity) && (
							<StatusIconAndText
								className={styles.verification_form__status}
								status={getStatusForStatusIconAndText(verificationStatus.identity)}
								text={
									verificationStatus.identity === fileStatuses.PROCESSING
										? t('CLIENT.PROFILE.MODERATION')
										: `${t('CLIENT.PROFILE.BIRTHDAY')}: ${info.data.birthday}`
								}
								colorText="gray-light"
							/>
						)}
						{!showStatusIconAndText(verificationStatus.identity) && (
							<Button
								type="button"
								theme="secondary"
								size="large"
								className={styles.verification_form__btn}
								onClick={() => dispatch(modalOpenAction(<IdentityVerificationModal />))}
							>
								{t('CLIENT.PROFILE.PASS_BUTTON')}
							</Button>
						)}
					</div>

					<div className={styles.verification_form__btn_wrap}>
						<Text type="h3">{t('CLIENT.PROFILE.ADDRESS_VERIFICATION')}</Text>
						{showStatusIconAndText(verificationStatus.address) && (
							<>
								<StatusIconAndText
									className={styles.verification_form__status}
									status={getStatusForStatusIconAndText(verificationStatus.address)}
									text={
										verificationStatus.address === fileStatuses.PROCESSING
											? t('CLIENT.PROFILE.MODERATION') : addressData
									}
									colorText="gray-light"
								/>
							</>
						)}
						{!showStatusIconAndText(verificationStatus.address) && (
							<Button
								type="button"
								theme="secondary"
								size="large"
								className={styles.verification_form__btn}
								onClick={() => dispatch(modalOpenAction(<AddressVerificationModal />))}
							>
								{t('CLIENT.PROFILE.PASS_BUTTON')}
							</Button>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default null;
