import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from '@client_broker/modals/accounts/CreateAccountModal/CreateAccountModal.module.scss';
import {
	Button, Select, Text, LoaderSpinner, Input,
} from '@base/components';
import { modalCloseAction } from '@base/store/Modal/actions';
import { BaseState, OptionType } from '@base/types';
import { createCustomerAccountAction } from '@client_broker/store/СustomerAccounts/actions';

import { ClientBrokerState } from '@client_broker/types';

import { object, string } from 'yup';
import { useFormik } from 'formik';
import { authPersistLogin } from '@base/store/AuthPersist/actions';
import accountTypes from '@base/constants/accountTypes';
import { useTranslation } from 'react-i18next';

type CreateAccountModalProps = {
	accountsType: typeof accountTypes.is_live | typeof accountTypes.is_demo
};

const currencyDefaultOptions = [
	{ label: 'USD', value: 'USD' },
	{ label: 'EUR', value: 'EUR' },
	{ label: 'RUB', value: 'RUB' },
];
const accountTypesDefaultOptions = [
	{ label: 'ECN', value: 'ECN' },
	{ label: 'ECN Fix', value: 'FIX' },
	{ label: 'ECN Mini', value: 'MINI' },
];

interface CreateAccountModalFieldsType {
	balance: string,
}

export const initialValues = {
	balance: '',
};

// eslint-disable-next-line no-empty-pattern
export const CreateAccountModal = ({ accountsType }: CreateAccountModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const validationSchema = object().shape({
		balance: string()
			.required('requiredText'),
	});

	const [
		accountTypeOptions, setAccountTypeOptions,
	] = useState<Array<OptionType<string>>>(accountTypesDefaultOptions);
	const [
		currencyOptions, setCurrencyOptions,
	] = useState<Array<OptionType<string>>>(currencyDefaultOptions);

	const [leverageSelected, setLeverageSelected] = useState<number>();
	const [accountTypeSelected, setAccountTypeSelected] = useState();
	const [currencySelected, setCurrencySelected] = useState<string>();

	const {
		data: { account_types },
		optionsOfData,
		isLoading,
		// eslint-disable-next-line no-unused-vars
		error,
	} = useSelector((state: BaseState) => state.base.core);

	const {
		createAccount,
	} = useSelector((state: ClientBrokerState) => state.clientBroker.customerAccounts);

	useEffect(() => {
		const accountTypesOptions = accountTypesDefaultOptions
			.filter((accountTypesOption) => account_types[accountsType].find((
				account_type,
			) => (account_type.account_type_group === accountTypesOption.value)));
		setAccountTypeOptions(accountTypesOptions);
	}, [account_types[accountsType]]);

	useEffect(() => {
		if (accountTypeSelected) {
			const currenciesOptionsNew = currencyDefaultOptions
				.filter((
					currencyOption,
				) => account_types[accountsType].find((
					account_type,
				) => (
					account_type.account_type_group === accountTypeSelected
					&& account_type.currency === currencyOption.value
				)));

			const currencySelectedNew = currenciesOptionsNew
				.find((currencyOption) => currencyOption.value === currencySelected);

			setCurrencyOptions(currenciesOptionsNew);
			setCurrencySelected(currencySelectedNew ? currencySelectedNew.value : undefined);
		}
	}, [accountTypeSelected]);

	const onClick = () => {
		const accountType = account_types[accountsType].filter((
			account_type,
		) => (
			account_type.account_type_group === accountTypeSelected
			&& account_type.currency === currencySelected));

		let payload = {
			account_type_id: accountType[0].id,
			leverage_id: leverageSelected || 0,
			is_demo: false,
			report: true,
		};

		if (accountsType === accountTypes.is_demo) {
			payload = {
				...payload,
				is_demo: true,
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				balance: Number(values.balance),
			};
		}

		if (accountType.length > 0) {
			dispatch(createCustomerAccountAction(payload));
		}
	};

	const {
		handleChange,
		handleBlur,
		// eslint-disable-next-line no-unused-vars
		handleSubmit,
		values,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<CreateAccountModalFieldsType>({
		initialValues,
		onSubmit: (formData: any): any => dispatch(authPersistLogin(formData)),
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			let target = { name, value };

			if (name === 'balance') {
				target = { name, value: value.replace(/[^\d]/g, '')	};
			}

			handleChange({ target });
		},
		[
			handleChange,
		],
	);

	const modalLabel = useMemo(() => {
		let label: string;
		switch (accountsType) {
			case 'is_demo':
				label = 'Открытие demo счёта';
				break;
			case 'is_live':
				label = 'Открытие live счёта';
				break;
			default:
				label = '';
		}

		return label;
	}, [accountsType]);

	const isDisabled = useMemo(() => {
		if (createAccount.isLoading) {
			return true;
		}
		if (!currencySelected || !accountTypeSelected || !leverageSelected) {
			return true;
		}

		if (accountsType === accountTypes.is_demo && !isValid) {
			return true;
		}

		return false;
	}, [
		createAccount.isLoading,
		currencySelected,
		accountTypeSelected,
		leverageSelected,
		isValid,
		accountsType,
	]);

	return (

		<div className={styles.create_account_modal}>
			<Text type="h3">{modalLabel}</Text>
			<div className={styles.create_account_modal__content}>
				{isLoading || createAccount.isLoading ? <LoaderSpinner />
					: (
						<>
							<Select
								labelName="Тип счета"
								name="accountType"
								className={styles.create_account_modal__content__select}
								options={accountTypeOptions}
								value={accountTypeSelected}
								onChange={(accountTypeOption) => {
									setAccountTypeSelected(accountTypeOption.value.value);
								}}
							/>
							<Select
								labelName="Валюта"
								name="currency"
								className={styles.create_account_modal__content__select}
								options={currencyOptions}
								value={currencySelected}
								onChange={(currencyOption) => {
									setCurrencySelected(currencyOption.value.value);
								}}
							/>
							{accountsType === accountTypes.is_demo
							&& (
								<div className={styles.create_account_modal__content__input}>
									<Input
										label="Баланс"
										name="balance"
										required
										value={values.balance}
										onChange={onChangeHandler}
										onBlur={handleBlur}
										error={
											(touched.balance !== undefined && errors.balance !== undefined)
												? errors.balance || ''
												: undefined
										}
									/>
								</div>
							)}
							<Select
								labelName="Кредитное плечо"
								name="leverage"
								className={styles.create_account_modal__content__select}
								options={optionsOfData.account_leverages[accountsType]}
								value={leverageSelected}
								onChange={(leverageOption) => {
									setLeverageSelected(leverageOption.value.value);
								}}
							/>
						</>
					)}
			</div>
			<div className={styles.create_account_modal__btns}>
				<Button
					type="button"
					theme="primary"
					size="large"
					onClick={onClick}
					disabled={isDisabled}
					// (!(currencySelected && accountTypeSelected && leverageSelected)
					// || createAccount.isLoading)
					// && (accountsType === 'is_demo')
					// }
				>
					Открыть
				</Button>
				<Button
					type="button"
					theme="secondary"
					size="large"
					onClick={() => { dispatch(modalCloseAction()); }}
				>
					Отмена
				</Button>
			</div>

		</div>
	);
};
export default null;
