import { PartnerRegistrationIcon } from '@base/assets/icons/menu/partner_registration';
import { LiveAccountsIcon } from '@base/assets/icons/menu/live_accounts';
import { DemoAccountsIcon } from '@base/assets/icons/menu/demo_accounts';
import { RammCopytradingIcon } from '@base/assets/icons/menu/ramm_copytrading';
import { MyWalletIcon } from '@base/assets/icons/menu/my_wallet';
import { DownloadTerminalsIcon } from '@base/assets/icons/menu/download_terminals';

import partner_registration from './partner_registration.svg';
import demo_accounts from './demo_accounts.svg';
import live_accounts from './live_accounts.svg';
import my_wallet from './my_wallet.svg';
import ramm_copytrading from './ramm_copytrading.svg';
import download_terminals from './download_terminals.svg';

export const MenuIcons: { [key: string]: any; } = {
	my_wallet: MyWalletIcon,
	partner_registration: PartnerRegistrationIcon,
	live_accounts: LiveAccountsIcon,
	demo_accounts: DemoAccountsIcon,
	ramm_copytrading: RammCopytradingIcon,
	download_terminals: DownloadTerminalsIcon,
};

export default {
	partner_registration,
	demo_accounts,
	live_accounts,
	my_wallet,
	ramm_copytrading,
	download_terminals,
};
