import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cx from 'classnames';
import { Button } from '@base/components';
import { paymentsDepositAction, paymentsWithdrawAction } from '@client_broker/store/Payments/actions';
import { ClientBrokerState } from '@client_broker/types';
import { Payments, PaymentsTypes } from '@base/types';
import { ChoosingDepositMethod } from '@client_broker/containers/wallet/WalletInputOutput/ChoosingDepositMethod';
import { DehiscentElements } from '@client_broker/containers/wallet/WalletInputOutput/DehiscentElements';
import { customerAccountsAction } from '@client_broker/store/СustomerAccounts/actions';
import { modalOpenAction } from '@base/store/Modal/actions';
import { PrepaidCardActivationModal } from '@client_broker/modals/wallet/PrepaidCardActivationModal';
import styles from './WalletInputOutput.module.scss';

interface OwnProps {
}

type WalletInputProps = {
	paymentsType: PaymentsTypes
} & OwnProps;

export const WalletInputOutput = ({ paymentsType }: WalletInputProps) => {
	const dispatch = useDispatch();

	const payments = useSelector(
		(state: ClientBrokerState) => state.clientBroker.payments,
	);
	const [data, setData] = useState<Payments>();
	const [isActive, setIsActive] = useState({
		banks: false,
		cards: false,
		cryptocurrencies: false,
		wallets: false,
	});

	const [isActiveIcons, setIsActiveIcons] = useState({
		banks: false,
		cards: false,
		cryptocurrencies: false,
		wallets: false,
	});

	useEffect(() => {
		dispatch(customerAccountsAction());
	}, [customerAccountsAction]);

	useEffect(() => {
		if (payments[paymentsType].data) {
			setData(payments[paymentsType].data);
		}
	}, [payments[paymentsType]]);

	useEffect(() => {
		switch (paymentsType) {
			case 'withdraw':
				dispatch(paymentsWithdrawAction());
				break;
			case 'deposit':
				dispatch(paymentsDepositAction());
				break;
			default:
				break;
		}
	}, [paymentsType]);

	const onChangeIcon = ({ name, value }: { name: string, value: boolean }) => {
		let values = {
			banks: false,
			cards: false,
			cryptocurrencies: false,
			wallets: false,
		};
		Object.keys(isActive).forEach((key: string) => {
			values = {
				...values,
				[key]: (key === name) ? value : false,
			};
		});
		setIsActive(values);
		setIsActiveIcons(values);
	};

	const onChangeDehiscentElements = ({ name, value }: { name: string, value: boolean }) => {
		setIsActive({
			...isActive,
			[name]: value,
		});
		setIsActiveIcons({
			banks: false,
			cards: false,
			cryptocurrencies: false,
			wallets: false,
		});
	};

	return (
		<div className={styles.wallet_input}>
			<div className={styles.header}>
				<ChoosingDepositMethod
					onChangeIcon={onChangeIcon}
					stateIcons={isActiveIcons}
					paymentsType={paymentsType}
				/>
				{paymentsType === 'deposit' && (
					<div className={cx(
						styles.header__element,
						styles.header__element__prepaid_card,
					)}
					>
						<Button
							fluid
							size="large"
							theme="primary"
							onClick={() => dispatch(modalOpenAction(<PrepaidCardActivationModal />))}
						>
							Активировать предоплаченную карту
						</Button>
					</div>
				)}
			</div>
			<DehiscentElements
				data={data}
				onChange={onChangeDehiscentElements}
				stateShowElements={isActive}
				paymentsType={paymentsType}
			/>
		</div>
	);
};

export default null;
