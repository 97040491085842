import {
	all,
	fork,
} from 'redux-saga/effects';
import { passwordRecoveryWatcher } from '@auth/store/PasswordRecovery/sagas';
import { registrationWatcher } from '@auth/store/Registration/sagas';
import { verificationPhoneWatcher } from '@auth/store/VerificationPhone/sagas';
import { verificationEmailWatcher } from '@auth/store/VerificationEmail/sagas';
import { partnerRegistrationWatcher } from '@auth/store/PartnerRegistration/sagas';

export function* authSagaRoot() {
	try {
		yield all([
			fork(passwordRecoveryWatcher),
			fork(registrationWatcher),
			fork(verificationPhoneWatcher),
			fork(verificationEmailWatcher),
			fork(partnerRegistrationWatcher),
		]);
	} finally {
		// always runs
	}
}
