/* eslint-disable max-len,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Button, LoaderSpinner, Text,
	AttachButtonDeprecated,
	AttachedImage,
	Paragraph,
	StatusIconAndText, ImageFlipper, Textarea,
} from '@base/components';

import hash from 'object-hash';

import { ClientBrokerState } from '@client_broker/types';

import { modalCloseAction } from '@base/store/Modal/actions';
import fileTypes from '@base/constants/fileTypes';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { registrationAction } from '@auth/store/Registration/actions';
import { useTranslation } from 'react-i18next';
import { getInfoInput } from '@base/utils/inputFunctions';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';
import ReactMarkdown from 'react-markdown';
import correct_1 from '@base/assets/images/photosOfIdentityVerificationExamples/correct_1.svg';
import correct_2 from '@base/assets/images/photosOfIdentityVerificationExamples/correct_2.svg';
import wrong_1 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_1.svg';
import wrong_2 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_2.svg';
import wrong_3 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_3.svg';
import wrong_4 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_4.svg';
import wrong_5 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_5.svg';
import wrong_6 from '@base/assets/images/photosOfIdentityVerificationExamples/wrong_6.jpg';

import { ImagePreview } from '@client_broker/modals/profile/ImagePreview';
import { sendDocumentFilesAction } from '@client_broker/store/DocumentFiles/actions';
import { imageFlipperFunction, imageFlipperOnScroll } from '@base/components/ImageFlipper';
import i18n from '@starter/i18n';
import regex from '@base/constants/regex';
import { FileType } from '@base/components/AttachedFileName';
import styles from './UploadDocumentModal.module.scss';

type UploadDocumentModalProps = {};

export interface UploadDocumentModalFieldsType {
	document_text: string,
	files: Array<any>,
}

export const initialValues = {
	document_text: '',
	files: [],
};

// eslint-disable-next-line no-empty-pattern
export const UploadDocumentModal = ({}: UploadDocumentModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { profileVerification, documentFiles: { sendingFiles } } = useSelector(
		(state: ClientBrokerState) => state.clientBroker,
	);
	const [filesRemoved, setFilesRemoved] = useState<Array<any>>([]);
	const [openFiles, setOpenFiles] = useState<Array<{ img: any, name: string }>>([]);
	const [openIndexPhotoWrong, setOpenIndexPhotoWrong] = useState<number>(0);
	const [indexPhotoWrongScroll, setIndexPhotoWrongScroll] = useState<number>(0);

	const photosWrong = [
		{ img: wrong_1, name: t('CLIENT.DOCUMENTS.IMAGES.1') },
		{ img: wrong_2, name: t('CLIENT.DOCUMENTS.IMAGES.2') },
		{ img: wrong_3, name: t('CLIENT.DOCUMENTS.IMAGES.3') },
		{ img: wrong_4, name: t('CLIENT.DOCUMENTS.IMAGES.4') },
		{ img: wrong_5, name: t('CLIENT.DOCUMENTS.IMAGES.5') },
		{ img: wrong_6, name: t('CLIENT.DOCUMENTS.IMAGES.6') },
	];

	const validationSchema = object().shape({
		document_text: string()
			.min(1, i18n.t('CLIENT.DOCUMENTS.UPLOAD_DOCUMENT_ERROR_VALIDATION'))
			.max(100, i18n.t('CLIENT.DOCUMENTS.UPLOAD_DOCUMENT_ERROR_VALIDATION'))
			.required(t('OTHER.FIELD_IS_REQUIRED'))
			.matches(
				regex.document_text,
				t('CLIENT.DOCUMENTS.UPLOAD_DOCUMENT_ERROR_VALIDATION'),
			),
	});

	const {
		handleChange,
		handleBlur,
		values,
		setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<UploadDocumentModalFieldsType>({
		initialValues,
		onSubmit: (formData: any): any => dispatch(registrationAction(formData)),
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const event = {
				target: { name, value },
			};
			if (event.target.value.length <= 100) {
				handleChange(event);
			}
		},
		[
			handleChange,
		],
	);

	const handleImageChange = (e: any) => {
		e.preventDefault();

		// const reader = new FileReader();
		const file = e.target.files[0];

		if (!file) return;

		if (file.size > 15000000) {
			dispatch(notificationsModalOpenAction({
				messageTKey: t('OTHER.MAXIMUM_FILE_SIZE_EXCEEDED'),
				type: 'error',
			}));
			return;
		}

		switch (file.type) {
			case 'image/png':
			case 'image/jpeg':
			case 'application/pdf':
				break;
			default:
				dispatch(notificationsModalOpenAction({
					messageTKey: t('OTHER.INVALID_FILE_TYPE'),
					type: 'error',
				}));
				return;
		}
		// @ts-ignore
		// setFiles([...files, file]);
		setValues({
			...values,
			files: [...values.files, file],
		});
		// reader.onloadend = () => {
		// 	this.setState({
		// 		file,
		// 		imagePreviewUrl: reader.result,
		// 	});
		// };

		// reader.readAsDataURL(file);
	};

	const deleteFileByIndex = (index: number) => {
		setFilesRemoved([
			...filesRemoved,
			values.files[0],
		]);
		values.files.splice(index, 1);
		setValues({
			...values,
			files: [...values.files],
		});
	};

	const sendData = () => {
		const {
			files,
			document_text,
		} = values;
		dispatch(sendDocumentFilesAction({
			files: [...files, ...filesRemoved],
			file_type: `${fileTypes.OTHER}`,
			others_type: document_text,
			is_identity: false,
		}));

		// setValues(initialValues);
		// setTouched({
		// 	...touched,
		// 	document_text: false,
		// });
	};

	const openPhotoWrong = (openIndex: number) => {
		if (sendingFiles.isLoading) return;
		setOpenIndexPhotoWrong(openIndex);
		setOpenFiles(photosWrong);
	};

	return (
		<>
			{openFiles.length > 0 && (
				<ImagePreview
					onClickClose={() => setOpenFiles([])}
					images={openFiles}
					indexSelected={openIndexPhotoWrong}
				/>
			)}
			{openFiles.length === 0 && (
				<div className={styles.upload_document_modal}>
					{(profileVerification.isLoading || sendingFiles.isLoading) && <LoaderSpinner className={styles.loader_spinner} />}
					<div>
						<Text type="h2">Загрузка документа</Text>
						<Paragraph className={styles.upload_document_modal__paragraph} value="1">
							<Text type="h4">{t('CLIENT.DOCUMENTS.FILL_IN_THE_FIELDS')}</Text>
						</Paragraph>
						<Textarea
							required
							className={styles.input}
							label={t('CLIENT.DOCUMENTS.UPLOAD_DOCUMENT_LABEL')}
							placeholder={t('CLIENT.DOCUMENTS.UPLOAD_DOCUMENT_PLACEHOLDER')}
							name="document_text"
							value={values.document_text}
							onChange={onChangeHandler}
							onBlur={(event) => {
								handleBlur(event);
								setTouched({
									...touched,
									document_text: false,
								});
							}}
							onFocus={handleBlur}
							info={getInfoInput({
								name: 'document_text',
								// text: t('CLIENT.DOCUMENTS.UPLOAD_DOCUMENT_HINT'),
								text: '', // нет подсказки
								touched,
								errors,
								values,
							})}
							floatInfo="bottom"
						/>
						<div className={styles.upload_document_modal__description}>
							<Paragraph className={styles.upload_document_modal__paragraph} value="2">
								<Text type="h4">{t('CLIENT.DOCUMENTS.ATTACH_DOCUMENT')}</Text>
							</Paragraph>
							<div className={styles.upload_document_modal__description__text}>
								<Text type="h5">
									<ReactMarkdown>
										{ /* {t('CLIENT.DOCUMENTS.TEXTS.IDENTITY_VERIFICATION_DESC')} */ }
										{t('CLIENT.PROFILE.IDENTITY_VERIFICATION_DESC')}
										{/*	нет такого ключа. Возможно, нужно добавить  */}
									</ReactMarkdown>
								</Text>
							</div>
						</div>
					</div>
					<div className={styles.upload_document_modal__photo}>
						<>
							<StatusIconAndText
								status="positive"
								text={t('CLIENT.DOCUMENTS.CORRECT_SCAN_DOCUMENT')}
							/>
							<div className={styles.upload_document_modal__photo__correct}>
								<img alt="" src={correct_1} />
								<img alt="" src={correct_2} />
							</div>
						</>
						<>
							<StatusIconAndText
								status="negative"
								text={t('CLIENT.DOCUMENTS.INCORRECT_SCAN_DOCUMENT')}
							/>
							<div
								className={styles.upload_document_modal__photo__wrong}
								id="scrollElement"
								onScroll={() => imageFlipperOnScroll({
									scrollElementId: 'scrollElement',
									setImgIndexSelected: setIndexPhotoWrongScroll,
								})}
							>
								{photosWrong.map((photoWrong, index) => (
									<div id={`imgElement${index}`} className={styles.img_wrap} key={hash(index)}>
										<img alt="" src={photoWrong.img} onClick={() => openPhotoWrong(index)} />
										<Text type="h5" className={styles.img_wrap__text}>
											{photoWrong.name}
										</Text>
									</div>
								))}
							</div>
							<ImageFlipper
								className={styles.image_flipper}
								index={indexPhotoWrongScroll}
								setIndex={(imgIndex) => {
									imageFlipperFunction({
										scrollElementId: 'scrollElement',
										imgElementId: 'imgElement1',
										imgIndex,
										imgIndexSelected: indexPhotoWrongScroll,
										setImgIndexSelected: setIndexPhotoWrongScroll,
									});
								}}
								length={
									// @ts-ignore
									document.getElementById('scrollElement')?.children.length || 0
								}
							/>
						</>
					</div>
					<div>
						{values.files.length > 0 ? (
							<div
								className={styles.upload_document_modal__attached_files_name}
							>
								{values.files.map((file: FileType, index) => (
									<AttachedImage
										className={styles.upload_document_modal__attached_file_name}
										key={hash(index)}
										file={file}
										name={file.name}
										size={file.size}
										onClickOpen={() => {
											setOpenIndexPhotoWrong(0);
											setOpenFiles([{
											// @ts-ignore
												img: URL.createObjectURL(file),
												name: file.name,
											}]);
										}}
										onClickClose={() => deleteFileByIndex(index)}
										type="max"
									/>
								))}
							</div>
						) : <></>}
						{values.files.length === 0 && (
							<AttachButtonDeprecated
								// className={styles.upload_document_modal__add_file}
								text={t('CLIENT.DOCUMENTS.ATTACH_FILE_BUTTON')}
								onClick={() => {
									// @ts-ignore
									document.getElementById('selectImage').click();
								}}
							/>
						)}
						<div className={styles.upload_document_modal__btns}>
							<input
								id="selectImage"
								hidden
								type="file"
								accept="application/pdf, image/jpeg, image/png"
								onChange={(e) => handleImageChange(e)}
							/>
							<Button
								type="button"
								theme="primary"
								size="large"
								onClick={sendData}
								disabled={values.files.length === 0 || profileVerification.isLoading || !isValid || sendingFiles.isLoading}
							>
								{t('CLIENT.DOCUMENTS.UPLOAD_BUTTON')}
							</Button>
							<Button
								className={styles.upload_document_modal__btns__cancel}
								type="button"
								theme="secondary"
								size="large"
								onClick={() => { dispatch(modalCloseAction()); }}
								disabled={profileVerification.isLoading}
							>
								{t('OTHER.CANCEL_BUTTON')}
							</Button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default null;
