import {
	// eslint-disable-next-line no-unused-vars
	call, delay, put, takeLeading,
} from 'redux-saga/effects';

import {
	customerAccountsSuccessAction,
	customerAccountsFailAction,
	updateCustomerAccountSuccessAction,
	customerAccountsAction, createCustomerAccountSuccessAction,
} from '@client_broker/store/СustomerAccounts/actions';
import {
	CREATE_CUSTOMER_ACCOUNT_REQUEST,
	CUSTOMER_ACCOUNTS_REQUEST,
	UPDATE_CUSTOMER_ACCOUNT_REQUEST,
} from '@client_broker/store/СustomerAccounts/constants';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { Account, GetAccountsResp, PostAccountResp } from '@base/types';
import {
	CreateCustomerAccountActionType,
	UpdateCustomerAccountActionType,
} from '@client_broker/store/СustomerAccounts/types';
import { setNotificationAction } from '@base/store/Notifications/actions';
import { modalCloseAction } from '@base/store/Modal/actions';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';

function* customerAccountsSaga() {
	const accounts: {
		live: Array<Account>,
		demo: Array<Account>
	} = { live: [], demo: [] };
	try {
		const resp: GetAccountsResp = yield call(apiRequestClientBroker, 'getAccounts');
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		accounts.live = resp.data.filter((account) => account.is_live);
		accounts.demo = resp.data.filter((account) => account.is_demo);

		yield put(customerAccountsSuccessAction(accounts));
	} catch (e) {
		console.log(e);
		yield put(customerAccountsFailAction({ error: e.message }));
	}
}

function* updateCustomerAccountSaga({ payload }: UpdateCustomerAccountActionType) {
	try {
		const req = {
			id: payload.id,
			body: payload.body,
		};
		const resp: GetAccountsResp = yield call(apiRequestClientBroker, 'patchAccount', { req });
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(updateCustomerAccountSuccessAction());
		yield put(notificationsModalOpenAction({
			messageTKey: 'Изменения счета успешно применены!',
			type: 'success',
		}));
		// yield delay(5000);
		yield put(customerAccountsAction());
	} catch (e) {
		console.log(e);
		yield put(customerAccountsFailAction({ error: e.message }));
	}
}

function* createCustomerAccountSaga({ payload }: CreateCustomerAccountActionType) {
	try {
		const body = {
			...payload,
		};
		const resp: PostAccountResp = yield call(apiRequestClientBroker, 'postAccount', { body });
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(createCustomerAccountSuccessAction());
		yield put(customerAccountsAction());
		yield put(modalCloseAction());
		yield put(setNotificationAction({
			message: 'Счёт успешно создан!',
			type: 'success',
		}));
	} catch (e) {
		console.log(e);
		yield put(customerAccountsFailAction({ error: e.message }));
	}
}

export function* customerAccountsWatcher() {
	yield takeLeading(CUSTOMER_ACCOUNTS_REQUEST, customerAccountsSaga);
	yield takeLeading(UPDATE_CUSTOMER_ACCOUNT_REQUEST, updateCustomerAccountSaga);
	yield takeLeading(CREATE_CUSTOMER_ACCOUNT_REQUEST, createCustomerAccountSaga);
}
