import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { ButtonText } from '@base/components/ButtonText';
import styles from './DehiscentElement.module.scss';

interface DehiscentElementProps {
  className?: string;
  text?: string;
  children: React.ReactNode;
	// eslint-disable-next-line no-unused-vars
	onClick?: (value: boolean) => void,
	value?: boolean,
}

export const DehiscentElement = ({
	className, text, children, onClick, value,
}: DehiscentElementProps) => {
	const [isElementShow, setIsElementShow] = useState(false);

	useEffect(() => {
		if (value === false || value === true) {
			setIsElementShow(value);
		}
	}, [value]);

	const onClickElement = () => {
		setIsElementShow(!isElementShow);
		if (onClick) {
			onClick(!isElementShow);
		}
	};

	return (
		<div className={cx(
			styles.dehiscent_element,
			className,
		)}
		>
			<ButtonText
				onClick={onClickElement}
				fluid
			>
				<div className={cx(
					styles.dehiscent_element__button,
					{ [styles['dehiscent_element--active']]: isElementShow },
				)}
				>
					<div className={styles.dehiscent_element__button__text}>
						{text}
					</div>
					<div className={cx(
						styles.dehiscent_element__button__triangle,
						{ [styles['dehiscent_element__button__triangle--active']]: isElementShow },
					)}
					/>
				</div>
			</ButtonText>
			{(isElementShow) && children}
		</div>
	);
};

export default null;
