export const BASE_TRANSLATIONS_PERSIST_REQUEST: 'BASE_TRANSLATIONS_PERSIST_REQUEST' = 'BASE_TRANSLATIONS_PERSIST_REQUEST';
export const BASE_TRANSLATIONS_PERSIST_SUCCESS: 'BASE_TRANSLATIONS_PERSIST_SUCCESS' = 'BASE_TRANSLATIONS_PERSIST_SUCCESS';
export const BASE_TRANSLATIONS_PERSIST_FAIL: 'BASE_TRANSLATIONS_PERSIST_FAIL' = 'BASE_TRANSLATIONS_PERSIST_FAIL';

export const BASE_TRANSLATIONS_PERSIST_DOWNLOAD_REQUEST: 'BASE_TRANSLATIONS_PERSIST_DOWNLOAD_REQUEST' = 'BASE_TRANSLATIONS_PERSIST_DOWNLOAD_REQUEST';
export const BASE_TRANSLATIONS_PERSIST_DOWNLOAD_SUCCESS: 'BASE_TRANSLATIONS_PERSIST_DOWNLOAD_SUCCESS' = 'BASE_TRANSLATIONS_PERSIST_DOWNLOAD_SUCCESS';
export const BASE_TRANSLATIONS_PERSIST_DOWNLOAD_FAIL: 'BASE_TRANSLATIONS_PERSIST_DOWNLOAD_FAIL' = 'BASE_TRANSLATIONS_PERSIST_DOWNLOAD_FAIL';

export const BASE_SELECT_LANGUAGE_REQUEST: 'BASE_SELECT_LANGUAGE_REQUEST' = 'BASE_SELECT_LANGUAGE_REQUEST';
export const BASE_SELECT_LANGUAGE_SUCCESS: 'BASE_SELECT_LANGUAGE_SUCCESS' = 'BASE_SELECT_LANGUAGE_SUCCESS';
export const BASE_SELECT_LANGUAGE_FAIL: 'BASE_SELECT_LANGUAGE_FAIL' = 'BASE_SELECT_LANGUAGE_FAIL';
