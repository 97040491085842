import React from 'react';
import cx from 'classnames';

interface OwnProps {
    onClick?: () => void,
    className?: string,
    color?: string,
}

type OpenMenuIconProps = OwnProps

export const Arrow = ({ color, className, onClick }: OpenMenuIconProps) => (
	<svg
		className={cx(className)}
		onClick={onClick}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 448 512"
	>
		<path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" fill={color} />
	</svg>

);
