import React from 'react';
import cx from 'classnames';
import { Text } from '@base/components';
import input_edit from '@base/assets/icons/input_edit.svg';

import styles from './InputEdit.module.scss';

interface InputEditProps {
	className?: string,
	label?: string,
	value: string,
	onClick?: () => void
	showIconEdit?: boolean,
}

export const InputEdit = ({
	className,
	label,
	value,
	onClick,
	showIconEdit = true,
}: InputEditProps) => (
	<div className={cx(styles.input_edit, className)}>
		<Text type="h3">{label}</Text>
		<div className={styles.input_edit__value}>
			<Text type="h3-extra">{value}</Text>
			{(onClick && showIconEdit)
			&& (
				<button className={styles.input_edit__value__btn} onClick={onClick}>
					<img src={input_edit} alt="" />
				</button>
			)}
		</div>
	</div>
);

export default null;
