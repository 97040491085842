import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import { Link, Text } from '@base/components';
import androidIcon from '@base/assets/icons/downloadTerminals/android.svg';
import iosIcon from '@base/assets/icons/downloadTerminals/ios.svg';
import windowsIcon from '@base/assets/icons/downloadTerminals/windows.svg';

import styles from './MetaTrader4.module.scss';

type MetaTrader4Props = {};

const DownloadElement = ({ icon, label, link }: {icon: any, label: string, link: string}) => (
	<div className={styles.downloads__element}>
		<img src={icon} alt="" />
		<div className={styles.downloads__element__texts}>
			<Text>{label}</Text>
			<Link
				to={link}
				target="_blank"
				underlined
			>
				Download
			</Link>
		</div>
	</div>
);

// eslint-disable-next-line no-empty-pattern
export const MetaTrader4 = ({}: MetaTrader4Props) => (
	// const dispatch = useDispatch();
	// const { isLoading, error } = useSelector((state: BaseState) => state.data);
	<div className={styles.meta_trader_4}>
		<div className={styles.meta_trader_4__content_top}>
			<Text className={styles.meta_trader_4__description_text}>
				MetaTrader 4 is one of the most popular software for online trading.
				Ease of use, high performance, reliability, rich functionality – all
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				make an optimal set of characteristics that meets the needs of today's traders.
				Adding the widest list of features and trading instruments, modern
				infrastructure and flexible conditions of cooperation with
				Esplanade Market Solutions to MetaTrader 4 potential and advantages, each
				customer receives a perfect set of necessary tools for effective trading.
			</Text>
			<div className={styles.downloads}>
				<DownloadElement
					icon={androidIcon}
					label="MetaTrader 4 for Android"
					link="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en"
				/>
				<DownloadElement
					icon={iosIcon}
					label="MetaTrader 4 for iOS"
					link="https://itunes.apple.com/us/app/metatrader-4-currency-market/id496212596?mt=8"
				/>
				<DownloadElement
					icon={windowsIcon}
					label="MetaTrader 4 for Windows"
					link="https://download.mql5.com/cdn/web/sibilit.limited/mt4/ecnbroker4setup.exe"
				/>
				<DownloadElement
					icon={iosIcon}
					label="MetaTrader 4 for macOS"
					link="https://client.ecn.broker/files/ecnbroker4setup.dmg"
				/>
			</div>
		</div>
		<div>
			<div className={styles.meta_trader_4__label}>
				Why MetaTrader 4
			</div>
			<div
				className={styles.meta_trader_4__description_text}
			>
				<li>Free downloading and operation;</li>
				<li>Intuitive interface, efficiency and ease of studying (before starting trading);</li>
				<li>Power, speed, reliability at very easy and convenient operation;</li>
				<li>Complement of multiple features with extensive customization options;</li>
				<li>
					Opportunity to work both with real (trading) and demonstrative accounts,
					which allows you without any risks and expenses to evaluate the terminal
					capabilities, as well as to test trading strategies;
				</li>
				<li>Availability of mobile trading;</li>
				<li>
					Opportunity to develop and to use automated trading
					software (Expert Advisors, EA) on the basis of MetaTrader 4 platform,
					as well as own trading advisers and technical indicators,
					created using MQL 4 trading strategies language;
				</li>
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				<li>"Trading Signals" social trading service support;</li>
				<li>Receiving financial news and technical analysis data.</li>
			</div>
		</div>
	</div>
);

export default null;
