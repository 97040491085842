// eslint-disable-next-line max-classes-per-file
import CustomerProvider from '@base/providers/clientBroker/customer';
import AccountsProvider from '@base/providers/clientBroker/accuonts';
import TransfersProvider from '@base/providers/clientBroker/transfers';
import TradesProvider from '@base/providers/clientBroker/trades';
import PaymentsProvider from '@base/providers/clientBroker/payments';
import PartnerProvider from '@base/providers/clientBroker/partner';
import TranslationsProvider from '@base/providers/base/translations';
import FileProvider from '@base/providers/base/file';
import ReferralProvider from '@base/providers/base/referral';
import CountriesProvider from '@base/providers/base/countries';
import CoreProvider from '@base/providers/base/core';
import AuthProvider from '@base/providers/auth/auth';
import SystemProvider from '@base/providers/base/system';

const aggregation = (baseClass: any, ...mixins: any) => {
	const base = class _Combined extends baseClass {
		constructor(...args: any) {
			super(...args);
			mixins.forEach((mixin: any) => {
				mixin.prototype.initializer.call(this);
			});
		}
	};
	const copyProps = (target: any, source: any) => {
		Object.getOwnPropertyNames(source)
			// @ts-ignore
			.concat(Object.getOwnPropertySymbols(source))
			.forEach((prop) => {
				if (prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/)) return;
				// @ts-ignore
				Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop));
			});
	};
	mixins.forEach((mixin: any) => {
		copyProps(base.prototype, mixin.prototype);
		copyProps(base, mixin);
	});
	return base;
};

class ApiProvider extends aggregation(
	AuthProvider,
	CustomerProvider,
	AccountsProvider,
	TransfersProvider,
	CoreProvider,
	TradesProvider,
	PaymentsProvider,
	PartnerProvider,
	CountriesProvider,
	ReferralProvider,
	FileProvider,
	TranslationsProvider,
	SystemProvider,
) {}

export default ApiProvider;
