import ApiProviderDefault from '@base/providers/apiProviderDefault';
import {
	GetCallAttemptReq,
	GetCallAttemptResp,
	GetTokenResp,
	LoginReq,
	PostPhoneMakeCallReq,
	PostPhoneMakeCallResp,
	PostVerifyPhoneReq,
	PostVerifyPhoneResp,
	RefreshTokenReq,
	RefreshTokenResp,
	RegistrationReq,
	RegistrationResp,
} from '@base/types/provider/auth';

export default class AuthProvider extends ApiProviderDefault {
	static async postLogin(parameters: {
		body: LoginReq,
	}) {
		const path = '/authenticate';
		// const path = '/login';
		let body;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}

		if (parameters.body !== undefined) {
			body = parameters.body;
		}

		return this.request<LoginReq, GetTokenResp>(path, 'POST', query, body);
	}

	static async postRefreshToken(parameters: {
		body: RefreshTokenReq,
	}) {
		const path = '/refresh_token';
		let body;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}

		if (parameters.body !== undefined) {
			body = parameters.body;
		}

		return this.request<RefreshTokenReq, RefreshTokenResp>(path, 'POST', query, body);
	}

	static async postRegistration(parameters: {
		body: RegistrationReq,
	}) {
		const path = '/customer';
		let body;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}

		if (parameters.body !== undefined) {
			body = parameters.body;
		}

		return this.request<RegistrationReq, RegistrationResp>(path, 'POST', query, body);
	}

	// static async postPhoneSendCode(parameters: {
	// 	body: PostPhoneSendCodeReq,
	// 	headers: Headers,
	// }) {
	// 	const path = '/monolith/send-code';
	// 	let headers;
	// 	const query = {};
	// 	if (parameters.body === undefined) {
	// 		throw new Error('Missing required  parameter: body');
	// 	}
	// 	const { body } = parameters;
	//
	// 	if (parameters.headers !== undefined) {
	// 		headers = parameters.headers;
	// 	}
	//
	// 	return this.request<PostPhoneSendCodeReq,
	//	PostPhoneSendCodeResp>(path, 'POST', query, body, headers);
	// }

	static async postPhoneMakeCall(parameters: {
		body: PostPhoneMakeCallReq,
		headers: Headers,
	}) {
		const path = '/monolith/make-call';
		let headers;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const { body } = parameters;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<PostPhoneMakeCallReq, PostPhoneMakeCallResp>(path, 'POST', query, body, headers);
	}

	static async postVerifyPhone(parameters: {
		body: PostVerifyPhoneReq,
		headers: Headers,
	}) {
		const path = '/monolith/verify-phone';
		let headers;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const { body } = parameters;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<PostVerifyPhoneReq, PostVerifyPhoneResp>(path, 'POST', query, body, headers);
	}

	static async getCallAttempt(parameters: {
		params: GetCallAttemptReq,
		headers: Headers,
	}) {
		let headers;
		let query;
		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}
		const path = '/monolith/call-attempt';

		return this.request<GetCallAttemptReq, GetCallAttemptResp>(path, 'GET', query, undefined, headers);
	}
}

// @ts-ignore
// AuthProvider.postPhoneSendCode.security = true;

// @ts-ignore
AuthProvider.postPhoneMakeCall.security = true;

// @ts-ignore
AuthProvider.postVerifyPhone.security = true;

// @ts-ignore
AuthProvider.getCallAttempt.security = true;
