import { AttachButtonIcon } from '@base/assets/icons/buttons/attach_button';

import send_button from '@base/assets/icons/buttons/send_button.svg';
import attach_button from '@base/assets/icons/buttons/attach_button.svg';
import save from '@base/assets/icons/buttons/save.svg';

export const ButtonIcons: { [key: string]: any; } = {
	attach_button: AttachButtonIcon,
};

export default {
	send_button,
	attach_button,
	save,
};
