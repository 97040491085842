import React, { useState } from 'react';
import cx from 'classnames';

import { Colors } from '@base/assets/styles/colors';
import { Text } from '@base/components';
import { ButtonIcons } from '@base/assets/icons/buttons';
import styles from './AttachButtonDeprecated.module.scss';

interface AttachButtonProps {
  className?: string;
  text: string;
	onClick: () => void,
}

const AttachIcon = ({ color }: {color: string}) => {
	const { attach_button: IconAttach } = ButtonIcons;
	return (
		<IconAttach
			onClick={() => {}}
			className={styles.icon_attach}
			color={color}
		/>
	);
};

export const AttachButtonDeprecated = ({
	className, text, onClick,
}: AttachButtonProps) => {
	const [isHover, setIsHover] = useState(false);

	const getColorForIcon = () => (isHover ? Colors.black : Colors.primary);

	return (
		// eslint-disable-next-line max-len
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/mouse-events-have-key-events
		<div
			className={cx(styles.attach_button, className)}
			onMouseOver={() => setIsHover(true)}
			onMouseOut={() => setIsHover(false)}
			onClick={onClick}
		>
			<AttachIcon color={getColorForIcon()} />
			<Text className={styles.attach_button__text} type="h4" color="primary">
				{text}
			</Text>
		</div>
	);
};

export default null;
