import React from 'react';
import cx from 'classnames';

interface OwnProps {
	onClick?: () => void,
	className?: string,
	color?: string,
}

type OpenMenuIconProps = OwnProps

export const OpenMenuIcon = ({ color, className, onClick }: OpenMenuIconProps) => (
	<svg className={cx(className)} onClick={onClick}>
		<path fillRule="evenodd" clipRule="evenodd" d="M0.5 1.125C0.5 0.826631 0.618526 0.540483 0.829505 0.329505C1.04048 0.118526 1.32663 0 1.625 0H20.375C20.5227 -3.11333e-09 20.669 0.0290989 20.8055 0.0856354C20.942 0.142172 21.066 0.225039 21.1705 0.329505C21.275 0.433971 21.3578 0.55799 21.4144 0.694481C21.4709 0.830972 21.5 0.977263 21.5 1.125C21.5 1.27274 21.4709 1.41903 21.4144 1.55552C21.3578 1.69201 21.275 1.81603 21.1705 1.9205C21.066 2.02496 20.942 2.10783 20.8055 2.16436C20.669 2.2209 20.5227 2.25 20.375 2.25H1.625C1.32663 2.25 1.04048 2.13147 0.829505 1.9205C0.618526 1.70952 0.5 1.42337 0.5 1.125ZM0.5 8.625C0.5 8.32663 0.618526 8.04048 0.829505 7.8295C1.04048 7.61853 1.32663 7.5 1.625 7.5H20.375C20.6734 7.5 20.9595 7.61853 21.1705 7.8295C21.3815 8.04048 21.5 8.32663 21.5 8.625C21.5 8.92337 21.3815 9.20952 21.1705 9.42049C20.9595 9.63147 20.6734 9.75 20.375 9.75H1.625C1.32663 9.75 1.04048 9.63147 0.829505 9.42049C0.618526 9.20952 0.5 8.92337 0.5 8.625ZM1.625 15C1.32663 15 1.04048 15.1185 0.829505 15.3295C0.618526 15.5405 0.5 15.8266 0.5 16.125C0.5 16.4234 0.618526 16.7095 0.829505 16.9205C1.04048 17.1315 1.32663 17.25 1.625 17.25H20.375C20.6734 17.25 20.9595 17.1315 21.1705 16.9205C21.3815 16.7095 21.5 16.4234 21.5 16.125C21.5 15.8266 21.3815 15.5405 21.1705 15.3295C20.9595 15.1185 20.6734 15 20.375 15H1.625Z" fill={color} />
	</svg>
);
