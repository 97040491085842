import React from 'react';
import cx from 'classnames';

interface OwnProps {
	onClick?: () => void,
	onMouseMove?: () => void,
	onMouseLeave?: () => void,
	className?: string,
	color?: string,
	isActive?: boolean,
}

type OpenMenuIconProps = OwnProps

export const BanksIcon = ({
	color, isActive, className, onClick, onMouseMove, onMouseLeave,
}: OpenMenuIconProps) => (
	<svg
		className={cx(className)}
		onClick={onClick}
		onMouseMove={onMouseMove}
		onMouseLeave={onMouseLeave}
	>
		<circle cx="27.5" cy="27.5" r="27" fill={isActive ? color : 'white'} stroke={isActive ? 'white' : color} />
		<path fillRule="evenodd" clipRule="evenodd" d="M27.0826 18.1496C27.405 17.9501 27.8124 17.9501 28.1347 18.1496L36.7416 23.4737C37.5981 24.0035 37.2227 25.3241 36.2156 25.3241H35.0103V34.8242H36.2156C36.6022 34.8242 36.9156 35.1376 36.9156 35.5242C36.9156 35.9108 36.6022 36.2242 36.2156 36.2242H19.1084C18.7218 36.2242 18.4084 35.9108 18.4084 35.5242C18.4084 35.1376 18.7218 34.8242 19.1084 34.8242H20.4087L20.4087 25.3241H19.0018C17.9947 25.3241 17.6192 24.0035 18.4757 23.4737L27.0826 18.1496ZM20.4087 23.9241L27.6087 19.4703L34.8086 23.9241H20.4087ZM21.8087 25.3241L21.8087 34.8242H24.8095V25.3241H21.8087ZM26.2095 25.3241V34.8242H29.2099V25.3241H26.2095ZM30.6099 25.3241V34.8242H33.6103V25.3241H30.6099Z" fill={isActive ? 'white' : color} />
	</svg>
);
