import React from 'react';

import { LabelWrapForPage } from '@base/components';
import { useTranslation } from 'react-i18next';
import { modalOpenAction } from '@base/store/Modal/actions';
import { useDispatch, useSelector } from 'react-redux';
import { BaseState } from '@base/types';
import { InfoModal } from '@client_broker/modals/InfoModal';
import cx from 'classnames';
import styles from './PageLayout.module.scss';

type PageLayoutProps = {
	children: React.ReactNode
};

export const PageLayout = ({
	children,
}: PageLayoutProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		pageInfo: { tKeyRoot },
	} = useSelector((state: BaseState) => state.base);
	const isShowIcon = () => (
		t(`${tKeyRoot}.INFO`) !== ''
		&& !t(`${tKeyRoot}.INFO`).includes(tKeyRoot)
	);

	return (
		<div className={styles.page_layout}>
			{!t(`${tKeyRoot}.TITLE`).includes(tKeyRoot) && (
				<LabelWrapForPage
					className={cx(
						{
							[styles.label]: t(`${tKeyRoot}.TITLE`) !== '',
						},
					)}
					labelText={t(`${tKeyRoot}.TITLE`)}
					isShowIcon={isShowIcon()}
					onClick={() => {
						dispatch(modalOpenAction(<InfoModal />));
					}}
				/>
			)}
			{children}
		</div>
	);
};

export default null;
