import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import history from '@base/utils/history';
import '@base/assets/styles/main.scss';

import { I18nextProvider } from 'react-i18next';
import configureStore, { ReduxState } from './store/configureStore';
import reportWebVitals from './reportWebVitals';
import { App } from './App';
import i18n from './i18n';

// @ts-ignore
const initialState: ReduxState = {};

const {
	store, persistor,
} = configureStore(initialState, history);

const MOUNT_NODE = document.getElementById('root');
if (MOUNT_NODE) {
	ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<I18nextProvider i18n={i18n}>
						<App />
					</I18nextProvider>
				</ConnectedRouter>
			</PersistGate>
		</Provider>, MOUNT_NODE,
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
