import React from 'react';

import styles from './TableDeprecated.module.scss';

// export type AnyObject = {[key: string]: any}

type TableItemProps = {
	id: string,
	TableRow?: React.ReactNode,
	isExternal?: boolean,
	item: any,
	// eslint-disable-next-line no-unused-vars
	customComponent?: (item: any, id: string) => React.ReactNode,
};

const TableItemDeprecated = ({
	TableRow,
	item,
	isExternal,
	id,
	customComponent,
}: TableItemProps) => {
	if (TableRow !== null) {
		// @ts-ignore
		return <TableRow id={id} item={item} />;
	}

	if (customComponent) {
		return (
			<div className={styles['table__item-content']}>
				{isExternal === true
					|| (item[id] !== ''
					&& item[id] !== null
					&& item[id] !== undefined)
					? customComponent(item, id) : null}
			</div>
		);
	}

	if (item.rowType === 'second') {
		return (
			<div className={styles['table__item-content']}>
				{
					item[id] !== ''
					&& item[id] !== null
					&& item[id] !== undefined
						? item[id] : ''
				}
			</div>
		);
	}

	return (
		<div className={styles['table__item-content']}>
			{
				item[id] !== ''
				&& item[id] !== null
				&& item[id] !== undefined
					? item[id] : '__'
			}
		</div>
	);
};

export default TableItemDeprecated;
