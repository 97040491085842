import * as notificationsModalTypes from '@base/store/NotificationsModal/constants';
import { TypeNotification } from '@base/types';
import React from 'react';
import { OnClickParamOfNotificationsModalType } from '@base/store/NotificationsModal/types';

export const notificationsModalOpenAction = (
	payload: {
		labelTKey?: string,
		messageTKey: string,
		descriptionTKey?: string,
		descriptionComponent?: string | React.ReactNode,
		type: TypeNotification
		// eslint-disable-next-line no-unused-vars
		onClick?: (obj: OnClickParamOfNotificationsModalType) => any,
		// eslint-disable-next-line no-unused-vars
		onClickClose?: (obj: OnClickParamOfNotificationsModalType) => any,
	},
) => {
	window.scrollTo({
		behavior: 'smooth',
	});
	return ({
		type: notificationsModalTypes.BASE_NOTIFICATIONS_MODAL_OPEN,
		payload,
	});
};

export const notificationsModalCloseAction = () => ({
	type: notificationsModalTypes.BASE_NOTIFICATIONS_MODAL_CLOSE,
});

export default null;
