import React, { useEffect } from 'react';

import { Button, Text, Input } from '@base/components';
import { modalCloseAction } from '@base/store/Modal/actions';
import { useDispatch } from 'react-redux';

import { object, string } from 'yup';
import errorMessages from '@base/constants/errorMessages';
import { useFormik } from 'formik';
import regex from '@base/constants/regex';
import { useTranslation } from 'react-i18next';
import styles from './AddingPaymentMethodModal.module.scss';

type AddingPaymentMethodModalProps = {};

interface AddingPaymentMethodModalFieldsType {
	walletNumber: string,
}

export const initialValues = {
	walletNumber: '',
};

const validationSchema = object().shape({
	walletNumber: string()
		.required(errorMessages.FIELD_IS_REQUIRED)
		.matches(
			regex.paymentCardNumber,
			errorMessages.INVALID_AMOUNT,
		),
});

// eslint-disable-next-line no-empty-pattern
export const AddingPaymentMethodModal = ({}: AddingPaymentMethodModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		// setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<AddingPaymentMethodModalFieldsType>({
		initialValues,
		onSubmit: (
			// eslint-disable-next-line no-unused-vars
			formData: AddingPaymentMethodModalFieldsType,
		): any => () => {},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const event = {
				target: {	name,	value	},
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);
	return (
		<div className={styles.adding_payment_method}>
			<Text type="h3">Добавить платёжный метод</Text>
			<div className={styles.transfer_between_user_accounts__content}>
				<>
					<Input
						className={styles.adding_payment_method__content__input}
						label="Номер карты / кошелька"
						name="walletNumber"
						required
						value={values.walletNumber}
						onChange={onChangeHandler}
						onBlur={handleBlur}
						error={
							(touched.walletNumber !== undefined && errors.walletNumber !== undefined)
								? errors.walletNumber || ''
								: undefined
						}
					/>
				</>
			</div>
			<div className={styles.adding_payment_method__btns}>
				<Button
					className={styles.adding_payment_method__btns__cancel}
					type="button"
					theme="secondary"
					size="large"
					onClick={() => { dispatch(modalCloseAction()); }}
				>
					Отмена
				</Button>
				<Button
					type="button"
					theme="primary"
					size="large"
					onClick={() => handleSubmit()}
					disabled={!isValid}
				>
					Отправить на проверку
				</Button>
			</div>
		</div>
	);
};
export default null;
