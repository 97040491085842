import React from 'react';

import cx from 'classnames';
import { TradingVolumes } from '@base/types';
import { format } from 'date-fns';
import { DATE_PICKER_TIME_FORMAT } from '@base/constants/dateFormat';
import styles from './TableRow.module.scss';

type OwnProps = {};

type TableRowProps = {
	id: string,
	item: TradingVolumes | any
} & OwnProps;

const TableRow = ({
	id,
	item,
}: TableRowProps) => {
	const ItemWrapper = ({
		classNameRowWrapper,
		classNameRow,
		children,
	}: { children: React.ReactNode, classNameRow?: string, classNameRowWrapper?: string }) => (
		<div className={cx(classNameRowWrapper, styles.table_row_wrapper)}>
			<div className={cx(classNameRow, styles.table_row)}>
				{children}
			</div>
		</div>
	);

	const ItemDefault = () => (
		<ItemWrapper>
			{
				item[id] !== undefined
				&& item[id] !== ''
				&& item[id] !== null
				&& item[id]
					? item[id] : '__'
			}
		</ItemWrapper>
	);

	const TimeRow = () => {
		const time = new Date(item.open_time * 1000);
		return (
			<ItemWrapper classNameRow={styles.account_name_row}>
				{format(new Date(time), DATE_PICKER_TIME_FORMAT)}
			</ItemWrapper>
		);
	};

	switch (id) {
		case 'open_time':
		case 'close_time':
			return <TimeRow />;
		default:
			return (<ItemDefault />);
	}
};

TableRow.defaultProps = {
	item: {
		accountName: 'testName',
	},
};

export default TableRow;
