export const CUSTOMER_REQUEST:
	'CUSTOMER_REQUEST' = 'CUSTOMER_REQUEST';
export const CUSTOMER_SUCCESS:
	'CUSTOMER_SUCCESS' = 'CUSTOMER_SUCCESS';
export const CUSTOMER_FAIL:
	'CUSTOMER_FAIL' = 'CUSTOMER_FAIL';

export const CUSTOMER_UPDATE_REQUEST:
	'CUSTOMER_UPDATE_REQUEST' = 'CUSTOMER_UPDATE_REQUEST';
export const CUSTOMER_UPDATE_SUCCESS:
	'CUSTOMER_UPDATE_SUCCESS' = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_FAIL:
	'CUSTOMER_UPDATE_FAIL' = 'CUSTOMER_UPDATE_FAIL';

export const CUSTOMER_PASSWORD_CHANGE_REQUEST:
	'CUSTOMER_PASSWORD_CHANGE_REQUEST' = 'CUSTOMER_PASSWORD_CHANGE_REQUEST';
export const CUSTOMER_PASSWORD_CHANGE_SUCCESS:
	'CUSTOMER_PASSWORD_CHANGE_SUCCESS' = 'CUSTOMER_PASSWORD_CHANGE_SUCCESS';
export const CUSTOMER_PASSWORD_CHANGE_FAIL:
	'CUSTOMER_PASSWORD_CHANGE_FAIL' = 'CUSTOMER_PASSWORD_CHANGE_FAIL';
