import * as paymentsTypes from '@client_broker/store/Payments/constants';
import type { PaymentsActionsType } from '@client_broker/store/Payments/types';
import { RequestInfoChildType, Payments } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type PaymentsState = {
  withdraw: {
    data: Payments | undefined;
  } & RequestInfoChildType;
	deposit: {
    data: Payments | undefined;
  } & RequestInfoChildType;
	depositSend: RequestInfoChildType,
};

const initialState: PaymentsState = {
	withdraw: {
		data: undefined,
		...RequestInfoChildState,
	},
	deposit: {
		data: undefined,
		...RequestInfoChildState,
	},
	depositSend: RequestInfoChildState,
};

export const paymentsReducer = (
	state: PaymentsState = initialState,
	action: PaymentsActionsType,
): PaymentsState => {
	switch (action.type) {
		case paymentsTypes.PAYMENTS_WITHDRAW_REQUEST: {
			return {
				...state,
				withdraw: {
					...state.withdraw,
					isLoading: true,
				},
			};
		}

		case paymentsTypes.PAYMENTS_WITHDRAW_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				withdraw: {
					...state.withdraw,
					data: payload,
					isLoading: false,
				},
			};
		}

		case paymentsTypes.PAYMENTS_WITHDRAW_FAIL: {
			const { payload } = action;
			return {
				...state,
				withdraw: {
					...state.withdraw,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		// --------------------------------

		case paymentsTypes.PAYMENTS_DEPOSIT_REQUEST: {
			return {
				...state,
				deposit: {
					...state.deposit,
					isLoading: true,
				},
			};
		}

		case paymentsTypes.PAYMENTS_DEPOSIT_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				deposit: {
					...state.deposit,
					data: payload,
					isLoading: false,
				},
			};
		}

		case paymentsTypes.PAYMENTS_DEPOSIT_FAIL: {
			const { payload } = action;
			return {
				...state,
				deposit: {
					...state.deposit,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		// --------------------------------

		case paymentsTypes.PAYMENTS_DEPOSIT_SEND_REQUEST: {
			return {
				...state,
				depositSend: {
					...state.depositSend,
					isLoading: true,
				},
			};
		}

		case paymentsTypes.PAYMENTS_DEPOSIT_SEND_SUCCESS: {
			return {
				...state,
				depositSend: {
					...state.depositSend,
					isLoading: false,
				},
			};
		}

		case paymentsTypes.PAYMENTS_DEPOSIT_SEND_FAIL: {
			const { payload } = action;
			return {
				...state,
				depositSend: {
					...state.depositSend,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
