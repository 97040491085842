/* eslint-disable */
import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';

import styles from './PartnerCabinet.module.scss';
import { TableReact } from '@base/components';
import TableRow from '@client_broker/containers/accounts/TableRow/TableRow';

type PartnerCabinetProps = {};


// eslint-disable-next-line no-empty-pattern
export const PartnerCabinet = ({}: PartnerCabinetProps) => {
	const data = React.useMemo(
		() => [
			{
				col1: 'MinskMinskMin skMinskMinskMinskMinsk',
				col2: '27',
				col3: 'rain',
				accountName: 'rain',
				history: 'rain',
			},
			{
				col1: 'Vilnius',
				col2: '30',
				col3: 'rain',
				accountName: 'rain',
				history: 'rain',
			},
			{
				col1: 'London',
				col2: '23',
				col3: 'rain',
				accountName: 'rain',
				history: 'rain',
			},
		],
		[],
	);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Weather Forecast',
				accessor: 'accountName', // accessor is the "key" in the data
			}, {
				Header: 'Weather Forecast',
				accessor: 'history', // accessor is the "key" in the data
			},
			{
				Header: 'City',
				accessor: 'col1', // accessor is the "key" in the data
			},
			{
				Header: 'Temperature',
				accessor: 'col2',
			},
			{
				Header: 'Weather Forecast',
				accessor: 'col3', // accessor is the "key" in the data
			},
		],
		[],
	);

	return (
		<div className={styles.partner_cabinet}>
			<TableReact
				columns={columns}
				data={data}
				tableRow={TableRow}
				type="frameless"
				// type="default"
			/>
		</div>
	);
};

export default null;
