import {
	all,
	call, put, select, takeLeading,
} from 'redux-saga/effects';

import {
	translationsPersistSuccessAction,
	translationsPersistFailAction,
	translationsPersistDownloadSuccessAction,
	translationsPersistDownloadFailAction,
	selectLanguageFailAction,
	selectLanguageSuccessAction,
} from '@base/store/TranslationsPersist/actions';
import * as translationsTypes from '@base/store/TranslationsPersist/constants';
import { BaseState } from '@base/types';
import { LanguageNameType, SelectLanguageRequestActionType } from '@base/store/TranslationsPersist/types';
import i18n from '@starter/i18n';
import { utils } from '@base/utils';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { GetTranslationsCabinetResp } from '@base/types/base/translations';
// import { changeCaseOfObjectKeys } from '@base/utils/translation';
// eslint-disable-next-line no-unused-vars
import { language_ru } from '@base/translations/ru_new';

export function* translationsPersistDownloadSaga() {
	try {
		const languageNames = [
			{ tag: 'ru', label: 'Русский' },
			{ tag: 'en', label: 'English' },
			{ tag: 'id', label: '' },
			{ tag: 'tr', label: '' },
			{ tag: 'es', label: '' },
		];

		const respTranslations: Array<GetTranslationsCabinetResp> = yield all(
			yield languageNames.map((languageName: LanguageNameType) => call(
				apiRequestClientBroker,
				'getTranslationsCabinet',
				{
					params: {
						lang: languageName.tag,
					},
				},
			)),
		);

		respTranslations.forEach((respTranslation) => {
			if (!respTranslation.success) throw new Error('Unknown error');
		});

		const translations: any = {
			keys: { translation: {} },
		};

		languageNames.forEach((languageName: LanguageNameType, index: number) => {
			if (!respTranslations[index].success) {
				throw new Error(respTranslations[index].error);
			}
			translations[languageName.tag] = {
				// @ts-ignore
				translation: respTranslations[index].data,
			};
		});

		// TODO Удалить
		// translations.ru = language_ru.ru.translation;

		// const resp = {
		// 	data: {
		// 		translations: {
		// 			...language_en,
		// 			...language_ru,
		// 			keys: {
		// 				translation: {},
		// 			},
		// 		},
		// 	},
		// 	success: true,
		// 	error: '',
		// };
		// if (!resp.success) {
		// 	yield put(errorHandlerAction({ ...resp }));
		// 	throw new Error(resp.error);
		// }
		// const { translations, languageNames } = resp.data;

		yield put(translationsPersistDownloadSuccessAction({
			// translations: changeCaseOfObjectKeys(translations),
			translations,
			languageNames: [
				...languageNames,
				{ tag: 'keys', label: '' },
			],
		}));
	} catch (e) {
		console.log(e);
		yield put(translationsPersistDownloadFailAction({ error: (e as Error).message }));
	}
}

function* translationsPersistSaga() {
	try {
		// yield i18n.init({ fallbackLng: 'keys' });

		// const {
		// isTranslationsDownloaded,
		// } = yield select(({ base }: BaseState) => base.translationsPersist);
		// if (!isTranslationsDownloaded) {
		yield call(translationsPersistDownloadSaga);
		// }
		const {
			selectedLanguage, translations,
		} = yield select(({ base }: BaseState) => base.translationsPersist);
		const translationsMap = new Map(Object.entries(translations.data));

		translationsMap.forEach((value, key) => {
			i18n.addResourceBundle(key, 'translation', {
				// @ts-ignore
				...value.translation,
			}, undefined, true);
		});

		const language = { tag: 'ru', label: '' };
		if (selectedLanguage.data && selectedLanguage.data.tag) {
			language.tag = selectedLanguage.data.tag;
		} else {
			const navigatorLanguage = utils.defineLanguage(translationsMap);
			if (navigatorLanguage) language.tag = navigatorLanguage;
		}

		yield i18n.changeLanguage(language.tag);

		yield put(translationsPersistSuccessAction(language));
	} catch (err) {
		yield put(translationsPersistFailAction({ error: (err as Error).message }));
	}
}

function* selectLanguageSaga({ payload: { tag } }: SelectLanguageRequestActionType) {
	try {
		if (tag === 'keys') {
			yield i18n.init({ fallbackLng: 'keys' });
		}

		const {
			languageNames,
		} = yield select(({ base }: BaseState) => base.translationsPersist);
		const languages = languageNames.filter((value: LanguageNameType) => value.tag === tag);

		if (languages.length === 0) throw new Error('Unknown error');
		yield put(selectLanguageSuccessAction(languages[0]));
		yield i18n.changeLanguage(tag);
	} catch (err) {
		yield put(selectLanguageFailAction({ error: (err as Error).message }));
	}
}

export function* translationsPersistWatcher() {
	yield takeLeading(
		translationsTypes.BASE_TRANSLATIONS_PERSIST_DOWNLOAD_REQUEST,
		translationsPersistDownloadSaga,
	);
	yield takeLeading(
		translationsTypes.BASE_TRANSLATIONS_PERSIST_REQUEST,
		translationsPersistSaga,
	);
	yield takeLeading(
		translationsTypes.BASE_SELECT_LANGUAGE_REQUEST,
		selectLanguageSaga,
	);
}
