import * as referralTypes from '@base/store/Referral/constants';
import {
	PostAssignParentReq,
	// ReferralRequest,
	ReferralSetReq,
} from '@base/types';

export const referralSetAction = (payload: ReferralSetReq) => ({
	type: referralTypes.BASE_REFERRAL_SET_REQ,
	payload,
});

export const referralSetSuccessAction = () => ({
	type: referralTypes.BASE_REFERRAL_SET_SUCCESS,
});

export const referralSetFailAction = (payload: { error: string }) => ({
	type: referralTypes.BASE_REFERRAL_SET_FAIL,
	payload,
});

//------------------------------------------

export const setReferralRequestsAction = (payload: PostAssignParentReq) => ({
	type: referralTypes.BASE_SET_REFERRAL_REQUESTS_REQ,
	payload,
});

export const setReferralRequestsSuccessAction = () => ({
	type: referralTypes.BASE_SET_REFERRAL_REQUESTS_SUCCESS,
});

export const setReferralRequestsFailAction = (payload: { error: string }) => ({
	type: referralTypes.BASE_SET_REFERRAL_REQUESTS_FAIL,
	payload,
});

//------------------------------------------

// export const referralRequestsAction = () => ({
// 	type: referralTypes.BASE_REFERRAL_REQUESTS_REQ,
// });
//
// export const referralRequestsSuccessAction = (payload: {
// 	data: Array<ReferralRequest>,
// }) => ({
// 	type: referralTypes.BASE_REFERRAL_REQUESTS_SUCCESS,
// 	payload,
// });
//
// export const referralRequestsFailAction = (payload: { error: string }) => ({
// 	type: referralTypes.BASE_REFERRAL_REQUESTS_FAIL,
// 	payload,
// });

//------------------------------------------

export const referralGetAction = () => ({
	type: referralTypes.BASE_REFERRAL_GET_REQ,
});

export const referralGetSuccessAction = (payload: {
	isHave: boolean
	ref_code: string
}) => ({
	type: referralTypes.BASE_REFERRAL_GET_SUCCESS,
	payload,
});

export const referralGetFailAction = (payload: { error: string }) => ({
	type: referralTypes.BASE_REFERRAL_GET_FAIL,
	payload,
});

//------------------------------------------

export const setReferralCodeAction = (payload: {
	isHave: boolean
	ref_code: string
}) => ({
	type: referralTypes.BASE_SET_REFERRAL_CODE,
	payload,
});

export default null;
