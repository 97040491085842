import * as registrationTypes from '@auth/store/Registration/constants';
import type { RegistrationActionsType } from '@auth/store/Registration/types';
import { RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type RegistrationState = {
  registration: {
		isSent: boolean,
  } & RequestInfoChildType;
};

const initialState: RegistrationState = {
	registration: {
		isSent: false,
		...RequestInfoChildState,
	},
};

export const registrationReducer = (
	state: RegistrationState = initialState,
	action: RegistrationActionsType,
): RegistrationState => {
	switch (action.type) {
		case registrationTypes.REGISTRATION_REQUEST: {
			return {
				...state,
				registration: {
					...state.registration,
					isLoading: true,
				},
			};
		}

		case registrationTypes.REGISTRATION_SUCCESS: {
			return {
				...state,
				registration: {
					...state.registration,
					isSent: true,
					isLoading: false,
				},
			};
		}

		case registrationTypes.REGISTRATION_FAIL: {
			const { payload } = action;
			return {
				...state,
				registration: {
					...state.registration,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
