import React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Tabs, Text } from '@base/components';
import { Redirect, Route, Switch } from 'react-router-dom';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import { getTabPaths } from '@base/utils/getTabPaths';
import { Demo } from '@client_broker/containers/accounts/demoAccounts/Demo';
import { modalOpenAction } from '@base/store/Modal/actions';
import { CreateAccountModal } from '@client_broker/modals/accounts/CreateAccountModal';
import { useTranslation } from 'react-i18next';
import styles from './DemoAccounts.module.scss';

interface OwnProps {}
interface DispatchProps {}
interface StateProps {}

type DemoAccountsProps = StateProps & DispatchProps & OwnProps;
const paths = getTabPaths(clientBrokerRoutes['demo-accounts']);

// eslint-disable-next-line no-empty-pattern
export const DemoAccounts = ({}: DemoAccountsProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<div className={styles.demo_accounts}>
			<Text type="h2">{t('CLIENT.ACCOUNTS.DEMO.TITLE')}</Text>
			<Text type="h4" className={styles.demo_accounts__description}>
				{t('CLIENT.ACCOUNTS.DEMO.DESCRIPTION')}
			</Text>
			<div className={styles.demo_accounts__tabs}>
				<Tabs paths={paths} />
			</div>
			<div className={styles.demo_accounts__content}>
				<Switch>
					<Route path={clientBrokerRoutes['demo-accounts'].demo.root} component={Demo} />
					{/* <Route */}
					{/*	path={clientBrokerRoutesDefault['demo-accounts'].archival.root} */}
					{/*	component={Archival} */}
					{/* /> */}
					<Redirect to={clientBrokerRoutes['demo-accounts'].demo.root} />
				</Switch>
			</div>

			<Button
				className={styles.demo_accounts__btn}
				size="large"
				theme="primary"
				onClick={() => dispatch(modalOpenAction(<CreateAccountModal accountsType="is_demo" />))}
			>
				{t('CLIENT.ACCOUNTS.DEMO.OPEN_ACCOUNT_BUTTON')}
			</Button>
		</div>
	);
};

export default null;
