import React from 'react';
import cx from 'classnames';

interface OwnProps {
	onClick?: () => void,
	onMouseMove?: () => void,
	onMouseLeave?: () => void,
	className?: string,
	color?: string,
	isActive?: boolean,
}

type OpenMenuIconProps = OwnProps

export const WalletsIcon = ({
	color, isActive, className, onClick, onMouseMove, onMouseLeave,
}: OpenMenuIconProps) => (
	<svg
		className={cx(className)}
		onClick={onClick}
		onMouseMove={onMouseMove}
		onMouseLeave={onMouseLeave}
		fill={isActive ? color : 'white'}
	>
		<circle cx="27.5" cy="27.5" r="27" fill={isActive ? color : 'white'} stroke={isActive ? 'white' : color} />
		<rect x="21.7" y="15.7" width="12.6" height="22.6" rx="1.3" stroke={isActive ? 'white' : color} strokeWidth="1.4" />
		<line x1="22" y1="33.3" x2="34" y2="33.3" stroke={isActive ? 'white' : color} strokeWidth="1.4" />
		<rect x="24" y="25.2001" width="6" height="1.4" rx="0.7" fill={isActive ? 'white' : color} />
		<rect x="26" y="22.4" width="6" height="1.4" rx="0.7" fill={isActive ? 'white' : color} />
		<rect x="24" y="23.8" width="6" height="1.4" rx="0.7" fill={isActive ? 'white' : color} />
	</svg>
);
