import ApiProviderDefault from '@base/providers/apiProviderDefault';
import {
	PostTrades,
	PostTradesReq,
	PostTradesResp,
} from '@base/types';

export default class TradesProvider extends ApiProviderDefault {
	static async postTrades(parameters: {
		params: PostTrades,
		headers: Headers,
	}) {
		let headers;
		const query = {};
		if (parameters.params === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const {
			time_from, time_to, accountType, accountId,
		} = parameters.params;
		const path = `/trades/${accountType}/${accountId}`;

		const body = { time_from, time_to };

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<PostTradesReq, PostTradesResp>(path, 'POST', query, body, headers);
	}
}

// @ts-ignore
TradesProvider.postTrades.security = true;
