import React from 'react';
import { LabelForm, Text } from '@base/components';
import { useSelector } from 'react-redux';
import { BaseState } from '@base/types';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import styles from './AuthInfoModal.module.scss';

type AuthInfoModalProps = {};

// eslint-disable-next-line no-empty-pattern
export const AuthInfoModal = ({}: AuthInfoModalProps) => {
	const { t } = useTranslation();
	const { pageInfo: { labelTKey, tKeyRoot } } = useSelector(({ base }: BaseState) => base);

	return (
		<div className={styles.auth_info_modal}>
			<LabelForm
				className={styles.label}
				text={t(labelTKey)}
			/>
			<div className={styles.content_wrap}>
				<Text type="h4">
					<ReactMarkdown>
						{t(`${tKeyRoot}.TEXTS.INFO`)}
					</ReactMarkdown>
				</Text>
			</div>
		</div>
	);
};
export default null;
