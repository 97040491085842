/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import back_arrow from '@base/assets/icons/back_arrow.svg';
import { ImageFlipper, Text } from '@base/components';
import styles from './ImagePreview.module.scss';

type ImagePreviewProps = {
	// eslint-disable-next-line no-unused-vars
	onClickClose: () => void
	images: Array<{ img: any, name: string }>,
	indexSelected?: number,
	isBackArrow?: boolean,
};

// eslint-disable-next-line no-empty-pattern
export const ImagePreview = ({
	onClickClose, images, indexSelected = 0, isBackArrow = true,
}: ImagePreviewProps) => {
	const [imgIndex, setImgIndex] = useState<number>(indexSelected);

	return (
		<div className={styles.image_preview}>
			{isBackArrow && (
				<button
					onClick={onClickClose}
					className={styles.button_back_arrow}
				>
					<img alt="" src={back_arrow} />
				</button>
			)}
			<div
				className={styles.photo_wrap}
			>
				<img
					className={styles.photo}
					alt=""
					src={images[imgIndex].img}
				/>
			</div>
			<Text type="h5" className={styles.text}>
				{images[imgIndex].name}
			</Text>
			{images.length > 1 && (
				<ImageFlipper
					index={imgIndex}
					setIndex={setImgIndex}
					length={images.length}
				/>
			)}
		</div>
	);
};
export default null;
