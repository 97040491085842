import { call, put, takeLeading } from 'redux-saga/effects';

import {
	countriesSuccessAction,
	countriesFailAction,
} from '@base/store/Countries/actions';
import * as countriesTypes from '@base/store/Countries/constants';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { GetCountriesResp } from '@base/types';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';

function* countriesSaga() {
	try {
		const resp: GetCountriesResp = yield call(
			apiRequestClientBroker, 'getCountries',
		);
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		const options = resp.data.map((value) => ({ value, label: value.name }));
		yield put(countriesSuccessAction({
			data: resp.data,
			options,
		}));
	} catch (e) {
		console.log(e);
		yield put(countriesFailAction({ error: e.message }));
	}
}

export function* countriesWatcher() {
	yield takeLeading(countriesTypes.BASE_COUNTRIES_REQUEST, countriesSaga);
}
