import {
	all,
	fork,
} from 'redux-saga/effects';

import { authPersistWatcher } from '@base/store/AuthPersist/sagas';
import { translationsPersistWatcher } from '@base/store/TranslationsPersist/sagas';
import { coreWatcher } from '@base/store/Core/sagas';
import { referralWatcher } from '@base/store/Referral/sagas';
import { countriesWatcher } from '@base/store/Countries/sagas';
import { errorHandlerWatcher } from '@base/store/ErrorHandler/sagas';
import { systemWatcher } from '@base/store/System/sagas';
import { userActionsInfoPersistWatcher } from '@base/store/UserActionsInfoPersist/sagas';

export function* baseSagaRoot() {
	try {
		yield all([
			fork(authPersistWatcher),
			fork(translationsPersistWatcher),
			fork(coreWatcher),
			fork(referralWatcher),
			fork(countriesWatcher),
			fork(errorHandlerWatcher),
			fork(systemWatcher),
			fork(userActionsInfoPersistWatcher),
		]);
	} finally {
		// always runs
	}
}
