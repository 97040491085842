const defineLanguage = (translationsMap: any): string | undefined => {
	const navigatorLanguage = window.navigator && window.navigator.language;
	let language;

	if (!navigatorLanguage) return undefined;

	// @ts-ignore
	translationsMap.forEach((value, key) => {
		if (navigatorLanguage.indexOf(key) !== -1) {
			language = key;
		}
	});
	// console.log('defineLanguage, language= ', language);

	return language;
};

export default {
	defineLanguage,
};
