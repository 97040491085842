/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import cx from 'classnames';

import styles from './Text.module.scss';

export type TextColorsType = 'primary' | 'white' | 'gray' | 'gray-2' | 'gray-light' | 'gray-dark' | 'black' | 'green' | 'red' | '';

export type TextProps = {
	type?: 'h1'
		| 'h1-extra'
		| 'h2' | 'h2-extra' | 'h2-bold' | 'h2-bold-2'
		| 'h3' | 'h3-extra' | 'h3-bold'
		| 'h4' | 'h4-extra' | 'h4-extra-1' | 'h4-bold'
		| 'h5' | 'h5-extra' | 'h5-1'
		| 'h6' | '',
	children: React.ReactNode,
	className?: string,
	align?: 'left' | 'center' | 'right',
	color?: TextColorsType,
	onClick?: () => void,
};

export const Text: React.FC<TextProps> = ({
	children,
	type,
	className,
	align,
	color,
	onClick,
}: TextProps) => (
	<span
		onClick={onClick}
		className={cx(
			styles.text,
			{
				[styles[`text--type--${type || ''}`]]: type,
				[styles[`text--align--${align || ''}`]]: align,
				[styles[`text--color--${color || ''}`]]: color,
			},
			className,
		)}
	>
		{children}
	</span>
);

export default null;
