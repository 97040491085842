import React from 'react';
import cx from 'classnames';

interface OwnProps {
	className?: string,
	color?: string,
}

type DownloadTerminalsIconProps = OwnProps

export const DownloadTerminalsIcon = ({ color, className }: DownloadTerminalsIconProps) => (
	<svg className={cx(className)}>
		<path d="M22.1284 1.20087H1.87162C0.839651 1.20087 0 2.11685 0 3.24263V18.6972C0 19.823 0.839651 20.739 1.87162 20.739H11.2929V22.2564H8.81666V23.7991H15.1833V22.2564H12.7071V20.739H22.1284C23.1604 20.739 24 19.823 24 18.6972V3.24263C24 2.11676 23.1604 1.20087 22.1284 1.20087ZM1.87162 2.74354H22.1284C22.3807 2.74354 22.5859 2.9674 22.5859 3.24263V15.5412H1.41411V3.24263C1.41411 2.96731 1.61933 2.74354 1.87162 2.74354ZM22.1284 19.1962H1.87162C1.61933 19.1962 1.41411 18.9723 1.41411 18.6971V17.0838H22.5859V18.6971C22.5859 18.9723 22.3807 19.1962 22.1284 19.1962Z" fill={color} />
	</svg>
);
