import * as coreTypes from '@base/store/Core/constants';
import { Core } from '@base/types';
import { CoreOptionsObjectsType } from '@base/types/base/core';

export const coreAction = () => ({
	type: coreTypes.BASE_CORE_REQUEST,
});

export const coreSuccessAction = (
	payload: {data: Core, optionsOfData: CoreOptionsObjectsType},
) => ({
	type: coreTypes.BASE_CORE_SUCCESS,
	payload,
});

export const coreFailAction = (payload: { error: string }) => ({
	type: coreTypes.BASE_CORE_FAIL,
	payload,
});

export default null;
