import * as transferInternalTypes from '@client_broker/store/TransferInternal/constants';
import type { TransferInternalActionsType } from '@client_broker/store/TransferInternal/types';
import { RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type TransferInternalState = {
	transferInternal: {
	} & RequestInfoChildType;
};

const initialState: TransferInternalState = {
	transferInternal: {
		...RequestInfoChildState,
	},
};

export const transferInternalReducer = (
	state: TransferInternalState = initialState,
	action: TransferInternalActionsType,
): TransferInternalState => {
	switch (action.type) {
		case transferInternalTypes.CLIENT_BROKER_TRANSFER_INTERNAL_REQUEST: {
			return {
				...state,
				transferInternal: {
					...state.transferInternal,
					isLoading: true,
				},
			};
		}

		case transferInternalTypes.CLIENT_BROKER_TRANSFER_INTERNAL_SUCCESS: {
			return {
				...state,
				transferInternal: {
					...state.transferInternal,
					isLoading: false,
				},
			};
		}

		case transferInternalTypes.CLIENT_BROKER_TRANSFER_INTERNAL_FAIL: {
			const { payload } = action;
			return {
				...state,
				transferInternal: {
					...state.transferInternal,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
