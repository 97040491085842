import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
	Button,
	LoaderSpinner,
	Input,
	ButtonLink,
	Text, Timer,
} from '@base/components';
import { object, string } from 'yup';
import authRoutes from '@auth/constants/authRoutes';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import { AuthState } from '@auth/types';
import {
	verificationPhoneAction,
	verificationPhoneMakeCallAction,
} from '@auth/store/VerificationPhone/actions';
import { authPersistLogout } from '@base/store/AuthPersist/actions';
import { useTranslation } from 'react-i18next';
import { getInfoInput } from '@base/utils/inputFunctions';
import ReactMarkdown from 'react-markdown';
import { InputEdit } from '@base/components/InputEdit';
import formats from '@base/utils/formats';
import styles from './VerificationPhoneCheckForm.module.scss';

type VerificationPhoneCheckFormProps = {
	// eslint-disable-next-line no-unused-vars
	setIsShowSendForm: (obj: boolean) => void
};

interface RegistrationFormFieldsType {
	code: string,
}

export const initialValues = {
	code: '',
};

// eslint-disable-next-line no-empty-pattern
export const VerificationPhoneCheckForm = ({
	setIsShowSendForm,
}: VerificationPhoneCheckFormProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { phoneMakeCall, verifyPhone, callAttempt } = useSelector((
		state: AuthState,
	) => state.auth.verificationPhone);

	const [seconds, setSeconds] = useState(0);
	const [secondDefault, setSecondDefault] = useState(0);

	const [phoneNumber, setPhoneNumber] = useState('');

	const validationSchema = object().shape({
		code: string()
			.min(6, t('AUTH.REGISTRATION.VERIFICATION_PHONE.CODE_ERROR_VALIDATION'))
			.max(6, t('AUTH.REGISTRATION.VERIFICATION_PHONE.CODE_ERRORS_VALIDATION'))
			.required(t('OTHER.FIELD_IS_REQUIRED')),
	});

	const {
		handleChange,
		handleBlur,
		values,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<RegistrationFormFieldsType>({
		initialValues,
		onSubmit: () => {},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	useEffect(() => {
		if (!callAttempt.data) return;
		const { phone, time_limit, ts } = callAttempt.data;
		setSecondDefault(time_limit);
		if (phone === '') setSeconds(time_limit);
		setPhoneNumber(phone);
		const reRequestIsAllowedAfter = (ts + time_limit) - (new Date().getTime() / 1000);
		if (reRequestIsAllowedAfter > 0) {
			setSeconds((ts + time_limit) - (new Date().getTime() / 1000));
		} else {
			setSeconds(0);
		}
	}, [callAttempt]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			let target = { name, value };
			switch (name) {
				case 'code':
					target = { name, value: value.replace(/[^\d]/g, '') };
					break;
				default:
					break;
			}
			handleChange({ target });
		},
		[
			handleChange,
		],
	);

	const isLoading = useMemo(() => (
		phoneMakeCall.isLoading || verifyPhone.isLoading
	), [phoneMakeCall.isLoading, verifyPhone.isLoading]);

	const numberOfAttemptsHasBeenExhausted = useMemo(() => {
		if (callAttempt.data && callAttempt.data?.attempt > 2) {
			return true;
		}
		return false;
	}, [callAttempt]);

	return (
		<div className={styles.verification_phone_check_form}>
			{isLoading && <LoaderSpinner />}
			<>
				<Text type="h4">
					<ReactMarkdown>
						{t('AUTH.REGISTRATION.VERIFICATION_PHONE.DESCRIPTION_SENT_PHONE')}
					</ReactMarkdown>
				</Text>
				<InputEdit
					className={styles.block}
					label={t('AUTH.REGISTRATION.VERIFICATION_PHONE.PHONE_NUMBER_LABEL')}
					value={formatIncompletePhoneNumber(formats.formatPhoneNumber(phoneNumber))}
					onClick={() => {
						setIsShowSendForm(true);
					}}
					showIconEdit={!numberOfAttemptsHasBeenExhausted}
				/>
				<Input
					className={styles.block}
					label={t('AUTH.REGISTRATION.VERIFICATION_PHONE.CODE_LABEL')}
					// placeholder={t('AUTH.REGISTRATION.VERIFICATION_PHONE.CODE_PLACEHOLDER')}
					placeholder="" // нет плейсхолдера
					name="code"
					required
					value={values.code}
					onChange={onChangeHandler}
					disabled={isLoading}
					onBlur={(event) => {
						handleBlur(event);
						setTouched({
							...touched,
							code: false,
						});
					}}
					onFocus={handleBlur}
					info={getInfoInput({
						name: 'code',
						// text: t('AUTH.REGISTRATION.VERIFICATION_PHONE.CODE_HINT'),
						text: '', // нет подсказки
						touched,
						errors,
						values,
					})}
				/>
				<Button
					size="large"
					type="button"
					theme="primary"
					onClick={() => {
						setSeconds(secondDefault);
						dispatch(
							verificationPhoneAction({
								phone: phoneNumber.replace(/\s/g, ''),
								code: values.code,
							}),
						);
					}}
					disabled={
						!phoneNumber
						|| isLoading
						|| !isValid
					}
					className={styles.button}
				>
					{t('AUTH.REGISTRATION.VERIFICATION_PHONE.CONFIRM_BUTTON')}
				</Button>
				{!numberOfAttemptsHasBeenExhausted && (
					<Button
						size="large"
						type="button"
						theme="secondary"
						onClick={() => {
							// setSeconds(secondDefault);
							dispatch(
								verificationPhoneMakeCallAction({ phone: phoneNumber.replace(/\s/g, '') }),
							);
						}}
						disabled={
							!phoneNumber
							|| phoneNumber === ''
							|| isLoading
							|| callAttempt.isLoading
							|| seconds > 0
						}
						className={styles.button}
					>
						{t('AUTH.REGISTRATION.VERIFICATION_PHONE.RE_REQUEST_CALL_BUTTON')}
						{seconds > 0 && (
							<>
								&nbsp;
								&nbsp;
								<Timer
									seconds={seconds}
									setCurrentSeconds={setSeconds}
								/>
							</>
						)}
					</Button>
				)}
			</>
			{numberOfAttemptsHasBeenExhausted && (
				<ButtonLink
					to={authRoutes.auth.root}
					type="submit"
					theme="secondary"
					size="large"
					className={styles.button}
					onClick={() => dispatch(authPersistLogout())}
				>
					{t('OTHER.CANCEL_BUTTON')}
				</ButtonLink>
			)}
		</div>
	);
};

export default null;
