import { ref, string } from 'yup';
import regex from '@base/constants/regex';
import i18n from '@starter/i18n';

export const passwordYup = (t: any, tKey: string) => string()
	.required(i18n.t('OTHER.FIELD_IS_REQUIRED'))
	.matches(
		regex.passwordForLogin,
		i18n.t(`${tKey}.PASSWORD_ERROR_VALIDATION`),
	);
// tKey должен быть AUTH.REGISTRATION

export const passwordConfirmYup = (t: any, tKey: string) => passwordYup(t, tKey)
	.oneOf([ref('password')], i18n.t(`${tKey}.PASSWORD_CONFIRM_ERROR_PASSWORD_MISMATCH`));
// tKey должен быть AUTH.PASSWORD_RECOVERY

export const passwordNewYup = (t: any, tKey: string) => string()
	.required(i18n.t('OTHER.FIELD_IS_REQUIRED'))
	.matches(
		regex.password,
		i18n.t(`${tKey}.PASSWORD_ERROR_VALIDATION`),
	);

// export const passwordNewConfirmYup = (t: any, tKey: string) => string()
// 	.required(i18n.t('OTHER.MESSAGES.INPUTS.FIELD_IS_REQUIRED'))
// 	.matches(
// 		regex.password,
// 		i18n.t(`${tKey}.PASSWORD_NEW_CONFIRM.ERRORS.VALIDATION`),
// 	)
// 	.oneOf([ref('password_new')], i18n.t(`${tKey}.PASSWORD_NEW_CONFIRM.ERRORS.PASSWORD_MISMATCH`));

export const firstnameYup = (t: any, tKey: string) => string()
	.min(1, i18n.t(`${tKey}.FIRSTNAME_ERROR_VALIDATION`))
	.max(40, i18n.t(`${tKey}.FIRSTNAME_ERROR_VALIDATION`))
	.required(i18n.t('OTHER.FIELD_IS_REQUIRED'))
	.matches(
		regex.firstname,
		i18n.t(`${tKey}.FIRSTNAME_ERROR_VALIDATION`),
	);
// tKey должен быть AUTH.REGISTRATION

export const lastnameYup = (t: any, tKey: string) => string()
	.min(1, i18n.t(`${tKey}.LASTNAME_ERROR_VALIDATION`))
	.max(40, i18n.t(`${tKey}.LASTNAME_ERROR_VALIDATION`))
	.required(i18n.t('OTHER.FIELD_IS_REQUIRED'))
	.matches(
		regex.lastname,
		i18n.t(`${tKey}.LASTNAME_ERROR_VALIDATION`),
	);
// tKey должен быть AUTH.REGISTRATION

export const emailYup = (t: any, tKey: string) => string()
	.min(6, i18n.t(`${tKey}.EMAIL_ERROR_VALIDATION`))
	.max(320, i18n.t(`${tKey}.EMAIL_ERROR_VALIDATION`))
	.required(i18n.t('OTHER.FIELD_IS_REQUIRED'))
	.matches(
		regex.email,
		i18n.t(`${tKey}.EMAIL_ERROR_VALIDATION`),
	);
// tKey должен быть AUTH.REGISTRATION

// TODO remove check and move to regexp
// export const referralYup = (t: any, tKey: string) => string()
// 	.min(5, i18n.t(`${tKey}.REFERRAL.ERRORS.VALIDATION`))
// 	.min(5, i18n.t(`${tKey}.REFERRAL.ERRORS.VALIDATION`))
// 	.max(32, i18n.t(`${tKey}.REFERRAL.ERRORS.VALIDATION`))
// 	.matches(
// 		regex.referralCode,
// 		i18n.t(`${tKey}.REFERRAL.ERRORS.VALIDATION`),
// 	);
// tKey должен быть AUTH.REGISTRATION
export const referralYup = () => string()
	.min(5)
	.min(5)
	.max(32)
	.matches(
		regex.referralCode,
	);
