export const AUTH_PERSIST_LOGOUT: 'AUTH_PERSIST_LOGOUT' = 'AUTH_PERSIST_LOGOUT';

export const AUTH_PERSIST_LOGIN_SEND: 'AUTH_PERSIST_LOGIN_SEND' = 'AUTH_PERSIST_LOGIN_SEND';
export const AUTH_PERSIST_LOGIN_SUCCESS: 'AUTH_PERSIST_LOGIN_SUCCESS' = 'AUTH_PERSIST_LOGIN_SUCCESS';
export const AUTH_PERSIST_LOGIN_ERROR: 'AUTH_PERSIST_LOGIN_ERROR' = 'AUTH_PERSIST_LOGIN_ERROR';

export const AUTH_PERSIST_REFRESH_TOKEN_SEND: 'AUTH_PERSIST_REFRESH_TOKEN_SEND' = 'AUTH_PERSIST_REFRESH_TOKEN_SEND';
export const AUTH_PERSIST_REFRESH_TOKEN_SUCCESS: 'AUTH_PERSIST_REFRESH_TOKEN_SUCCESS' = 'AUTH_PERSIST_REFRESH_TOKEN_SUCCESS';
export const AUTH_PERSIST_REFRESH_TOKEN_ERROR: 'AUTH_PERSIST_REFRESH_TOKEN_ERROR' = 'AUTH_PERSIST_REFRESH_TOKEN_ERROR';

export const AUTH_PERSIST_UPDATE_USER_DATA: 'AUTH_PERSIST_UPDATE_USER_DATA' = 'AUTH_PERSIST_UPDATE_USER_DATA';
