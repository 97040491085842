import * as paymentsTypes from '@client_broker/store/Payments/constants';
import { Payments, PostPaymentDeposit } from '@base/types';

export const paymentsWithdrawAction = () => ({
	type: paymentsTypes.PAYMENTS_WITHDRAW_REQUEST,
});

export const paymentsWithdrawSuccessAction = (payload: Payments) => ({
	type: paymentsTypes.PAYMENTS_WITHDRAW_SUCCESS,
	payload,
});

export const paymentsWithdrawFailAction = (payload: { error: string }) => ({
	type: paymentsTypes.PAYMENTS_WITHDRAW_FAIL,
	payload,
});

export const paymentsDepositAction = () => ({
	type: paymentsTypes.PAYMENTS_DEPOSIT_REQUEST,
});

export const paymentsDepositSuccessAction = (payload: Payments) => ({
	type: paymentsTypes.PAYMENTS_DEPOSIT_SUCCESS,
	payload,
});

export const paymentsDepositFailAction = (payload: { error: string }) => ({
	type: paymentsTypes.PAYMENTS_DEPOSIT_FAIL,
	payload,
});

export const paymentsDepositSendAction = (payload: PostPaymentDeposit) => ({
	type: paymentsTypes.PAYMENTS_DEPOSIT_SEND_REQUEST,
	payload,
});

export const paymentsDepositSendSuccessAction = () => ({
	type: paymentsTypes.PAYMENTS_DEPOSIT_SEND_SUCCESS,
});

export const paymentsDepositSendFailAction = (payload: { error: string }) => ({
	type: paymentsTypes.PAYMENTS_DEPOSIT_SEND_FAIL,
	payload,
});

export default null;
