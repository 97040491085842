import React from 'react';

import { Text, ButtonLink } from '@base/components';

import partnerRegistrationRoutes from '@auth/constants/partnersRegistrationRoutes';
import { useSelector } from 'react-redux';
import { AuthState } from '@auth/types';
import cx from 'classnames';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import { useTranslation } from 'react-i18next';
import styles from './ProgramSelection.module.scss';

type ProgramSelectionProps = {};

// eslint-disable-next-line no-empty-pattern
export const ProgramSelection = ({}: ProgramSelectionProps) => {
	const { t } = useTranslation();
	const { userData, token } = useSelector((
		state: AuthState,
	) => state.base.authPersist);
	return (
		<div className={styles.program_selection}>
			<Text type="h3">{t('PARTNER_REG.SELECT_PROGRAM')}</Text>
			<div className={cx(
				// { [styles.program_selection_buttons]: (!userData.isConfirmedEmail && !token) },
				styles.program_selection_buttons,

			)}
			>
				{(!userData.isConfirmedEmail && !token) && (
					<div className={styles.element}>
						<Text>{t('PARTNER_REG.IB')}</Text>
						<Text className={styles.description}>
							{t('PARTNER_REG.IB_DESCRIPTION')}
						</Text>
						<ButtonLink
							to={partnerRegistrationRoutes['partner-registration'].ib.root}
							theme="primary"
							size="large"
							fluid
							onClick={() => { }}
						>
							{t('PARTNER_REG.CHOOSE_BUTTON')}
						</ButtonLink>
						{' '}

					</div>
				)}
				<div className={styles.element}>
					<Text>{t('PARTNER_REG.MULTILEVEL')}</Text>
					<Text className={styles.description}>
						{t('PARTNER_REG.MULTILEVEL_DESCRIPTION')}
					</Text>
					<ButtonLink
						to={partnerRegistrationRoutes['partner-registration'].multilevel.root}
						theme="primary"
						size="large"
						fluid
						onClick={() => { }}
					>
						{t('PARTNER_REG.CHOOSE_BUTTON')}
					</ButtonLink>
				</div>
			</div>
			{(userData.isConfirmedEmail && token) && (
				<ButtonLink
					to={clientBrokerRoutes.root}
					theme="secondary"
					size="large"
					fluid
					onClick={() => { }}
				>
					{t('OTHER.CANCEL_BUTTON')}
				</ButtonLink>
			)}
		</div>
	);
};
export default null;
