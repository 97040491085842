import * as systemTypes from '@base/store/System/constants';
import type { SystemActionsType } from '@base/store/System/types';
import { RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type SystemState = {
	data: {
		isOk: boolean;
	} & RequestInfoChildType;
};

const initialState: SystemState = {
	data: {
		isOk: false,
		...RequestInfoChildState,
	},
};

export const systemReducer = (
	state: SystemState = initialState,
	action: SystemActionsType,
): SystemState => {
	switch (action.type) {
		case systemTypes.BASE_SYSTEM_REQUEST: {
			return {
				...state,
				data: {
					...state.data,
					isLoading: true,
				},
			};
		}

		case systemTypes.BASE_SYSTEM_SUCCESS: {
			return {
				...state,
				data: {
					...state.data,
					isLoading: false,
				},
			};
		}

		case systemTypes.BASE_SYSTEM_FAIL: {
			const { payload } = action;
			return {
				...state,
				data: {
					...state.data,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
