/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { TableDeprecated, TableReact, DehiscentElement } from '@base/components';

import { Payment, Payments, PaymentsTypes } from '@base/types';
import TableRow from '@client_broker/containers/wallet/WalletInputOutput/TableRow/TableRow';
import { modalOpenAction } from '@base/store/Modal/actions';
import { useDispatch } from 'react-redux';
import { DepositingToAccountModal } from '@client_broker/modals/wallet/DepositingToAccountModal';
import { WithdrawalFromAccountModal } from '@client_broker/modals/wallet/WithdrawalFromAccountModal';
import styles from './DehiscentElements.module.scss';

export type StateShowElements = {
	banks: boolean,
	cards: boolean,
	cryptocurrencies: boolean,
	wallets: boolean,
}

interface OwnProps {
}

type DehiscentElementsProps = {
	data?: Payments
	stateShowElements: StateShowElements,
	// eslint-disable-next-line no-unused-vars
	onChange: (event: { name: string, value: boolean }) => void,
	paymentsType: PaymentsTypes
} & OwnProps;

const columns = [
	{
		accessor: 'name',
		Header: 'Платежная системы',
		// isSort: false,
	}, {
		accessor: 'countries',
		Header: 'Страны',
		// isSort: false,
	}, {
		accessor: 'time-period_min',
		Header: 'Срок зачисления',
		// isSort: false,
	}, {
		accessor: 'charges',
		Header: 'Комиссия',
		// isSort: false,
	}, {
		accessor: 'limitations',
		Header: 'Доступные валюты',
		// isSort: false,
	}, {
		accessor: 'limitations_extended',
		Header: 'Минимальные / максимальные суммы',
		// isSort: false,
	},
];

export const DehiscentElements = ({
	data, stateShowElements, onChange, paymentsType,
}: DehiscentElementsProps) => {
	const dispatch = useDispatch();
	const [banktransfers, setBanktransfers] = useState<Array<Payment>>([]);
	const [cards, setCards] = useState<Array<Payment>>([]);
	const [ewallets, setEwallets] = useState<Array<Payment>>([]);

	useEffect(() => {
		if (data) {
			setBanktransfers(data.banktransfers);
			setEwallets(data.ewallets);

			let cardsNew: Array<Payment> = [];
			Object.keys(data.cards).forEach((key: string) => {
				cardsNew = [
					...cardsNew,
					// @ts-ignore
					...data.cards[key],
				];
			});
			setCards(cardsNew);
		}
	}, [data]);

	const onClickRow = (event: {
		accessor: string,
		item?: Payment,
	}) => {
		if (!event.item) return;

		switch (paymentsType) {
			case 'withdraw':
				dispatch(modalOpenAction(<WithdrawalFromAccountModal />));
				break;
			case 'deposit':
				switch (event.item.uid) {
					case 'bitcoin':
						break;
					default:
						dispatch(modalOpenAction(<DepositingToAccountModal paymentMethod={event.item} />));
						break;
				}
				break;
			default: break;
		}
	};

	return (
		<div className={styles.elements}>
			<DehiscentElement
				className={styles.elements__element}
				text="Банковские карты"
				onClick={() => onChange({ name: 'wallets', value: !stateShowElements.wallets })}
				value={stateShowElements.wallets}
			>
				{/* <TableDeprecated */}
				{/*	className={styles.elements__table} */}
				{/*	classNameTableRows={styles.elements__table__rows} */}
				{/*	type="frameless" */}
				{/*	nameSort="sort" */}
				{/*	valueSort="" */}
				{/*	onChangeSort={() => {}} */}
				{/*	nameOrder="order" */}
				{/*	valueOrder="asc" */}
				{/*	onChangeOrder={() => {}} */}
				{/*	namePage="page" */}
				{/*	valuePage={0} */}
				{/*	pageSize={22} */}
				{/*	onChangePage={() => {}} */}
				{/*	onChangeSelect={onChangeSelect} */}
				{/*	columns={columns} */}
				{/*	data={cards} */}
				{/*	isLoading={false} */}
				{/*	valueCount={cards.length} */}
				{/* > */}
				{/*	/!* @ts-ignore *!/ */}
				{/*	{(props) => ( */}
				{/*		<TableRow */}
				{/*			// eslint-disable-next-line react/jsx-props-no-spreading */}
				{/*			{...props} */}
				{/*		/> */}
				{/*	)} */}
				{/* </TableDeprecated> */}
				<TableReact
					onClickRow={onClickRow}
					className={styles.elements__table}
					columns={columns}
					data={cards}
					tableRow={TableRow}
					type="frameless"
				/>
			</DehiscentElement>
			<DehiscentElement
				className={styles.elements__element}
				text="Банковские переводы"
				onClick={() => onChange({ name: 'banks', value: !stateShowElements.banks })}
				value={stateShowElements.banks}
			>
				{/* <TableDeprecated */}
				{/*	className={styles.elements__table} */}
				{/*	classNameTableRows={styles.elements__table__rows} */}
				{/*	type="frameless" */}
				{/*	nameSort="sort" */}
				{/*	valueSort="" */}
				{/*	onChangeSort={() => {}} */}
				{/*	nameOrder="order" */}
				{/*	valueOrder="asc" */}
				{/*	onChangeOrder={() => {}} */}
				{/*	namePage="page" */}
				{/*	valuePage={0} */}
				{/*	pageSize={22} */}
				{/*	onChangeSelect={onChangeSelect} */}
				{/*	onChangePage={() => {}} */}
				{/*	columns={columns} */}
				{/*	data={banktransfers} */}
				{/*	isLoading={false} */}
				{/*	valueCount={banktransfers.length} */}
				{/* > */}
				{/*	/!* @ts-ignore *!/ */}
				{/*	{(props) => ( */}
				{/*		<TableRow */}
				{/*			// eslint-disable-next-line react/jsx-props-no-spreading */}
				{/*			{...props} */}
				{/*		/> */}
				{/*	)} */}
				{/* </TableDeprecated> */}
				<TableReact
					onClickRow={onClickRow}
					className={styles.elements__table}
					columns={columns}
					data={banktransfers}
					tableRow={TableRow}
					type="frameless"
				/>
			</DehiscentElement>
			<DehiscentElement
				className={styles.elements__element}
				text="Электронные кошельки"
				onClick={() => onChange({ name: 'cards', value: !stateShowElements.cards })}
				value={stateShowElements.cards}
			>
				{/* <TableDeprecated */}
				{/*	className={styles.elements__table} */}
				{/*	classNameTableRows={styles.elements__table__rows} */}
				{/*	type="frameless" */}
				{/*	nameSort="sort" */}
				{/*	valueSort="" */}
				{/*	onChangeSort={() => {}} */}
				{/*	nameOrder="order" */}
				{/*	valueOrder="asc" */}
				{/*	onChangeOrder={() => {}} */}
				{/*	namePage="page" */}
				{/*	valuePage={0} */}
				{/*	pageSize={22} */}
				{/*	onChangeSelect={onChangeSelect} */}
				{/*	onChangePage={() => {}} */}
				{/*	columns={columns} */}
				{/*	data={ewallets} */}
				{/*	isLoading={false} */}
				{/*	valueCount={ewallets.length} */}
				{/* > */}
				{/*	/!* @ts-ignore *!/ */}
				{/*	{(props) => ( */}
				{/*		<TableRow */}
				{/*			// eslint-disable-next-line react/jsx-props-no-spreading */}
				{/*			{...props} */}
				{/*		/> */}
				{/*	)} */}
				{/* </TableDeprecated> */}
				<TableReact
					onClickRow={onClickRow}
					className={styles.elements__table}
					columns={columns}
					data={ewallets}
					tableRow={TableRow}
					type="frameless"
				/>
			</DehiscentElement>
			<DehiscentElement
				className={styles.elements__element}
				text="Криптовалюта"
				onClick={() => onChange({
					name: 'cryptocurrencies', value: !stateShowElements.cryptocurrencies,
				})}
				// onClick={selectOffIcons}
				value={stateShowElements.cryptocurrencies}
			>
				{/* <TableDeprecated */}
				{/*	className={styles.elements__table} */}
				{/*	classNameTableRows={styles.elements__table__rows} */}
				{/*	type="frameless" */}
				{/*	nameSort="sort" */}
				{/*	valueSort="" */}
				{/*	onChangeSort={() => {}} */}
				{/*	nameOrder="order" */}
				{/*	valueOrder="asc" */}
				{/*	onChangeOrder={() => {}} */}
				{/*	namePage="page" */}
				{/*	valuePage={0} */}
				{/*	pageSize={22} */}
				{/*	onChangeSelect={onChangeSelect} */}
				{/*	onChangePage={() => {}} */}
				{/*	columns={columns} */}
				{/*	data={[]} */}
				{/*	isLoading={false} */}
				{/*	valueCount={0} */}
				{/* > */}
				{/*	/!* @ts-ignore *!/ */}
				{/*	{(props) => ( */}
				{/*		<TableRow */}
				{/*			// eslint-disable-next-line react/jsx-props-no-spreading */}
				{/*			{...props} */}
				{/*		/> */}
				{/*	)} */}
				{/* </TableDeprecated> */}
				<TableReact
					onClickRow={onClickRow}
					className={styles.elements__table}
					columns={columns}
					data={[]}
					tableRow={TableRow}
					type="frameless"
				/>
			</DehiscentElement>
		</div>
	);
};

export default null;
