import React from 'react';
import cx from 'classnames';

import send_button from '@base/assets/icons/buttons/send_button.svg';

import { Button } from '@base/components';
import styles from './index.module.scss';

type SendButtonProps = {
	className?: string,
	onClick: () => void,
	disabled?: boolean,
};

export const ButtonSend = ({
	className,
	onClick,
	disabled,
}: SendButtonProps) => (
	<Button
		className={cx(
			styles['send-button'],
			className,
		)}
		theme="primary"
		type="button"
		onClick={onClick}
		disabled={disabled}
	>
		<img src={send_button} alt="" />
	</Button>
);

export default null;
