import React, { useEffect } from 'react';
import {
	Switch,
	Route, Redirect,
} from 'react-router-dom';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import { Wallet } from '@client_broker/pages/Wallet';
import { getMenuPaths } from '@base/utils/getTabPaths';
import { DemoAccounts } from '@client_broker/pages/DemoAccounts';
import { MainLayout } from '@client_broker/layouts/MainLayout';
import { Profile } from '@client_broker/pages/Profile';
import { LiveAccounts } from '@client_broker/pages/LiveAccounts';
import { AccountHistory } from '@client_broker/pages/AccountHistory';
import { Security } from '@client_broker/pages/Security';
import { DownloadTerminals } from '@client_broker/pages/DownloadTerminals';
import { coreAction } from '@base/store/Core/actions';
import { useDispatch } from 'react-redux';
import { PartnerCabinet } from '@client_broker/pages/PartnerCabinet';
import { Documents } from '@client_broker/pages/Documents';
import { usePageInfo } from '@base/hooks/usePageInfo';

const ClientBrokerRouter = () => (
	<Switch>
		<Route path={clientBrokerRoutes.wallet.root} component={Wallet} />
		<Route
			path={clientBrokerRoutes['live-accounts'].history.rootParam}
			render={(props) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<AccountHistory {...props} accountType="live" />
			)}
		/>
		<Route
			path={clientBrokerRoutes['demo-accounts'].history.rootParam}
			render={(props) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<AccountHistory {...props} accountType="demo" />
			)}
		/>

		<Route path={clientBrokerRoutes['live-accounts'].root} component={LiveAccounts} />

		<Route path={clientBrokerRoutes['demo-accounts'].root} component={DemoAccounts} />
		<Route path={clientBrokerRoutes['download-terminals'].root} component={DownloadTerminals} />
		<Route path={clientBrokerRoutes.profile.root} component={Profile} />
		<Route path={clientBrokerRoutes.security.root} component={Security} />
		<Route path={clientBrokerRoutes.documents.root} component={Documents} />
		<Route path={clientBrokerRoutes['partner-cabinet'].root} component={PartnerCabinet} />
		<Route component={() => <Redirect to={clientBrokerRoutes.wallet.input.root} />} />
	</Switch>
);

const ClientBrokerWithRouter = () => {
	const dispatch = useDispatch();
	const menuLinksClientBroker = getMenuPaths(clientBrokerRoutes);
	usePageInfo(clientBrokerRoutes);

	useEffect(() => {
		dispatch(coreAction());
	}, [coreAction]);

	return (
		<MainLayout menuLinks={menuLinksClientBroker}>
			<ClientBrokerRouter />
		</MainLayout>
	);
};

export default ClientBrokerWithRouter;
