const formatPhoneNumber = (value: string): string => {
	let phoneNum = value;
	phoneNum = phoneNum.replace(/[^\d]/g, '');
	phoneNum = `+${phoneNum}`;
	if (phoneNum.length > 20) return phoneNum.slice(0, 21);
	return phoneNum;
};

export default {
	formatPhoneNumber,
};
