/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	Route,
	Redirect,
} from 'react-router-dom';

import { AuthState } from '@auth/types';

// OwnProps refers to the props that were passed down by the parent.
interface OwnProps {
	component: any,
	render?: any,
	children?: any,
	path?: string | Array<string>,
	exact?: boolean,
	strict?: boolean,
	location?: any,
	sensitive?: boolean,
	redirectTo: string
}

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
interface Props extends OwnProps {}

export const ConnectedRoute = ({
	component: Component,
	redirectTo,
	...rest
}: Props) => {
	const { isAuth } = useSelector((
		state: AuthState,
	) => state.base.authPersist);
	return (
		<Route
			{...rest}
			render={(props) => (
				!isAuth
					? <Component {...props} />
					: (
						<Redirect to={{
							pathname: redirectTo,
							state: {
								from: props.location,
							},
						}}
						/>
					)
			)}
		/>
		/* eslint-enable react/jsx-props-no-spreading */
	);
};

export default null;
