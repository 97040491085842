import { GetTranslationsCabinetReq, GetTranslationsCabinetResp } from '@base/types/base/translations';
import ApiProviderDefault from '../apiProviderDefault';

export default class TranslationsProvider extends ApiProviderDefault {
	static async getTranslationsCabinet(parameters: {
		params: GetTranslationsCabinetReq,
		headers: Headers,
	}): Promise<GetTranslationsCabinetResp> {
		let body;
		let headers;
		const query = {};
		const path = `/translations/cabinet/${parameters.params.lang}`;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request(path, 'GET', query, body, headers);
	}
}
