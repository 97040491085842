import React, { useEffect, useMemo, useState } from 'react';

import {
	Button, Select, Text, LoaderSpinner, Input,
} from '@base/components';
import { modalCloseAction } from '@base/store/Modal/actions';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import errorMessages from '@base/constants/errorMessages';
import { useFormik } from 'formik';
import regex from '@base/constants/regex';
import { Account, OptionType, Payment } from '@base/types';
import { ClientBrokerState } from '@client_broker/types';
import { paymentsDepositSendAction } from '@client_broker/store/Payments/actions';
import { useTranslation } from 'react-i18next';
import styles from './DepositingToAccountModal.module.scss';

type DepositingToAccountModalProps = {
	paymentMethod: Payment,
};

interface DepositingToAccountModalFieldsType {
	account: OptionType<Account> | undefined,
	currency: OptionType<string> | undefined,
	amount: string,
}

export const initialValues = {
	// account: { label: '', value: AccountDefault },
	account: undefined,
	currency: undefined,
	amount: '',
};

const validationSchema = object().shape({
	amount: string()
		.required(errorMessages.FIELD_IS_REQUIRED)
		.matches(
			regex.amount,
			errorMessages.INVALID_AMOUNT,
		),
});

// eslint-disable-next-line no-empty-pattern
export const DepositingToAccountModal = ({ paymentMethod }: DepositingToAccountModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [accountOptions, setAccountOptions] = useState<Array<OptionType<Account>>>([]);
	const [currenciesOptions, setCurrenciesOptions] = useState<Array<OptionType<string>>>([]);

	const {
		accounts,
	} = useSelector((state: ClientBrokerState) => state.clientBroker.customerAccounts);
	const {
		transferInternal,
	} = useSelector((state: ClientBrokerState) => state.clientBroker.transferInternal);
	const {
		depositSend,
	} = useSelector((state: ClientBrokerState) => state.clientBroker.payments);

	const {
		handleChange,
		handleBlur,
		// eslint-disable-next-line no-unused-vars
		handleSubmit,
		values,
		setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<DepositingToAccountModalFieldsType>({
		initialValues,
		onSubmit: (): any => () => {},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	useEffect(() => {
		const accountOptionsNew = accounts.live.map((account) => ({
			label: `${account.mt_id} | ${account.balance} | ${account.currency}`,
			value: account,
		}));
		if (accountOptionsNew.length !== 0) {
			setValues({
				...values,
				account: accountOptionsNew[0],
				currency: {
					value: accountOptionsNew[0].value.currency,
					label: accountOptionsNew[0].value.currency,
				},
			});
		}
		setAccountOptions(accountOptionsNew);
	}, [accounts.live]);

	useEffect(() => {
		if (values.account) {
			const currenciesOptionsNew: Array<OptionType<string>> = [{
				label: values.account.value.currency,
				value: values.account.value.currency,
			}];
			setValues({
				...values,
				currency: {
					value: values.account.value.currency,
					label: values.account.value.currency,
				},
			});
			setCurrenciesOptions(currenciesOptionsNew);
		}
	}, [values.account]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const event = {
				target: {	name,	value	},
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	const isLoading = useMemo(
		() => accounts.isLoading || transferInternal.isLoading || depositSend.isLoading,
		[accounts.isLoading, transferInternal.isLoading, depositSend.isLoading],
	);

	const onClick = () => {
		if (values.account && values.currency) {
			dispatch(paymentsDepositSendAction({
				amount: Number(values.amount),
				account_id: values.account.value.id,
				currency: values.currency.value,
				uid: paymentMethod.uid,
			}));
		}
	};

	return (
		<div className={styles.depositing_to_account}>
			<Text type="h3">Внесение средств на счёт</Text>
			<div className={styles.depositing_to_account__content}>
				{isLoading ? <LoaderSpinner />
					: (
						<>
							<Select
								labelName="№ счета"
								name="account"
								options={accountOptions}
								value={values.account?.value}
								className={styles.depositing_to_account__content__select}
								onChange={onChangeHandler}
							/>
							<Select
								labelName="Валюта"
								name="currencies"
								options={currenciesOptions}
								value={values.currency?.value}
								className={styles.depositing_to_account__content__select}
								onChange={onChangeHandler}
								disabled={currenciesOptions.length < 2}
							/>
							<Input
								label="Сумма"
								name="amount"
								required
								value={values.amount}
								onChange={onChangeHandler}
								onBlur={handleBlur}
								error={
									(touched.amount !== undefined && errors.amount !== undefined)
										? errors.amount || ''
										: undefined
								}
							/>
						</>
					)}
			</div>
			<div className={styles.depositing_to_account__btns}>
				<Button
					className={styles.depositing_to_account__btns__cancel}
					type="button"
					theme="secondary"
					size="large"
					onClick={() => { dispatch(modalCloseAction()); }}
				>
					Отмена
				</Button>
				<Button
					type="button"
					theme="primary"
					size="large"
					onClick={onClick}
					disabled={!isValid || isLoading}
				>
					Внести средства
				</Button>
			</div>
		</div>
	);
};
export default null;
