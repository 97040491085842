import React from 'react';
import { useLocation } from 'react-router-dom';

type BreadCrumbsPaths = {
	path: string,
	label: string,
	tKeyRoot: string,
};

export const useNavigation = (moduleRoutes: any) => {
	const {
		pathname,
	} = useLocation();
	const baseUrl = moduleRoutes.root.replace(/\//g, '');
	return React.useMemo<[Array<BreadCrumbsPaths>, string]>(() => {
		const pathParts = pathname.split('/')
			.slice(1)
			.filter((path) => path);
		let title: string = '';
		let crumbsPaths: any = [];

		pathParts.reduce((acc: any, part: any) => {
			const {
				routes,
			} = acc;

			if (baseUrl === part) {
				crumbsPaths = [];
				crumbsPaths.push({
					path: moduleRoutes.root,
					label: moduleRoutes.title,
					tKeyRoot: moduleRoutes.tKeyRoot,
				});
				return {
					path: moduleRoutes.root,
					routes: moduleRoutes,
					tKeyRoot: moduleRoutes.tKeyRoot,
				};
			}

			const newRoutes = routes[part];

			if (newRoutes === undefined && routes.rootParam) {
				// return;
				crumbsPaths.push({
					path: routes.root,
					label: routes.title,
					tKeyRoot: routes.tKeyRoot,
				});

				title = routes.title;

				return {
					path: routes.root,
					routes,
					tKeyRoot: routes.tKeyRoot,
				};
			}

			if (newRoutes !== undefined) {
				crumbsPaths.push({
					path: newRoutes.root,
					label: newRoutes.title,
					tKeyRoot: newRoutes.tKeyRoot,
				});

				title = newRoutes.title;

				return {
					path: newRoutes.root,
					routes: newRoutes,
					tKeyRoot: newRoutes.tKeyRoot,
				};
			}

			title = '';
			crumbsPaths = [];
			return {
				path: '/',
				routes: {},
				tKeyRoot: undefined,
			};
		}, {
			path: '/',
			routes: moduleRoutes,
			tKeyRoot: moduleRoutes.tKeyRoot,
		});

		return [
			crumbsPaths,
			title,
		];
	}, [
		pathname,
		baseUrl,
	]);
};

export default null;
