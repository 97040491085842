import {
	call, put, takeLeading, select,
} from 'redux-saga/effects';

import {
	customerSuccessAction,
	customerFailAction,
	customerUpdateSuccessAction,
	customerUpdateFailAction,
	customerPasswordChangeSuccessAction,
	customerPasswordChangeFailAction,
} from '@client_broker/store/Customer/actions';
import * as customerActionTypes from '@client_broker/store/Customer/constants';

import {
	BaseState, GetCustomerResp, PatchCustomerResp, postChangePasswordResp,
} from '@base/types';
import { apiRequestClientBroker } from '@base/providers/sagas';
import {
	CustomerPasswordChangeActionType,
	CustomerUpdateActionType,
} from '@client_broker/store/Customer/types';
import { authPersistLogout } from '@base/store/AuthPersist/actions';
import { errorHandlerAction } from '@base/store/ErrorHandler/actions';
import { securityPasswordEnteredIncorrectlyAction } from '@base/store/UserActionsInfoPersist/actions';
import { notificationsModalOpenAction } from '@base/store/NotificationsModal/actions';
import { OnClickParamOfNotificationsModalType } from '@base/store/NotificationsModal/types';

function* getCustomerSaga() {
	try {
		const resp: GetCustomerResp = yield call(apiRequestClientBroker, 'getCustomer');
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(customerSuccessAction(resp.data));
	} catch (e) {
		console.log(e);
		yield put(customerFailAction({ error: (e as Error).message }));
	}
}

function* patchCustomerSaga({ payload }: CustomerUpdateActionType) {
	try {
		const resp: PatchCustomerResp = yield call(
			apiRequestClientBroker,
			'patchCustomer',
			{
				body: {
					...payload,
				},
			},
		);
		if (!resp.success) {
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		}
		yield put(customerUpdateSuccessAction());
	} catch (e) {
		console.log(e);
		yield put(customerUpdateFailAction({ error: (e as Error).message }));
	}
}

function* patchCustomerPasswordChangeSaga({
	payload,
}: CustomerPasswordChangeActionType) {
	try {
		const { password, password_new, password_new_again } = payload;
		const {
			userActionsInfoPersist: {
				securityPasswordEnteredIncorrectly,
			},
			pageInfo,
		} = yield select((state: BaseState) => state.base);
		const resp: postChangePasswordResp = yield call(
			apiRequestClientBroker,
			'postChangePassword',
			{
				body: {
					password,
					password_new,
					password_new_again,
				},
			},
		);
		if (!resp.success && resp.error === 'INCORRECT_CURRENT_PASSWORD' && securityPasswordEnteredIncorrectly.count > 3) {
			// не менял ключи ниже, т.к. нет текстов
			yield put(notificationsModalOpenAction({
				labelTKey: pageInfo.labelTKey,
				messageTKey: 'CLIENT.SECURITY.ERRORS.LOCAL_WRONG_PASS_ENTERED_TOO_MANY.NAME',
				descriptionTKey: 'CLIENT.SECURITY.ERRORS.LOCAL_WRONG_PASS_ENTERED_TOO_MANY.DESC.DEFAULT',
				type: 'error',
				onClick: ({ dispatch }: OnClickParamOfNotificationsModalType) => {
					dispatch(authPersistLogout());
				},
				onClickClose: ({ dispatch }: OnClickParamOfNotificationsModalType) => {
					dispatch(authPersistLogout());
				},
			}));
		} else if (!resp.success) {
			yield put(securityPasswordEnteredIncorrectlyAction());
			yield put(errorHandlerAction({ ...resp }));
			throw new Error(resp.error);
		} else {
			yield put(notificationsModalOpenAction({
				labelTKey: pageInfo.labelTKey,
				messageTKey: 'AUTH.PASSWORD_RECOVERY.PASSWORD_CHANGED_SUCCESS',
				type: 'success',
				onClick: ({ dispatch }: OnClickParamOfNotificationsModalType) => {
					dispatch(authPersistLogout());
				},
				onClickClose: ({ dispatch }: OnClickParamOfNotificationsModalType) => {
					dispatch(authPersistLogout());
				},
			}));
			yield put(customerPasswordChangeSuccessAction());
			// yield delay(5000);
			// yield put(authPersistLogout());
		}
	} catch (e) {
		console.log(e);
		yield put(customerPasswordChangeFailAction({ error: (e as Error).message }));
	}
}

export function* customerWatcher() {
	yield takeLeading(
		customerActionTypes.CUSTOMER_REQUEST,
		getCustomerSaga,
	);
	yield takeLeading(
		customerActionTypes.CUSTOMER_UPDATE_REQUEST,
		patchCustomerSaga,
	);
	yield takeLeading(
		customerActionTypes.CUSTOMER_PASSWORD_CHANGE_REQUEST,
		patchCustomerPasswordChangeSaga,
	);
}
