import ApiProviderDefault from '@base/providers/apiProviderDefault';
import {
	GetCustomerResp,
	PatchCustomerReq,
	PatchCustomerResp,
	postChangePasswordReq,
	postChangePasswordResp,
	PostConfirmMailResp,
	PostConfirmMailReq,
	PatchCustomerRestoreReq,
	PatchCustomerRestoreResp,
	PostConfirmResp,
	PostConfirmReq,
	PostCustomerCheckPassTokenResp,
	PostCustomerCheckPassTokenReq,
	PostCustomerRestoreReq,
	PostCustomerRestoreResp,
} from '@base/types';

export default class CustomerProvider extends ApiProviderDefault {
	static async getCustomer(parameters: {
		headers: Headers,
	}): Promise<GetCustomerResp> {
		let body;
		let headers;
		const query = {};
		const path = '/customer';

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request(path, 'GET', query, body, headers);
	}

	static async patchCustomer(parameters: {
		body: PatchCustomerReq,
		headers: Headers,
	}): Promise<PatchCustomerResp> {
		let body;
		let headers;
		const query = {};
		const path = '/customer';

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		if (parameters.body !== undefined) {
			body = parameters.body;
		}

		return this.request(path, 'PATCH', query, body, headers);
	}

	static async postChangePassword(parameters: {
		body: postChangePasswordReq,
		headers: Headers,
	}): Promise<postChangePasswordResp> {
		let body;
		let headers;
		const query = {};
		const path = '/change-password';

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		if (parameters.body !== undefined) {
			body = parameters.body;
		}

		return this.request(path, 'POST', query, body, headers);
	}

	static async postConfirmMail(parameters: {
		body: PostConfirmMailReq,
		headers: Headers,
	}) {
		const path = '/customer/confirm-mail';
		let headers;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const { body } = parameters;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}
		return this.request<PostConfirmMailReq, PostConfirmMailResp>(path, 'POST', query, body, headers);
	}

	static async postConfirm(parameters: {
		body: PostConfirmReq,
		headers: Headers,
	}) {
		const path = '/customer/confirm';
		let headers;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const { body } = parameters;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}
		return this.request<PostConfirmReq, PostConfirmResp>(path, 'POST', query, body, headers);
	}

	static async postCustomerRestore(parameters: {
		body: PostCustomerRestoreReq,
	}) {
		const path = '/customer/restore';
		let body;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}

		if (parameters.body !== undefined) {
			body = parameters.body;
		}

		return this.request<PostCustomerRestoreReq, PostCustomerRestoreResp>(path, 'POST', query, body);
	}

	static async patchCustomerRestore(parameters: {
		body: PatchCustomerRestoreReq,
	}): Promise<PatchCustomerRestoreResp> {
		let body;
		const query = {};
		const path = '/customer/restore';

		if (parameters.body !== undefined) {
			body = parameters.body;
		}

		return this.request(path, 'PATCH', query, body);
	}

	static async postCustomerCheckPassToken(parameters: {
		body: PostCustomerCheckPassTokenReq,
	}) {
		const path = '/customer/check-pass-token';
		let body;
		const query = {};
		if (parameters.body === undefined) {
			throw new Error('Missing required  parameter: body');
		}

		if (parameters.body !== undefined) {
			body = parameters.body;
		}

		return this.request<PostCustomerCheckPassTokenReq, PostCustomerCheckPassTokenResp>(path, 'POST', query, body);
	}
}

// @ts-ignore
CustomerProvider.postConfirmMail.security = true;
// @ts-ignore
CustomerProvider.getCustomer.security = true;
// @ts-ignore
CustomerProvider.patchCustomer.security = true;
// @ts-ignore
CustomerProvider.postChangePassword.security = true;
