const second = 1000;
const minute = 60000;
const hour = 3600000;
const day = 86400000;
const week = 604800000;

export const DateValues = {
	second,
	minute,
	hour,
	day,
	week,
};

export const DateInMilliseconds = {
	second: second * 1000,
	minute: minute * 1000,
	hour: hour * 1000,
	day: day * 1000,
	week: week * 1000,
};
