import { OpenMenuIcon } from '@base/assets/icons/header/open_menu';
import { ProfileDesktopIcon } from '@base/assets/icons/header/profile_desktop';

import profile_desktop from './profile_desktop.svg';
import profile_mobile from './profile_mobile.svg';
import open_menu from './open_menu.svg';

export const HeaderIcons: { [key: string]: any; } = {
	open_menu: OpenMenuIcon,
	profile_desktop: ProfileDesktopIcon,
};

export default {
	profile_desktop,
	profile_mobile,
	open_menu,
};
