/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
	Redirect,
	Route,
	Switch,
	useHistory, useLocation,
} from 'react-router-dom';

import { ConnectedRoute } from '@base/containers/PrivateRoute/ConnectedRoute';
import { PrivateRoute } from '@base/containers/PrivateRoute';

import { AuthLayoutWithRouter } from '@auth/routes';
import authRoutes from '@auth/constants/authRoutes';
import partnersRegistrationRoutes from '@auth/constants/partnersRegistrationRoutes';
import modulesRoutes from '@base/constants/routesModules';
import ClientBrokerWithRouter from '@client_broker/routes';
import { PartnerRegistration } from '@auth/pages/PartnerRegistration';
import { AuthLayout } from '@auth/layouts/AuthLayout';
import { getAndDeleteQueryParamsByNames } from '@base/utils/queryRarams';
import { useDispatch, useSelector } from 'react-redux';
import {
	referralGetAction,
	referralSetAction,
	setReferralCodeAction,
} from '@base/store/Referral/actions';
import { selectLanguageRequestAction } from '@base/store/TranslationsPersist/actions';
import { ClientBrokerState } from '@client_broker/types';

export const BaseRouter = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const { referral: { referralCode }, authPersist: { token } } = useSelector(
		(state: ClientBrokerState) => state.base,
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		const {
			lang, referral, bid,
		} = getAndDeleteQueryParamsByNames([
			'lang', 'referral', 'bid',
		], location, history);
		if (lang) {
			dispatch(selectLanguageRequestAction({ tag: lang, label: lang }));
		}

		if (referralCode.ref_code) return;
		if (token) return;

		// eslint-disable-next-line no-unused-vars
		// const doActionWithReferral = () => {
		// 	if (referral) {
		// 		dispatch(setReferralCodeAction({ ref_code: referral, isHave: true }));
		// 		dispatch(referralSetAction({ ref_code: referral, bid: bid || undefined }));
		// 	} else {
		// 		dispatch(referralGetAction());
		// 	}
		// };

		const setReferral = () => {
			if (referral) {
				dispatch(setReferralCodeAction({ ref_code: referral, isHave: true }));
				dispatch(referralSetAction({ ref_code: referral, bid: bid || undefined }));
			}
		};

		switch (location.pathname) {
			case authRoutes.auth.root:
				setReferral();
				break;
			case authRoutes.auth.registration.root:
			case partnersRegistrationRoutes['partner-registration'].root:
				setReferral();
				if (!referral) dispatch(referralGetAction());
				break;
			default:
				break;
		}
	}, [location, token]);

	return (
		<Switch>
			<ConnectedRoute
				path={authRoutes.auth.root}
				redirectTo={modulesRoutes.client.root}
				component={AuthLayoutWithRouter}
			/>

			<PrivateRoute
				path={modulesRoutes.client.root}
				redirectTo={authRoutes.auth.root}
				component={ClientBrokerWithRouter}
			/>

			<Route
				path={partnersRegistrationRoutes['partner-registration'].root}
				render={(props) => (
					<AuthLayout>
						{/* @ts-ignore */}
						<PartnerRegistration {...props} />
					</AuthLayout>
				)}
			/>

			<Route component={() => <Redirect to={modulesRoutes.client.root} />} />
		</Switch>
	);
};

export default null;
