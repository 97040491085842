import * as countriesTypes from '@base/store/Countries/constants';
import { CountryType, OptionType } from '@base/types';

export const countriesAction = () => ({
	type: countriesTypes.BASE_COUNTRIES_REQUEST,
});

export const countriesSuccessAction = (payload: {
	data: Array<CountryType>,
	options: Array<OptionType<CountryType>>,
}) => ({
	type: countriesTypes.BASE_COUNTRIES_SUCCESS,
	payload,
});

export const countriesFailAction = (payload: { error: string }) => ({
	type: countriesTypes.BASE_COUNTRIES_FAIL,
	payload,
});

export default null;
