import React from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@base/components';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import styles from './ReplenishBalance.module.scss';

type ReplenishBalanceProps = {
	className?: string,
}
// eslint-disable-next-line no-unused-vars
export const ReplenishBalance = ({ className }: ReplenishBalanceProps) => {
	const { t } = useTranslation();
	return (
		<ButtonLink
			theme="primary"
			to={clientBrokerRoutes.wallet.root}
			onClick={() => {}}
		>
			<span className={styles.plus}>+</span>
			{t('CLIENT.HEADER.REPLENISH_BALANCE_BUTTON')}
		</ButtonLink>
	);
};

export default null;
