import {
	call,
	put,
	takeLeading,
} from 'redux-saga/effects';

import {
	systemSuccessAction,
	systemFailAction,
} from '@base/store/System/actions';
import * as systemTypes from '@base/store/System/constants';
import { apiRequestClientBroker } from '@base/providers/sagas';
import { GetSystemLimitsResp } from '@base/types/provider/base/system';

function* systemSaga() {
	try {
		const resp: GetSystemLimitsResp = yield call(apiRequestClientBroker, 'getSystemLimits');
		if (resp.success) {
			yield put(systemSuccessAction());
		} else if (
			resp.error !== null
			&& resp.error !== undefined
			&& resp.error !== ''
		) {
			throw new Error(resp.error);
		} else {
			throw new Error('Unknown error');
		}
	} catch (err) {
		yield put(systemFailAction({ error: err.message }));
	}
}

export function* systemWatcher() {
	yield takeLeading(systemTypes.BASE_SYSTEM_REQUEST, systemSaga);
}
