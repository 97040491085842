import * as verificationEmailTypes from '@auth/store/VerificationEmail/constants';
import type { VerificationEmailActionsType } from '@auth/store/VerificationEmail/types';
import { RequestInfoChildType } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type VerificationEmailState = {
  emailCheck: RequestInfoChildType;
  emailResend: RequestInfoChildType;
};

const initialState: VerificationEmailState = {
	emailCheck: RequestInfoChildState,
	emailResend: RequestInfoChildState,
};

export const verificationEmailReducer = (
	state: VerificationEmailState = initialState,
	action: VerificationEmailActionsType,
): VerificationEmailState => {
	switch (action.type) {
		case verificationEmailTypes.VERIFICATION_EMAIL_CHECK_REQUEST: {
			return {
				...state,
				emailCheck: {
					...state.emailCheck,
					isLoading: true,
				},
			};
		}

		case verificationEmailTypes.VERIFICATION_EMAIL_CHECK_SUCCESS: {
			return {
				...state,
				emailCheck: {
					...state.emailCheck,
					isLoading: false,
				},
			};
		}

		case verificationEmailTypes.VERIFICATION_EMAIL_CHECK_FAIL: {
			const { payload } = action;
			return {
				...state,
				emailCheck: {
					...state.emailCheck,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		//---------------------------------------

		case verificationEmailTypes.VERIFICATION_EMAIL_RESEND_REQUEST: {
			return {
				...state,
				emailCheck: {
					...state.emailCheck,
					isLoading: true,
				},
			};
		}

		case verificationEmailTypes.VERIFICATION_EMAIL_RESEND_SUCCESS: {
			return {
				...state,
				emailCheck: {
					...state.emailCheck,
					isLoading: false,
				},
			};
		}

		case verificationEmailTypes.VERIFICATION_EMAIL_RESEND_FAIL: {
			const { payload } = action;
			return {
				...state,
				emailCheck: {
					...state.emailCheck,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
