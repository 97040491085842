import React from 'react';

import { LoginForm } from '@auth/containers/login/LoginForm';
import { AuthFormLayout } from '@auth/layouts/AuthFormLayout';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import styles from './Login.module.scss';

export function Login() {
	const { t } = useTranslation();

	return (
		<AuthFormLayout text={t('AUTH.LOGIN.TITLE')}>
			<LoginForm />
		</AuthFormLayout>
	);
}

export default null;
