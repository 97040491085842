/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';

import { ButtonText, LabelForm, Text } from '@base/components';

import authRoutes from '@auth/constants/authRoutes';
import { authPersistLogout } from '@base/store/AuthPersist/actions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { modalOpenAction } from '@base/store/Modal/actions';
import { AuthInfoModal } from '@auth/modals/AuthInfoModal';
import { BaseState } from '@base/types';
import styles from './AuthFormLayout.module.scss';

const SymbolComponent = ({ onClick, to }: { onClick?: () => void, to?: string }) => {
	const getSymbolComponent = () => (
		<div className={styles.symbol_wrap}>
			<span
				className={styles.symbol}
				onClick={() => onClick && onClick()}
			>
				&#215;
			</span>
		</div>
	);

	return (
		to ? (
			<NavLink to={to}>
				{getSymbolComponent()}
			</NavLink>
		) : getSymbolComponent()
	);
};

type AuthFormLayoutProps = {
	text: string,
	children: React.ReactNode,
};

export function AuthFormLayout({ children, text }: AuthFormLayoutProps) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		pageInfo: { tKeyRoot },
	} = useSelector((state: BaseState) => state.base);

	const location = useLocation();

	const symbolComponent = useMemo(() => {
		switch (location.pathname) {
			case authRoutes.auth.root:
				return (
					<a href="https://esp-ms.com/">
						<SymbolComponent />
					</a>
				);
			default:
				return (
					<SymbolComponent
						to={authRoutes.auth.root}
						onClick={() => dispatch(authPersistLogout())}
					/>
				);
		}
	}, []);

	const isShowIcon = () => (
		t(`${tKeyRoot}.INFO`) !== ''
			&& !t(`${tKeyRoot}.INFO`).includes(tKeyRoot)
	);

	return (
		<div className={styles.auth_form_layout}>
			<>
				{symbolComponent}
			</>
			<>
				<div>
					<LabelForm
						className={styles.label}
						text={text}
						isShowIcon={isShowIcon()}
						onClick={() => {
							dispatch(modalOpenAction(<AuthInfoModal />));
						}}
					/>
					{children}
				</div>
				<Text type="h5" className={styles.support_text}>
					{t('AUTH.SUPPORT.TEXT')}
					<ButtonText
						color="primary"
						onClick={() => window.location.assign(`mailto:${t('AUTH.SUPPORT.EMAIL')}`)}
					>
						{t('AUTH.SUPPORT.EMAIL')}
					</ButtonText>
					{' '}
					{t('AUTH.SUPPORT.CHAT')}
				</Text>
			</>
		</div>
	);
}

export default null;
