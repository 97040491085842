import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import { object } from 'yup';
import {
	Button, Checkbox, Link, LoaderSpinner, Text,
	Input, ButtonLink,
} from '@base/components';

import { registrationAction } from '@auth/store/Registration/actions';

import authRoutes from '@auth/constants/authRoutes';
import { useTranslation } from 'react-i18next';
import { ButtonText } from '@base/components/ButtonText';
import { AuthState } from '@auth/types';
import { authPersistLogout } from '@base/store/AuthPersist/actions';
import {
	emailYup, firstnameYup, lastnameYup, passwordYup, referralYup,
} from '@base/utils/yup';
import { getInfoInput } from '@base/utils/inputFunctions';
import cx from 'classnames';
import { ClientBrokerState } from '@client_broker/types';
import generate from '@base/utils/generate';
import styles from './RegistrationForm.module.scss';

type RegistrationFormProps = {};

export interface RegistrationFormFieldsType {
	first_name: string,
	last_name: string,
	email: string,
	password: string,
	referral: string,
}

export const initialValues = {
	first_name: '',
	last_name: '',
	email: '',
	password: '',
	referral: '',
};

// eslint-disable-next-line no-empty-pattern
export const RegistrationForm = ({}: RegistrationFormProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { isLoading } = useSelector((
		state: AuthState,
	) => state.auth.registration.registration);
	const { referralCode } = useSelector(
		(state: ClientBrokerState) => state.base.referral,
	);

	const [showInputReferral, setShowInputReferral] = useState(false);
	const [isCustomerAgreementAccepted, setIsCustomerAgreementAccepted] = useState(false);

	const validationSchema = object().shape({
		first_name: firstnameYup(undefined, 'AUTH.REGISTRATION'),
		last_name: lastnameYup(undefined, 'AUTH.REGISTRATION'),
		email: emailYup(undefined, 'AUTH.REGISTRATION'),
		password: passwordYup(undefined, 'AUTH.REGISTRATION'),
		// referral: referralYup(undefined, 'MODULES.AUTH.REGISTRATION.REG.INPUTS'),
		referral: referralYup(),
	});

	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<RegistrationFormFieldsType>({
		initialValues,
		onSubmit: (formData: any): any => dispatch(registrationAction(formData)),
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	useEffect(() => {
		if (referralCode.isHave) {
			setValues({
				...values,
				referral: referralCode.ref_code,
			});
		}
	}, [referralCode.ref_code, referralCode.isHave]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			let valueNew = value;
			// eslint-disable-next-line default-case
			switch (name) {
				case 'first_name':
				case 'last_name':
					if (value.length > 40) return;
					break;
				case 'email':
					if (value.length > 320) return;
					break;
				case 'password':
					if (value.length > 30) return;
					break;
				case 'referral':
					if (value.length > 32) return;
					valueNew = value.replace(/[^\da-zA-Z]/g, '');
					break;
			}
			const event = {
				target: { name, value: valueNew },
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	const generatePassword = () => {
		const password: any = generate.generateMultiplePassword();
		setValues({
			...values,
			password: `${password}`,
		});
	};

	return (
		<div
			className={styles.registration_form}
		>
			{isLoading && <LoaderSpinner />}
			<Input
				className={styles.input}
				label={t('AUTH.REGISTRATION.FIRSTNAME_LABEL')}
				placeholder={t('AUTH.REGISTRATION.FIRSTNAME_PLACEHOLDER')}
				name="first_name"
				required
				value={values.first_name}
				onChange={onChangeHandler}
				onBlur={(event) => {
					handleBlur(event);
					setTouched({
						...touched,
						first_name: false,
					});
				}}
				onFocus={handleBlur}
				info={getInfoInput({
					name: 'first_name',
					text: t('AUTH.REGISTRATION.FIRSTNAME_HINT'),
					touched,
					errors,
					values,
				})}
				disabled={isLoading}
			/>
			<Input
				className={styles.input}
				label={t('AUTH.REGISTRATION.LASTNAME_LABEL')}
				placeholder={t('AUTH.REGISTRATION.LASTNAME_PLACEHOLDER')}
				name="last_name"
				required
				value={values.last_name}
				onChange={onChangeHandler}
				onBlur={(event) => {
					handleBlur(event);
					setTouched({
						...touched,
						last_name: false,
					});
				}}
				onFocus={handleBlur}
				info={getInfoInput({
					name: 'last_name',
					text: t('AUTH.REGISTRATION.LASTNAME_HINT'),
					touched,
					errors,
					values,
				})}
				disabled={isLoading}
			/>
			<Input
				className={styles.input}
				label={t('AUTH.REGISTRATION.EMAIL_LABEL')}
				placeholder={t('AUTH.REGISTRATION.EMAIL_PLACEHOLDER')}
				name="email"
				required
				value={values.email}
				onChange={onChangeHandler}
				onBlur={(event) => {
					handleBlur(event);
					setTouched({
						...touched,
						email: false,
					});
				}}
				onFocus={handleBlur}
				info={getInfoInput({
					name: 'email',
					text: t('AUTH.REGISTRATION.EMAIL_HINT'),
					touched,
					errors,
					values,
				})}
				disabled={isLoading}
			/>
			<Input
				className={styles.input}
				label={t('AUTH.REGISTRATION.PASSWORD_LABEL')}
				// placeholder={t('AUTH.REGISTRATION.PASSWORD_PLACEHOLDER')}
				name="password"
				required
				type="password"
				value={values.password}
				onChange={onChangeHandler}
				onBlur={(event) => {
					handleBlur(event);
					setTouched({
						...touched,
						password: false,
					});
				}}
				onFocus={handleBlur}
				info={getInfoInput({
					name: 'password',
					text: t('AUTH.REGISTRATION.PASSWORD_HINT'),
					touched,
					errors,
					values,
				})}
				textAction={{
					text: t('AUTH.REGISTRATION.GENERATE_BUTTON'),
					value: '',
					type: 'button',
					onClick: () => generatePassword(),
				}}
				disabled={isLoading}
			/>
			{referralCode.isHave === false && (showInputReferral ? (
				<Input
					className={styles.input}
					label={t('AUTH.REGISTRATION.REFERRAL_LABEL')}
					// placeholder={t('.AUTH.REGISTRATION.REFERRAL_PLACEHOLDER')} // нет плейсхолдера
					name="referral"
					value={values.referral}
					onChange={onChangeHandler}
					onBlur={(event) => {
						handleBlur(event);
						setTouched({
							...touched,
							referral: false,
						});
					}}
					onFocus={handleBlur}
					info={getInfoInput({
						name: 'referral',
						// text: t('MODULES.AUTH.REGISTRATION.REG.INPUTS.REFERRAL.HINTS.DEFAULT'),
						text: '', // нет подсказки для реф кода
						touched,
						errors,
						values,
					})}
					disabled={isLoading}
				/>
			) : (
				<ButtonText
					className={styles.input}
					onClick={() => setShowInputReferral(true)}
					// className={styles.registration_form__text}
					text={t('AUTH.REGISTRATION.THERE_IS_REFERRAL_CODE')}
					underscore
				/>
			))}
			<div className={styles.registration_form__checkbox_wrap}>
				<Checkbox
					label=""
					name="isCustomerAgreementAccepted"
					value={isCustomerAgreementAccepted}
					onChange={() => setIsCustomerAgreementAccepted(!isCustomerAgreementAccepted)}
					disabled={isLoading}
				/>
				<Text className={styles.registration_form__checkbox_wrap__text}>
					{t('AUTH.REGISTRATION.CUSTOMER_AGREEMENT_1')}
					<Link
						to={t('AUTH.REGISTRATION.CLIENT_AGREEMENT_LINK')}
						className={cx(
							styles.registration_form__checkbox_wrap__text,
							styles.registration_form__checkbox_wrap__text__link,
						)}
						target="_blank"
					>
						{t('AUTH.REGISTRATION.CUSTOMER_AGREEMENT_2')}
					</Link>
				</Text>
			</div>
			<Button
				size="large"
				type="submit"
				theme="primary"
				onClick={() => handleSubmit()}
				disabled={!isCustomerAgreementAccepted || !isValid || isLoading}
				className={styles.registration_form__button}
			>
				{t('AUTH.REGISTRATION.REGISTER_BUTTON')}
			</Button>
			{window.history.state && (
				<ButtonLink
					to={authRoutes.auth.root}
					type="submit"
					theme="secondary"
					size="large"
					className={styles.registration_form__button}
					onClick={() => dispatch(authPersistLogout())}
				>
					{t('OTHER.CANCEL_BUTTON')}
				</ButtonLink>
			)}
		</div>
	);
};

export default null;
