import * as verificationEmailTypes from '@auth/store/VerificationEmail/constants';
import { PostConfirmMailReq } from '@base/types';

export const verificationEmailCheckAction = (payload: PostConfirmMailReq) => ({
	type: verificationEmailTypes.VERIFICATION_EMAIL_CHECK_REQUEST,
	payload,
});

export const verificationEmailCheckSuccessAction = () => ({
	type: verificationEmailTypes.VERIFICATION_EMAIL_CHECK_SUCCESS,
});

export const verificationEmailCheckFailAction = (payload: { error: string }) => ({
	type: verificationEmailTypes.VERIFICATION_EMAIL_CHECK_FAIL,
	payload,
});

//---------------------------------------

export const verificationEmailResendAction = () => ({
	type: verificationEmailTypes.VERIFICATION_EMAIL_RESEND_REQUEST,
});

export const verificationEmailResendSuccessAction = () => ({
	type: verificationEmailTypes.VERIFICATION_EMAIL_RESEND_SUCCESS,
});

export const verificationEmailResendFailAction = (payload: { error: string }) => ({
	type: verificationEmailTypes.VERIFICATION_EMAIL_RESEND_FAIL,
	payload,
});

export default null;
