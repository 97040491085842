import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Button,
	LoaderSpinner,
	Text,
	// Timer,
	ButtonLink, Input,
} from '@base/components';

import authRoutes from '@auth/constants/authRoutes';
import { useTranslation } from 'react-i18next';

import { object, string } from 'yup';
import regex from '@base/constants/regex';
import { useFormik } from 'formik';
import { authPersistLogout } from '@base/store/AuthPersist/actions';
import { BaseState } from '@base/types';
import {
	verificationEmailCheckAction,
	// verificationEmailResendAction,
} from '@auth/store/VerificationEmail/actions';
// import { customerUpdateAction } from '@client_broker/store/Customer/actions';
import { ClientBrokerState } from '@client_broker/types';
import { AuthState } from '@auth/types';
// import { getInfoInput } from '@base/utils/inputFunctions';
import { InputEdit } from '@base/components/InputEdit';
import styles from './VerificationEmailForm.module.scss';

interface OwnProps {
}

type TimerSendingLetterProps = {} & OwnProps;

export interface TimerSendingLetterFieldsType {
	code: string,
}

export const initialValues = {
	code: '',
};

// const secondDefault = 299;
// const secondDefault = 10;

// eslint-disable-next-line no-empty-pattern
export const VerificationEmailForm = ({}: TimerSendingLetterProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { userData } = useSelector((
		state: BaseState,
	) => state.base.authPersist);
	const customerUpdate = useSelector((
		state: ClientBrokerState,
	) => state.clientBroker.customer.update);
	const { emailCheck } = useSelector((
		state: AuthState,
	) => state.auth.verificationEmail);

	// const [seconds, setSeconds] = useState<number>(secondDefault);

	const requiredText = t('OTHER.FIELD_IS_REQUIRED');

	const validationSchema = object().shape({
		code: string()
			.required(requiredText)
			.matches(
				regex.codeEmail,
				t('AUTH.REGISTRATION.VERIFICATION_EMAIL.CODE_ERROR_VALIDATION'),
			),
	});

	const {
		handleChange,
		handleBlur,
		values,
		// errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<TimerSendingLetterFieldsType>({
		initialValues,
		onSubmit: () => {},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	// useEffect(() => {
	// 	if (!userData.email) return;
	//
	// 	setValues({
	// 		...values,
	// 		email: userData.email,
	// 	});
	// }, [userData]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			let target = { name, value };
			switch (name) {
				case 'code':
					if (value.length > 6) return;
					target = { name, value: value.replace(/[^\d]/g, '') };
					break;
				default:
					break;
			}
			const event = {
				target,
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	// eslint-disable-next-line no-unused-vars
	// const onSave = ({ name, value }: { name: string, value: string | number }) => {
	// 	dispatch(customerUpdateAction(
	// 		{ [name]: value },
	// 	));
	// 	setSeconds(0);
	// };

	const sendCode = () => {
		dispatch(verificationEmailCheckAction({ code: Number(values.code) }));
	};

	const isLoading = useMemo(() => (
		customerUpdate.isLoading || emailCheck.isLoading
	), [customerUpdate.isLoading, emailCheck.isLoading]);

	return (
		<>
			{isLoading && <LoaderSpinner />}
			<div className={styles.verification_email}>
				<Text type="h4">
					{t('AUTH.REGISTRATION.VERIFICATION_EMAIL.DESCRIPTION')}
				</Text>
				{/* <InputEdit */}
				{/* <Input */}
				{/*	className={styles.input} */}
				{/*	label={t('other.inputs.email.label')} */}
				{/*	name="email" */}
				{/*	value={values.email} */}
				{/*	// onChange={onChangeHandler} */}
				{/*	onChange={() => {}} */}
				{/*	// onBlur={handleBlur} */}
				{/*	// onSave={onSave} */}
				{/*	// error={ */}
				{/*	// 	(touched.email !== undefined && errors.email !== undefined) */}
				{/*	// 		? errors.email || '' */}
				{/*	// 		: undefined */}
				{/*	// } */}
				{/*	disabled */}
				{/* /> */}
				<InputEdit
					label={t('AUTH.REGISTRATION.VERIFICATION_EMAIL.EMAIL_LABEL')}
					// label="email"
					value={userData.email || ''}
				/>
				<Input
					className={styles.input}
					label={t('AUTH.REGISTRATION.VERIFICATION_EMAIL.CODE_LABEL')}
					// placeholder={t('AUTH.REGISTRATION.VERIFICATION_EMAIL.CODE_PLACEHOLDER')}
					placeholder="" // нет плейсхолдера
					name="code"
					value={values.code}
					onChange={onChangeHandler}
					onBlur={(event) => {
						handleBlur(event);
						setTouched({
							...touched,
							code: false,
						});
					}}
					onFocus={handleBlur}
					// info={getInfoInput({
					// 	name: 'code',
					// 	text: t('MODULES.AUTH.registration.verification_email.inputs.code.HINTS.DEFAULT'),
					// 	touched,
					// 	errors,
					// 	values,
					// })}
					disabled={isLoading}
				/>
				<Button
					size="large"
					type="submit"
					theme="primary"
					onClick={sendCode}
					disabled={!isValid || customerUpdate.isLoading || emailCheck.isLoading}
				>
					{t('AUTH.REGISTRATION.VERIFICATION_EMAIL.CONFIRM_BUTTON')}
				</Button>
				{/* <Timer */}
				{/*	className={styles.timer} */}
				{/*	seconds={seconds} */}
				{/*	setCurrentSeconds={setSeconds} */}
				{/* /> */}
				{/* <Button */}
				{/*	size="large" */}
				{/*	type="submit" */}
				{/*	theme="primary" */}
				{/*	onClick={() => { */}
				{/*		setSeconds(secondDefault); */}
				{/*		dispatch(verificationEmailResendAction()); */}
				{/*	}} */}
				{/*	className={styles.verification_email__button} */}
				{/*	disabled={seconds !== 0 || customerUpdate.isLoading || emailCheck.isLoading} */}
				{/* > */}
				{/*	{t('MODULES.AUTH.registration.verification_email.BUTTONS.re_request_letter')} */}
				{/* </Button> */}
				<ButtonLink
					to={authRoutes.auth.root}
					type="submit"
					theme="secondary"
					size="large"
					onClick={() => dispatch(authPersistLogout())}
				>
					{t('OTHER.CANCEL_BUTTON')}
				</ButtonLink>
			</div>
		</>

	);
};

export default null;
