import React from 'react';
import cx from 'classnames';

import { TextColorsType } from '@base/components/Text';
import { Text } from '@base/components';
import styles from './ButtonText.module.scss';

interface ButtonTextProps {
	className?: string;
	text?: string;
	children?: React.ReactNode,
	fluid?: boolean,
	underscore?: boolean,
	// eslint-disable-next-line no-unused-vars
	onClick?: (event?: React.SyntheticEvent<HTMLButtonElement>) => void,
	color?: TextColorsType,
	disabled?: boolean,
}

export const ButtonText = ({
	onClick, className, text, children, fluid, underscore, color = 'primary', disabled,
}: ButtonTextProps) => (
	<button
		onClick={onClick}
		className={cx(
			styles.button,
			className,
			{
				[styles['button--fluid']]: fluid,
			},
		)}
		disabled={disabled}
	>

		<Text
			className={cx(
				styles.button__text,
				{
					[styles['button__text--underscore']]: underscore,
				},
			)}
			type="h4"
			color={color}
		>
			{children || text || 'text'}
		</Text>
	</button>
);

ButtonText.defaultProps = {
	className: '',
};

export default null;
