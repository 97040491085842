import React from 'react';

import styles from '@client_broker/containers/wallet/WalletVerification/WalletVerification.module.scss';
import { Button, TableDeprecated } from '@base/components';
import TableRow from '@client_broker/containers/wallet/WalletVerification/TableRow/TableRow';
import { modalOpenAction } from '@base/store/Modal/actions';
import { AddingPaymentMethodModal } from '@client_broker/modals/wallet/AddingPaymentMethodModal';
import { useDispatch } from 'react-redux';

interface OwnProps {}

type WalletVerificationProps = {} & OwnProps;

const columns = [
	{
		id: 'system_name',
		header: 'system_name',
		isSort: false,
	},	{
		id: 'number',
		header: 'number',
		isSort: false,
	},	{
		id: 'verification',
		header: 'verification',
		isSort: false,
	}, {
		id: 'setting',
		header: 'setting',
		isSort: false,
	},
];

const data = [
	{
		system_name: 'Карта: Visa',
		number: 'Номер карты: 0214 **** **** 2545',
		verification: 'Пройдена',
		setting: 'Удалить',
	},	{
		system_name: 'Карта: Master card',
		number: 'Номер карты: 0214 **** **** 2545',
		verification: 'На модерации',
		setting: 'Удалить',
	},	{
		system_name: 'Расчетный счет: ПАО Сбербанк',
		number: 'Номер р/с: 02143265652545',
		verification: 'На модерации',
		setting: 'Удалить',
	},
];

// eslint-disable-next-line no-empty-pattern
export const WalletVerification = ({}: WalletVerificationProps) => {
	const dispatch = useDispatch();

	return (
		<div className={styles.wallet_verification}>
			<div className={styles.walletVerification__table}>
				<TableDeprecated
					nameSort="sort"
					valueSort=""
					onChangeSort={() => {}}
					nameOrder="order"
					valueOrder="asc"
					onChangeOrder={() => {}}
					namePage="page"
					valuePage={1}
					pageSize={99}
					onChangePage={() => {}}
					columns={columns}
					data={data}
					isLoading={false}
					valueCount={data.length}
					type="no_header"
				>
					{/* @ts-ignore */}
					{(props) => (
						<TableRow
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...props}
						/>
					)}
				</TableDeprecated>
			</div>
			<div className={styles.walletVerification__add_btn}>
				<Button
					size="large"
					theme="primary"
					onClick={() => {
						dispatch(modalOpenAction(<AddingPaymentMethodModal />));
					}}
				>
					Добавить платёжный метод
				</Button>
			</div>
		</div>
	);
};

export default null;
