import * as customerAccountsActionsTypes from '@client_broker/store/СustomerAccounts/constants';
import type { CustomerAccountsActionsType } from '@client_broker/store/СustomerAccounts/types';
import { RequestInfoChildType, Account } from '@base/types';
import { RequestInfoChildState } from '@base/statesDefault';

export type CustomerAccountsState = {
	accounts: {
		live: Array<Account>,
		demo: Array<Account>,
	} & RequestInfoChildType
	updateAccount: {
	} & RequestInfoChildType;
	createAccount: {
	} & RequestInfoChildType;
}
const initialState: CustomerAccountsState = {
	accounts: {
		live: [],
		demo: [],
		...RequestInfoChildState,
		isLoading: true,
	},
	updateAccount: {
		...RequestInfoChildState,
	},
	createAccount: {
		...RequestInfoChildState,
	},
};

export const customerAccountsReducer = (
	state: CustomerAccountsState = initialState,
	action: CustomerAccountsActionsType,
): CustomerAccountsState => {
	switch (action.type) {
		case customerAccountsActionsTypes.CUSTOMER_ACCOUNTS_REQUEST: {
			return {
				...state,
				accounts: {
					...state.accounts,
					isLoading: true,
				},
			};
		}

		case customerAccountsActionsTypes.CUSTOMER_ACCOUNTS_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				accounts: {
					...state.accounts,
					live: payload.live,
					demo: payload.demo,
					isLoading: false,
				},
			};
		}

		case customerAccountsActionsTypes.CUSTOMER_ACCOUNTS_FAIL: {
			const { payload } = action;
			return {
				...state,
				accounts: {
					...state.accounts,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		//-----------------------------

		case customerAccountsActionsTypes.UPDATE_CUSTOMER_ACCOUNT_REQUEST: {
			return {
				...state,
				updateAccount: {
					...state.updateAccount,
					isLoading: true,
				},
			};
		}

		case customerAccountsActionsTypes.UPDATE_CUSTOMER_ACCOUNT_SUCCESS: {
			return {
				...state,
				updateAccount: {
					...state.updateAccount,
					isLoading: false,
				},
			};
		}

		case customerAccountsActionsTypes.UPDATE_CUSTOMER_ACCOUNT_FAIL: {
			const { payload } = action;
			return {
				...state,
				updateAccount: {
					...state.updateAccount,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		//-----------------------------

		case customerAccountsActionsTypes.CREATE_CUSTOMER_ACCOUNT_REQUEST: {
			return {
				...state,
				createAccount: {
					...state.createAccount,
					isLoading: true,
				},
			};
		}

		case customerAccountsActionsTypes.CREATE_CUSTOMER_ACCOUNT_SUCCESS: {
			return {
				...state,
				createAccount: {
					...state.createAccount,
					isLoading: false,
				},
			};
		}

		case customerAccountsActionsTypes.CREATE_CUSTOMER_ACCOUNT_FAIL: {
			const { payload } = action;
			return {
				...state,
				createAccount: {
					...state.createAccount,
					isLoading: false,
					error: payload.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};
