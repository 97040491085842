import React from 'react';
import cx from 'classnames';

interface OwnProps {
	onClick?: () => void,
	onMouseMove?: () => void,
	onMouseLeave?: () => void,
	className?: string,
	color?: string,
	isActive?: boolean,
}

type OpenMenuIconProps = OwnProps

export const CryptocurrenciesIcon = ({
	color, isActive, className, onClick, onMouseMove, onMouseLeave,
}: OpenMenuIconProps) => (
	<svg
		className={cx(className)}
		onClick={onClick}
		onMouseMove={onMouseMove}
		onMouseLeave={onMouseLeave}
	>
		<circle cx="27.5" cy="27.5" r="27" fill={isActive ? color : 'white'} stroke={isActive ? 'white' : color} />
		<path fillRule="evenodd" clipRule="evenodd" d="M23.416 27.7226C23.3233 27.5836 23.1672 27.5 23 27.5C22.8328 27.5 22.6767 27.5836 22.584 27.7226L20.584 30.7226C20.4789 30.8802 20.4719 31.0836 20.5659 31.2481L22.5659 34.7481C22.6549 34.9039 22.8206 35 23 35C23.1794 35 23.3451 34.9039 23.4341 34.7481L25.4341 31.2481C25.5281 31.0836 25.5211 30.8802 25.416 30.7226L23.416 27.7226ZM22.7764 32.4472L22.2534 32.1857L23 33.4922L23.7466 32.1857L23.2236 32.4472C23.0828 32.5176 22.9172 32.5176 22.7764 32.4472ZM23 31.441L24.2698 30.8061L23 28.9014L21.7302 30.8061L23 31.441Z" fill={isActive ? 'white' : color} />
		<path d="M33.7598 27.8501C34.1187 27.9936 34.2933 28.401 34.1497 28.76L33.5337 30.3H33.9998C34.3864 30.3 34.6998 30.6134 34.6998 31C34.6998 31.3866 34.3864 31.7 33.9998 31.7H32.9737L32.5337 32.8H33.9998C34.3864 32.8 34.6998 33.1134 34.6998 33.5C34.6998 33.8866 34.3864 34.2 33.9998 34.2H31.4998C31.2675 34.2 31.0504 34.0848 30.9202 33.8924C30.7899 33.7001 30.7636 33.4557 30.8499 33.24L31.4659 31.7H30.9998C30.6132 31.7 30.2998 31.3866 30.2998 31C30.2998 30.6134 30.6132 30.3 30.9998 30.3H32.0259L32.8499 28.24C32.9935 27.8811 33.4008 27.7065 33.7598 27.8501Z" fill={isActive ? 'white' : color} />
		<path fillRule="evenodd" clipRule="evenodd" d="M22 22C22 23.0942 22.2929 24.1199 22.8045 25.0031C19.5813 25.1063 17 27.7517 17 31C17 34.3137 19.6863 37 23 37C25.087 37 26.9251 35.9345 28 34.3178C29.0749 35.9345 30.913 37 33 37C36.3137 37 39 34.3137 39 31C39 27.7517 36.4187 25.1063 33.1955 25.0031C33.7071 24.1199 34 23.0942 34 22C34 18.6863 31.3137 16 28 16C24.6863 16 22 18.6863 22 22ZM32.5904 22.2999C32.5968 22.2008 32.6 22.1008 32.6 22C32.6 19.4595 30.5405 17.4 28 17.4C25.4595 17.4 23.4 19.4595 23.4 22C23.4 22.1008 23.4032 22.2008 23.4096 22.2999H25.0778V19.9999C25.0778 19.7235 25.2405 19.473 25.493 19.3605C25.7455 19.248 26.0406 19.2947 26.2461 19.4796L28 21.0582L29.7539 19.4796C29.9594 19.2947 30.2545 19.248 30.507 19.3605C30.7595 19.473 30.9222 19.7235 30.9222 19.9999V22.2999H32.5904ZM32.2757 23.6999H30.2222C29.8356 23.6999 29.5222 23.3865 29.5222 22.9999V21.5717L28.4683 22.5202C28.2021 22.7598 27.7979 22.7598 27.5317 22.5202L26.4778 21.5717V22.9999C26.4778 23.3865 26.1644 23.6999 25.7778 23.6999H23.7243C23.9629 24.2994 24.3238 24.8369 24.7764 25.2815C24.8265 25.3127 24.8693 25.3548 24.9069 25.4049C25.4487 25.8973 26.1085 26.2623 26.8389 26.4522C26.8544 26.4553 26.8695 26.4591 26.884 26.4637C27.2413 26.5527 27.6151 26.6 28 26.6C29.94 26.6 31.5996 25.399 32.2757 23.6999ZM23 26.4C23.3634 26.4 23.717 26.4421 24.0561 26.5218C24.684 27.0699 25.4272 27.4894 26.2444 27.7391C26.676 28.1685 27.0228 28.683 27.2576 29.2553C27.0901 29.8074 27 30.3932 27 31C27 31.6068 27.0901 32.1926 27.2576 32.7447C26.5703 34.4201 24.923 35.6 23 35.6C20.4595 35.6 18.4 33.5405 18.4 31C18.4 28.4595 20.4595 26.4 23 26.4ZM28.5273 31C28.5273 29.6672 29.0617 28.5143 29.8906 27.6961C30.6474 27.445 31.3374 27.0473 31.9269 26.5366C32.2744 26.4471 32.6345 26.4 33 26.4C35.3478 26.4 37.4727 28.3423 37.4727 31C37.4727 33.6577 35.3478 35.6 33 35.6C30.6522 35.6 28.5273 33.6577 28.5273 31Z" fill={isActive ? 'white' : color} />
	</svg>
);
