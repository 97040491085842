import React, { useEffect, useMemo, useState } from 'react';

import {
	Button, LoaderSpinner, Select, Text,
	Input,
} from '@base/components';
import { modalCloseAction } from '@base/store/Modal/actions';
import { useDispatch, useSelector } from 'react-redux';

import { object, string } from 'yup';
import errorMessages from '@base/constants/errorMessages';
import { useFormik } from 'formik';
import regex from '@base/constants/regex';
import { Account, OptionType } from '@base/types';
import { AccountDefault } from '@base/statesDefault';
import { ClientBrokerState } from '@client_broker/types';
import { transferInternalAction } from '@client_broker/store/TransferInternal/actions';
import { useTranslation } from 'react-i18next';
import styles from './TransferBetweenUserAccountsModal.module.scss';

type TransferBetweenUserAccountsModalProps = {};

interface TransferBetweenUserAccountsModalFieldsType {
	fromAccount: OptionType<Account>,
	amount: string,
	toAccount: OptionType<Account>,
}

export const initialValues = {
	fromAccount: { label: '', value: AccountDefault },
	amount: '',
	toAccount: { label: '', value: AccountDefault },
};

const validationSchema = object().shape({
	amount: string()
		.required(errorMessages.FIELD_IS_REQUIRED)
		.matches(
			regex.amount,
			errorMessages.INVALID_AMOUNT,
		),
});

// eslint-disable-next-line no-empty-pattern
export const TransferBetweenUserAccountsModal = ({}: TransferBetweenUserAccountsModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [fromAccountOptions, setFromAccountOptions] = useState<Array<OptionType<Account>>>([]);
	const [toAccountOptions, setToAccountOptions] = useState<Array<OptionType<Account>>>([]);
	const {
		accounts,
	} = useSelector((state: ClientBrokerState) => state.clientBroker.customerAccounts);
	const {
		transferInternal,
	} = useSelector((state: ClientBrokerState) => state.clientBroker.transferInternal);

	useEffect(() => {
		const fromAccountOptionsNew = accounts.live.map((account) => ({
			label: `${account.mt_id} | ${account.balance} | ${account.currency}`,
			value: account,
		}));
		setFromAccountOptions(fromAccountOptionsNew);
		setToAccountOptions(fromAccountOptionsNew);
	}, [accounts.live]);

	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		setValues,
		errors,
		touched,
		setTouched,
		isValid,
	} = useFormik<TransferBetweenUserAccountsModalFieldsType>({
		initialValues,
		onSubmit: (
			formData: TransferBetweenUserAccountsModalFieldsType,
		): any => dispatch(transferInternalAction({
			amount: Number(formData.amount),
			sender: { id: formData.fromAccount.value.id, type: 'live' },
			target: { id: formData.toAccount.value.id, type: 'live' },
		})),
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		setTouched(touched);
	}, [t]);

	useEffect(() => {
		const toAccountOptionsNew = accounts.live.filter((
			account,
		) => (
			account.id !== values.fromAccount.value.id
			&& account.currency === values.fromAccount.value.currency
		))
			.map((account) => ({
				label: `${account.mt_id} | ${account.balance} | ${account.currency}`,
				value: account,
			}));
		setToAccountOptions(toAccountOptionsNew);
	}, [values.fromAccount]);

	useEffect(() => {
		if (values.toAccount.value.id === values.fromAccount.value.id) {
			setValues({
				...values,
				toAccount: { label: '', value: AccountDefault },
			});
		}
	}, [values.fromAccount]);

	const onChangeHandler = React.useCallback(
		({ name, value }) => {
			const event = {
				target: {	name,	value	},
			};
			handleChange(event);
		},
		[
			handleChange,
		],
	);

	const isDisabled = useMemo(() => !isValid
			|| accounts.isLoading
			|| transferInternal.isLoading
			|| values.fromAccount.value.id === 0
			|| values.toAccount.value.id === 0,
	[
		isValid,
		accounts.isLoading,
		transferInternal.isLoading,
		values.fromAccount,
		values.toAccount,
	]);

	return (
		<div className={styles.transfer_between_user_accounts}>
			<Text type="h3">Внутренний перевод между своими счетами</Text>
			<div className={styles.transfer_between_user_accounts__content}>
				{accounts.isLoading || transferInternal.isLoading ? <LoaderSpinner />
					: (
						<>
							<Select
								labelName="Со счёта"
								name="fromAccount"
								options={fromAccountOptions}
								value={values.fromAccount.value}
								className={styles.transfer_between_user_accounts__content__select}
								onChange={onChangeHandler}
							/>
							<Input
								label="Сумма"
								name="amount"
								required
								value={values.amount}
								onChange={onChangeHandler}
								onBlur={handleBlur}
								error={
									(touched.amount !== undefined && errors.amount !== undefined)
										? errors.amount || ''
										: undefined
								}
							/>
							<Select
								labelName="На счёт"
								name="toAccount"
								options={toAccountOptions}
								value={values.toAccount.value}
								className={styles.transfer_between_user_accounts__content__select}
								onChange={onChangeHandler}
							/>
						</>
					)}
			</div>
			<div className={styles.transfer_between_user_accounts__btns}>
				<Button
					type="button"
					theme="primary"
					size="large"
					onClick={() => handleSubmit()}
					disabled={isDisabled}
				>
					Подтвердить
				</Button>
				<Button
					type="button"
					theme="secondary"
					size="large"
					onClick={() => { dispatch(modalCloseAction()); }}
				>
					Отмена
				</Button>
			</div>
		</div>
	);
};
export default null;
