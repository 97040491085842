import React, { useEffect } from 'react';

import { LoaderSpinner } from '@base/components';
import {
	Redirect, Route, Switch, useParams,
} from 'react-router-dom';
import authRoutes from '@auth/constants/authRoutes';
import { SendEmailForm } from '@auth/containers/passwordRecovery/SendEmailForm';
import { RestoreForm } from '@auth/containers/passwordRecovery/RestoreForm';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '@auth/types';
import { passwordRecoveryCheckPassTokenAction } from '@auth/store/PasswordRecovery/actions';
import { AuthFormLayout } from '@auth/layouts/AuthFormLayout';
import cx from 'classnames';
import styles from './PasswordRecovery.module.scss';

interface OwnProps {
}

type PasswordRecoveryProps = {} & OwnProps;

const RestoreFormWrap = () => {
	const dispatch = useDispatch();
	const { token } = useParams<{ token: string }>();
	const { checkPassToken } = useSelector(
		({ auth }: AuthState) => auth.passwordRecovery,
	);

	useEffect(() => {
		dispatch(passwordRecoveryCheckPassTokenAction({ token }));
	}, [token]);

	return (
		<div className={cx(
			styles.password_recovery,
			{
				[styles.password_recovery__display]: checkPassToken.isLoading,
			},
		)}
		>
			{checkPassToken.isLoading
				? <LoaderSpinner />
				: (
					<RestoreForm />
				)}
		</div>
	);
};

// eslint-disable-next-line no-empty-pattern
export const PasswordRecovery = ({}: PasswordRecoveryProps) => {
	const { t } = useTranslation();

	return (
		<Switch>
			<Route
				exact
				path={authRoutes.auth['password-recovery'].root}
				render={() => (
					<AuthFormLayout text={t('.AUTH.PASSWORD_RECOVERY.SEND_EMAIL.TITLE')}>
						<SendEmailForm />
					</AuthFormLayout>
				)}
			/>
			<Route
				path={authRoutes.auth['password-recovery'].restore.rootParam}
				render={() => (
					<AuthFormLayout text={t('AUTH.PASSWORD_RECOVERY.TITLE')}>
						<RestoreFormWrap />
					</AuthFormLayout>
				)}
			/>
			<Redirect to={authRoutes.auth['password-recovery'].root} />
		</Switch>
	);
};

export default null;
