import * as transferInternalTypes from '@client_broker/store/TransferInternal/constants';
import { PostTransferInternalReq } from '@base/types';

export const transferInternalAction = (payload :PostTransferInternalReq) => ({
	type: transferInternalTypes.CLIENT_BROKER_TRANSFER_INTERNAL_REQUEST,
	payload,
});

export const transferInternalSuccessAction = () => ({
	type: transferInternalTypes.CLIENT_BROKER_TRANSFER_INTERNAL_SUCCESS,
});

export const transferInternalFailAction = (payload: { error: string }) => ({
	type: transferInternalTypes.CLIENT_BROKER_TRANSFER_INTERNAL_FAIL,
	payload,
});

export default null;
