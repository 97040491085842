import * as tradesTypes from '@client_broker/store/Trades/constants';
import { PostTrades, TradesData } from '@base/types';

export const tradesAction = (payload: PostTrades) => ({
	type: tradesTypes.CLIENT_BROKER_TRADES_REQUEST,
	payload,
});

export const tradesSuccessAction = (payload: TradesData) => ({
	type: tradesTypes.CLIENT_BROKER_TRADES_SUCCESS,
	payload,
});

export const tradesFailAction = (payload: { error: string }) => ({
	type: tradesTypes.CLIENT_BROKER_TRADES_FAIL,
	payload,
});

export default null;
