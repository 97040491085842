import React from 'react';

import { ButtonText, Text } from '@base/components';
import { useTranslation } from 'react-i18next';

export const AuthNotificationDescriptionError = () => {
	const { t } = useTranslation();
	return (
		<Text type="h4">
			{`${t('AUTH.SUPPORT.TEXT')} `}
			<ButtonText
				color="primary"
				onClick={() => window.location.assign(`mailto:${t('AUTH.SUPPORT.EMAIL')}`)}
			>
				{t('AUTH.SUPPORT.EMAIL')}
			</ButtonText>
			{' '}
			{t('AUTH.SUPPORT.CHAT')}
		</Text>
	);
};

export const AuthNotificationDescPROCESSING_ERROR = () => {
	const { t } = useTranslation();
	return (
		<Text type="h4">
			{`${t('AUTH.REGISTRATION.VERIFICATION_PHONE.ERROR_WRONG_CREDENTIALS_DESC')} `}
			<ButtonText
				color="primary"
				onClick={() => window.location.assign(`mailto:${t('AUTH.SUPPORT.EMAIL')}`)}
			>
				{t('AUTH.SUPPORT.EMAIL')}
			</ButtonText>
			{' '}
			{t('AUTH.SUPPORT.CHAT')}
		</Text>
	);
};
