import ApiProviderDefault from '@base/providers/apiProviderDefault';
import { GetCoreReq } from '@base/types';

export default class CoreProvider extends ApiProviderDefault {
	static async getCore(parameters: {
		headers: Headers,
	}): Promise<GetCoreReq> {
		let body;
		let headers;
		const query = {};
		const path = '/core';

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request(path, 'GET', query, body, headers);
	}
}

// @ts-ignore
CoreProvider.getCore.security = true;
