import React, { useMemo } from 'react';

import cx from 'classnames';
import { BanksIcon } from '@base/assets/icons/wallet/banks';
import { Colors } from '@base/assets/styles/colors';
import { Text } from '@base/components';
import { CardsIcon } from '@base/assets/icons/wallet/cards';
import { WalletsIcon } from '@base/assets/icons/wallet/wallets';
import { CryptocurrenciesIcon } from '@base/assets/icons/wallet/cryptocurrencies';
import styles from './ChoosingDepositMethod.module.scss';

export type StateShowElements = {
	banks: boolean,
	cards: boolean,
	cryptocurrencies: boolean,
	wallets: boolean,
}

interface OwnProps {
}

type ChoosingDepositMethodProps = {
	stateIcons: StateShowElements,
	// eslint-disable-next-line no-unused-vars
	onChangeIcon: (event: { name: string, value: boolean }) => void,
	paymentsType: 'withdraw' | 'deposit'

} & OwnProps;

export const ChoosingDepositMethod = ({
	stateIcons,
	onChangeIcon,
	paymentsType,
}: ChoosingDepositMethodProps) => {
	const text = useMemo(() => {
		switch (paymentsType) {
			case 'withdraw':
				return 'Выберите способ пополнения';
			case 'deposit':
				return 'Выберите способ вывода';
			default:
				return '';
		}
	}, [paymentsType]);

	return (
		<div className={cx(
			styles.main__element,
			styles.main__element__deposit_method,
		)}
		>
			<div
				className={styles.main__element__deposit_method__text}
			>
				<Text type="h5">{text}</Text>
			</div>
			<div
				className={styles.main__element__deposit_method__icons}
			>
				<WalletsIcon
					onClick={() => onChangeIcon({ name: 'wallets', value: !stateIcons.wallets })}
					color={Colors.primary}
					isActive={stateIcons.wallets}
				/>
				<BanksIcon
					onClick={() => onChangeIcon({ name: 'banks', value: !stateIcons.banks })}
					color={Colors.primary}
					isActive={stateIcons.banks}
				/>
				<CardsIcon
					onClick={() => onChangeIcon({ name: 'cards', value: !stateIcons.cards })}
					color={Colors.primary}
					isActive={stateIcons.cards}
				/>
				<CryptocurrenciesIcon
					onClick={() => onChangeIcon({ name: 'cryptocurrencies', value: !stateIcons.cryptocurrencies })}
					color={Colors.primary}
					isActive={stateIcons.cryptocurrencies}
				/>
			</div>
		</div>
	);
};

export default null;
