import ApiProviderDefault from '@base/providers/apiProviderDefault';
import {
	GetPayments,
	GetPaymentsReq,
	GetPaymentsResp,
	PostPaymentDeposit,
	PostPaymentDepositReq, PostPaymentDepositResp,
} from '@base/types';

export default class PaymentsProvider extends ApiProviderDefault {
	static async getPayments(parameters: {
		params: GetPayments,
		headers: Headers,
	}) {
		let headers;
		const query = {};
		const body = {};
		if (parameters.params === undefined) {
			throw new Error('Missing required  parameter: body');
		}
		const {
			paymentType,
		} = parameters.params;
		const path = `/payment/${paymentType}`;

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<GetPaymentsReq, GetPaymentsResp>(path, 'GET', query, body, headers);
	}

	static async postPaymentDeposit(parameters: {
		params: PostPaymentDeposit,
		headers: Headers,
	}) {
		let headers;
		const query = {};
		if (parameters.params === undefined) {
			throw new Error('Missing required  parameter: params');
		}

		const {
			account_id, amount, currency, uid,
		} = parameters.params;

		const path = `/payment/deposit/${uid}`;

		const body = { account_id, amount, currency };

		if (parameters.headers !== undefined) {
			headers = parameters.headers;
		}

		return this.request<PostPaymentDepositReq, PostPaymentDepositResp>(path, 'POST', query, body, headers);
	}
}

// @ts-ignore
PaymentsProvider.getPayments.security = true;
// @ts-ignore
PaymentsProvider.postPaymentDeposit.security = true;
