import { useEffect } from 'react';
import { setPageInfoAction } from '@base/store/PageInfo/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigation } from '@base/hooks/useNavigation';

export const usePageInfo = (moduleRoutes: any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation();
	const [paths] = useNavigation(moduleRoutes);

	useEffect(() => {
		if (!paths[paths.length - 1] || !paths[paths.length - 1].label) return;
		dispatch(setPageInfoAction({
			// label: paths[paths.length - 1].label,
			labelTKey: `${paths[paths.length - 1].tKeyRoot}.TITLE`,
			tKeyRoot: paths[paths.length - 1].tKeyRoot,
		}));
	}, [location, t]);

	return null;
};

export default null;
