import React from 'react';
import cx from 'classnames';

interface OwnProps {
	className?: string,
	color?: string,
}

type RammCopytradingIconProps = OwnProps

export const RammCopytradingIcon = ({ color, className }: RammCopytradingIconProps) => (
	<svg className={cx(className)}>
		<g clipPath="url(#clip0)">
			<mask id="path-1-inside-1" fill={color}>
				<rect y="0.5" width="22" height="22" rx="1" />
			</mask>
			<rect y="0.5" width="22" height="22" rx="1" stroke={color} strokeWidth="2.4" strokeLinecap="round" mask="url(#path-1-inside-1)" />
			<line x1="3.6" y1="6.9" x2="13.4" y2="6.9" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
			<line x1="16.6" y1="6.9" x2="18.4" y2="6.9" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
			<line x1="3.6" y1="11.9" x2="13.4" y2="11.9" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
			<line x1="16.6" y1="11.9" x2="18.4" y2="11.9" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
			<line x1="3.6" y1="16.9" x2="13.4" y2="16.9" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
			<line x1="16.6" y1="16.9" x2="18.4" y2="16.9" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="24" height="24" fill={color} transform="translate(0 0.5)" />
			</clipPath>
		</defs>
		{' '}

	</svg>
);
