/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import cx from 'classnames';

import styles from './ImageFlipper.module.scss';

interface ImageFlipperProps {
	className?: string,
	index: number
	length: number
	// eslint-disable-next-line no-unused-vars,no-shadow
	setIndex: (index: number) => void
}

export const imageFlipperOnScroll = ({
	scrollElementId,
	setImgIndexSelected,
}: {
	scrollElementId: string,
	// eslint-disable-next-line no-shadow,no-unused-vars
	setImgIndexSelected: (imgIndexSelected: number) => void,
}) => {
	const {
		// @ts-ignore
		scrollLeft, scrollWidth, clientWidth, children,
	} = document.getElementById(scrollElementId);
	if (scrollLeft + clientWidth + 1 >= scrollWidth) {
		setImgIndexSelected(children.length - 1);
		return;
	}
	if (scrollLeft === 0) {
		setImgIndexSelected(0);
		return;
	}
	setImgIndexSelected(1);
};

export const imageFlipperFunction = ({
	scrollElementId,
	imgElementId,
	imgIndex,
	imgIndexSelected,
	setImgIndexSelected,
}: {
	scrollElementId: string,
	imgElementId: string,
	imgIndex: number,
	imgIndexSelected: number,
	// eslint-disable-next-line no-shadow,no-unused-vars
	setImgIndexSelected: (imgIndexSelected: number) => void,
}) => {
	const scrollElement: HTMLElement | null = document.getElementById(scrollElementId);
	const imgElement: HTMLElement | null = document.getElementById(imgElementId);
	if (!imgElement || !scrollElement) return;
	const imgListLength = scrollElement.children.length;
	const imgListWidth = scrollElement.scrollWidth;
	const imgWidth = imgElement.clientWidth;
	const spaceBetween = (imgListWidth - (imgWidth * imgListLength)) / imgListLength;
	if (imgIndex < imgIndexSelected) {
		if (scrollElement.scrollLeft
			+ scrollElement.clientWidth
			+ imgWidth + spaceBetween < scrollElement.scrollWidth) {
			scrollElement.scrollLeft = 0;
			setImgIndexSelected(0);
		} else {
			scrollElement.scrollLeft -= imgWidth + spaceBetween;
			setImgIndexSelected(imgIndex);
		}
	}
	if (imgIndex > imgIndexSelected) {
		if (scrollElement.scrollLeft
			+ scrollElement.clientWidth
			+ imgWidth + spaceBetween > scrollElement.scrollWidth) {
			scrollElement.scrollLeft += scrollElement.scrollWidth;
			setImgIndexSelected(imgListLength - 1);
		} else {
			scrollElement.scrollLeft += imgWidth + spaceBetween;
			setImgIndexSelected(imgIndex);
		}
	}
};

export const ImageFlipper = ({
	className, index, setIndex, length,
}: ImageFlipperProps) => (
	<div className={cx(
		className,
		styles.image_flipper,
	)}
	>
		<div
			className={cx(
				styles.image_flipper__left,
				{
					[styles.image_flipper__pale]: index === 0,
				},
			)}
			onClick={() => {
				if (index > 0) setIndex(index - 1);
			}}
		>
			&#8592;
		</div>
		<div />
		<div
			className={cx(
				styles.image_flipper__right,
				{
					[styles.image_flipper__pale]: index === length - 1,
				},
			)}
			onClick={() => {
				if (index < length - 1) setIndex(index + 1);
			}}
		>
			&#8594;
		</div>
	</div>
);

export default null;
