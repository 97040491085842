const PROCESSING: 'PROCESSING' = 'PROCESSING';
const REJECTED: 'REJECTED' = 'REJECTED';
const ACCEPTED: 'ACCEPTED' = 'ACCEPTED';
const NOT_VERIFIED: 'NOT_VERIFIED' = 'NOT_VERIFIED';

export default {
	PROCESSING,
	REJECTED,
	ACCEPTED,
	NOT_VERIFIED,
};

export type FileStatusesType = 'PROCESSING' | 'REJECTED' | 'ACCEPTED' | 'NOT_VERIFIED';
