import React, { useState } from 'react';
import cx from 'classnames';

import { ButtonIcons } from '@base/assets/icons/buttons';
import { Colors } from '@base/assets/styles/colors';
import styles from './Button.module.scss';

export interface ButtonProps {
	children: React.ReactNode;
	className?: string;
	// eslint-disable-next-line no-unused-vars
	onClick: (event?: React.SyntheticEvent<HTMLButtonElement>) => void,
	disabled?: boolean,
	fluid?: boolean,
	type?: 'button' | 'submit' | 'reset',
	theme?: 'default' | 'primary' | 'secondary' | 'black' | 'attach',
	size?: 'mini'
		| 'tiny'
		| 'small'
		| 'medium'
		| 'large'
		| 'big'
		| 'huge'
		| 'massive'
		| 'text',
	backgroundColor?: string;
	color?: string;
}

// TODO декомпозировать функционал прикрепления
const AttachIcon = ({ color }: {color: string}) => {
	const { attach_button: IconAttach } = ButtonIcons;
	return (
		<IconAttach
			onClick={() => {}}
			className={styles.icon_attach}
			color={color}
		/>
	);
};

export const Button = ({
	className, size, children, backgroundColor, color, onClick, disabled,	fluid, type, theme,
}: ButtonProps) => {
	// eslint-disable-next-line no-unused-vars
	const [isHover, setIsHover] = useState(false);

	const getColorForIcon = () => {
		if (disabled) {
			return Colors.disabled;
		}
		return isHover ? Colors.white : Colors.primary;
	};

	return (
		// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
		<button
			onMouseOver={() => setIsHover(true)}
			onMouseOut={() => setIsHover(false)}
			onClick={onClick}
			disabled={disabled}
			type={type}
			className={cx(
				styles.button,
				{
					[styles['button--fluid']]: fluid,
					[styles[`button--theme--${theme || ''}`]]: theme,
					[styles[`button--size--${size || ''}`]]: size,
				}, className,
			)}
			style={{ backgroundColor, color }}
		>
			{theme === 'attach' && (
				<AttachIcon color={getColorForIcon()} />
			)}
			<span className={styles.button__content}>
				{children}
			</span>
		</button>
	);
};

Button.defaultProps = {
	disabled: false,
	fluid: false,
	type: 'button',
	theme: 'default',
	size: 'medium',
	className: '',
};

export default null;
