/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import cx from 'classnames';
import { DropdownButton, Text } from '@base/components';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomerAccountAction } from '@client_broker/store/СustomerAccounts/actions';
import { DropDownMenuEventType } from '@base/components/DropDownMenu';
import historyIcon from '@base/assets/icons/history.svg';
import { clientBrokerRoutes } from '@client_broker/constants/routes';
import { NavLink } from 'react-router-dom';
import { modalOpenAction } from '@base/store/Modal/actions';
import hash from 'object-hash';
import { Account, BaseState, OptionType } from '@base/types';

import { ChangeAccountPasswordModal } from '@client_broker/modals/accounts/ChangeAccountPasswordModal';
import { TransferBetweenUserAccountsModal } from '@client_broker/modals/accounts/TransferBetweenUserAccountsModal';
import { useTranslation } from 'react-i18next';
import styles from './TableRow.module.scss';

type OwnProps = {};

type TableRowProps = {
	id: string,
	item: Account | any
} & OwnProps;

const TableRow = ({
	id,
	item,
}: TableRowProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const ItemWrapper = ({
		className,
		children,
	}: { children: React.ReactNode, className?: string }) => (
		<div className={cx(className, styles.table_row_wrapper)}>
			{children}
		</div>
	);

	const ItemDefault = () => (
		<ItemWrapper>
			{
				item[id] !== undefined
				&& item[id] !== ''
				&& item[id] !== null
				&& item[id]
					? item[id] : '__'
			}
		</ItemWrapper>
	);

	const AccountNameRow = () => (
		<ItemWrapper>
			<div>без названия</div>
		</ItemWrapper>
	);

	const LeverageRow = ({
		itemId = id,
	}: { itemId?: string }) => {
		const {
			optionsOfData,
		} = useSelector((state: BaseState) => state.base.core);
		const [leverage, setLeverage] = useState(item[itemId]);
		const onClick = ({ option }: DropDownMenuEventType) => {
			setLeverage(option.label);
			dispatch(
				updateCustomerAccountAction({
					id: item.id,
					body: { leverage: option.label.substring(option.label.indexOf(':') + 1, option.label.length) },
				}),
			);
		};

		return (
			<ItemWrapper>
				<div className={styles.tem_with_menu}>
					<div className={styles.tem_with_menu__value}>{leverage}</div>
					<DropdownButton
						onClick={onClick}
						className={styles.tem_with_menu__btn}
						options={optionsOfData.account_leverages[item.is_live ? 'is_live' : 'is_demo']}
						inlineBlock="right"
						align="right"
					/>
				</div>
			</ItemWrapper>
		);
	};

	const BalanceRow = ({
		itemId = id,
	}: { itemId?: string }) => {
		const menuValues = [
			{ label: t('CLIENT.ACCOUNTS.TABLE.DROPDOWN.BALANCE_INPUT'), value: 0 },
			{ label: t('CLIENT.ACCOUNTS.TABLE.DROPDOWN.BALANCE_OUTPUT'), value: 1 },
			{ label: t('CLIENT.ACCOUNTS.TABLE.DROPDOWN.BALANCE_TRANSFER'), value: 2 },
		];
		const [showMenu, setShowMenu] = useState<false | undefined>(undefined);
		const onClick = ({ option }: DropDownMenuEventType) => {
			switch (option.value) {
				case 2:
					dispatch(modalOpenAction(<TransferBetweenUserAccountsModal />));
					break;
				default: break;
			}
			setShowMenu(false);
		};
		const getItem = (option: OptionType<any>) => {
			const Item = ({ link, label }: {link: string, label: string}) => (
				<NavLink
					activeClassName={styles['menu__link--active']}
					className={styles.menu__link}
					to={link}
				>
					<Text align="right" type="h5" color="gray-light" className={styles.menu__item}>{label}</Text>
				</NavLink>
			);
			switch (option.value) {
				case 0:
					return (
						<Item link={clientBrokerRoutes.wallet.input.root} label={option.label} />
					);
				case 1:
					return (
						<Item link={clientBrokerRoutes.wallet.output.root} label={option.label} />
					);
				default:
					return (<Text type="h5" color="gray-light" className={styles.menu__item}>{option.label}</Text>);
			}
		};
		return (
			<ItemWrapper>
				<div className={styles.tem_with_menu}>
					<div className={styles.tem_with_menu__value}>{item[itemId]}</div>
					{item.is_live && (
						<DropdownButton
							showMenu={showMenu}
							className={styles.tem_with_menu__btn}
							inlineBlock="right"
						>
							{menuValues.map((option, index) => (
								<div key={hash(index)} onClick={() => onClick({ option, index })}>
									{getItem(option)}
									{menuValues.length - 1 !== index && <br />}
								</div>
							))}
						</DropdownButton>
					)}
				</div>
			</ItemWrapper>
		);
	};

	const ManagementRow = ({
		itemId = id,
	}: { itemId?: string }) => {
		let menuValues: Array<any> = [];

		switch (true) {
			case item.is_live:
				menuValues = [
					{
						label: item.report
							? t('CLIENT.ACCOUNTS.TABLE.MANAGEMENT_DISABLE_ALERT')
							: t('CLIENT.ACCOUNTS.TABLE.MANAGEMENT_ENABLE_ALERT'),
						value: 1,
					},
					{ label: t('CLIENT.ACCOUNTS.TABLE.MANAGEMENT_CHANGE_TRADING_PASS'), value: 2 },
					{ label: t('CLIENT.ACCOUNTS.TABLE.MANAGEMENT_CHANGE_PHONE_PASS'), value: 3 },
					{ label: t('CLIENT.ACCOUNTS.TABLE.MANAGEMENT_CHANGE_INVESTOR_PASS'), value: 4 },
				];
				break;
			case item.is_demo:
				menuValues = [
					{
						label: item.report
							? t('CLIENT.ACCOUNTS.TABLE.MANAGEMENT_DISABLE_ALERT')
							: t('CLIENT.ACCOUNTS.TABLE.MANAGEMENT_ENABLE_ALERT'),
						value: 1,
					},
				];
				break;
			default: break;
		}
		const onClick = ({ option }: DropDownMenuEventType) => {
			switch (option.value) {
				case 1:
					dispatch(
						updateCustomerAccountAction({
							id: item.id, body: { report: !item.report },
						}),
					);
					break;
				case 2:
					dispatch(modalOpenAction(<ChangeAccountPasswordModal id={item.id} mt_id={item.mt_id} type="trading" />));
					break;
				case 3:
					dispatch(modalOpenAction(<ChangeAccountPasswordModal id={item.id} mt_id={item.mt_id} type="phone" />));
					break;
				case 4:
					dispatch(modalOpenAction(<ChangeAccountPasswordModal id={item.id} mt_id={item.mt_id} type="investor" />));
					break;
				default:
					break;
			}
		};
		return (
			<ItemWrapper>
				<div className={styles.tem_with_menu}>
					<div>
						Настройки
						{item[itemId]}
					</div>
					<DropdownButton
						className={styles.tem_with_menu__btn}
						onClick={onClick}
						options={menuValues}
						inlineBlock="right"
						align="right"
					/>
				</div>
			</ItemWrapper>
		);
	};

	const HistoryRow = () => {
		let path = '';
		// eslint-disable-next-line default-case
		switch (true) {
			case item.is_live:
				path = clientBrokerRoutes['live-accounts'].history.root;
				break;
			case item.is_demo:
				path = clientBrokerRoutes['demo-accounts'].history.root;
				break;
		}
		return (
			<ItemWrapper>
				<NavLink
					className={styles.history_link}
					to={`${path}/${item.mt_id}`}
				>
					<img src={historyIcon} alt="" />
				</NavLink>
			</ItemWrapper>
		);
	};

	switch (id) {
		case 'accountName':
			return (<AccountNameRow />);
		case 'leverage':
			return (<LeverageRow />);
		case 'balance':
			return (<BalanceRow />);
		case 'management':
			return (<ManagementRow />);
		case 'history':
			return (<HistoryRow />);

		default:
			return (<ItemDefault />);
	}
};

TableRow.defaultProps = {
	item: {
		accountName: 'testName',
	},
};

export default TableRow;
