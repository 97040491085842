import { AuthPersistActionsType } from '@base/store/AuthPersist/types';
import * as authPersistTypes from './constants';

export type TokenStateType = {
	accessToken: string,
	refreshToken: string,
	expiresAt?: number
};

export type AuthPersistStateType = {
	isLoading: boolean,
	error: string,
	isAuth?: boolean,
	token: TokenStateType | null,
	userData: {
		isConfirmedEmail: boolean,
		isConfirmedPhone: boolean,
		email: string | undefined,
		phone: string | undefined
	},
};

const initialState: AuthPersistStateType = {
	isLoading: false,
	error: '',
	isAuth: undefined,
	token: null,
	userData: {
		isConfirmedEmail: false,
		isConfirmedPhone: false,
		email: undefined,
		phone: undefined,
	},
};

export const authPersistReducer = (
	state: AuthPersistStateType = initialState,
	action: AuthPersistActionsType,
): AuthPersistStateType => {
	switch (action.type) {
		case authPersistTypes.AUTH_PERSIST_LOGIN_SEND: {
			return {
				...state,
				isLoading: true,
			};
		}

		case authPersistTypes.AUTH_PERSIST_LOGIN_SUCCESS: {
			const {
				accessToken, refreshToken, isAuth,
			} = action.payload;
			return {
				...state,
				token: {
					...state.token,
					accessToken,
					refreshToken,
				},
				isAuth,
				isLoading: false,
			};
		}

		case authPersistTypes.AUTH_PERSIST_LOGIN_ERROR: {
			const { payload } = action;
			return {
				...state,
				isLoading: false,
				error: payload.error,
			};
		}

		case authPersistTypes.AUTH_PERSIST_REFRESH_TOKEN_SEND: {
			return {
				...state,
				isLoading: true,
			};
		}

		case authPersistTypes.AUTH_PERSIST_REFRESH_TOKEN_SUCCESS: {
			const {
				accessToken, refreshToken, isAuth,
			} = action.payload;
			return {
				...state,
				token: {
					...state.token,
					accessToken,
					refreshToken,
				},
				isAuth,
				isLoading: false,
			};
		}

		case authPersistTypes.AUTH_PERSIST_REFRESH_TOKEN_ERROR: {
			const { payload } = action;
			return {
				...state,
				isLoading: false,
				error: payload.error,
			};
		}

		case authPersistTypes.AUTH_PERSIST_UPDATE_USER_DATA: {
			const {
				userData,
			} = action.payload;
			return {
				...state,
				userData: {
					...state.userData,
					...userData,
				},
			};
		}

		case authPersistTypes.AUTH_PERSIST_LOGOUT: {
			return initialState;
		}

		default: {
			return state;
		}
	}
};
