import * as verificationPhoneTypes from '@auth/store/VerificationPhone/constants';
import {
	CallAttemptResp,
	PostPhoneMakeCallReq,
	PostVerifyPhoneReq,
} from '@base/types';

// export const verificationPhoneSendCodeAction = (payload: PostPhoneSendCodeReq) => ({
// 	type: verificationPhoneTypes.VERIFICATION_PHONE_SEND_CODE_REQUEST,
// 	payload,
// });
//
// export const verificationPhoneSendCodeSuccessAction = () => ({
// 	type: verificationPhoneTypes.VERIFICATION_PHONE_SEND_CODE_SUCCESS,
// });
//
// export const verificationPhoneSendCodeFailAction = (payload: {
// 	error: string;
// }) => ({
// 	type: verificationPhoneTypes.VERIFICATION_PHONE_SEND_CODE_FAIL,
// 	payload,
// });

// =======================================

export const verificationPhoneMakeCallAction = (payload: PostPhoneMakeCallReq) => ({
	type: verificationPhoneTypes.VERIFICATION_PHONE_MAKE_CALL_REQUEST,
	payload,
});

export const verificationPhoneMakeCallSuccessAction = () => ({
	type: verificationPhoneTypes.VERIFICATION_PHONE_MAKE_CALL_SUCCESS,
});

export const verificationPhoneMakeCallFailAction = (payload: {
	error: string;
}) => ({
	type: verificationPhoneTypes.VERIFICATION_PHONE_MAKE_CALL_FAIL,
	payload,
});

// =======================================

export const verificationPhoneAction = (payload: PostVerifyPhoneReq) => ({
	type: verificationPhoneTypes.VERIFICATION_PHONE_REQUEST,
	payload,
});

export const verificationPhoneSuccessAction = () => ({
	type: verificationPhoneTypes.VERIFICATION_PHONE_SUCCESS,
});

export const verificationPhoneFailAction = (payload: {
	error: string;
}) => ({
	type: verificationPhoneTypes.VERIFICATION_PHONE_FAIL,
	payload,
});
// =======================================

export const verificationPhoneCallAttemptAction = () => ({
	type: verificationPhoneTypes.VERIFICATION_PHONE_CALL_ATTEMPT_REQUEST,
});

export const verificationPhoneCallAttemptSuccessAction = (payload: CallAttemptResp) => ({
	type: verificationPhoneTypes.VERIFICATION_PHONE_CALL_ATTEMPT_SUCCESS,
	payload,
});

export const verificationPhoneCallAttemptFailAction = (payload: {
	error: string;
}) => ({
	type: verificationPhoneTypes.VERIFICATION_PHONE_CALL_ATTEMPT_FAIL,
	payload,
});

export default null;
